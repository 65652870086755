import React from 'react';
import Toggle from '../../../components/toggle'
import CurrencyInput from 'react-currency-input-field';
import { useSettings } from '../../../contexts/settings';
import Core from '../../../util/core';

function Misc() {
    const settings = useSettings();

    const updateSettings = (setting) => {
        let data = {...settings.tenant, ...setting}
        Core.runAction('update_tenant_settings', data, (response) => {
            //PubSub.publish('success', 'Settings successfully saved');
            settings.refreshTenantSettings();
        })
    }

    return (
        <div className='communication-area' style={{padding:'40px'}}>
            <div>
                <label>Caller Id</label>
                <p>If hide caller id is enabled you see the phone number of the caller in your phones caller id
                    , if it is disabled you will see your groups phone number in the caller id.</p>
                <div style={{display:'flex'}}>
                    {`Display Middletwin number`}
                    <Toggle 
                        checked={settings.tenant.hideCallerId} 
                        onChange={(newVal) => {updateSettings({hideCallerId: newVal})}} />
                    {`Display caller number`}
                </div>
            </div>
            <div style={{clear:'both'}}>
                <label>Call Delay (0-30 recommended)</label>
                <p>If your phones voice mail is being triggered and answering the call before the middletwin voice mail, you can reduce the length
                    of time the system waits before sending the call to voice mail. The default is 30 seconds.
                </p>
                <div>
                    <CurrencyInput 
                        allowDecimals={false} 
                        allowNegativeValue={false} 
                        value={settings.tenant.voiceWaitDelay} 
                        onValueChange={(value) => updateSettings({voiceWaitDelay: value})} />
                </div>
            </div>
        </div>
    )
}

export default Misc
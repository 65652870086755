import React from 'react';
import Toggle from '../../components/toggle';
import { usePermissions } from 'contexts/permissions';

function Permissions(props) {
    const { permissions } = usePermissions();
    
    return (
        <div className="communication-area selected">
			<table>
                <tbody>
                    {
                        permissions.map((pm) => {
                            let isEnabled = props.permissions.find((p) => p.id == pm.id);
                            return <tr key={pm.id}><td><Toggle checked={isEnabled} onChange={(newValue) => props.onChange(pm, newValue)}/></td><td>{pm.label}</td></tr>
                        })
                    }
                </tbody>
            </table>
		</div>
    )
}

export default Permissions
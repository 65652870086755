import React from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';
import AmazingTable from 'components/amazingTable';
import { usePaymentHistory } from 'contexts/paymentHistory';
import { TableSetupProvider } from 'contexts/tableSetup';

const phCols = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "created", label: "Date", display: true, formatter: (obj) => {return moment(new Date(obj.created*1000)).format("YYYY-MM-DD");}},
    {id: 'description', label: 'Description', display: true, datum: 'description'},
    {id: "amount", label: "Amount", display: true, formatter: (obj) => {return formatCurrency(obj.amount/100, 'USD');}},
    {id: "status", label: "Status", display: true, datum: "status"}
];

function PaymentHistory() {
    const { historyLoading, history, getPaymentHistory } = usePaymentHistory();

    return (
        <TableSetupProvider tableName='paymenthistory' defaultColumns={phCols}>
            <AmazingTable 
                rows={history}
                onRefresh={getPaymentHistory}
                loading={historyLoading}
            />
        </TableSetupProvider>
    )
}

export default PaymentHistory;
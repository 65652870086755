import { useCore } from 'contexts/core';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

function AccountMessages(props) {
    const { runAction } = useCore();
    const [messageList, setMessageList] = useState([]);

    useEffect(() => {
        if (props.messages.length > 0) {
            setMessageList(props.messages);
        }
    }, [props.messages])

    const acknowledgeHandler = (msgId) => {
        runAction('acknowledge_tenant_message', {id: msgId}, response => {
            if (messageList.length === 1) {
                props.onAcknowledge();
            }
            // remove the acknowledged msg from the message list
            let msgListCopy = [...messageList];
            const msgIndex = msgListCopy.findIndex(msg => msg.id === msgId);
            if (msgIndex >= 0) {
                msgListCopy.splice(msgIndex, 1);
                setMessageList(msgListCopy);
            }
        })
    }

    const ignoreClick = (evt) => {
        evt.preventDefault();
    }

    const readLaterHandler =  () => {
        props.onReadLater();
    }

    return (
        <div className='account-messages' onClick={ignoreClick}>
            <div className='inner-area'>
                <div className='inner-title-area'>
                    <h1>Messages</h1>
                </div>
                <div className='inner-center-area'>
                    <p>Please review the following message(s) from your group administrator.</p>
                    <div className='message-list-area'>
                        <ul>
                            {messageList.map(msg => 
                                <li key={msg.id}>
                                    <h3>Message {moment(msg.created).format('YYYY-MM-DD')}</h3>
                                    <div>
                                        {msg.message} 
                                    </div>
                                    <div className='button-area'>
                                        <button onClick={() => acknowledgeHandler(msg.id)}>Acknowleged</button>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='inner-button-area'>
                    <button onClick={readLaterHandler}>Read Later</button>
                </div>
            </div>
        </div>
    )
}

export default AccountMessages;
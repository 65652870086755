import React, { useEffect, useState } from 'react';
import { useFiles } from 'contexts/files';
import ColorChooser from 'components/colorChooser';

function FolderDetails({selectedFolder, onSave}) {
    const { saveFolder } = useFiles();
    const [folderName, setFolderName] = useState('');
    const [folderColor, setFolderColor] = useState('');

    useEffect(() => {
        if (selectedFolder) {
            setFolderName(selectedFolder.label);
            setFolderColor(selectedFolder.color);
        } else {
            setFolderName('');
            setFolderColor('');
        }
    }, [selectedFolder])

    const saveFolderHandler = () => {
        let data = {label: folderName, color: folderColor}
        if (selectedFolder) {
            data.id = selectedFolder.id;
        }
        saveFolder(data, () => {
            setFolderName('');
            setFolderColor('');
            onSave();
        })
    }

    return (
        <div className='contact-preview'>
            <label>Folder name</label>
            <input type='text' value={folderName} onChange={e => setFolderName(e.target.value)} maxLength="64" />
            <label>Folder color (optional)</label>
            <ColorChooser value={folderColor} onChange={(col) => setFolderColor(col)}/>
            <div style={{marginTop:'10px'}}>
                <button onClick={saveFolderHandler}>Save</button>
            </div>
        </div>
    )
}

export default FolderDetails
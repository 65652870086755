import React, { useState } from 'react';
import moment from 'moment';
import {formatCurrency} from '_base';
import AmazingTable from 'components/amazingTable';
import MemberTypeDetails from './memberTypeDetails';
import DetailsPanel from 'components/detailsPanel';
import SidePanel from 'components/sidePanel';
import { useMemberTypes } from 'contexts/memberTypes';
import { TableSetupProvider } from 'contexts/tableSetup';

const typesColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'label', label: 'Label', display: true, datum: 'label'},
    {id: 'price', label: 'Price', display: true, formatter: (obj) => {return formatCurrency(obj.price);}},
    {id: 'purchase', label: 'Can purchase', display: true, formatter: obj => obj.purchase ? 'Yes' : 'No'},
    {id: 'allowChildren', label: 'Can Add Portal Logins', display: true, formatter: obj => obj.allowChildren ? 'Yes' : 'No'},
    {id: 'anniversaryRenewal', label: 'Anniversary Type', display: true, formatter: obj => obj.anniversaryRenewal ? 'Yes' : 'No'},
    {id: 'anniversaryDate', label: 'Anniversary Date', display: true, formatter: (o) => (o.anniversaryDate ? moment(o.anniversaryDate).format('MM/DD') : 'N/A')}
];

function MemberTypes() {
    const { loadingMemberTypes, memberTypes, loadMemberTypes, saveMemberType, deleteMemberType } = useMemberTypes();
    const [type, setType] = useState(null);
    const detailsPanelRef = React.useRef(null);

    const editType = (typeData) => {
        setType(typeData);
        detailsPanelRef.current.show();
    }
    const createType = () => {
        let data = {
            id: null,
            label: '',
            price: 0,
            purchase: true,
            allowChildren: true,
            anniversaryRenewal: false,
            anniversaryDate: '',
            teiredPricing: false
        }
        setType(data);
        detailsPanelRef.current.show();
    }
    const hideDetailsPanel = () => {
        setType(null);
    }
    const save = (data) => {
        saveMemberType(data, () => setType(null));
        detailsPanelRef.current.hide();
    }
    const remove = (rows) => {
        deleteMemberType(rows[0].id);
    }

    return (
        <div>
            <TableSetupProvider tableName='membertypes' defaultColumns={typesColumns}>
                <AmazingTable 
                    rows={memberTypes}
                    onRefresh={loadMemberTypes}
                    onRowClick={editType}
                    onCreate={createType}
                    onCreateLabel='Add member type'
                    onDelete={remove} 
                    loading={loadingMemberTypes} />
            </TableSetupProvider>
            <SidePanel title='Member Type' ref={detailsPanelRef} onHide={hideDetailsPanel} >
                {type && <MemberTypeDetails memberType={type} onSave={save} onBack={hideDetailsPanel} />}
            </SidePanel>
        </div>
    )
}

export default MemberTypes;
import React, { useEffect, useState } from 'react';
import Members from './members';
import Tags from './tags';
import MemberTypes from './memberTypes';
import { MemberTypesProvider } from 'contexts/memberTypes';
import { TagsProvider } from 'contexts/tags';
import { MembersProvider } from 'contexts/members';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faTags, faUser, faUserCog } from '@fortawesome/pro-regular-svg-icons';

export async function loader({params}) {
    return params;
}

function Membership() {
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const [tag, setTag] = useState(null)
    const [view, setView] = useState(null);

    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : null);
            setTag(loaderData.tag ? loaderData.tag : null);
        }
    }, [loaderData])

    const getTitle = () => {
        return view === 'members' ? 'Members' : view === 'tags' ? 'Member Tags' : 'Member Types'
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Members {view && ': ' + getTitle()}</div>
                </div>
            </div>
            <MemberTypesProvider>
                <MembersProvider tag={tag}>
                    <TagsProvider>
                        {!view &&
                            <div className='showLanding'>
                                <div className='showLandingTile' onClick={() => navigate('/admin/membership/members')}>
                                    <FontAwesomeIcon icon={faUserCog} size='6x'/>
                                    <h6>Members</h6>
                                </div>
                                <div className='showLandingTile' onClick={() => navigate('/admin/membership/tags')}>
                                    <FontAwesomeIcon icon={faTags} size='6x'/>
                                    <h6>Member Tags</h6>
                                </div>
                                <div className='showLandingTile' onClick={() => navigate('/admin/membership/types')}>
                                    <FontAwesomeIcon icon={faIdCard} size='6x'/>
                                    <h6>Member Types</h6>
                                </div>
                            </div>
                        }
                        {view === 'members' &&
                            <Members />
                        }
                        {view === 'tags' && 
                            <Tags />
                        }
                    </TagsProvider>
                    {view === 'types' && <MemberTypes />}
                </MembersProvider>
            </MemberTypesProvider>
        </div>
    )
}

export default Membership;
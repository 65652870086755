import { useAuditLog } from 'contexts/auditLog';
import React from 'react';
import moment from 'moment';
import AmazingTable from 'components/amazingTable';
import ReactDatePicker from 'react-datepicker';
import { TableSetupProvider } from 'contexts/tableSetup';

function AuditLog() {
    const { auditLogLoading, auditLog, getAuditLog, date, setDate } = useAuditLog();
    
    const columns = [
        {id: 'id', label:'ID', display: false, datum: 'id'},
        {id: 'date', label: 'Date', display: true, formatter: obj => {return moment(obj.date).format('YYYY-MM-DD')}},
        {id: 'event', label: 'Event', display: true, datum: 'event'},
        {id: 'user', label: 'User', display: true, formatter: obj => {return obj.userDetail ? obj.userDetail.name : 'N/A' }},
        {id: 'details', label: 'Details', display: true, datum: 'detail', style: {width: '1000px'}}
    ];

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Audit Log</div>
                </div>
            </div>
                <div style={{marginLeft: '50px'}}>Show audit logs for: <ReactDatePicker selected={date} onChange={setDate}/></div>
                <TableSetupProvider tableName='audits' defaultColumns={columns}>
                    <AmazingTable 
                        rows={auditLog}
                        loading={auditLogLoading}
                        onRefresh={getAuditLog}
                    />
                </TableSetupProvider>
        </div>
    )
}

export default AuditLog;
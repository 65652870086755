import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';

// <Toggle checked={true || false} onChange={handler(bool)} />

function Toggle(props) {
    
    const toggleHandler = (event) => {
        event.preventDefault();
        let newValue = !props.checked;
        props.onChange(newValue);
    }
    
    return(
        <span className={'toggle' + (props.checked ? '' : ' off')} onClick={toggleHandler}> 
            <FontAwesomeIcon icon={props.checked ? faToggleOn : faToggleOff} />
        </span>
    );
}

export default Toggle;
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useCore } from "./core";
import { publish } from "pubsub-js";

const ActivitiesContext = React.createContext(null);

function ActivitiesProvider({ children }) {
    const { runAction } = useCore();
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [activities, setActivities] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('year').valueOf());
    const [endDate, setEndDate] = useState(moment().endOf('year').valueOf());

    useEffect(() => {
        getActivities();
    }, [startDate, endDate]);

    const getActivities = () => {
        setLoadingActivities(true);
        runAction('get_activities', {'start': startDate, 'end': endDate}, (response) => {
            setActivities(response.activities);
            setLoadingActivities(false);
        });
    }

    const saveActivity = (activity, callback) => {
        const action = activity.id ? 'update_activity' : 'create_activity';
        runAction(action, activity,
            (result) => {
            if (result) {
                publish('success', 'The activity has been saved.');
                getActivities();
                callback(result);
            }
        });
    }

    const deleteActivity = (activitiyId) => {
        const data = {id: activitiyId};
        runAction('delete_activity', data, 
            (result) => {
                if (result) {
                    getActivities();
                }
            });
    }

    const createReminder = (data, callback) => {
        runAction('create_activity_campaign', data, response => {
            getActivities();
            callback();
        })
    }

    const provider = {
        loadingActivities,
        activities,
        getActivities,
        saveActivity,
        deleteActivity,
        startDate, setStartDate, endDate, setEndDate,
        createReminder
    };
    
    return <ActivitiesContext.Provider value={provider}>{children}</ActivitiesContext.Provider>;
}

function useActivities() {
    const context = React.useContext(ActivitiesContext);
    if (context === undefined) {
        throw new Error(`useActivities must be used within a ActivitiesProvider`);
    }
    return context;
}

export { ActivitiesProvider, useActivities }
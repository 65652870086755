import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useChannel } from '../../contexts/channel';
import Core from '../../util/core';
import ChatMessages from './ChatMessages';
import EditableDiv from './EditableDiv';
import PubSub from 'pubsub-js';

function ChatRoom(props) {
    const auth = useAuth();
    const channel = useChannel();
    const [msgToSend, setMsgToSend] = useState('');

    useEffect(() => {
        let chatSub = PubSub.subscribe('chat', newChatHandler);
        return () => {
            PubSub.unsubscribe(chatSub);
        }
    }, [])

    // handle a new chat
    const newChatHandler = (topic, message) => {
        channel.getMessages();
    }

    const sendMessage = () => {
        let data = {
            affiliate: auth.group.id,
            sender: auth.user.user_id,
            message: msgToSend,
            mediaUrl: '',
            contentType: '',
            channel: channel.channel.id,
        }
        Core.runAction('create_chat_message', data, response => {
            setMsgToSend('');
            channel.getMessages();
        })
    }

    const keyUpHandler = (e) => {
        let activity = msgToSend.length === 0 ? 'none' : 'typing';
        if (e.key === 'Enter' && !e.shiftKey) {
            sendMessage();
            activity = 'none';
        }
        let data = {channel: channel.channel.id, activity: activity}
        Core.runAction('update_chat_activity', data, response => {
            // nothing to do with response
        })
    }

    return (
        <div className='rightPaneView'>
            <div className='heading'>
                <div className='headingLeft'>
                </div>
                <div className='headingCenter'>Chat Room</div>
                <div className='headingRight'>
                </div>
            </div>
            <ChatMessages />
            <div className='chat-actions'>
                <div className='msg-action'>
                    <EditableDiv value={msgToSend} onChange={html => setMsgToSend(html)} onKeyUp={keyUpHandler}/>
                    <div className='button-holder'>
                        <button disabled={msgToSend.length === 0} onClick={sendMessage}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatRoom;
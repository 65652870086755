import React from 'react'
import 'style/amazingTabs.scss';

function AmazingTabs({tabs, selected, onClick}) {
    
    return (
        <div className='tab-container'>
            {tabs.map((tab) => 
                <div 
                    className={'tab' + (selected === tab.id ? ' selected' : '')} 
                    id={tab.id} 
                    key={tab.id} 
                    onClick={() => {onClick(tab)}}>
                        {tab.label}
                </div>
            )}
        </div>
    )
}

export default AmazingTabs
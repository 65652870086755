import { faTimes, faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { subscribe } from 'pubsub-js';
import 'style/notifications.scss';

const TYPES = [
    {topic: 'notification', icon: faExclamationCircle, iconColor: '#00355E'},
    {topic: 'error', icon: faExclamationTriangle, iconColor: '#B00020'},
    {topic: 'success', icon: faCheckCircle, iconColor: '#21AA3E'},
    {topic: 'warning', icon: faExclamationTriangle, iconColor: '#C67917'},
];

function Notifications() {
    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {
        TYPES.forEach((type) => 
            subscribe(type.topic, (msg, data) => addNotification(type, msg, data)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (notificationList.length > 0) {
            setTimeout(() => {
                let list = [...notificationList];
                list.splice(0, 1);
                setNotificationList(list);
            }, 5000);
        }
    }, [notificationList])

    const addNotification = (type, topic, msg) => {
        console.log(type + ' ' + topic + ' ' + msg);
        let list = [...notificationList];
        list.push({message: msg, type: type});
        setNotificationList(list);
    }

    const dismiss = (index) => {
        let list = [...notificationList];
        list.splice(index, 1);
        setNotificationList(list);
    }

    return (
        <div className='notifications'>
            <ul>
                {notificationList.map((n, idx) => 
                    <li key={idx} className={n.type.topic}>
                        <div className='message'>
                            <FontAwesomeIcon icon={n.type.icon} style={{color:n.type.iconColor,paddingRight:'5px'}}/>
                            {n.message}
                        </div>
                        <div className='dismiss' role='button' onClick={() => dismiss(idx)}><FontAwesomeIcon icon={faTimes}/> Dismiss</div>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Notifications;
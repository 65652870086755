import React, {useState, useEffect} from 'react';
import TimePicker from 'components/timePicker';
import CurrencyInput from 'react-currency-input-field';
import { useWorkers } from 'contexts/workers';
import { useShifts } from 'contexts/shifts';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function ShiftDetails({shift, schedule, onSave}) {
    const { workers } = useWorkers();
    const { saveShift } = useShifts();
    const [quantity, setQuantity] = useState(1);
    const [shiftDate, setShiftDate] = useState(moment().toDate());
    const [start, setStart] = useState(8*60*60*1000);
    const [startMinute, setStartMinute] = useState(0);
    const [duration, setDuration] = useState(3);
    const [worker, setWorker] = useState('0');
    
    useEffect(() => {
        if (shift) {
            setShiftDate(moment(shift.shiftDate).toDate())
            setStart(shift.start*60*60*1000);
            setStartMinute(shift.startMinute);
            setDuration(shift.duration);
            setWorker(shift.worker);
        }
    }, [shift]);

    const save = () => {
        let data = {
            shiftDate: moment(shiftDate).valueOf(),
            start: start/(60*60*1000),
            startMinute,
            duration,
            quantity
        }
        if (shift) {
            data.id = shift.id;
            data.schedule = shift.schedule;
        } else {
            data.schedule = schedule.id;
        }
        if (worker != '0') {
            data.worker = worker;
        }
        saveShift(data, () => {
            setQuantity(1);
            setShiftDate(moment().toDate());
            setStart(8*60*60*1000);
            setStartMinute(0);
            setDuration(3);
            setWorker('0');
            onSave();
        });
    }

    return (
        <div>
            <div className='contact-preview'>
                {shift ?
                    <>
                        <label>Worker</label>
                        <select value={worker} onChange={(evt) => setWorker(evt.target.value)}>
                            <option value='0'>Unassigned</option>
                            {workers.map(w => <option value={w.id} key={w.id}>{w.fname + ' ' + w.lname}</option>)}
                        </select>
                    </>
                :
                    <>
                        <label>Quantity (number of workers for this shift)</label>
                        <CurrencyInput allowDecimals={false} allowNegativeValue={false} value={quantity} onValueChange={(value) => setQuantity(value)} />
                    </>
                }
                <label>Date</label>
                <DatePicker selected={moment(shiftDate).toDate()} onChange={setShiftDate} />
				<label>Start hour</label>
                <TimePicker value={start} onChange={(t) => setStart(t)}/>
                <label>Start minute</label>
                <input type='number' value={startMinute} onChange={evt => setStartMinute(evt.target.value)} min={0} max={59} step={5}/>
				<label>Duration (hours)</label>
                <input type='number' value={duration} onChange={(evt) => setDuration(evt.target.value)} min={1} max={8} step={.25} />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default ShiftDetails;
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Toggle from 'components/toggle';
import { publish } from 'pubsub-js';
import { useApiKey } from 'contexts/apiKey';

function APIKeyDetails(props) {
    const { apikey, secret, keyPermissions, setKeyPermissions, saveKey } = useApiKey();

    const [copied, setCopied] = useState(false);

    const generate = () => {
        saveKey(() => {props.onDone()});
        
    }

    const togglePermission = (id, enabled) => {
        const perms = [...keyPermissions];
        let idx = perms.findIndex((p) => p.id === id);
        if (idx !== -1) {
            perms[idx].enabled = enabled;
        }
        setKeyPermissions(perms);
    }

    const copySecretToClipboard = () => {
        if (!navigator.clipboard) {
            publish('warning', 'Unable to copy key to clipboard');
        } else {
            navigator.clipboard.writeText(secret);
            setCopied(true);
            setTimeout(() => setCopied(false), 5*1000);
        }
    }

    return (
        <div className='contact-preview'>
            <p>API Keys consist of 2 parts, a public key and a secret key. See our developer documentation to 
            learn how to use API keys with the platform API.</p>
            {props.apikey === null && 
                <p style={{fontWeight:'bold',textDecoration:'underline'}}>IMPORTANT! The secret key will only be revealed 
                to you when you save the key the first time. After that you will not be able to retrieve it. Make 
                sure you save it in a safe place.</p>
            }
            <label>Public Key</label>
            <div>{apikey || 'Click Generate button'}</div>
            {props.apikey === null &&
                <span>
                    <label>Secret Key</label>
                    <div style={{color:'red',fontWeight:'bold',wordWrap:'break-word',wordBreak:'break-all'}}>{secret || 'Click Generate button'}</div>
                    {secret && <FontAwesomeIcon icon={faClipboard} onClick={copySecretToClipboard} />}
                    {copied && <span style={{fontSize:'12px',marginLeft:'5px'}}>Copied</span>}
                </span>
            }
            <hr style={{margin:'20px 0'}}/>
            <div style={{textAlign:'center'}}><h3>API Permissions</h3></div>
            <p>Permissions allow you to control what programs using this API key can and cannot do within this account.</p>
            <table style={{width:'250px',margin:'auto'}}>
                <tbody>
                    {keyPermissions.map(p => 
                        <tr key={p.id}>
                            <td>{p.label}</td>
                            <td><Toggle checked={p.enabled} onChange={(val) => togglePermission(p.id, val)} /></td>
                        </tr>)
                    }
                </tbody>
            </table>
            <div>
                <button onClick={generate} disabled={secret}>{apikey ? 'Save' : 'Generate'}</button>
                <button onClick={props.onDone}>Done</button>
            </div>
        </div>
    )
}

export default APIKeyDetails;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ParticipantContext = React.createContext(null);

function ParticipantsProvider({eventId, children}) {
    const { runAction } = useCore();
    const [participants, setParticipants] = useState([]);
    const [reservations, setReservations] = useState([]);

    useEffect(() => {
        getParticipants();
    }, [])

    const getParticipants = () => {
        runAction('get_all_participants', {event: eventId}, (response) => {
            setParticipants(response.participants);
            setReservations(response.reservations);
        })
    }

    const createParticipant = (participantData, seat) => {
        if (participantData.id) {
            const data = {eventId, vendor: {...participantData, eventId}};
            runAction('update_participant', data, () => {
                getParticipants();
            })
        }
        const data = {eventId, vendor: {...participantData, eventId}, reservations: [seat]}
        runAction('create_participant', data, () => {
            getParticipants();
        });
    }

    const createReservation = (participantId, seat) => {
        const data = {event: eventId, participant: participantId, seats: [seat]}
        runAction('create_reservation', data, () => {
            getParticipants();
        })
    }

    const deleteReservation = (reservationId) => {
        runAction('delete_reservation', {id: reservationId}, (response) => {
            if (response.success) {
                getParticipants();
            }
        })
    }

    const provider = {
        participants,
        reservations,
        createParticipant,
        createReservation,
        deleteReservation
    }

    return <ParticipantContext.Provider value={provider}>{children}</ParticipantContext.Provider>
}

function useParticipants() {
    const context = React.useContext(ParticipantContext);
    if (!context) {
        throw new Error('useParticipants must be used within a ParticipantsProvider');
    }
    return context;
}

export { ParticipantsProvider, useParticipants }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const StripePayoutContext = React.createContext(null);

function StripePayoutProvider({bankId, children}) {
    const { runAction, runCSVAction } = useCore();
    const [payoutsLoading, setPayoutsLoading] = useState(true);
    const [payouts, setPayouts] = useState([]);

    useEffect(() => {
        getPayouts();
    }, [])

    const getPayouts = () => {
        setPayoutsLoading(true);
        runAction('stripe_get_payouts', {bankId}, response => {
            const stripePayouts = response.payouts;
            setPayouts(stripePayouts.map((poJson) => JSON.parse(poJson)));
            setPayoutsLoading(false);
        })
    }

    const downloadCSV = () => {
        runCSVAction('stripe_get_payouts', {bankId, format:'csv'}, response => {
            const blob = new Blob([response], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            window.location.assign(url);
        });
    }

    const provider = {
        payoutsLoading,
        payouts,
        downloadCSV,
    }

    return <StripePayoutContext.Provider value={provider}>{children}</StripePayoutContext.Provider>
}

function useStripePayouts() {
    const context = React.useContext(StripePayoutContext);
    if (!context) {
        throw new Error('useStripePayouts must be used within a StripePayoutsProvider');
    }
    return context;
}

export { StripePayoutProvider, useStripePayouts }
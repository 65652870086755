import React, { useEffect, useState } from 'react';
import Toggle from 'components/toggle';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { TableSetupProvider } from 'contexts/tableSetup';

const VendorsContext = React.createContext(null);

function VendorsProvider({vendorList, onChange, children}) {
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        let _vendors = [...vendorList];
        _vendors.forEach((v, k) => v._key=k);
        setVendors(_vendors);
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(vendors);
        }
    }, [vendors])

    const addVendor = (item) => {
        const maxKey = vendors.length > 0 ? Math.max(...vendors.map(v => v._key)) : 0;
        item._key = maxKey + 1;
        setVendors([...vendors, item]);
    }

    const updateVendor = (item) => {
        const idx = vendors.findIndex(v => v._key === item._key);
        if (idx >= 0 ) {
            let _vendors = [...vendors];
            _vendors.splice(idx, 1, item);
            setVendors(_vendors);
        }
    }

    const removeVendors = (items) => {
        let _vendors = [...vendors];
        items.forEach(i => {
            let idx = _vendors.findIndex(v => v._key === i._key);
            if (idx > 0) {
                _vendors.splice(idx, 1);
            }
        });
        setVendors(_vendors);
    }

    const provider = {
        vendors,
        addVendor,
        updateVendor,
        removeVendors
    }

    return <VendorsContext.Provider value={provider}>{children}</VendorsContext.Provider>
}

function useVendors() {
    const context = React.useContext(VendorsContext);
    if (!context) {
        throw new Error('useVendors must be used within an VendorsContext provider');
    }
    return context;
}

export { VendorsProvider, Vendors }

const columns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'eventId', label: 'Event ID', display: false, datum: 'eventid'},
    {id: 'location', label: 'Location', display: true, datum: 'location'},
    {id: 'food', label: 'Food', display: true, formatter: obj => {return obj.food ? 'Yes': 'No'}},
    {id: 'name', label: 'Name', display: true, datum: 'name'},
    {id: 'description', label: 'Description', display: true, datum: 'description'},
];

function Vendors({eventId}) {
    const vendors = useVendors();
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [food, setFood] = useState(false);
    const [editingVendor, setEditingVendor] = useState(null);
    const vendorPanel = React.useRef();

    const selectVendor = (row) => {
        setName(row.name);
        setLocation(row.location);
        setDescription(row.description);
        setFood(row.food);
        setEditingVendor(row);
        vendorPanel.current.show();
    }

    const saveVendor = () => {
        if (editingVendor) {
            const vendor = {...editingVendor, eventId, name, location, description, food};
            vendors.updateVendor(vendor);
        } else {
            const vendor = {eventId, name, location, description, food};
            vendors.addVendor(vendor);
        }
        onCloseVendorPanel();
    }

    const onCloseVendorPanel = () => {
        setName('');
        setLocation('');
        setDescription('');
        setFood(false);
        setEditingVendor(null);
        vendorPanel.current.hide();
    }

    const deleteVendors = (rows) => {
        vendors.removeVendors(rows);
    }

    return (
        <>
            <TableSetupProvider tableName='vendors' defaultColumns={columns}>
                <AmazingTable 
                    rows={vendors.vendors}
                    onRowClick={selectVendor}
                    onRowSelect={() => {}}
                    onCreate={() => vendorPanel.current.show()}
                    onCreateLabel='Add vendor'
                    onDelete={deleteVendors} />
            </TableSetupProvider>
            <SidePanel title='Vendor' ref={vendorPanel}>
                <div>
                    <label>Name</label><br/>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength={64} />
                </div>
                <div>
                    <label>Location</label><br/>
                    <input type='text' value={location} onChange={e => setLocation(e.target.value)} maxLength={64} />
                </div>
                <div>
                    <label>Description</label><br/>
                    <textarea
                        value={description} 
                        onChange={e => setDescription(e.target.value)}
                        style={{height:'200px',width:'100%'}} 
                        maxLength='2048'></textarea>
                </div>
                <div>
                    <label>Food Vendor</label>
                    <Toggle checked={food} 
                            onChange={newVal => setFood(newVal)}/>
                </div>
                <button onClick={onCloseVendorPanel}>Cancel</button>
                <button onClick={saveVendor}>{editingVendor ? 'Modify' : 'Add'}</button>
            </SidePanel>
        </>
    )
}

import React, { useState } from 'react';
import { formatPhoneNumber } from '../../_base';

function Communication(props) {
    const [reason, setReason] = useState('');

    const handleClear = () => {
        props.onMessageAction('clear');
    }
    
    const handleRelease = () => {
        props.onMessageAction('release');
    }

    const handleReject = () => {
        props.onMessageAction('reject', reason)
    }

    return (
        <div>
            {props.comm &&
            <div className='contact-preview'>
                <label>From Number</label>
                <div>{formatPhoneNumber(props.comm.from_number)}</div>
                <label>To Number</label>
                <div>{formatPhoneNumber(props.comm.to_number)}</div>
                <label>Message Direction</label>
                <div>{props.comm.direction}</div>
                <label>Message is holding</label>
                <div>{props.comm.holding ? 'Yes' : 'No'}</div>
                <label>Message</label>
                {props.comm.messages.map(m => <div key={m.id}>{m.sms}</div>)}
            </div>
}
            <div className='buttonsHolder'>
				<button onClick={handleClear}>Clear</button>
                <button onClick={handleRelease}>Release</button>
			</div>
            <hr/>
                <div className='contact-preview'>
                    <label>Rejection Reason</label>
                    <input type='text' value={reason} onChange={e => setReason(e.target.value)} maxLength='160'/>
                </div>
            <div>
                <button onClick={handleReject}>Reject</button>
            </div>
        </div>
    )
}

export default Communication;
import React from 'react';
import { useTimezones } from 'contexts/timezones';

function Timezones(props) {
    const  { timezones } = useTimezones();

    return (
        <select name={props.name} onChange={props.onChange} value={props.value}>
            {
                timezones.map((tz) => <option value={tz.id} key={tz.id}>{tz.label}</option>)
            }
        </select>
    )
}

export default Timezones
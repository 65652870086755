import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SchedulesContext = React.createContext(null);

function SchedulesProvider({eventId, children}) {
    const { runAction } = useCore();
    const [schedulesLoading, setSchedulesLoading] = useState(true);
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        getSchedules();
    }, [eventId])

    const getSchedules = () => {
        setSchedulesLoading(true);
        runAction('get_all_schedules', {eventId}, response => {
            setSchedules(response.schedules);
            setSchedulesLoading(false);
        })
    }

    const getSchedule = (schedId) => {
        return schedules.find(s => s.id == schedId);
    }

    const saveSchedule = (data, callback) => {
        const action = data.id ? 'update_schedule' : 'create_schedule';
        runAction(action, data, () => {
            callback();
            getSchedules();
        });
    }

    const copySchedule = (data, callback) => {
        runAction('copy_schedule', data, () => {
            callback();
            getSchedules();
        })
    }

    const deleteSchedules = (ids, callback) => {
        runAction('delete_schedule', {ids}, response => {
            callback();
            getSchedules();
        })
    }

    const provider = {
        schedulesLoading,
        eventId,
        schedules,
        reload: () => getSchedules(),
        getSchedules,
        getSchedule,
        saveSchedule,
        copySchedule,
        deleteSchedules,
    };

    return <SchedulesContext.Provider value={provider}>{children}</SchedulesContext.Provider>
}

function useSchedules() {
    const context = React.useContext(SchedulesContext);
    if (!context) {
        throw new Error('useSchedule must be used within a ScheduleProvider');
    }
    return context;
}

export { SchedulesProvider, useSchedules }
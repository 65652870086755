import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import 'style/sidePanel.scss';

class SidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: false
        }
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }
    show() {
        this.setState({showing: true});
    }
    hide() {
        this.setState({showing: false});
        if (this.props.onHide) {
            this.props.onHide();
        }
    }
    render() {
        const {showing} = this.state;
        const openStyle = {right:'0', transition:'right 0.5s'};
        const closeStyle = {right:'-410px', transition:'right 0.5s'};
        return (
            <div className='side-panel' style={showing ? openStyle : closeStyle}>
                <div className='focus-header'>
                    <div>{this.props.title}</div>
                    <div className='closer' onClick={this.hide}><FontAwesomeIcon icon={faTimes}/></div>
                </div>
	            <div className='focus-contents'>{this.props.children}</div>
            </div>
        );
    }
}

export default SidePanel;
import React, {useState, useEffect} from 'react';
import { useClasses } from 'contexts/classes';
import { useBallot } from 'contexts/ballot';
import 'style/ballot.scss';

var votes = new Map();

function Ballot(props) {
    const { classes } = useClasses();
    const { createBallot } = useBallot();
    const [classList, setClassList] = useState(null)

    useEffect(() => {
        votes.clear();
        if (classes.length > 0) {
            let _classes = [...classes];
            _classes.sort(function(a, b) {return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;});
            setClassList(
                _classes.map((c, idx) => 
                    <li key={c.id}>
                        <input id={idx} data-classid={c.id} placeholder={c.id} style={{width:'100px'}} onKeyUp={keyUpHandler} onBlur={saveVote}/>
                    </li>
                )
            )
            focusFirstInput();
        }
    }, [classes])

    let keyUpHandler = (evt) => {
        if (evt.key === 'Enter') {
            // place focus on the next input
            let id = parseInt(evt.target.id, 10);
            let nextInputElem = document.getElementById(id + 1);
            if (nextInputElem) {
                nextInputElem.focus();
            }
        }
    }

    let saveVote = (evt) => {
        // save the vote
        let classId = evt.target.getAttribute('data-classid');
        let classVal = evt.target.value;
        if (classVal.length > 0) {
            votes.set(classId, classVal);
        }
    }

    let onSubmit = () => {
        let data = {event: props.eventId, votes: [], pc: null};
        for( const entry of votes ) {
            data.votes.push(entry[1]);
        }
        createBallot(data, () => {
            let i = 0;
            let elem = document.getElementById(i)
            while (elem) {
                elem.value = '';
                elem = document.getElementById(++i);
            }
            focusFirstInput();
            votes.clear();
        })
    }

    let focusFirstInput = () => {
        let elem = document.getElementById('0');
        if (elem) {
            elem.focus();
        }
    }

    return (
        <div style={{margin:'20px'}}>
            <div style={{fontSize:'20px',height:'30px',margin:'10px'}}>Voting Ballot</div>
            <div className='settingsSection'>
                <p>Fill out a voting ballot by typing in the number of each registration that you 
                want to place a vote for. Enter key or tab moves cursor to the next class. 
                Click the "Submit Ballot" button at the bottom of page when ballot is ready to submit.
                Once submitted the ballot will be cleared and you can enter another ballot. You
                do not need to use the "Back" button after each ballot is submitted.</p>
                <ul className='ballot_list' style={{width:'100%'}} data-dojo-attach-point="ballot">
                    {classList}
                </ul>
            </div>
            <div className='toolbar'>
                <div className='contents'>
                    Click here to submit ballot --&gt;
                    <button onClick={onSubmit}>Submit Ballot</button>
                    <button onClick={props.cancelBallot}>Back</button>
                </div>
            </div>
        </div>
    )
}

export default Ballot;
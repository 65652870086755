import React, { useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Toggle from 'components/toggle';
import CurrencyInput from 'react-currency-input-field';

import "react-datepicker/dist/react-datepicker.css";
import { useReceipts } from 'contexts/receipts';

const DEFAULT_RECEIPT = {
    date: Date.now(),
    description: '',
    amount: 0,
    fee: 0,
    paid: false,
    status: '0',
    bt_resp_code: '1000',
    bt_resp_text: 'Approved',
    bt_setl_code: '',
    bt_setl_text: '',
    contact: 0,
    payoutStatus: ''
}

function  NewReceipt (props) {
    const { createReceipt } = useReceipts();
    const [receipt, setReceipt] = useState({...DEFAULT_RECEIPT});

    const setDate = (date) => {
        let _receipt = Object.assign({}, receipt);
        _receipt.date = date.getTime();
        setReceipt(_receipt);
    }

    const handleChange = (setting, value) => {
        let _receipt = Object.assign({}, receipt);
        if (typeof setting === 'string') {
            _receipt[setting] = value;
        } else {
            _receipt[setting.target.name] = setting.target.value;
        }
        setReceipt(_receipt);
    }

    const saveHandler = () => {
        createReceipt(receipt, () => {
            props.onSave();
        });
    }
    
    return(
        <div className="contact-preview">
            <label>Date</label>
            <div><DatePicker selected={moment(receipt.date).toDate()} onChange={setDate}/></div>
            <label>Description</label>
            <div><input type='text' name='description' value={receipt.description} onChange={handleChange} maxLength="128" /></div>
            <label>Amount</label>
            <div><CurrencyInput prefix='$' value={receipt.amount} onValueChange={(value) => handleChange('amount', value)} /></div>
            <label>Fee</label>
            <div><CurrencyInput prefix='$' value={receipt.fee} onValueChange={(value) => handleChange('fee', value)} /></div>
            <label>Paid</label>
            <div><Toggle checked={receipt.paid} onChange={(newValue) => handleChange('paid', newValue)} /></div>
            <label>Status</label>
            <select name='statusSelect' value={receipt.status} onChange={handleChange}>
                <option value='0'>0 - Pending</option>
                <option value='1'>1 - Paid</option>
                <option value='2'>2 - Refunded</option>
            </select>
            <label>Braintree Response Code</label>
            <div><input type='text' name='bt_resp_code' value={receipt.bt_resp_code} maxLength="64" onChange={handleChange} /></div>
            <label>Braintree Response Text</label>
            <div><input type='text' name='bt_resp_text' value={receipt.bt_resp_text} maxLength="64" onChange={handleChange} /></div>
            <label>Braintree Settlement Code</label>
            <div><input type='text' name='bt_setl_code' value={receipt.bt_setl_code} maxLength="64" onChange={handleChange} /></div>
            <label>Braintree Settlement Text</label>
            <div><input type='text' name='bt_setl_text' value={receipt.bt_setl_text} maxLength="64" onChange={handleChange} /></div>
            <label>Contact</label>
            <div><CurrencyInput allowDecimals={false} allowNegativeValue={false} value={receipt.contact} onValueChange={(value) => handleChange('contact', value)} /></div>
            <label>PayoutStatus</label>
            <div><input type='text' name='payoutStatus' value={receipt.payoutStatus} onChange={handleChange} maxLength="12" /></div>
            <div className='button-container'>
                <button onClick={saveHandler}>Save</button>
            </div>
        </div>
    )
}

export default NewReceipt;
import React, { useEffect, useRef, useState } from 'react';
import {isSupported} from 'util/contentType';
import PubSub from 'pubsub-js';
import './pageImage.scss';

function PageImage({value, width, height, onChange}) {
    const [avatar, setAvatar] = useState('');
    const fileInput = useRef(null);
    const imageCanvas = useRef(null);

    const [bufferedImage, setBufferedImage] = useState(null);

    useEffect(() => {
        if (value) {
            setAvatar(value);
        }
    }, [value])

    const selectImage = () => {
        fileInput.current.click();
    }

    const handleFileSelection = (evt) => {
        var files = evt.target.files;
		// eslint-disable-next-line no-cond-assign
        for (var i = 0, f; f = files[i]; i++) {
            var name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                var img = new Image();
                // eslint-disable-next-line no-loop-func
                img.onload = () => bufferImage(img);
                img.crossOrigin = 'Anonymous';
                img.src = URL.createObjectURL(f);
            } else {
                PubSub.publish('warning', f.name + ' is not a supported image type.');
            }
        }
    }

    const bufferImage = (imageObj) => {
        setBufferedImage(imageObj);
    }

    useEffect(() => {
        if (bufferedImage != null) {
        let imgCanvas = imageCanvas.current;
        var context = imgCanvas.getContext('2d');
        context.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        
        var imageAspectRatio = bufferedImage.width / bufferedImage.height;
        var canvasAspectRatio = imgCanvas.width / imgCanvas.height;
        var renderableHeight, renderableWidth, xStart, yStart;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if(imageAspectRatio < canvasAspectRatio) {
            renderableHeight = imgCanvas.height;
            renderableWidth = bufferedImage.width * (renderableHeight / bufferedImage.height);
            xStart = (imgCanvas.width - renderableWidth) / 2;
            yStart = 0;
        }
        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        else if(imageAspectRatio > canvasAspectRatio) {
            renderableWidth = imgCanvas.width
            renderableHeight = bufferedImage.height * (renderableWidth / bufferedImage.width);
            xStart = 0;
            yStart = (imgCanvas.height - renderableHeight) / 2;
        }

        // Happy path - keep aspect ratio
        else {
            renderableHeight = imgCanvas.height;
            renderableWidth = imgCanvas.width;
            xStart = 0;
            yStart = 0;
        }
        context.drawImage(bufferedImage, xStart, yStart, renderableWidth, renderableHeight);
        // add the image to the images array
        let image = imgCanvas.toDataURL("image/png", 1.0);
        onChange(image);
        setBufferedImage(null);
        }
    }, [bufferedImage])

    return (
        <>
            <input type='file' style={{display:'none'}} ref={fileInput} onChange={handleFileSelection} />
            <div className='avatar-container' style={{width:width+'px', height:height+'px'}}>
                <div style={{width:width+'px', height:height+'px', backgroundImage:`url(${avatar})`,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}></div>
                {bufferedImage !== null &&
                    <canvas width={bufferedImage.width} height={bufferedImage.height} ref={imageCanvas} style={{display:'none'}}></canvas>
                }
            </div>
            <div className='avatar-button-container'>
                <button className='linkButton' onClick={selectImage}>Change Image</button>
            </div>
        </>
    )
}



export default PageImage;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const PageContext = React.createContext(null);

function PageProvider({children}) {
    const { runAction } = useCore();
    const [pagesLoading, setPagesLoading] = useState(true);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        getPages();
    }, [])

    const getPages = () => {
        setPagesLoading(true);
        runAction('get_pages', {}, response => {
            setPages(response.pages);
            setPagesLoading(false);
        });
    }

    const savePage = (pageData) => {
        const action = pageData.id ? 'update_page' : 'create_page';
        runAction(action, pageData, response => {
            getPages();
        })
    }

    const deletePages = (ids) => {
        runAction('delete_pages', {ids}, () => {
            getPages();
        })
    }

    const provider = {
        pagesLoading,
        pages,
        getPages,
        savePage,
        deletePages,
    }

    return <PageContext.Provider value={provider}>{children}</PageContext.Provider>
}

function usePage() {
    const context = React.useContext(PageContext);
    if (!context) {
        throw new Error('usePage must be used within a PageProvider');
    }
    return context;
}

export { PageProvider, usePage }
import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import Core from 'util/core';

export async function loader({params}) {
    return params;
}

function Vote() {
    const loaderData = useLoaderData();
    const [message, setMessage] = useState('Please Wait...');

    useEffect(() => {
        let voterId = window.localStorage.getItem('voterId');
        if (!voterId) {
            voterId = Date.now();
            window.localStorage.setItem('voterId', voterId);
        }
        let data = {voterId: voterId, tenantUUID: loaderData.tenant, event: loaderData.event, id: loaderData.car}
        Core.runNoAuthAction('vote', data, (response) => {
            setMessage(response.message);
        });
    }, [loaderData.event, loaderData.tenant, loaderData.car]);

    return (
        <div>
            <div style={{textAlign:'center'}}>{message}</div>
        </div>
    )
}

export default Vote;
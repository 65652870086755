import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';
import Core from '../util/core';
import { useCore } from './core';

const ChatUsersContext = React.createContext(null);

function ChatUsersProvider({children}) {
    const { runAction } = useCore();
    const auth = useAuth();
    const [users, setUsers] = useState([]);

    const getChatUsers = () => {
        runAction('get_chat_users', {affiliate: auth.group.id}, response => {
            setUsers(response.users);
        })
    }

    useEffect(() => {
        getChatUsers();
    }, [])

    const getUser = (userId) => {
        return users.find(usr => usr.user_id === userId);
    }

    const provider = {
        users,
        getUser
    }

    return <ChatUsersContext.Provider value={provider}>{children}</ChatUsersContext.Provider>
}

function useChatUsers() {
    const context = React.useContext(ChatUsersContext);
    if (!context) {
        throw new Error('useChatUsers must be used in a ChatUsersProvider');
    }
    return context;
}

export { ChatUsersProvider, useChatUsers }
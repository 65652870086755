import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const HealthContext = React.createContext(null);

function HealthProvider({children}) {
    const { runAction } = useCore();
    const [healthLoading, setHealthLoading] = useState(true);
    const [health, setHealth] = useState({});

    useEffect(() => {
        runHealthCheck();
    }, [])

    const runHealthCheck = () => {
        setHealthLoading(true);
        runAction('run_health_check', {}, response => {
            setHealth(response);
            setHealthLoading(false)
        })
    }

    const provider = {
        healthLoading,
        health,
        runHealthCheck
    }

    return <HealthContext.Provider value={provider}>{children}</HealthContext.Provider>
}

function useHealth() {
    const context = React.useContext(HealthContext);
    if (!context) {
        throw new Error('useHealth must be used within a HealthProvider');
    }
    return context;
}

export { HealthProvider, useHealth }
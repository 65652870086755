import { useCore } from 'contexts/core';
import React, {useState, useEffect} from 'react'

function WaitMusic(props) {
    const { runAction } = useCore();
    const [music, setMusic] = useState([]);

    useEffect(() => {
        runAction('get_wait_music', {}, 
            (response) => {
                if (response) {
                    setMusic(response.music);
                }
            })
    }, []);

    return (
        <select value={props.value} onChange={(evt) => props.onChange(music.find((m) => m.id === evt.target.value))}>
            {
                music.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)
            }
        </select>
    )
}

export default WaitMusic;
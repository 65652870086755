import React, { useRef, useState } from 'react'
import AmazingTable from 'components/amazingTable'
import SidePanel from 'components/sidePanel'
import Login from './login';
import { useLogins } from 'contexts/logins';
import { TableSetupProvider } from 'contexts/tableSetup';

const statusFormatter = (obj) => {
    let resp = 'Unknown';
    switch (obj.status) {
    case 0: resp = "Inactive"; break;
    case 1: resp = "Active"; break;
    case 2: resp = "Temporary"; break;
    case 3: resp = "Review"; break;
    default: break;
    }
    return resp;
};

const loginColumns = [
    {id: "id", label:"ID", display: false, datum: "user_id"},
    {id: "name", label: "Name", display: true, datum: "name"},
    {id: "email", label: "Email", display: true, datum: "email"},
    {id: "mfatype", label: "Multi-Factor Authentication", display: true, datum: "mfaType"},
    {id: "mobile", label: "Mobile", display: true, datum: "mobile"},
    {id: "status", label: "Status", display: true, formatter: statusFormatter}
];

function Logins(props) {
    const { loginsLoading, logins, getLogins, saveLogin, deleteLogins} = useLogins();
    const [selectedLogin, setSelectedLogin] = useState(null);
    const detailsPanel = useRef(null);

    const selectLoginHandler = (login) => {
        setSelectedLogin(login);
        detailsPanel.current.show();
    }

    const createHandler = () => {
        setSelectedLogin(null);
        detailsPanel.current.show();
    }

    const deleteHandler = (logins) => {
        deleteLogins(logins);
    }

    const saveHandler = (data) => {
        if (selectedLogin) {
            data.id = selectedLogin.user_id;
        }
        saveLogin(data, () => {
            detailsPanel.current.hide();
            setSelectedLogin(null);
        });
    }
    
    return (
        <div>
            <TableSetupProvider tableName='logins' defaultColumns={loginColumns}>
                <AmazingTable 
                    rows={logins}
                    onRefresh={getLogins}
                    onRowClick={selectLoginHandler}
                    onCreate={createHandler}
                    onDelete={deleteHandler}
                    loading={loginsLoading} />
            </TableSetupProvider>
            <SidePanel title='Login Details' ref={detailsPanel}>
                <Login login={selectedLogin} context={props.type} resourceId={props.resourceId} onSave={saveHandler} />
            </SidePanel>
        </div>
    )
}

export default Logins
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Toggle from 'components/toggle';
import CurrencyInput from 'react-currency-input-field';
import Loading from 'components/loading';
import { formatCurrency } from '_base';
import { useSubscriptionTypes } from 'contexts/subscriptionTypes';
import { useSubscription } from 'contexts/subscription';

function Subscription() {
    const { types } = useSubscriptionTypes();
    const { subscriptionLoading, subscription, add1Month, add1Year, saveSubscription } =  useSubscription();
    const [annualRenewal, setAnnualRenewal] = useState(true);
    const [renewalAmount, setRenewalAmount] = useState(0);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [expiration, setExpiration] = useState(moment().valueOf());
    const [subscriptionTypeId, setSubscriptionTypeId] = useState(null);
    const [updated, setUpdated] = useState(null);

    useEffect(() => {
        if (subscription) {
            setAnnualRenewal(subscription.annualRenewal);
            setRenewalAmount(subscription.renewalAmount);
            setTransactionAmount(subscription.transactionAmount);
            setExpiration(subscription.expiration);
            setSubscriptionTypeId(subscription.subscriptionTypeId);
            setUpdated(subscription.updated);
        }
    }, [subscription]);

    const add1MonthHandler = () => {
        add1Month();
    }

    const add1YearHandler = () => {
        add1Year();
    }
    
    const saveHandler = () => {
        const settings = {annualRenewal, renewalAmount, transactionAmount, expiration, subscriptionTypeId};
        saveSubscription(settings);
    }

    return (
        <div style={{display:'flex',flexFlow:'column nowrap'}}>
            {subscriptionLoading ? <Loading /> : 
            <div className='contact-preview'>
                <label>Renew Annually (10% discount automatic applied)</label>
                <div><Toggle checked={annualRenewal} onChange={setAnnualRenewal} /></div>
                <label>Monthly Renewal Amount</label>
                <CurrencyInput prefix='$' value={renewalAmount} onValueChange={setRenewalAmount} />
                <label>Transaction Service Charge Amount</label>
                <CurrencyInput prefix='$' value={transactionAmount} onValueChange={setTransactionAmount} />
                <div style={{clear:'both'}}><label>Expiration</label></div>
                <DatePicker selected={moment(expiration).toDate()} onChange={date => setExpiration(date.getTime())} />
                <label>Subscription Type</label>
                <select name='subscriptionTypeId' value={subscriptionTypeId} onChange={e => setSubscriptionTypeId(e.target.value)}>
                    <option value='0'>Unknown</option>
                    {types.map(t => <option key={t.id} value={t.id}>{`${t.label} - ${formatCurrency(t.amount)}/month`}</option>)}
                </select>
                <label>Date of last subscription change</label>
                <div>{moment(updated).format('YYYY-MM-DD')}</div>
                <div className='buttonsHolder' style={{paddingBottom:'10px'}}>
                    <button onClick={saveHandler}>Save Changes</button>
                </div>
                <div className='buttonsHolder'>
                    <button onClick={add1MonthHandler}>Quick Add 1 Month</button>
                    <button onClick={add1YearHandler}>Quick Add 1 Year</button>
                </div>
            </div>
            }
        </div>
    )
}

export default Subscription;
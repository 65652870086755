import { faPlus, faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import Core from '../../util/core';
import PubSub from 'pubsub-js';
import SidePanel from 'components/sidePanel';
import FocusedChatRoom from './FocusedChatRoom';
import OpenChatRoom from './OpenChatRoom';
import Loading from '../../components/loading';
import './GroupChat.scss'
import { ChatUsersProvider } from '../../contexts/chatUsers';
import ChatRoom from './ChatRoom';
import { ChannelProvider } from '../../contexts/channel';
import ChatRoomDetails from './ChatRoomDetails';

function GroupChat() {
    const auth = useAuth();
    const [publicChannels, setPublicChannels] = useState([]);
    const [privateChannels, setPrivateChannels] = useState([]);
    const [channel, setChannel] = useState(null);
    const [editingChannel, setEditingChannel] = useState(null);
    const [loading, setLoading] = useState(false);
    const ChatRoomDetailsPanel = useRef(null);

    useEffect(() => {
        getChannels();
    }, [])

    useEffect(() => {
        // subscribe to web socket events
        let mentionSub = PubSub.subscribe('mention', newMentionHandler);
        let chatStatusSub = PubSub.subscribe('chatstatus', chatStatusHandler);
        return () => {
            PubSub.unsubscribe(mentionSub);
            PubSub.unsubscribe(chatStatusSub);
        }
    }, [])

    const getChannels = () => {
        setLoading(true);
        let data = {userId: auth.user.userId, affiliate: auth.group.id}
        Core.runAction('get_chat_channels', data, response => {
            setPublicChannels(response.public);
            setPrivateChannels(response.private);
            setLoading(false);
        })
    }
    // there was a mention
    const newMentionHandler = (topic, message) => {
        console.log('new mention '+ topic);
        console.log(message);
    }
    // someones status changed
    const chatStatusHandler = (topic, message) => {
        console.log('new chat status '+ topic);
        console.log(message);
    }

    const search = () => {}
    
    const createChannel = () => {
        setEditingChannel(null);
        ChatRoomDetailsPanel.current.show();
    }

    const editPublicChannel = (channel) => {
        setEditingChannel(channel);
        ChatRoomDetailsPanel.current.show();
    }

    const chatRoomSavedHandler = () => {
        ChatRoomDetailsPanel.current.hide();
        getChannels();
    }

    return (
        <div className='group-chat-outer'>
            <ChatUsersProvider>
                <div className='group-chat-inner'>
                    <div className='left-pane-view'>
                        <div className='top-container'>
                            <div className='top-container-side'>
                                <div className='left-pane-title'>My Status</div>
                                <ChatStatus />
                            </div>
                            <div className='top-container-side'>
                                <div className='transparentButton' onClick={getChannels}><FontAwesomeIcon icon={faSync} /></div>
                                <div className='transparentButton' onClick={search}><FontAwesomeIcon icon={faSearch} /></div>
                            </div>
                        </div>
                        <div className='center-container'>
                            {loading ? <Loading /> : 
                                <>
                                <ChannelList 
                                    channels={publicChannels} 
                                    selectedChannel={channel}
                                    title='Open Chat Rooms' 
                                    onEdit={editPublicChannel}
                                    onChannelClick={setChannel}
                                    type='public' />
                                <ChannelList 
                                    channels={privateChannels} 
                                    selectedChannel={channel}
                                    title='Focused Chat Rooms' 
                                    onEdit={editPublicChannel}
                                    onChannelClick={setChannel}
                                    type='private' />
                                </>
                            }
                        </div>
                        <div className='bottom-container'>
                            <button className='linkButton' onClick={createChannel}>Create Chat Room <FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                    </div>
                    {channel !== null 
                        ? <ChannelProvider channel={channel}><ChatRoom /></ChannelProvider>
                        :  <div className='rightPaneView'></div>
                    }
                </div>
                <SidePanel title='Chat Room Details' ref={ChatRoomDetailsPanel}>
                    <ChatRoomDetails channel={editingChannel} onSave={chatRoomSavedHandler} />
                </SidePanel>
            </ChatUsersProvider>
        </div>
    )
}

export default GroupChat;

function ChatStatus() {
    const auth = useAuth();
    const [status, setStatus] = useState('available');

    useEffect(() => {
        setStatus(auth.chatStatus);
    }, [])

    const statusChangeHandler = (stat) => {
        Core.runAction('update_chat_status', {status: stat}, response => {
            setStatus(stat);
            //auth.setChatStatus(stat);
        })
    }

    return (
        <div className='status-toggle'>
            <select value={status} style={{width:'120px'}} onChange={e => statusChangeHandler(e.target.value)}>
                <option value='available'>Available</option>
                <option value='away'>Away</option>
                <option value='inameeting'>In a meeting</option>
                <option value='donotdisturb'>Do not disturb</option>
            </select>
        </div>
    )
}

function ChannelList(props) {

    const isSelected = (channel) => {
        return (props.selectedChannel && props.selectedChannel.id === channel.id);
    }

    return (
        <div className='channelListContainer'>
            <div className='channelListHeader'>{props.title}</div>
            <div className='channelList'>
                <ul>
                    {props.channels.map(channel => 
                        <li key={channel.id} className={isSelected(channel) ? 'selected' : ''}>
                            {props.type === 'public'
                                ? <OpenChatRoom 
                                    room={channel} 
                                    onClick={() => props.onChannelClick(channel)} 
                                    onEdit={() => props.onEdit(channel)}
                                />
                                : <FocusedChatRoom 
                                    room={channel} 
                                    onClick={() => props.onChannelClick(channel)} 
                                    onEdit={() => props.onEdit(channel)}
                                />
                            }
                        </li>)
                    }
                </ul>
            </div>
        </div>
    )
} 

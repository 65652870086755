import React, {useState, useEffect} from 'react';
import Core from '../../../util/core';
import Toggle from '../../../components/toggle';
import TimePicker from 'components/timePicker';
import PubSub from 'pubsub-js';
import './availability.scss';
import { useSettings } from '../../../contexts/settings';

function Availability(props) {
    const settings = useSettings();
    const [availability, setAvailability] = useState({});
    const [setup, setSetup] = useState({});
    
    useEffect(() => {
        Core.runAction('get_voice_availability', {}, (response) => {
            setAvailability(response.availablity);
        });
    }, []);

    const saveSetting = (key, value) => {
        let curSetup = {...setup};
        curSetup[key] = value;
        // update avaialability
        let _availability = {...availability};
        _availability[curSetup.weekday] = curSetup;
        // update the state
        setSetup(curSetup);
        setAvailability(_availability);
    }

    const loadAvailability = (day) => {
        if (availability[day]) {
            setSetup({...availability[day]});
        } else {
            setSetup({weekday: day, available: true, startHour: 28800000, endHour: 61200000});
        }
    }

    const saveAvailability = () => {
        let data = {availability: {...availability}};
        Core.runAction('save_voice_availability', data, (response) => {
            PubSub.publish('success', 'Availability Updated');
        })
    }

    const updateSettings = (newValue) => {
        let data = {...settings.tenant, useVoiceAvailable: newValue}
        Core.runAction('update_tenant_settings', data, (response) => {
            //PubSub.publish('success', 'Settings successfully saved');
            settings.refreshTenantSettings();
        })
    }

    return (
        <div className='communication-area'>
            <h3>Availability</h3>
            <p>Turn on &quot;Availability&quot; if you want to limit when incoming calls go to your mobile phone.</p>
            <label>Availablity</label>
            <Toggle 
                checked={settings.tenant.useVoiceAvailable}
                onChange={(newValue) => { updateSettings(newValue)}} />
            
            <p>For availability, specify if you are available, and the hours you want calls to be routed to your forwarding number.
            For the times that you do not indicate as available, your unavailable greeting will be played and the caller will 
            be able to leave a voice mail message after they hear a beep.</p>
            <div className='ivrWeekdays'>
                <div onClick={() =>  loadAvailability('Sun')} className={setup.weekday && setup.weekday==='Sun' ? 'selected' : ''}>Sun</div>
                <div onClick={() =>  loadAvailability('Mon')} className={setup.weekday && setup.weekday==='Mon' ? 'selected' : ''}>Mon</div>
                <div onClick={() =>  loadAvailability('Tue')} className={setup.weekday && setup.weekday==='Tue' ? 'selected' : ''}>Tue</div>
                <div onClick={() =>  loadAvailability('Wed')} className={setup.weekday && setup.weekday==='Wed' ? 'selected' : ''}>Wed</div>
                <div onClick={() =>  loadAvailability('Thu')} className={setup.weekday && setup.weekday==='Thu' ? 'selected' : ''}>Thu</div>
                <div onClick={() =>  loadAvailability('Fri')} className={setup.weekday && setup.weekday==='Fri' ? 'selected' : ''}>Fri</div>
                <div onClick={() =>  loadAvailability('Sat')} className={setup.weekday && setup.weekday==='Sat' ? 'selected' : ''}>Sat</div>
            </div>
            
            <div>
                <div><label>Available</label></div>
                <Toggle checked={setup.available} onChange={(newValue) =>  saveSetting('available', newValue)} />
                <div style={{clear:'both'}}><label>Start Time</label></div>
                <TimePicker value={setup.startHour} onChange={(t) =>  saveSetting('startHour', t)}/>
                <div><label>End Time</label></div>
                <TimePicker value={setup.endHour} onChange={(t) =>  saveSetting('endHour', t)} />
            </div>
            
            <div style={{marginTop: '10px'}}>
                <button onClick={saveAvailability}>Save Availability</button>
            </div>
        </div>
    )
}

export default Availability;
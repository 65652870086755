import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import Promotion from './promotion';
import { TableSetupProvider } from 'contexts/tableSetup';
import { usePromotion } from 'contexts/promotions';

const promotionColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "name", label:"Name", display: true, datum: "name"},
    {id: "code", label:"Code", display: true, datum: "code"},
    {id: "amount", label:"Amount", display: true, datum: "amount"},
    {id: "mustspend", label:"Must Spend", display: true, datum: "mustspend"},
    {id: "start", label:"Start Date", display: true, formatter: (obj) => {return moment(obj.startdate).format("YYYY-MM-DD");}},
    {id: "end", label:"End Date", display: true, formatter: (obj) => {return moment(obj.enddate).format("YYYY-MM-DD");}}
];

function ProductPromotions() {
    const { promotions, loadPromotions, createPromotion, updatePromotion, deletePromotion } = usePromotion();
    const [item, setItem] = useState(null);
    const promoPanel = useRef(null);

    const showPromo = (item) => {
        setItem(item);
        promoPanel.current.show();
    }

    const createPromo = () => {
        let item = {name: '', code: '', amount: 0, mustspend: 0, startdate: Date.now(), enddate: Date.now()}
        setItem(item);
        promoPanel.current.show();
    }

    const hidePromo = () => {
        setItem(null);
    }

    const save = (data) => {
        if ('id' in data) {
            updatePromotion(data, () => {
                promoPanel.current.hide();
            })
        } else {
            createPromotion(data, () => {
                promoPanel.current.hide();
            });
        }
    }

    const deletePromo = (items) => {
        deletePromotion(items[0].id);
    }
    
    return(
        <div>
            <TableSetupProvider tableName='promotion' defaultColumns={promotionColumns}>
                <AmazingTable 
                    rows={promotions}
                    onRefresh={loadPromotions}
                    onRowClick={showPromo}
                    onCreate={createPromo}
                    onCreateLabel='Add promotion'
                    onDelete={deletePromo} />
            </TableSetupProvider>
            <SidePanel title='Promotion' ref={promoPanel} onHide={hidePromo}>
                {item && <Promotion promo={item} onSave={save} />}
            </SidePanel>
        </div>
    )
}

export default ProductPromotions
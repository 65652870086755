import { useSetupAccount } from 'contexts/setupAccount';
import React, { useState } from 'react';


const specialCharacters = [" ","!","\"","#","$","%","&","'","(",")","*","+",",","-",".","/",";",":","<","=",">","?","@","[","\\","]","^","_","`","{","|","}","~"];

function Password({onComplete}) {
    const { password, setPassword } = useSetupAccount();
    const [password2, setPassword2] = useState('');
    const [strength, setStrength] = useState('');
    const [nextDisabled, setNextDisabled] = useState(true);

    const passwordCheck = (evt) => {
        // disable the next button
        setNextDisabled(true);
        let c1 = 0, c2 = 0, c3 = 0, c4 = 0, illegal = false;
        for (var i = 0; i < password.length; i++) {
            var c = password.charAt(i);
            if (!isNaN(parseInt(c))) {
                c1 = 1;
            } else if (specialCharacters.indexOf(c) > -1) {
                c2 = 1;
            } else if (c === c.toUpperCase()) {
                c3 = 1;
            } else if (c === c.toLowerCase()) {
                c4 = 1;
            } else {
                illegal = true;
            }
        }
        let total = c1 + c2 + c3 + c4;
        if (password.length > 7 && !illegal) {
            setStrength("Looks good!");
            if (total >= 3 && password === password2) {
                setNextDisabled(false);
            }
        } else {
            setStrength("Needs to be stronger");
        }
    }

    const next = () => {
        onComplete();
    }

    return (
        <div className='setupSlideBody'>
            <p>Security is really important. For your account you will need to create a password that is at least 8 characters long, contains at least one uppercase letter, 
            one lowercase letter, and one number.</p>
            <ul>
                <li>
                    <input type='password' 
                        value={password} onChange={evt => setPassword(evt.target.value)} placeholder='Password'
                        onKeyUp={passwordCheck} maxLength='64' />
                </li>
                <li>Password Strength: <span>{strength}</span></li>
                <li>
                    <input type='password' 
                        value={password2} onChange={evt => setPassword2(evt.target.value)} placeholder='Verify Password'
                        onKeyUp={passwordCheck} maxLength='64' />
                </li>
            </ul>
            <div style={{textAlign:'center'}}>
                <button className='bigButton' onClick={next} disabled={nextDisabled}>Go to Organization Info</button>
            </div>
        </div>
    )
}

export default Password;
import React, { useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import ImageManager from '../events/imageManager';
import ProductInventory from './productInventory';
import ProductPromotions from './productPromotions';
import { useStore } from 'contexts/store';
import { InventoryProvider } from 'contexts/inventory';
import { PromotionProvider } from 'contexts/promotions';

const tablabels = ['Media','Inventory','Promotions'];

function ProductDetails({product, onSave, onBack}) {
    const { categories } = useStore();
    const [name, setName] = useState(product.name);
    const [sku, setSku] = useState(product.sku);
    const [upc, setUpc] = useState(product.upc);
    const [description, setDescription] = useState(product.description);
    const [category, setCategory] = useState(product.productCategories.length > 0 ? product.productCategories[0].category : 0);
    const [images, setImages] = useState(product.images);

    const [tablet, setTablet] = useState(0);

    const save = () => {
        const _categories = [{id: category}];
        const data = {
            id: product.id,
            name,
            sku,
            upc,
            description,
            categories: _categories,
            images
        };
        onSave(data);
    }

    const imagesChangeHandler = (images) => {
        setImages(images);
    }

    return (
        <div>
            <div className='contact-details'>
                <div className='contact-settings-panel'>
                    <div className='breadcrumb' onClick={onBack}>
                        <div className='breadcrumb-label'>
                            <FontAwesomeIcon icon={faAngleLeft} /> Products
                        </div>
                    </div>
                    <div className='contact-name'>{name}</div>
                    <hr/>
                    <label>Name</label>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength='64' />
                    <label>SKU (leave blank if none)</label>
                    <input type='text' value={sku} onChange={e => setSku(e.target.value)} maxLength='64' />
                    <label>UPC (leave blank if none)</label>
                    <input type='text' value={upc} onChange={e => setUpc(e.target.value)} maxLength='64' />
                    <label>Description</label>
                    <textarea value={description} onChange={e => setDescription(e.target.value)} style={{height:'100px',resize:'vertical'}} maxLength='160'></textarea>
                    <label>Category</label>
                    <select value={category} onChange={e => setCategory(e.target.value)}>
                        {categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                    </select>
                    <hr/>
                    <div className='button-container'>
                        <button onClick={save}>Save</button>
                    </div>
                </div>
                <div className='contact-communication-panel' style={{width:'75%'}}>
                    <div className='tablets-outer'>
                        <div className='tablets-inner'>
                            {
                                tablabels.map((l, i) => 
                                    <div key={i} className={'tablet' + (tablet === i ? ' selected' :'')} onClick={() => setTablet(i)}>{l}</div>
                                )
                            }
                        </div>
                    </div>
                    <div className='communication-area-outer'>
                        <div className='communication-area-inner'>
                            {
                                tablet === 0 ? <ImageManager images={images} onChange={imagesChangeHandler} /> :
                                tablet === 1 ? <InventoryProvider productId={product.id}><ProductInventory /></InventoryProvider> :
                                tablet === 2 ? <PromotionProvider productId={product.id}><ProductPromotions /></PromotionProvider> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const CountriesContext = React.createContext(null);

function CountriesProvider({children}) {
    const { runAction } = useCore();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries();
    }, [])

    const getCountries = () => {
        runAction('get_countries', {},
            (response) => {
                if (response) {
                    setCountries(response.countries);
                }
        });
    }

    const provider = {
        countries
    }

    return <CountriesContext.Provider value={provider}>{children}</CountriesContext.Provider>
}

function useCountries() {
    const context = React.useContext(CountriesContext);
    if (!context) {
        throw new Error('useCountries must be used within a CountriesProvider');
    }
    return context;
}

export { CountriesProvider, useCountries }
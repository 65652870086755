import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function Root() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(pathname);
        if (pathname === '/') {
            // only if user is an account admin
            navigate('/admin/');
        }
    }, [])

    return (
        <div className='outer-root-container'>
            <Outlet />
        </div>
    )
}

export default Root
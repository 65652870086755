import React from 'react';
import './resultsTable.scss';

function ResultsTable(props) {
    
    const carCount = (car_class) => {
        let classEntries = props.entries.filter((entry) => entry.car_class === car_class);
        return classEntries.length;
    };

    const getTableRows = () => {
        let car_class=0, classCount=0, carsInClass=0;
        let rows = [];
        props.entries.forEach((e) => {
            if (e.car_class !== car_class) {
                car_class = e.car_class;
                classCount = 0;
                carsInClass = carCount(car_class);
                rows.push(<tr key={car_class}>
                    <td colSpan='6' className='subheader'>{`Class ${car_class} : ${e.description}`}</td>
                    <td colSpan='2' className='subheader'>{`Total: ${carsInClass}`}</td></tr>);
            }
            if (classCount < 10) {
                rows.push(<tr key={e.car_number}>
                    <td>{e.votes}</td>
                    <td>{e.fname + ' ' + e.lname}</td>
                    <td>{e.year}</td>
                    <td>{e.make}</td>
                    <td>{e.model}</td>
                    <td>{e.trim}</td>
                    <td>{e.color}</td>
                    <td>{e.car_number}</td>
                </tr>)
            }
            ++classCount;
        })

        return rows;
    }

    return(
        <table style={{borderCollapse:'collapse',width:'100%'}}>
            <tbody className='resultsTable'>
                <tr><th>Votes (weight)</th><th>Name</th><th>Year</th><th>Make</th><th>Model</th><th>Trim</th><th>Color</th><th>Number</th></tr>
                {getTableRows()}
            </tbody>
        </table>
    )
}

export default ResultsTable;
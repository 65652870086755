import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const AccountMessagesContext = React.createContext(null);

function AccountMessagesProvider({tenantId, children}) {
    const { runAction } = useCore();
    const [messagesLoading, setMessagesLoading] = useState(true);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        getMessages();
    }, [])

    const getMessages = () => {
        setMessagesLoading(true);
        let data = {tenant: tenantId};
        runAction('get_tenant_messages', data, response => {
            setMessages(response.messages);
            setMessagesLoading(false);
        })
    }

    const saveMessage = (data, callback) => {
        const action = data.id ? 'update_tenant_message' : 'create_tenant_message';
        data.tenant = tenantId;
        runAction(action, data, response => {
            if (response.updatedCount) {
                publish('notification', response.updatedCount + ' messages were updated. Messages that have been acknowledged were not updated.');
            }
            getMessages();
            callback();
        })
    }

    const deleteMessages = (ids) => {
        let data = {ids}
        runAction('delete_tenant_message', data, response => {
            publish('notification', response.deleteCount + ' messages were deleted. Messages that have been acknowledged were not deleted.');
            getMessages();
        })
    }

    const provider = {
        messagesLoading,
        messages,
        getMessages,
        deleteMessages,
        saveMessage
    }

    return <AccountMessagesContext.Provider value={provider}>{children}</AccountMessagesContext.Provider>
}

function useAccountMessages() {
    const context = React.useContext(AccountMessagesContext);
    if (!context) {
        throw new Error('useAccountMessages must be used within an AccountMessagesProvider');
    }
    return context;
}

export { AccountMessagesProvider, useAccountMessages }
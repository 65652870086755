import React, {useEffect, useRef, useState} from 'react';
import MFA from './mfa';
import MFASetup from './mfaSetup';
import { useToken } from 'contexts/token';
import { useNavigate } from 'react-router-dom';

function Password(props) {
    const { updateToken } = useToken();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [mobile, setMobile] = useState(null);
    const [userId, setUserId] = useState(null);
    const [mfaMode, setMfaMode] = useState(null);
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    const inputRef = useRef(null);
    
    useEffect(() => {
        inputRef.current.focus();
    }, []);
    /**
	 * key up handler
	 */
	const keyUpHandler = (event) => {
		if (event.keyCode && event.keyCode === 13) {
			login();
		} else if (event.keyCode === 8) {
            setError(null);
        }
    }
    const login = () => {
        let deviceCode = localStorage.deviceCode;
		let data = {
				action: 'login', 
				username: props.username,
				password,
                keepLoggedIn
			};
			fetch('/dart/core', {
				method: "POST",
				headers: {'Content-Type':'application/json', 'X-Device': deviceCode},
				body: JSON.stringify(data)
			})//.then(res => res.json())
			.then((response) => {
                const xToken = response.headers.get('X-Dart-Token');
                updateToken(xToken);
				return response.json();
			})
			.then(
                (result) => {
                    if (result.success) {
                        if (result.user) {
                            navigate('/admin/');
                        } else if (result.mfa) {
                            setUserId(result.mfa);
                            setMobile(result.mobile);
                            setMfaMode('verify');
                        } else if (result.mobile) {
                            setUserId(result.mobile);
                            setMfaMode('setup');
                        }
                    } else {
                        setError(result.errorMessage);
                    }
                }, 
                (error) => {
                    if (error) {
                        setError(error);
                    }
                }
            )
    }
    return(
        <div>
            <div className='passwordLabelRow'>
                <label>Password</label>
                <button className='linkButton' style={{padding:'0'}} onClick={props.onForgotPassword}>Forgot Password</button>
            </div>
            <div className='input-container'>
                <input type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} ref={inputRef} onKeyUp={keyUpHandler} disabled={mfaMode !== null} />
            </div>
            <div className='input-error'>{error}</div>
            <div className='password-stuff'>
                <div className='option-container'>
                    <input type='checkbox' value={keepLoggedIn} onChange={evt => setKeepLoggedIn(evt.target.checked)} />
                    <label>Keep Me Logged In</label>
                </div>
            </div>
            {mfaMode === 'verify'
                ? <MFA mobile={mobile} 
                    userId={userId} 
                    onMfaComplete={login} />
                : mfaMode === 'setup' 
                    ?   <MFASetup 
                            email={props.username}
                            userId={userId} 
                            onMfaSetupComplete={login} />
                    : <div><button onClick={login}>Login</button></div>
            }
        </div>
    );
}

export default Password;
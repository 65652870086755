import React, { useEffect, useRef, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import PubSub from 'pubsub-js';
import { useApps } from 'contexts/apps';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

function AppButton(props) {
    const [notify, setNotify] = useState(false);

    useEffect(() => {
        if (props.app.id === 'messages') {
            let smsSub = PubSub.subscribe('sms', newMessageHandler);
            return () => {PubSub.unsubscribe(smsSub)}
        }
    }, [props.app]);

    const newMessageHandler = (topic, message) => {
        if (!props.active) {
            setNotify(true);
        }
        props.onPlaySound();
    }

    const onClickHandler = () => {
        setNotify(false);
        props.onClick(props.app);
    }

    return (
        <div className={'app-button' + (props.active ? ' selected' : '')} onClick={onClickHandler}>
            <div className='app-icon'>
                <FontAwesomeIcon icon={props.app.icon} />
            </div>
            <FontAwesomeIcon icon={faCircle} className='app-button-notify' style={{visibility: notify ? 'visible' : 'hidden'}}/>
            <div className='app-label'>{props.app.label}</div>
        </div>
    )
}

function AppMenu() {
    const { apps } = useApps();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dingWav = useRef(null);

    const handleClick = (app) => {
        navigate(app.route);
    }

    const playSoundHandler = () => {
        dingWav.current.play();
    }

    return (
        <div className='app-menu'>
            <div className= 'app-buttons'>
                {apps.map((a) => <AppButton key={a.id} app={a} active={pathname.startsWith(a.route)} onClick={handleClick} onPlaySound={playSoundHandler} />)}
            </div>
            <audio src={process.env.PUBLIC_URL + '/ding.wav'} autostart='false' ref={dingWav}></audio>
            <div className='menu-footer'>&copy; Copyright {moment().format('YYYY')} Wade Girard</div>
        </div>
    )
}

export default AppMenu;
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons';
import 'style/checkbox.scss';

function Checkbox(props) {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ('checked' in props) {
            setChecked(props.checked);
        }
    }, [props])

    const toggleHandler = (event) => {
        event.preventDefault();
        let newValue = !checked;
        setChecked(newValue);
        if ('onChange' in props) {
            props.onChange(newValue);
        }
    }

    return(
        <div className='dart-checkbox' onClick={toggleHandler}>
            <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} />
        </div>
    );
}

export default Checkbox;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SubscriptionInvoicesContext = React.createContext(null);

function SubscriptionInvoicesProvider({tenantId, children}) {
    const { runAction } = useCore();
    const [invoicesLoading, setInvoicesLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        getInvoices();
    }, [])

    const getInvoices = () => {
        setInvoicesLoading(true);
        runAction('get_sub9_invoices', {tenantId}, response => {
            setInvoices(response.invoices);
            setInvoicesLoading(false);
        });
    }

    const saveInvoice = (invoice, callback) => {
        const action = invoice.id ? 'update_sub9_invoice' : 'create_sub9_invoice';
        const data = {tenantId, ...invoice};
        runAction(action, data, () => {
            getInvoices();
            callback();
        });
    }

    const deleteInvoice = (invoiceId) => {
        runAction('delete_sub9_invoice', {id: invoiceId}, () => {
            getInvoices();
        });
    }

    const provider = {
        invoicesLoading,
        invoices,
        getInvoices,
        saveInvoice,
        deleteInvoice,
    }

    return <SubscriptionInvoicesContext.Provider value={provider}>{children}</SubscriptionInvoicesContext.Provider>
}

function useSubscriptionInvoices() {
    const context = React.useContext(SubscriptionInvoicesContext);
    if (!context) {
        throw new Error('useSubscriptionInvoices must be used within a SubscriptionInvoicesProvider');
    }
    return context;
}

export { SubscriptionInvoicesProvider, useSubscriptionInvoices }
import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import { useCountries } from 'contexts/countries';
import { useTimezones } from 'contexts/timezones';
import { useAffiliates } from 'contexts/affiliates';

function NewAccount({onSave}) {
    const { group } = useAuth();
    const { countries } = useCountries();
    const { timezones } = useTimezones();

    const [name, setName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [isoCountry, setIsoCountry] = useState('');
    const [zip, setZip] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [timezone, setTimezone] = useState('');
    const [affiliate, setAffiliate] = useState('0');

    const { affiliates } = useAffiliates();
    
    useEffect(() => {
        if (group) {
            setAffiliate(group.id);
        }
    }, [group])

    const save = () => {
        let data = {
            name,
            address1,
            address2,
            city,
            state,
            zip,
            contact,
            phone,
            email,
            timezone,
            sendMail: false,
			returnPassword: true,
            affiliate
        }
        onSave(data);
    }

    return (
        <div className='contact-preview'>
            <label>Name</label>
            <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
            <label>Address</label>
            <input type='text' value={address1} onChange={evt => setAddress1(evt.target.value)} maxLength='64' />
            <label>Address</label>
            <input type='text' value={address2} onChange={evt => setAddress2(evt.target.value)} maxLength='64' />
            <label>City</label>
            <input type='text' value={city} onChange={evt => setCity(evt.target.value)} maxLength='64' />
            <label>State</label>
            <input type='text' value={state} onChange={evt => setState(evt.target.value)} maxLength='64' />
            <label>Country</label>
            <select value={isoCountry} onChange={evt => setIsoCountry(evt.target.value)}>
                {
                    countries.map((c, i) => <option key={i} value={c.isoCode}>{c.label}</option>)
                }
            </select>
            <label>Zip</label>
            <input type='text' value={zip} onChange={evt => setZip(evt.target.value)} maxLength='64' />
            <label>Contact</label>
            <input type='text' value={contact} onChange={evt => setContact(evt.target.value)} maxLength='64' />
            <label>Email</label>
            <input type='text' value={email} onChange={evt => setEmail(evt.target.value)} maxLength='64' />
            <label>Voice Number</label>
            <input type='text' value={phone} onChange={evt => setPhone(evt.target.value)} maxLength='64' />
            <label>Timezone</label>
            <select value={timezone} onChange={evt => setTimezone(evt.target.value)}>
                {
                    timezones.map((t, i) => <option key={i} value={t.id}>{t.label}</option>)
                }
            </select>
            {!group &&
                <>
                    <label>Group</label>
                    <select value={affiliate} onChange={evt => setAffiliate(evt.target.value)}>
                        <option value='0'>None</option>
                        {
                            affiliates.map((a, i) => <option key={i} value={a.id}>{a.name}</option>)
                        }
                    </select>
                </>
            }
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default NewAccount;
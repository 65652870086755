import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const EventTicketsContext = React.createContext(null);

function EventTicketsProvider({eventId, children}) {
    const { runAction } = useCore();
    const [eventTickets, setEventTickets] = useState([]);

    useEffect(() => {
        getEventTickets();
    }, [])

    const getEventTickets = () => {
        let data = {event: eventId}
        runAction('get_event_tickets', data, (response) => {
            setEventTickets(response.eventtickets);
        })
    }

    const saveEventTicket = (data) => {
        const action = data.id ? 'update_event_ticket' : 'create_event_ticket';
        data.event = eventId;
        runAction(action, data, (response) => {
            getEventTickets();
        })
    }

    const deleteEventTickets = (ids) => {
        runAction('delete_event_ticket', {ids}, (response) => {
            getEventTickets();
        })
    }

    const provider = {
        eventTickets,
        getEventTickets,
        saveEventTicket,
        deleteEventTickets,
    }

    return <EventTicketsContext.Provider value={provider}>{children}</EventTicketsContext.Provider>
}

function useEventTickets() {
    const context = React.useContext(EventTicketsContext);
    if (!context) {
        throw new Error('useEventTickets must be used within an EventTicketsProvider');
    }
    return context;
}

export { EventTicketsProvider, useEventTickets }
import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import { useCore } from 'contexts/core';

function Portal(props) {
    const { tenant } = useAuth();
    const { runAction } = useCore();
    const [proposedSubdomain, setProposedSubdomain] = useState('');

    useEffect(() => {
        if (tenant && tenant.subdomain.length > 0) {
            props.onNext(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant])

    const saveSubdomain = () => {
        let data = {subdomain: proposedSubdomain};
        runAction('set_subdomain', data, (response) => {
            if (response) {
                props.onNext(true);
            }
        })
    }

    return (
        <div>
            <div className='setupInstructions'>
                <p>Your member portal subdomain needs to be setup.</p>
            </div>
            <div className='setupPane' style={{top:'150px'}}>
                <div className='setupPaneTitle' >The subdomain needs to be unique for your group</div>
                    <div style={{width:'70%',margin:'50px auto',textAlign:'center'}}>
                        <p>Configure a custom URL for a member portal that your members will use to access 
                            their member information. (only letters, hyphen, or underscores, no spaces)</p>
                        <input 
                            type='text' 
                            style={{width:'150px',margin:'10px 0'}} 
                            value={proposedSubdomain} 
                            onChange={(evt) => setProposedSubdomain(evt.target.value)}
                            maxLength='32'/>.middletwin.com
                        <div>
                            <button onClick={saveSubdomain}>Save</button>
                        </div>
                        <p>There are additional customizations you can make to the member portal experience in your settings.</p>
                    </div>
                <div className='setupPaneFooter'>
                    
                    <button className='linkButton' onClick={() => props.onNext(false)}>Skip</button>
                </div>
            </div>
        </div>
    )
}

export default Portal;
import React, {useState} from 'react';
import { useEvents } from 'contexts/events';
import Modal from 'components/modal';

function NewEvent({onSave}) {
    const { events, venues, createVenue } = useEvents();
    const [name, setName] = useState('');
    const [venue, setVenue] = useState('');
    const [copy, setCopy] = useState('0');
    
    const [createNewVenue, setCreateNewVenue] = useState(false);
    const [newVenueName, setNewVenueName] = useState('');

    const save = () => {
        let data = {
            name: name,
            venue: venue,
            reg_price: 0.0, 
            merchant: 0
        }
        if (copy !== '0') {
            data.copy = copy;
        }
        if (onSave) {
            onSave(data);
        }
    }

    const showNewVenueModal = () => {
        setNewVenueName('');
        setCreateNewVenue(true);
    }

    const saveNewVenueHandler = () => {
        let data = {
            name: newVenueName
        }
        createVenue(data, () => {
            hideNewVenueModal();
        });
    }

    const hideNewVenueModal = () => {
        setCreateNewVenue(false);
        setNewVenueName('');
    }

    return(
        <div>
            <div className='contact-preview'>
                <label>Name</label>
                <input type='text' value={name} onChange={(evt) => setName(evt.target.value)} maxLength='64' />
                <label>Venue<button style={{float:'right'}} className='linkButton' onClick={() => showNewVenueModal()}>Create new venue</button></label>
                <select value={venue} onChange={(evt) => setVenue(evt.target.value)}>
                    <option value='0'>-Required-</option>
                    {venues.map((v) => <option key={v.id} value={v.id}>{v.name}</option>)}
                </select>
                <label>Copy Event</label>
                <select value={copy} onChange={(evt) => setCopy(evt.target.value)}>
                    <option value='0'>-Optional-</option>
                    {events.map((e) => <option key={e.id} value={e.id}>{e.name}</option>)}
                </select>
            </div>
            <div className='buttonsHolder'>
                <button onClick={save}>Save</button>
            </div>
            <Modal
                title='Create new venue' 
                showing={createNewVenue} 
                onHide={() => hideNewVenueModal(false)}
                buttons={[
                    {
                        type: 'primary', 
                        handler: () => {saveNewVenueHandler()},
                        label:'Create venue'}
                ]}>
                <p style={{width:'400px'}}>A Venue represents the physical location where you host your event. What is the name of the venue you would like to create?</p>
				<input type="text" style={{width:'378px'}} value={newVenueName} onChange={evt => setNewVenueName(evt.target.value)} maxLength="64" />
            </Modal>
        </div>
    )
}

export default NewEvent
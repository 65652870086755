import React, { useEffect, useState } from 'react';
import { permission, hasPermission } from '../util/permission';
import { useAuth } from './auth';
import { useCore } from './core';

const SettingsContext = React.createContext();

function SettingsProvider({children}) {
    const auth = useAuth();
    const { runAction } = useCore();
    const [tenantSettings, setTenantSettings] = useState(null);
    const [groupSettings, setGroupSettings] = useState(null);

    useEffect(() => {
        if (auth.tenant && hasPermission(auth.user, permission.ACCOUNTADMIN)) {
            getTenantSettings()
        }
        if (hasPermission(auth.user, permission.GROUPADMIN)) {
            getGroupSettings(auth.group.uuid);
        }
    }, [auth])

    const getTenantSettings = () => {
        runAction('get_tenant_settings', {}, (response) => {
            setTenantSettings(response.settings);
        })
    }

    const getGroupSettings = (uuid) => {
        runAction('get_affiliates', {uuid}, response => {
            setGroupSettings(response.affiliate);
        })
    }

    const updateGroupSettings = (settings) => {
        runAction('update_affiliate', settings, response => {
            getGroupSettings(settings.uuid);
        });
    }

    const provider = {
        tenant: tenantSettings,
        group: groupSettings,
        refreshTenantSettings: () => getTenantSettings(),
        refreshGroupSettings: (uuid) => getGroupSettings(uuid),
        updateGroupSettings
    }

    return <SettingsContext.Provider value={provider}>{children}</SettingsContext.Provider>
}

function useSettings() {
    const context = React.useContext(SettingsContext);
    if (!context) {
        throw new Error('SettingsContext must be used within a SettingsContext');
    }
    return context;
}

export {SettingsProvider, useSettings}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import PortalSettings from './portalSettings';
import { useAuth } from 'contexts/auth';
import { useSettings } from 'contexts/settings';
import { useCore } from 'contexts/core';
import { PageProvider } from 'contexts/page';

function Portal() {
    const { tenant } = useAuth()
    const { refreshTenantSettings } = useSettings();
    const { runAction } = useCore();
    const [subdomain, setSubdomain] = useState(tenant.subdomain);
    const [proposedSubdomain, setProposedSubdomain] = useState('');
    const [error, setError] = useState(null);

    const handleSubdomainChange = (evt) => {
        setProposedSubdomain(evt.target.value);
        setError(null);
    }

    const saveSubdomain = () => {
        let data = {subdomain: proposedSubdomain};
        runAction('set_subdomain', data, (response) => {
            if (response) {
                setSubdomain(proposedSubdomain);
                setProposedSubdomain('');
                refreshTenantSettings();
            }
        })
    }

    return (
        <div style={{padding:'20px 40px'}}>
            {subdomain ?
                <div>
                    <p>The url for your member portal is:</p>
                    <span style={{fontWeight:'bold'}}>{`${subdomain}.middletwin.com`} <a href={'https://' + subdomain + '.middletwin.com/'} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faExternalLink} /></a></span>
                    <p>You need to advertise this to your members.</p>
                    <PageProvider><PortalSettings /></PageProvider>
                </div>
            :
                <div>
                    <p>Configure a custom URL for a member portal that your members will use to access 
                        their member information. (only letters, hyphen, or underscores, no spaces)</p>
                    <div>
                        <input 
                            type='text' 
                            style={{width:'150px'}} 
                            value={proposedSubdomain} 
                            onChange={handleSubdomainChange} 
                            maxLength='32'/>.middletwin.com
                    </div>
                    <div>{error}</div>
                    <div className='buttonsHolder'>
                        <button onClick={saveSubdomain}>Save</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Portal;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ChatDatabasesContext = React.createContext(null);

function ChatDatabasesProvider({children}) {
    const { runAction } = useCore()
    const [chatDatabases, setChatDataBases] = useState([]);

    useEffect(() => {
        getChatDatabases();
    }, [])

    const getChatDatabases = () => {
        runAction('get_chat_databases', {}, response => {
            setChatDataBases(response.databases);
        })
    }

    const createChatDatabase = (name, affiliateId) => {
        const data = {name, affiliate: affiliateId};
        runAction('create_chat_database', data, () => {
            getChatDatabases()
        })
    }

    const changeChatDatabase = (affiliateId, chatDb, callback) => {
        const data = {affiliate: affiliateId, chatDb};
        runAction('update_group_chat_database', data, () => {
            callback();
        });
    }

    const provider = {
        chatDatabases,
        getChatDatabases,
        createChatDatabase,
        changeChatDatabase
    }

    return <ChatDatabasesContext.Provider value={provider}>{children}</ChatDatabasesContext.Provider>
}

function useChatDatabases() {
    const context = React.useContext(ChatDatabasesContext);
    if (!context) {
        throw new Error('useChatDatabases must be used within a ChataDatabaseProvider');
    }
    return context;
}

export { ChatDatabasesProvider, useChatDatabases }
import React, { useEffect, useState } from 'react';

function AccountMessage(props) {
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (props.message) {
            setMessage(props.message.message);
        }
    }, [props.message])

    const save = () => {
        let data = {message}
        props.onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Message</label>
                <input type='text' value={message} onChange={evt => setMessage(evt.target.value)} maxLength='2048' />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default AccountMessage;
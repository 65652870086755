import { faArrowAltDown, faArrowAltLeft, faArrowAltRight, faArrowAltUp, faBracketsCurly, faFillDrip, faImage, faPencil, faRectangleWide, faText, faTools, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { usePageContent } from './context/pageContent';
import EditableDiv from 'apps/groupChat/EditableDiv';
import Modal from 'components/modal';
import PageImage from './pageImage';

function ElementSettings() {

    return (
        <>
            <PageElement />
        </>
    )
}

export default ElementSettings;

function PageElement() {
    const { sections, insertElement, type } =  usePageContent();
    const [showAddModuleModal, setShowAddModuleModal] = useState(false);
    const [sectionIndexToAddModuleTo, setSectionIndexToAddModuleTo] = useState(null);
    const [addElementType, setAddElementType] = useState('');

    const showAddModuleHandler = (sectionIndex) => {
        setSectionIndexToAddModuleTo(sectionIndex);
        setShowAddModuleModal(true);
    }

    const addElementHandler = () => {
        insertElement(sectionIndexToAddModuleTo, {type: addElementType});
        setShowAddModuleModal(false);
        setSectionIndexToAddModuleTo(null);
    }

    return (
        <>
            {sections.map((section, index) => 
                <SectionElement 
                    key={index} 
                    section={section} 
                    sectionIndex={index} 
                    onAddElement={() => showAddModuleHandler(index)}/>)
            }
            <Modal 
                title='Add module' 
                showing={showAddModuleModal} 
                onHide={() => setShowAddModuleModal(false)}
                buttons={[
                    {type: 'primary', handler:() => addElementHandler(), label: 'Add Module'},
                    {type: 'secondary', handler:() => setShowAddModuleModal(false), label: 'Cancel'}
                ]}>
                <p>What type of module would you like to add to this section?</p>
                <select value={addElementType} onChange={e => setAddElementType(e.target.value)}>
                    <option value=''>--Choose One--</option>
                    <option value='image'>Image</option>
                    <option value='text'>Text</option>
                    <option value='button'>Button</option>
                    <option value='registrationYear' disabled={type < 3}>Registration Year</option>
                    <option value='registrationNumber' disabled={type < 3}>Registration Number</option>
                    <option value='registrationMake' disabled={type < 3}>Registration Make</option>
                    <option value='registrationModel' disabled={type < 3}>Registration Model</option>
                    <option value='registrationName' disabled={type < 3}>Registration Name</option>
                    <option value='registrationLocation' disabled={type < 3}>Registration Location</option>
                    <option value='registrationQRCode' disabled={type < 3}>Registration QR Code</option>
                </select>
            </Modal>
        </>
    )
}

function SectionElement({section, sectionIndex, onAddElement}) {
    const { moveSectionUp, moveSectionDown, removeSection, type } = usePageContent();

    return (
        <div className='section-block'>
            <details>
                <summary>
                    <div className='summary-left'>
                        <FontAwesomeIcon icon={faRectangleWide} /><div className='summary-title'>Page Section</div>
                    </div>
                    <div className='summary-right'>
                        {type < 3 && 
                            <>
                                <button onClick={() => moveSectionUp(sectionIndex)}><FontAwesomeIcon icon={faArrowAltUp} /></button>
                                <button onClick={() => moveSectionDown(sectionIndex)}><FontAwesomeIcon icon={faArrowAltDown} /></button>
                            </>
                        }
                        <button onClick={() => removeSection(sectionIndex)}><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </summary>
                <div className='modules'>
                    {section.elements.map((element, index) => <ModuleElement key={index} element={element} sectionIndex={sectionIndex} elementIndex={index}/>)}
                    <button onClick={onAddElement}>Add module</button>
                </div>
                
            </details>
        </div>
    )
}

function ModuleElement({element, sectionIndex, elementIndex}) {
    const { moveElementLeft, moveElementRight, removeElement, updateElement, type } = usePageContent();
    const [tab, setTab] = useState(0);

    const [image, setImage] = useState(element.settings?.url ? element.settings.url : '0');
    const [imageData, setImageData] = useState(element.settings?.imageData ? element.settings.imageData : '');
    const [theText, setTheText] = useState(element.settings?.text ? element.settings.text : '');
    const [theUrl, setTheUrl] = useState(element.settings?.url ? element.settings.url : '');

    const [imageWidth, setImageWidth] = useState(element.settings?.imageWidth ? element.settings.imageWidth : 100);
    const [imageHeight, setImageHeight] = useState(element.settings?.imageHeight ? element.settings.imageHeight: 100);
    const [contentWidth, setContentWidth] = useState(element.settings?.contentWidth ? element.settings.contentWidth : 100);
    const [elementWidth, setElementWidth] = useState(element.settings?.elementWidth ? element.settings.elementWidth : 100);
    const [elementHeight, setElementHeight] = useState(element.settings?.elementHeight ? element.settings.elementHeight : 100);
    const [wrapAlign, setWrapAlign] = useState('');
    const [textAlign, setTextAlign] = useState('');
    const [textColor, setTextColor] = useState(element.settings?.textColor ? element.settings.textColor : '#000000');
    const [fontSize, setFontSize] = useState(element.settings?.fontSize ? element.settings.fontSize : '16');
    const [fontWeight, setFontWeight] = useState(element.settings?.fontWeight ? element.settings.fontWeight : 'normal');
    const [backgroundColor, setBackgroundColor] = useState(element.settings?.backgroundColor ? element.settings.backgroundColor : '#FFFFFF');
    const [marginTop, setMarginTop] = useState(element.settings?.marginTop ? element.settings.marginTop : 0);
    const [marginRight, setMarginRight] = useState(element.settings?.marginRight ? element.settings.marginRight : 0);
    const [marginBottom, setMarginBottom] = useState(element.settings?.marginBottom ? element.settings.marginBottom : 0);
    const [marginLeft, setMarginLeft] = useState(element.settings?.marginLeft ? element.settings.marginLeft : 0);
    const [paddingTop, setPaddingTop] = useState(element.settings?.paddingTop ? element.settings.paddingTop : 0);
    const [paddingRight, setPaddingRight] = useState(element.settings?.paddingRight ? element.settings.paddingRight : 0);
    const [paddingBottom, setPaddingBottom] = useState(element.settings?.paddingBottom ? element.settings.paddingBottom : 0);
    const [paddingLeft, setPaddingLeft] = useState(element.settings?.paddingLeft ? element.settings.paddingLeft : 0);
    const [border, setBorder] = useState('none');
    const [borderTop, setBorderTop] = useState(element.settings?.borderTop ? element.settings.borderTop : 0);
    const [borderRight, setBorderRight] = useState(element.settings?.borderRight ? element.settings.borderRight : 0);
    const [borderBottom, setBorderBottom] = useState(element.settings?.borderBottom ? element.settings.borderBottom : 0);
    const [borderLeft, setBorderLeft] = useState(element.settings?.borderLeft ? element.settings.borderLeft : 0);
    const [borderColor, setBorderColor] = useState(element.settings?.borderColor ? element.settings.borderColor : '#000000');
    const [borderRadius, setBorderRadius] = useState(element.settings?.borderRadius ? element.settings.borderRadius : 0);

    useEffect(() => {updateElement(sectionIndex, elementIndex, {url: image})}, [image])
    useEffect(() => {updateElement(sectionIndex, elementIndex, {imageData})}, [imageData])
    useEffect(() => {updateElement(sectionIndex, elementIndex, {text: theText})}, [theText])

    useEffect(() => {
        console.log('updating element')
        updateElement(sectionIndex, elementIndex, {imageWidth, imageHeight, contentWidth, elementWidth, elementHeight, wrapAlign, 
            textAlign, textColor, backgroundColor, marginTop, marginRight, marginBottom, marginLeft,
            paddingTop, paddingRight, paddingBottom, paddingLeft, border, borderTop, borderRight, borderBottom, borderLeft,
        borderColor, borderRadius, fontSize, fontWeight})
    }, [imageWidth, imageHeight, contentWidth, elementWidth, elementHeight, wrapAlign, textAlign, textColor, backgroundColor, marginTop, marginRight, marginBottom, marginLeft,
            paddingTop, paddingRight, paddingBottom, paddingLeft, border, borderTop, borderRight, borderBottom, borderLeft,
        borderColor, borderRadius, fontSize, fontWeight])
 
    return (
        <div className='module-block'>
            <details>
                <summary>
                    <div className='summary-left'>
                        <FontAwesomeIcon icon={element.type === 'image' ? faImage : element.type.startsWith('registration') ? faBracketsCurly : faText} />
                        <div className='summary-title'>{element.type} Module</div>
                    </div>
                    <div className='summary-right'>
                        {type < 3 &&
                            <>
                                <button onClick={() => moveElementLeft(sectionIndex, elementIndex)}><FontAwesomeIcon icon={faArrowAltLeft} /></button>
                                <button onClick={() => moveElementRight(sectionIndex, elementIndex)}><FontAwesomeIcon icon={faArrowAltRight} /></button>
                            </>
                        }
                        <button onClick={() => removeElement(sectionIndex, elementIndex)}><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </summary>
                <div className='module-settings'>
                    <div className='module-settings-tabs'>
                        <div role='button' 
                                className={'module-settings-tab' + (tab === 0 ? ' selected' : '')}
                                onClick={() => setTab(0)}>
                            <FontAwesomeIcon icon={faPencil} />
                            <div className='module-settings-tab-label'>Content</div>
                        </div>
                        <div role='button' 
                                className={'module-settings-tab' + (tab === 1 ? ' selected' : '')}
                                onClick={() => setTab(1)}>
                            <FontAwesomeIcon icon={faFillDrip} />
                            <div className='module-settings-tab-label'>Style</div>
                        </div>
                        <div role='button' 
                                className={'module-settings-tab' + (tab === 2 ? ' selected' : '')}
                                onClick={() => setTab(2)}>
                            <FontAwesomeIcon icon={faTools} />
                            <div className='module-settings-tab-label'>Advanced</div>
                        </div>
                    </div>
                    <div className='module-settings-container'>
                        {tab === 0 &&
                            <>
                            {element.type === 'image' &&
                                <>
                                    <label>Image</label>
                                    <PageImage 
                                        width='278' 
                                        height='100'
                                        value={imageData} 
                                        onChange={(img) => setImageData(img)} />
                                </>
                            }
                            {element.type === 'text' &&
                                <>
                                    <EditableDiv value={theText} onChange={html => setTheText(html)}/>
                                    <label>Font size</label>
                                    <SliderSetting value={fontSize} onChange={setFontSize} />
                                    <label>Font weight</label>
                                    <select value={fontWeight} onChange={event => setFontWeight(event.target.value)}>
                                        <option value='normal'>Normal</option>
                                        <option value='bold'>Bold</option>
                                        <option value='bolder'>Bolder</option>
                                        <option value='lighter'>Lighter</option>
                                    </select>
                                </>
                            }
                            {
                                element.type === 'button' &&
                                <>
                                    <label>Label</label>
                                    <input type='text' value={theText} onChange={evt => setTheText(evt.target.value)} maxLength={32} />
                                    <label>Url</label>
                                    <input type='text' value={theUrl} onChange={evt => setTheUrl(evt.target.value)} maxLength={128} />

                                </>
                            }
                            {element.type.startsWith('registration') &&
                                <>
                                    <label>Font size</label>
                                    <SliderSetting value={fontSize} onChange={setFontSize} />
                                    <label>Font weight</label>
                                    <select value={fontWeight} onChange={event => setFontWeight(event.target.value)}>
                                        <option value='normal'>Normal</option>
                                        <option value='bold'>Bold</option>
                                        <option value='bolder'>Bolder</option>
                                        <option value='lighter'>Lighter</option>
                                    </select>
                                </>
                            }
                            </>
                        }
                        {tab === 1 &&
                            <>
                                <h5>Element</h5>
                                
                                {element.type === 'image' ?
                                    <>
                                        <h5>Image size</h5>
                                        <label>Width</label>
                                        <input type='number' min={50} max={1000} value={imageWidth} onChange={(evt) => setImageWidth(evt.target.value)}/>
                                        <label>Height</label>
                                        <input type='number' min={50} max={1000} value={imageHeight} onChange={(evt) => setImageHeight(evt.target.value)}/>

                                    </>
                                :
                                    <>
                                        <label>Width</label>
                                        <SliderSetting value={elementWidth} onChange={setElementWidth} />
                                        <label>Height</label>
                                        <SliderSetting value={elementHeight} onChange={setElementHeight} />
                                        <h5>Content</h5>
                                        <label>Width</label>
                                        <SliderSetting value={contentWidth} onChange={setContentWidth} />
                                        <label>Wrap align</label>
                                        <ButtonSetting 
                                            buttons={
                                                [
                                                    {label:'Left', value:'left'},
                                                    {label:'Center', value:'center'},
                                                    {label:'Right', value:'right'}
                                                ]
                                            }
                                            value={wrapAlign}
                                            onChange={setWrapAlign}
                                        />
                                        <label>Text align</label>
                                        <ButtonSetting 
                                            buttons={
                                                [
                                                    {label:'Left', value:'left'},
                                                    {label:'Center', value:'center'},
                                                    {label:'Right', value:'right'}
                                                ]
                                            }
                                            value={textAlign}
                                            onChange={setTextAlign}
                                        />
                                        <label>Text color</label>
                                        <ColorSetting value={textColor} onChange={setTextColor} />
                                        <label>Background color</label>
                                        <ColorSetting value={backgroundColor} onChange={setBackgroundColor} />
                                    </>
                                }
                            </>
                        }
                        {tab === 2 &&
                            <>
                                <h5>Spacing</h5>
                                <label>Margin</label>
                                <TableSetting 
                                    top={marginTop} right={marginRight} bottom={marginBottom} left={marginLeft}
                                    onChangeTop={setMarginTop} onChangeRight={setMarginRight} onChangeBottom={setMarginBottom} onChangeLeft={setMarginLeft}
                                    units='px'
                                />
                                <label>Padding</label>
                                <TableSetting 
                                    top={paddingTop} right={paddingRight} bottom={paddingBottom} left={paddingLeft}
                                    onChangeTop={setPaddingTop} onChangeRight={setPaddingRight} onChangeBottom={setPaddingBottom} onChangeLeft={setPaddingLeft}
                                    units='px'
                                />
                                <h5>Border</h5>
                                <label>Border style</label>
                                <ButtonSetting 
                                    buttons={
                                        [
                                            {label:'None', value:'none'},
                                            {label:'Solid', value:'solid'},
                                            {label:'Dashed', value:'dashed'},
                                            {label:'Double', value:'double'},
                                            {label:'Dotted', value:'dotted'}
                                        ]
                                    }
                                    value={border}
                                    onChange={setBorder}
                                />
                                <label>Border width</label>
                                <TableSetting 
                                    top={borderTop} right={borderRight} bottom={borderBottom} left={borderLeft}
                                    onChangeTop={setBorderTop} onChangeRight={setBorderRight} onChangeBottom={setBorderBottom} onChangeLeft={setBorderLeft}
                                    units='px'
                                />
                                <label>Border color</label>
                                <ColorSetting value={borderColor} onChange={setBorderColor} />
                                <label>Border radius</label>
                                <SliderSetting value={borderRadius} onChange={setBorderRadius} max={30} unit='px' />
                            </>
                        }
                    </div>
                </div>
            </details>
        </div>
    )
}

function SliderSetting({min=0, max=100, unit='%', value, onChange}) {
    return (
        <div className='module-setting'>
            <input type='range' min={min} max={max} value={value} onChange={evt => onChange(evt.target.value)}/>
            <div className='module-setting-value'>{`${value}${unit}`}</div>
        </div>
    )
}

function ColorSetting({value, onChange}) {
    return (
        <div className='module-setting'>
            <input type='color' value={value} onChange={evt => onChange(evt.target.value)} />
        </div>
    )
}

function ButtonSetting({buttons, value, onChange}) {
    return (
        <div className='module-setting centered'>
            <div className='button-setting-buttons'>
                {buttons.map((btn, idx) => 
                    <div role='button' 
                        key={idx} 
                        className={'setting-button' + (btn.value === value ? ' selected' : '')}
                        onClick={() => onChange(btn.value)}>
                            {btn.label}
                    </div>)
                }
            </div>
        </div>
    )
}

function TableSetting({top, right, bottom, left, onChangeTop, onChangeRight, onChangeBottom, onChangeLeft, units}) {
    return (
        <div className='module-setting'>
            <div className='table-setting'>
                <table>
                    <thead><tr><th>Top</th><th>Right</th><th>Bottom</th><th>Left</th></tr></thead>
                    <tbody>
                        <tr>
                            <td><input type='number' value={top} onChange={evt => onChangeTop(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={right} onChange={evt => onChangeRight(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={bottom} onChange={evt => onChangeBottom(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={left} onChange={evt => onChangeLeft(evt.target.value)} min={0} max={100} /></td>
                        </tr>
                        <tr><td>{units}</td><td>{units}</td><td>{units}</td><td>{units}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
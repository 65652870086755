import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import SidePanel from 'components/sidePanel';
import 'style/schedule.scss';

const ScheduleContext = React.createContext(null);

function ScheduleProvider({jsonStr, onChange, children}) {
    const [days, setDays] = useState([]);

    useEffect(() => {
        if (jsonStr && jsonStr.length > 0) {
            try {
                setDays(JSON.parse(jsonStr));
            } catch (err) {
                console.error('Error parsing json:' + err);
            }
        }
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(JSON.stringify(days));
        }
    }, [days])

    const addDay = (day) => {
        const maxId = days.length > 0 ? Math.max(...days.map(d => d.id)) : 0;
        day.id = maxId + 1;
        setDays([...days, day]);
    }

    const updateDay = (day) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            _days[idx].title = day.title;
            setDays(_days);
        }
    }

    const removeDay = (day) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            _days.splice(idx, 1);
            setDays(_days);
        }
    }

    const addItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            let maxId = data.length > 0 ? Math.max(data.map(i => i.id)) : 0;
            item.id = maxId + 1;
            data.push(item);
            _days[idx].data = [...data];
            setDays(_days);
        }
    }

    const updateItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            const itemIdx = data.findIndex(i => i.id === item.id);
            if (itemIdx >= 0) {
                data.splice(itemIdx, 1, item);
                _days[idx].data = [...data];
                setDays(_days);
            }
        }
    }

    const removeItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            const itemIdx = data.findIndex(i => i.id === item.id);
            if (itemIdx >= 0) {
                data.splice(itemIdx, 1);
                _days[idx].data = [...data];
                setDays(_days);
            }
        }
    }

    const provider = {
        days,
        addDay,
        updateDay,
        removeDay,
        addItem,
        updateItem,
        removeItem,
    }

    return <ScheduleContext.Provider value={provider}>{children}</ScheduleContext.Provider>
}

function useSchedule() {
    const context = React.useContext(ScheduleContext);
    if (!context) {
        throw new Error('useSchedule must be used within an ScheduleContext provider');
    }
    return context;
}

export { ScheduleProvider, Schedule }

function Schedule() {
    const schedule = useSchedule();
    const dayPanel = useRef();
    const itemPanel = useRef();
    const [dayTitle, setDayTitle] = useState('');
    const [editingDay, setEditingDay] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [label, setLabel] = useState('');
    const [editingItem, setEditingItem] = useState(null);

    const editDay = (day) => {
        setDayTitle(day.title);
        setEditingDay(day);
        dayPanel.current.show();
    }

    const saveDay = () => {
        if (editingDay) {
            const day = {...editingDay, title: dayTitle};
            schedule.updateDay(day);
        } else {
            const day = {title: dayTitle, data: []};
            schedule.addDay(day);
        }
        onCloseDayPanel();
    }

    const onCloseDayPanel = () => {
        setDayTitle('');
        setEditingDay(null);
        dayPanel.current.hide();
    }

    const removeDay = (day) => {
        schedule.removeDay(day);
    }
    // item
    const addItem = (day) => {
        setEditingDay(day);
        itemPanel.current.show();
    }

    const editItem = (day, item) => {
        setEditingDay(day);
        setStartTime(item.startTime);
        setEndTime(item.endTime);
        setLabel(item.label);
        setEditingItem(item);
        itemPanel.current.show();
    }

    const saveItem = () => {
        if (editingItem) {
            const item = {...editingItem, startTime, endTime, label};
            schedule.updateItem(editingDay, item);
        } else {
            const item = {startTime, endTime, label};
            schedule.addItem(editingDay, item);
        }
        onCloseItemPanel();
    }

    const onCloseItemPanel = () => {
        setEditingDay(null);
        setStartTime('');
        setEndTime('');
        setLabel('');
        setEditingItem(null);
        itemPanel.current.hide();
    }

    const removeItem = (day, item) => {
        schedule.removeItem(day, item);
    }

    return (
        <>
            <p>
                Click <button onClick={() => dayPanel.current.show()}>Add Day</button> to add a new day to the schedule. 
                Use the buttons within the row of the day label to manage the day. Use the pencil to edit a day label,
                the trashcan to delete the day and all its items, and use the plus to add an item to the day. 
                Use the buttons within the row of the item label to manage the item. Use the pencil to edit a label or
                start and end times, and the trashcan to remove the item.<br/><br/>
                Note that changes are not saved until you save the event using the button at the bottom of all the event details.
            </p>
            <div className='eventSchedule'>
                {schedule && 
                    schedule.days.map((day) =>
                        <div key={day.id}> 
                            <div className='day'>
                                {day.title}
                                <div className='actionButtons'>
                                    <button onClick={() => editDay(day)}><FontAwesomeIcon icon={faPencil} /></button>
                                    <button onClick={() => removeDay(day)}><FontAwesomeIcon icon={faTrash} /></button>
                                    <button onClick={() => addItem(day)}><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                            </div>
                            {day.data.map(item => 
                                <div key={item.id}>
                                    <div className='item'>
                                        {`${item.startTime} - ${item.endTime} ${item.label}`}
                                        <div className='actionButtons'>
                                            <button onClick={() => editItem(day, item)}><FontAwesomeIcon icon={faPencil} /></button>
                                            <button onClick={() => removeItem(day, item)}><FontAwesomeIcon icon={faTrash} /></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
            <SidePanel title='Day' ref={dayPanel}>
                <div>
                    <label>Day Title</label><br/>
                    <input type='text' value={dayTitle} onChange={e => setDayTitle(e.target.value)} maxLength={32} />
                </div>
                <button onClick={onCloseDayPanel}>Cancel</button>
                <button onClick={saveDay}>{editingDay ? 'Modify' : 'Add'}</button>
            </SidePanel>
            <SidePanel title='Schedule Item' ref={itemPanel}>
                <div>
                    <label>Start Time</label><br/>
                    <input type='text' value={startTime} onChange={e => setStartTime(e.target.value)} maxLength={24} />
                </div>
                <div>
                    <label>End Time</label><br/>
                    <input type='text' value={endTime} onChange={e => setEndTime(e.target.value)} maxLength={24} />
                </div>
                <div>
                    <label>Label</label><br/>
                    <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength={32} />
                </div>
                <button onClick={onCloseItemPanel}>Cancel</button>
                <button onClick={saveItem}>{editingItem ? 'Modify' : 'Add'}</button>
            </SidePanel>
        </>
    )
}

const style = {
    day: {
        backgroundColor: '#ddd'
    }
}
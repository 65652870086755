import React, { useEffect, useRef, useState } from 'react';
import 'style/mfaCode.scss';

function MFACode(props) {
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [code5, setCode5] = useState('');
    const [code6, setCode6] = useState('');
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);
    const input5 = useRef(null);
    const input6 = useRef(null);

    useEffect(() => {
        input1.current.focus();
    }, [])

    useEffect(() => {
        if (props.onChange) {
            const val = code1 + code2 + code3 + code4 + code5 + code6;
            props.onChange(val)
        }
    }, [code1, code2, code3, code4, code5, code6]);

    const code1Handler = (e) => {
        setCode1(e.target.value);
        input2.current.focus();
    }

    const code2Handler = (e) => {
        setCode2(e.target.value);
        input3.current.focus();
    }

    const code3Handler = (e) => {
        setCode3(e.target.value)
        input4.current.focus();
    }

    const code4Handler = (e) => {
        setCode4(e.target.value);
        input5.current.focus();
    }

    const code5Handler = (e) => {
        setCode5(e.target.value);
        input6.current.focus();
    }

    const code6Handler = (e) => {
        setCode6(e.target.value);
    }

    const keyCheckHandler = (e, input) => {
        console.log('keycheck');
        if (e.key === 'Backspace') {
            switch (input) {
                case 1: setCode1(''); break;
                case 2: setCode2(''); input1.current.focus(); break;
                case 3: setCode3(''); input2.current.focus(); break;
                case 4: setCode4(''); input3.current.focus(); break;
                case 5: setCode5(''); input4.current.focus(); break;
                case 6: setCode6(''); input5.current.focus(); break;
            }
            e.preventDefault();
        } else if (e.key === 'Enter' &&  input === 6) {
            props.onEnter();
        }
    }

    return (
        <div className='mfaCode'>
            <input type='text' value={code1} onChange={code1Handler} onKeyDown={(e) => keyCheckHandler(e, 1)} ref={input1} maxLength='1' autoComplete='off'></input>
            <input type='text' value={code2} onChange={code2Handler} onKeyDown={(e) => keyCheckHandler(e, 2)} ref={input2} maxLength='1' autoComplete='off'></input>
            <input type='text' value={code3} onChange={code3Handler} onKeyDown={(e) => keyCheckHandler(e, 3)} ref={input3} maxLength='1' autoComplete='off'></input>
            <div className='dash'>-</div>
            <input type='text' value={code4} onChange={code4Handler} onKeyDown={(e) => keyCheckHandler(e, 4)} ref={input4} maxLength='1' autoComplete='off'></input>
            <input type='text' value={code5} onChange={code5Handler} onKeyDown={(e) => keyCheckHandler(e, 5)} ref={input5} maxLength='1' autoComplete='off'></input>
            <input type='text' value={code6} onChange={code6Handler} onKeyDown={(e) => keyCheckHandler(e, 6)} ref={input6} maxLength='1' autoComplete='off'></input>
        </div>
    )
}

export default MFACode;
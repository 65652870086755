export let permission = {};

function initPermissions() {
    permission.SYSTEMADMIN = 1;
	permission.ACCOUNTADMIN = 2
	permission.GROUPADMIN = 15;
	permission.RESELLER = 16;
	permission.ORGADMIN = 17;
    permission.CUSTOMERSERVICE = 18;

	permission.EVENTADMIN = 3;
	permission.MEMBERSHIP = 4;
	permission.BALLOTING = 5;
	permission.REGISTRATION = 6;
	permission.SCHEDULE = 7;
    permission.FINANCE = 8;
    permission.STOREADMIN = 11;
    permission.PHONE = 9;
	permission.VIDEO = 12;
}

export function hasPermission(user, perm) {
    if (user.permissions !== null) {
        let hasPerm = user.permissions.filter((p) => p.id === perm);
        return hasPerm.length > 0;
    }
    return false;
}

initPermissions();
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsers, faCalendarStar, faBullhorn, faDollarSign, faChartNetwork, faFile, faUserCrown, faNotesMedical, faCheckCircle, faTimes, faExclamationCircle, faStethoscope} from '@fortawesome/pro-regular-svg-icons';
import {formatCurrency} from '_base';
import { useAuth } from 'contexts/auth';
import Loading from 'components/loading';
import Chart from 'react-apexcharts'
import { useAnalytics } from 'contexts/analytics';
import 'style/dashboard.scss';
import { useHealth } from 'contexts/health';

const options = {
    chart: {type: 'donut', height: '100px', width: '100px'},
    plotOptions: {pie: {startAngle: -90, endAngle: 90, offsetY: 10}},
    grid: {padding:{bottom:-100}},
    legend: {show: true, position: 'bottom'},
    responsive: [{breakpoint:480, options: {chart: {width: 200}}}],
    dataLabels: {enabled: false},
    colors: ['#2599C4', '#FFDD4A']
}

function Dashboard() {
    const auth = useAuth();
    const { getCount, count, tenantCount } = useAnalytics();
    const { healthLoading, health } = useHealth();

    useEffect(() => {
        getCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const membershipDetails = () => {}
    const eventDetails = () => {}
    const campaignDetails = () => {}
    const salesDetails = () => {}

    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Dashboard</div>
                </div>
            </div>
            {count ? 
                <div className='analytic-dashboard'>
                    <div className='analytic-panels_row'>
                        <div className='analytic-group'>
                            <div className='analytic-title'><FontAwesomeIcon icon={faUsers} /> Membership</div>
                            <div className='analytic-subtitle'>(*in the last 30 days)</div>
                            <Chart 
                                options={{...options, labels: ['Active', 'Total']}}
                                series={[count.member.active, (count.member.total)]}
                                type='donut'
                                height='150px'
                            />
                            <div className='analytics-row'>
                                <div className='analytic'>
                                    <div className='analytic-data'>{count.member.active}</div>
                                    <div className='analytic-label'>Active</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{count.member.child}</div>
                                    <div className='analytic-label'>Child</div>
                                </div>
                            </div>
                            <div className='analytics-row'>
                                <div className='analytic'>
                                    <div className='analytic-data'>{count.member["new"]}</div>
                                    <div className='analytic-label'>*New</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{count.member.expiring}</div>
                                    <div className='analytic-label'>*Expiring</div>
                                </div>
                            </div>
                        </div>
                        <div className="analytic-group">
                            <div className='analytic-title'><FontAwesomeIcon icon={faCalendarStar} /> Events</div>
                            <div className='analytic-subtitle'>(*for upcoming events)</div>
                            <Chart 
                                options={{...options, labels: ['Ended', 'Total']}}
                                series={[count.event.ended, (count.event.total)]}
                                type='donut'
                                height='150px'
                            />
                            
                            <div className='analytic'>
                                <div className='analytic-data'>{count.event.upcoming}</div>
                                <div className='analytic-label'>Upcoming</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{count.event.registrations}</div>
                                <div className='analytic-label'>*Registrations</div>
                            </div>
                        </div>
                        <div className="analytic-group">
                            <div className='analytic-title'><FontAwesomeIcon icon={faBullhorn} /> Campaigns</div>
                            <div className='analytic-subtitle'>(*in the last 30 days)</div>
                            <Chart 
                                options={{...options, labels: ['Templates', 'Campaigns']}}
                                series={[count.campaign.templates, (count.campaign.total)]}
                                type='donut'
                                height='150px'
                            />
                            
                            <div className='analytic'>
                                <div className='analytic-data'>{tenantCount?.tracker.total}</div>
                                <div className='analytic-label'>*Emails Sent</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{tenantCount?.tracker.views}</div>
                                <div className='analytic-label'>*Email views</div>
                            </div>
                        </div>
                        <div className="analytic-group">
                            <div className='analytic-title'><FontAwesomeIcon icon={faDollarSign} /> Sales</div>
                            <div className='analytic-subtitle'>(*in the last 30 days)</div>
                            <div className='analytic live' onClick={salesDetails}>
                                <div className='analytic-data'>{count.receipt.total}</div>
                                <div className='analytic-label'>*Total</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{formatCurrency(count.receipt.amount/100)}</div>
                                <div className='analytic-label'>*Amount</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{formatCurrency(count.receipt.fee/100)}</div>
                                <div className='analytic-label'>*Fees</div>
                            </div>
                        </div>
                    </div>
                    <div className='analytic-panels_row'>
                        {!healthLoading &&
                        <>
                            <div className="analytic-group">
                                <div className='analytic-title'><FontAwesomeIcon icon={faNotesMedical} /> Finance Health</div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{health?.bankConnection.healthy ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>Bank link setup</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{health?.paymentMethod.hasPaymentMethod ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>Payment method setup</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{!health?.paymentMethod.cardExpired ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>Card {!health?.paymentMethod.cardExpired ? 'valid' : 'expired'}</div>
                                </div>
                            </div>
                            <div className="analytic-group">
                                <div className='analytic-title'><FontAwesomeIcon icon={faStethoscope} /> Member Health</div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{health?.members.missingEmailAddress === 0 ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>{health?.members.missingEmailAddress} Members missing email</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{health?.members.duplicateMemberSettings === 0 ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>{health?.members.duplicateMemberSettings} Members with duplicate settings</div>
                                </div>
                                <div className='analytic'>
                                    <div className='analytic-data'>{health?.members.unusedTags === 0 ? <FontAwesomeIcon icon={faCheckCircle} style={{color:'#21AA3E'}} /> :  <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#B00020'}} />}</div>
                                    <div className='analytic-label'>{health?.members.unusedTags} Unused tags</div>
                                </div>
                            </div>
                        </>
                        }
                        <div className="analytic-group">
                            <div className='analytic-title'><FontAwesomeIcon icon={faChartNetwork} /> Member Activity</div>
                            <div className='analytic-subtitle'>(Active members only)</div>
                            <div className='analytic'>
                                <div className='analytic-data'>{count.member.portalLogins}</div>
                                <div className='analytic-label'>Portal logins</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{count.member.mailNewsletter}</div>
                                <div className='analytic-label'>Mail enabled</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{count.member.emailNewsletter}</div>
                                <div className='analytic-label'>Email enabled</div>
                            </div>
                        </div>
                        <div className="analytic-group">
                            <div className='analytic-title'><FontAwesomeIcon icon={faUserCrown} /> Admin Activity</div>
                            <div className='analytic-subtitle'>(*in the last 30 days)</div>
                            <div className='analytic'>
                                <div className='analytic-data'>{tenantCount?.admin.total}</div>
                                <div className='analytic-label'>Total</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{tenantCount?.admin.logins}</div>
                                <div className='analytic-label'>Total logins</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{tenantCount?.admin.loginTotal}</div>
                                <div className='analytic-label'>*Logins</div>
                            </div>
                        </div>
                    </div>
                </div>
            : <Loading />}
        </div>
    )
}

export default Dashboard;
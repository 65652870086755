import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Finished(props) {
    const navigate = useNavigate();

    return (
        <div>
            <div className='setupInstructions'>
                <p>Ready... Set... Go!</p>
            </div>
            <div className='setupPane' style={{top:'150px'}}>
                <div style={{width:'70%',margin:'50px auto'}}>
                    <div className='bigIcon'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    <div style={{fontSize:'24px'}}>
                        <p>Your account is all set up and has that new account smell.</p>
                        <p>Resources for learning the system are available in the support area (look for the question mark icon).</p>
                        <p>Contact us if you get stuck at support@middletwin.com.</p>
                        <p>You can manage all the settings you just setup in the setting panel (look for the gear icon)</p>
                        
                        <p style={{textAlign:'center'}}>
                            <button className='linkButton' style={{fontSize: '36px'}} onClick={() => navigate('/admin/dashboard')}>Go to Middletwin</button>
                        </p>
                    </div>
                </div>
                <div className='setupPaneFooter'>
                    <button className='linkButton' onClick={props.onBack}>Back</button>
                    
                </div>
            </div>
        </div>
    )
}

export default Finished;
import { faAddressCard, faFile, faFileAudio, faFileExcel, faFileImage, faFilePdf, faFileVideo, faQuestion } from "@fortawesome/pro-regular-svg-icons";

export let contentType = {}

function initContentTypes() {
    contentType.types = [
        {type:"audio/basic", ext:"snd", icon: faFileAudio},
        {type:"audio/L24", ext:"l24", icon: faFileAudio},
        {type:"audio/mp4", ext:"mp4", icon: faFileAudio},
        {type:"audio/mpeg", ext:"mp3", icon: faFileAudio},
        {type:"audio/mpeg3",ext:"mp3", icon: faFileAudio},
        {type:"audio/ogg", ext:"oga", icon: faFileAudio},
        {type:"audio/vorbis", ext:"ogg", icon: faFileAudio},
        {type:"audio/vnd.rn-realaudio", ext:"ram", icon: faFileAudio},
        {type:"audio/vnd.wave", ext:"wav", icon: faFileAudio},
        {type:"audio/vnd.wav", ext:"wav", icon: faFileAudio},
        {type:"audio/3gpp", ext:"3gp", icon: faFileAudio},
        {type:"audio/3gpp2", ext:"3g2", icon: faFileAudio},
        {type:"audio/ac3", ext:"ac3", icon: faFileAudio},
        {type:"audio/webm", ext:"wbm", icon: faFileAudio},
        {type:"audio/amr-nb", ext:"amr", icon: faFileAudio},
        {type:"audio/amr", ext:"amr", icon: faFileAudio},
        // video
        {type:"video/mpeg", ext:"mp3", icon: faFileVideo},
        {type:"video/mp4", ext:"mp4", icon: faFileVideo},
        {type:"video/quicktime", ext:"mov", icon: faFileVideo},
        {type:"video/webm", ext:"wbm", icon: faFileVideo},
        {type:"video/3gpp", ext:"3gp", icon: faFileVideo},
        {type:"video/3gpp2", ext:"3g2", icon: faFileVideo},
        {type:"video/3gpp-tt", ext:"3gt", icon: faFileVideo},
        {type:"video/H261", ext:"", icon: faFileVideo},
        {type:"video/H263", ext:"", icon: faFileVideo},
        {type:"video/H263-1998", ext:"", icon: faFileVideo},
        {type:"video/H263-2000", ext:"", icon: faFileVideo},
        {type:"video/H264", ext:"", icon: faFileVideo},
        // image
        {type:"image/jpeg", ext:"jpeg", icon: faFileImage}, // supported
        {type:"image/gif", ext:"gif", icon: faFileImage}, // supported
        {type:"image/png", ext:"png", icon: faFileImage}, // supported
        {type:"image/bmp", ext:"bmp", icon: faFileImage},
        // text
        {type:"text/vcard", ext:"vcf", icon: faAddressCard},
        {type:"text/csv", ext:"csv", icon: faFileExcel},
        {type:"text/rtf", ext:"rtf", icon: faFile},
        {type:"text/richtext", ext:"rtf", icon: faFile},
        {type:"text/calendar", ext:"cal", icon: faFile},
        {type:"text/directory", ext:"dir", icon: faFile},
        // application
        {type:"application/pdf", ext:"pdf", icon: faFilePdf}
    ];
}

export function isSupported(type) {
    var supported = contentType.types.find((ct) => {return ct.type === type;});
    return supported;
};
export function isSupportedImageType(type) {
    return type.startsWith("image") && isSupported(type);
}
export function getIcon(type) {
    let foundFileType = contentType.types.find((ct) => {return ct.type === type;});
    if (foundFileType) {
        return foundFileType.icon;
    }
    return faQuestion;
}
initContentTypes();
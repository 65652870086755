import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const MemberSettingsContext = React.createContext(null);

function MemberSettingsProvider({memberId, children}) {
    const { runAction } = useCore();
    const [settingsLoading, setSettingsLoading] = useState(true);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        if (memberId) {
            loadSettings();
        }
    }, [memberId])

    const loadSettings = () => {
        const data = {memberId};
        runAction('get_member_settings', data, response => {
            setSettings(response.settings);
            setSettingsLoading(false);
        })
    }

    const saveSettings = (settings) => {
        runAction('update_member_settings', settings, () => {
            loadSettings();
        })
    }

    const provider = {
        settingsLoading,
        settings,
        saveSettings
    }

    return <MemberSettingsContext.Provider value={provider}>{children}</MemberSettingsContext.Provider>
}

function useMemberSettings() {
    const context = React.useContext(MemberSettingsContext);
    if (!context) {
        throw new Error('useMemberSettings must be used within a MemberSettingsProvider');
    }
    return context;
}

export { MemberSettingsProvider, useMemberSettings }
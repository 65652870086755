import { useFiles } from 'contexts/files';
import React, { useState } from 'react';

function MoveToFolder({onContinue}) {
    const { folders } = useFiles();
    const [moveToFolder, setMoveToFolder] = useState('0');

    const continueHandler = () => {
        onContinue(moveToFolder);
    }

    return (
        <div className='contact-preview'>
            <p>Choose the folder you would like to move the selected files to.</p>
            <label>Folder</label>
            <select value={moveToFolder} onChange={e => setMoveToFolder(e.target.value)}>
                <option value='0'>/</option>
                {folders.map(f => <option key={f.id} value={f.id}>/{f.label}</option>)}
            </select>
            <div style={{marginTop:'10px'}}>
                <button onClick={continueHandler}>Continue</button>
            </div>
        </div>
    )
}

export default MoveToFolder;
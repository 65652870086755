import React, { useRef } from 'react';
import AmazingTable from 'components/amazingTable';
import NewCategory from './newCategory';
import SidePanel from 'components/sidePanel';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useStore } from 'contexts/store';

const categoryColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "name", label:"Name", display: true, datum: "name"}
];

function Categories() {
    const { storeLoading, loadStore, categories, createCategory } = useStore();
    const newCategoryPanel = useRef(null);
    
    const saveCategory = (data) => {
        createCategory(data, () => {
            newCategoryPanel.current.hide()
        })
    }
    
    return(
        <div>
            <TableSetupProvider tableName='category' defaultColumns={categoryColumns}>
                <AmazingTable
                    rows={categories}
                    onRefresh={loadStore}
                    onCreate={() => newCategoryPanel.current.show()}
                    loading={storeLoading} />
            </TableSetupProvider>
            <SidePanel title='New Category' ref={newCategoryPanel}>
                <NewCategory onSave={saveCategory} />
            </SidePanel>
        </div>
    )
}

export default Categories;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Notifications from 'components/notifications';
import 'style/signup.scss';
import 'style/slide.scss';
import ProgressIndicator from 'components/progressIndicator';
import logo from 'assets/logo-navy.png';
import Email from './slides/email';
import Password from './slides/password';
import Basic from './slides/basic';
import Finish from './slides/finish';

const signupSteps = [
    {id: 'email', label: 'Email Address', complete: false, state: 'active'},
    {id: 'password', label: 'Account Password', complete: false, state: 'todo'},
    {id: 'address', label: 'Organization Info', complete: false, state: 'todo'},
    {id: 'finish', label: 'Finish', complete: false, state: 'todo'},
];

function Signup() {
    const navigate = useNavigate();
    const [steps, setSteps] = useState(signupSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(noActives.toSpliced(currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    const completeStepHandler = () => {
        setSteps(steps.toSpliced(currentStep, 1, {...steps[currentStep], complete: true}));
        setCurrentStep(currentStep+1);
    }

    const gotoLogin = () => {
        navigate('/login');
    }

    const gotoMiddletwin = () => {
        window.location.href = 'https://www.middletwin.com/';
    }

    return (
        <>
        <div className='signup'>
            <div className='signup-inner'>
                <div className='signup-title-area' >
                    <div className='signup-logo-container'>
                        <img src={logo} alt='Middletwin logo' />
                    </div>
                    <div className='signup-title-container'>
                        <h2>Signup for a free 30-day trial</h2>
                        <div className='signup-sub-head' >
                            (Do you already have an account?
                            <button className='linkButton' onClick={gotoLogin} style={{fontSize:'20px',fontWeight:'bold'}}>Click here to go to login</button>)
                        </div>
                    </div>
                    <ProgressIndicator steps={steps} activeStep={currentStep} />
                </div>
                <div className='signup-slide-area'>
                    <div className='setupSlide'>
                        {currentStep === 0 && <Step title='Email Address'><Email onComplete={completeStepHandler} /></Step>}
                        {currentStep === 1 && <Step title='Account Password'><Password onComplete={completeStepHandler} /></Step>}
                        {currentStep === 2 && <Step title='Organization info'><Basic onComplete={completeStepHandler} /></Step>}
                        {currentStep === 3 && <Step title='Finish'><Finish onComplete={gotoMiddletwin} /></Step>}
                    </div>

                </div>
            </div>
            <Notifications />
        </div>
        
        </>
    )
}

function Step({title, children}) {

    return (
        <div>
            <div className='title'>{title}</div>
            <div className='body'>{children}</div>
        </div>
    )
}

export default Signup;
import React, {useState, useEffect} from 'react';
import QRCode from 'react-qr-code';
import 'style/dashCard.scss';

function DefaultDashCard({tenantId, eventId, entryData}) {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (entryData) {
            setName(entryData.fname + ' ' + entryData.lname);
            setLocation(entryData.city + ', ' + entryData.state);
        }
    }, [entryData]);

    return (
        <div>
            <div>
                <div className='dashCard'>
                    <div className='field year'>{entryData.year}</div>
                    <div className='field className'>{entryData.car_number}</div>
                    <div className='field make'>{entryData.make}</div>
                    <div className='field model'>{entryData.model}</div>
                    <div className='field owner'>{name}</div>
                    <div className='field location'>{location}</div>
                    <div className='field qrcode'>
                        <QRCode size='100' value={`https://app.middletwin.com/vote/${tenantId}/${eventId}/${entryData.car_number}`}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultDashCard;
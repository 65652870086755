import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SubscriptionTypesContext = React.createContext(null);

function SubscriptionTypesProvider({groupId, children}) {
    const { runAction } = useCore();
    const [types, setTypes] = useState([]);

    useEffect(() => {
        getTypes();
    }, []);

    const getTypes = () => {
        runAction('get_subscription_types', {affiliateId: groupId}, response => {
            setTypes(response.types);
        });
    }

    const saveSubscriptionType = (settings, callback) => {
        const data = {...settings, affiliateId: groupId};
        const action = data.id ? 'update_subscription_type' : 'create_subscription_type';
        runAction(action, data, () => {
            getTypes();
            callback();
        });
    }

    const provider = {
        types,
        getTypes,
        saveSubscriptionType
    }

    return <SubscriptionTypesContext.Provider value={provider}>{children}</SubscriptionTypesContext.Provider>
}

function useSubscriptionTypes() {
    const context = React.useContext(SubscriptionTypesContext);
    if (!context) {
        throw new Error('useSubscriptionTypes must be used within a SubscriptionTypesProvider');
    }
    return context;
}

export { SubscriptionTypesProvider, useSubscriptionTypes }
import React, { useEffect, useState } from 'react';
import Core from '../../util/core';
import { useVideo } from '../../contexts/video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';

function RoomList() {
    const video = useVideo();
    const [rooms, setRooms] = useState([]);
    
    useEffect(() => {
        getRoomList();
    }, [])

    const getRoomList = () => {
        Core.runAction('get_rooms', {}, response => {
            if (response && response.success) {
                setRooms(response.roomList);
            }
        });
    }

    const selectRoom = room => {
        video.updateRoomSid(room.roomSid);
        video.updateRoomName(room.name);
    }

    return (
        <React.Fragment>
            <div>
                <label>Select a meeting to join <FontAwesomeIcon icon={faSync} onClick={getRoomList}/></label>
                <ul style={{listStyle:'none'}}>
                    {!rooms || rooms.length === 0 
                        ? <li>There are no meetings</li> 
                        : rooms.map((room, idx) => <li key={idx} onClick={() => selectRoom(room)}>{room.name}</li>)
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default RoomList;
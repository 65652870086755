import React from 'react';
import { useCore } from './core';

const BallotContext = React.createContext(null);

function BallotProvider({children}) {
    const { runAction } = useCore();

    const createBallot = (data, callback) => {
        runAction('create_ballot', data, (response) => {
            callback();
        });
    }

    const provider = {
        createBallot
    }

    return <BallotContext.Provider value={provider}>{children}</BallotContext.Provider>
}

function useBallot() {
    const context = React.useContext(BallotContext);
    if (!context) {
        throw new Error('useBallot must be used within a BallotProvider');
    }
    return context;
}

export { BallotProvider, useBallot }
import React, { useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faFileDownload, faEnvelope, faSync, faCog, faFileUpload,
    faEdit, faFileAlt, faIdCard, faSpinner, faCopy, faBroom,
    faPrint,
    faBellOn} from '@fortawesome/pro-regular-svg-icons';
import Checkbox from './checkbox';
import AmazingTableRow from './AmazingTableRow';
import 'style/amazingTable.scss'
import IconButton from './iconButton';
import AmazingSearch from './amazingSearch';
import Pagination from './pagination';
import { useTableSetup } from 'contexts/tableSetup';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function AmazingTable ({ 
        createDisabled,
        dates,
        filters, 
        hasPagination,
        hasSearch = false,
        hasLimitSelector = false,
        loading,
        rows,
        sorters,
        // events
        onCards,
        onClean,
        onCopy,
        onCreate,
        onCreateLabel,
        onDateChange,
        onDelete,
        onEdit,
        onExport,
        onImport,
        onLabel,
        onPrint, 
        onRefresh,
        onReminder,
        onRoster,
        onRowClick,
        onTableChange,
    }) {
    const { columns, updateColumns } = useTableSetup();
    const [filter, setFilter] = useState(null);
    const [sorter, setSorter] = useState(null);
    const [google, setGoogle] = useState('');
    const [columnSelectorOpen, setColumnSelectorOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [limit, setLimit] = useState(25);

    useEffect(() => {
        if (filters) {
            let defaultValue = filters.find((f) => f.isDefault);
            setFilter(defaultValue.state);
        }
        if (sorters) {
            let defaultValue = sorters.find((s) => s.isDefault);
            setSorter(defaultValue.order);
        }
    }, [])

    useEffect(() => {
        if (rows) {
            setRowData(rows);
        }
    }, [rows])

    const searchHandler = (google) => {
        setGoogle(google);
        setSelectedRows([]);
        if (onTableChange) {
            onTableChange({filter, sorter, google});
        }
    }
    const copyHandler = () => {
        if (selectedRows.length > 1) {
            alert('Please select only one row to copy');
            return;
        }
        onCopy(selectedRows);
        setSelectedRows([]);
    }
    const editHandler = () => {
        if (selectedRows.length > 1) {
            alert('Please select only one row to edit');
            return;
        }
        onEdit(selectedRows);
        setSelectedRows([]);
    }
    const deleteHandler = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    }
    const onReminderHandler = () => {
        onReminder(selectedRows);
        setSelectedRows([]);
    }
    const refreshHandler = () => {
        setRowData([]);
        setSelectedRows([]);
        if (onTableChange) {
            onTableChange({filter, sorter, google});
        } else if (onRefresh) {
            onRefresh();
        }
    }
    const filterHandler = (event) => {
        let _filter = event.target.value;
        setFilter(_filter);
        setSelectedRows([]);
        onTableChange({filter: _filter, sorter, google, offset: 0});
    }
    const sorterHandler = (event) => {
        let _sorter = event.target.value;
        setSorter(_sorter);
        setSelectedRows([]);
        onTableChange({filter, sorter: _sorter, google, offset:0});
    }
    const limitHandler = (event) => {
        let _limit = event.target.value;
        setLimit(_limit);
        setSelectedRows([]);
        onTableChange({filter, sorter, google, limit: _limit, offset: 0});
    }
    const showColumnSelector = () => {
        setColumnSelectorOpen(!columnSelectorOpen);
    }
    const toggleColumn = (col) => {
        let newColumns = columns.map((c) => {
                if (c.id === col.id) {
                    c.display = !c.display;
                }
                return c;
            }
        );
        updateColumns(newColumns);
    }
    const selectRow = (row, value) => {
        if (value) {
            setSelectedRows(selectedRows.concat(row));
        } else {
            let idx = selectedRows.findIndex((e) => e.id === row.id);
            if (idx > -1) {
                let _selectedRows = [...selectedRows];
                _selectedRows.splice(idx, 1);
                setSelectedRows(_selectedRows);
            }
        }
    }
    const selectAllRows = () => {
        if (selectedRows.length === rowData.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows([...rowData]);
        }
    }
    const exportHandler = () => {
        let cols = columns.filter((c) => c.display);
        onExport(filter, cols.map((c) => c.id).toString(), sorter);
    }
    const labelHandler = () => {
        onLabel(filter);
    }
    const cardsHandler = () => {
        onCards(filter);
    }
    const rosterHandler = () => {
        onRoster(filter);
    }
    const rowClickHandler = (row) => {
        if (onRowClick) {
            onRowClick(row);
        }
    }
    
    return(
        <div className='table-area'>
            <div className='table-search-and-create-container'>
                <div>
                    {hasSearch &&
                        <section>
                            <AmazingSearch onSearch={searchHandler} />
                        </section>
                    }
                </div>
                <div className='table-filters'>
                    {filters &&
                        <section>
                            <label>Filter by:</label>
                            <select className='table-select' onChange={filterHandler}>
                                {filters.map((f) => <option key={f.id} value={f.state}>{f.label}</option>)}
                            </select>
                        </section>
                    }
                    {sorters && 
                        <section>
                            <label>Sort by:</label>
                            <select className='table-select' onChange={sorterHandler}>
                                {sorters.map((s) => <option key={s.id} value={s.order}>{s.label}</option>)}
                            </select>
                        </section>
                    }
                    {dates && 
                        <section>
                            {dates.map(dte => 
                                <>
                                    <label>{dte.label}</label>
                                    <DatePicker selected={moment(dte.date).toDate()} onChange={d => dte.onChange(d.getTime())} />
                                </>
                            )}
                        </section>
                    }
                    {hasLimitSelector &&
                        <section>
                            <label>Limit:</label>
                            <select className='table-select' value={limit} onChange={limitHandler}>
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                            </select>
                        </section>
                    }
                </div>
            </div>
            <div className='table-filters-container'>
                <div className='create-button-container'>
                    {onCreate
                        ? <button onClick={onCreate} disabled={createDisabled || false}>
                            {onCreateLabel || 'Create'}</button>
                        : null }
                </div>
                <div className='table-actions'>
                    {onCopy && selectedRows.length > 0
                        ? <IconButton icon={faCopy} onClick={copyHandler} label='Copy'/>
                        : null }
                    {onEdit && selectedRows.length > 0
                        ? <IconButton icon={faEdit} onClick={editHandler} label='Edit'/>
                        : null }
                    {onDelete && selectedRows.length > 0
                        ? <IconButton icon={faTrash} onClick={deleteHandler} label='Delete'/>
                        : null }
                    {onReminder && selectedRows.length === 1
                        ? <IconButton icon={faBellOn} onClick={onReminderHandler} label='Reminder'/> : null}
                    {onPrint
                        ? <IconButton icon={faPrint} onClick={() => onPrint()} label='Print' />
                        : null}
                    {onClean
                        ? <IconButton icon={faBroom} onClick={() => onClean()} label='Clean' />
                        : null }
                    {onLabel
                        ? <IconButton icon={faEnvelope} onClick={labelHandler} label='Mailing Labels'/>
                        : null }
                    {onCards
                        ? <IconButton icon={faIdCard} onClick={cardsHandler} label='ID Cards'/>
                        : null }
                    {onRoster
                        ? <IconButton icon={faFileAlt} onClick={rosterHandler} label='Roster'/>
                        : null }
                    {onImport
                        ? <IconButton icon={faFileUpload} onClick={() => onImport()} label='Import'/>
                        : null }
                    {onExport
                        ? <IconButton icon={faFileDownload} onClick={exportHandler} label='Export'/>
                        : null }
                    {onRefresh
                        ? <IconButton icon={faSync} onClick={refreshHandler} label='Refresh'/>
                        : null }
                    <IconButton icon={faCog} onClick={showColumnSelector} label='Show/Hide'/>
                </div>
            </div>
            <div className={'table-column-select ' + (columnSelectorOpen ? 'open' : 'close')}>
                {
                    columns.map((c) => 
                    <div key={c.id}>
                        <Checkbox onChange={() => toggleColumn(c)} checked={c.display} />
                        {c.label}
                    </div>
                )
                }
            </div>
            <div className='table-container'>
                <div className='table-outer'>
                    <div className='table-header'>
                        {columns.findIndex(c => c.datum && c.datum === 'id') > -1 &&
                            <div className='checkbox-col'><Checkbox onChange={selectAllRows} /></div>
                        }
                        {
                            columns.map((c) => c.display ? <div key={c.id} style={c.style ? c.style : {}}>{c.label}</div> : null)
                        }
                    </div>
                    <div className='table-rows'>
                        {loading &&
                            <div className='table-loading'>
                                <FontAwesomeIcon icon={faSpinner} spin /> Loading...</div>
                        }
                        {
                            rowData.map((r, i) => 
                                <AmazingTableRow 
                                    key={i} 
                                    selected={selectedRows.findIndex((e) => e.id === r.id) > -1}
                                    data={r}
                                    onClick={() => rowClickHandler(r)} 
                                    onSelect={(v) => selectRow(r, v)} />
                            )
                        }
                    </div>
                </div>
            </div>
            {hasPagination && <Pagination />}
        </div>
    );
}

export default AmazingTable;
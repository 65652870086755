import React from 'react';
import Checkbox from './checkbox';
import { useTableSetup } from 'contexts/tableSetup';

function AmazingTableRow({onClick, onSelect, selected, data}) {
    const { columns } = useTableSetup();

    const clickHandler = (event) => {
        event.preventDefault();
        onClick();
    }
    const selectHandler = (value) => {
        onSelect(value);
    }
    
    return(
        <div className='contact-row' tabIndex={0}>
            {data.id !== undefined &&
                <div className='checkbox-col'>
                    <Checkbox onChange={selectHandler} checked={selected} />
                </div>
            }
            {
                columns.map((c, i) => 
                    c.display ? 
                        c.builder ? c.builder(data) :
                    <div key={i} onClick={clickHandler} style={c.style ? c.style : {}}>
                        {c.datum ? data[c.datum] 
                            : c.formatter ? c.formatter(data) 
                            : '!NO DATUM, FORMATTER, OR BUILDER!'}
                    </div>
                    : null
                )
            }
        </div>
    );
}

export default AmazingTableRow;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ClassesContext = React.createContext(null);

function ClassesProvider({eventId, children}) {
    const { runAction } = useCore();
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        getAllClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllClasses = () => {
        const data = {event: eventId}
        runAction('get_all_eventclasses', data, (response) => {
            setClasses(response.classes);
        });
    }

    const saveClass = (data, callback) => {
        data.event = eventId;
        const action = data.isNew ? 'create_eventclass' : 'update_eventclass';
        runAction(action, data, (response) => {
            getAllClasses();
            callback();
        });
    }

    const deleteClass = (data) => {
        data.event = eventId;
        runAction('delete_eventclass', data, () => {
            getAllClasses();
        })
    }

    const provider = {
        classes,
        saveClass,
        deleteClass
    }

    return <ClassesContext.Provider value={provider}>{children}</ClassesContext.Provider>
}

function useClasses() {
    const context = React.useContext(ClassesContext);
    if (!context) {
        throw new Error('useClasses must be used within a ClassesProvider');
    }
    return context;
}

export { ClassesProvider, useClasses }
import React, {useState, useEffect} from 'react';
import 'style/t134dashCard.scss';
import nsmcLogo from 'assets/dashCard/NSMC-logo.png';
import appleAutoLogo from 'assets/dashCard/AppleAuto-logo.png'
import { useClasses } from 'contexts/classes';

function T134DashCard({entryData}) {
    const { classes } = useClasses();
    const [name, setName] = useState('');

    useEffect(() => {
        if (entryData) {
            setName(entryData.fname + ' ' + entryData.lname);
        }
    }, [entryData]);

    return (
        <div style={{backgroundColor:'gray'}}>
            <div className='t134dashCard'>
                <div className='logosContainer'>
                    <img src={nsmcLogo} />
                    <img src={appleAutoLogo} />
                </div>
                <div className='fieldsTable'>
                    <table cellPadding={0} cellSpacing={0}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Car#:</div>
                                        <div className='field' style={{fontSize:'60px'}}>{entryData.car_number}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Class:</div>
                                        <div className='field' style={{fontSize:'24px'}}>{`${entryData.car_class} - ${classes.find(c => c.id == entryData.car_class)?.description}`}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Year:</div>
                                        <div className='field'>{entryData.year}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Make:</div>
                                        <div className='field'>{entryData.make}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Model:</div>
                                        <div className='field'>{entryData.model}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Trim:</div>
                                        <div className='field'>{entryData?.trim || ' '}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Engine:</div>
                                        <div className='field'>{entryData.engine}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fieldData'>
                                        <div className='label'>Color:</div>
                                        <div className='field'>{entryData.color}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{borderBottom:'1px solid'}}>
                                    <div className='fieldData'>
                                        <div className='label'>Owner:</div>
                                        <div className='field'>{name}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default T134DashCard;
import PubSub from 'pubsub-js';

const BASEURL = '/dart/websocket';
const TIMEOUT = 1000*15;
const ECHO = 'Echoing: ';
const SENDTOPIC = 'socketSendMessage';

let _websocket = null;
let retryTimeout = null;
let sendSubscription = null;

/**
 * connect the web socket to the server
 */
const connectSocket = () => {
    if (_websocket === null) {
        const token = window['X-Dart-Token'];
        if (token) {
            // check the token
            let jwt = parseJwt(token);
            let now = new Date().getTime();
            if (jwt.exp > (now/1000)) {
                // build the websocket url
                let wsServer = 'wss://' + window.location.host;
                if (window.location.hostname === 'localhost') {
                    wsServer = 'ws:/localhost:8080';
                }
                // create the web socket
                //let websocket = new WebSocket("wss://" + window.location.host + "/" + BASEURL + "?X-Dart-Token=" + token);
                let websocket = new WebSocket(wsServer + BASEURL + "?X-Dart-Token=" + token);
                // connect listeners for ws events
                websocket.addEventListener('open', _openHandler);
                websocket.addEventListener('message', _messageHandler);
                websocket.addEventListener('close', _closeHandler);
                websocket.addEventListener('error', _errorHandler);
                websocket.addEventListener('room', _roomHandler);
                /// store this connection 
                _websocket = websocket;
            } else {
                // clear any reconnect timeout
                if (retryTimeout !== null) {
                    clearTimeout(retryTimeout);
                    retryTimeout = null;
                }
            }
        }
    }
}

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}
/**
 * close the web socket connection
 */
const disconnectSocket = () => {
    if (_websocket !== null) {
        _websocket.close();
    }
}
/**
 * handle open events from the web socket
 */
const _openHandler = (message) => {
    //console.log("Server connection established");
    // clear any reconnect timeout
    if (retryTimeout !== null) {
        clearTimeout(retryTimeout);
        retryTimeout = null;
    }
}
/**
 * handle message events from the web socket
 */
const _messageHandler = (message) => {
    //console.log("Web socket message: " + message.data);
    try {
        var jsonStr = message.data.startsWith(ECHO) ? message.data.substring(ECHO.length) : message.data;
        var jsonMsg = JSON.parse(jsonStr);
        if (jsonMsg.message) {
            PubSub.publish(jsonMsg.message.topic, jsonMsg.message);
        } else if (!jsonMsg.success) {
            PubSub.publish('error', jsonMsg.errorMessage);
        }
    } catch (err) {
        console.error("unable to parse response message:" + err);
    }
}
/**
 * handle close events from the web socket
 */
const _closeHandler = (message) => {
    //console.log("Server connection lost - will automatically retry every 15 seconds.");
    _websocket = null;
    // reconnect
    _reconnect();
}
/**
 * handle error events from the web socket
 */
const _errorHandler = (message) => {
    PubSub.publish('error', "Web socket error: " + message);
    // TODO: properly handle error scenarios
}
/**
 * send a message on the socket
 */
const _sendHandler = (message, data) => {
    if (_websocket !== null) {
        _websocket.send(data);
    }
}
const _roomHandler = (message) => {
    console.log(`room handler: ${message}`);
}
/**
 * reconnect
 */
const _reconnect = () => {
    if (retryTimeout !== null) {
        clearTimeout(retryTimeout);
        retryTimeout = null;
    }
    retryTimeout = setTimeout(connectSocket, TIMEOUT);
}

export function startup() {
    if (_websocket === null && retryTimeout === null) {
        sendSubscription = PubSub.subscribe(SENDTOPIC, (msg, data) => _sendHandler(msg, data));
        connectSocket();
    }
}

export function shutdown() {
    //console.log('shutting down socket connection');
    PubSub.unsubscribe(sendSubscription);
    disconnectSocket();
}
/**
 * get the connection status
 */
export function connectionStatus() {
    if (_websocket === null) {
        return "Not Connected";
    }
    return "Connected";
}
import React, { useEffect, useState } from 'react';

function AuditDetails(props) {
    const [messageAction, setMessageAction] = useState('');

    useEffect(() => {
        if (props.audit) {
            try {
                const auditJson = JSON.parse(props.audit.detail);
                setMessageAction(auditJson.messageAction);
            } catch (err) {
                console.error('unable to parse audit json')
            }

        }
    }, [props.audit])

    return (
        <div>
            <div className='contact-preview'>
                <label>Message Action</label>
                <div>{messageAction}</div>
            </div>
        </div>
    )
}

export default AuditDetails;
import React from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const StripeSettingsContext = React.createContext(null);

function StripeSettingsProvider({tenantId, children}) {
    const { runAction } = useCore();

    const createStripeAccount = (callback) => {
        runAction('stripe_create_account', {tenantId}, response => {
            if (response.success) {
                publish('success', 'Successfully created a stripe account id for account.')
                callback();
            }
        })

    }

    const createStripeCustomer = (callback) => {
        runAction('stripe_create_customer', {tenantId}, response => {
            if (response.success) {
                publish('success', 'Successfully created a stripe customer id for account.')
                callback();
            }
        });
    }

    const provider = {
        createStripeAccount,
        createStripeCustomer,
    }

    return <StripeSettingsContext.Provider value={provider}>{children}</StripeSettingsContext.Provider>
}

function useStripeSettings() {
    const context = React.useContext(StripeSettingsContext);
    if (!context) {
        throw new Error('useStripeSettings must be used within a StripeSettingsProvider');
    }
    return context;
}

export { StripeSettingsProvider, useStripeSettings }
import React from 'react';
import Copyright from 'components/copyright';
import logo from 'assets/logo-white.png';
import Username from './username';
import { useNavigate } from 'react-router-dom';
import 'style/login.scss';

function Login(props) {
    const navigate = useNavigate();

    const gotoSignup = () => {
        navigate('/signup');
    }

    return(
        <div className='login-container'>
            <div className='logo-container'>
                <img src={logo} alt='Middletwin Logo' />
            </div>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-center-pane-inner'>
                        <h1>Log in</h1>
                        <Username onLogin={props.onLogin} />
                        <hr/>
                        <div className="signup-container">
                            <div>Don&apos;t have an account?</div>
                            <div style={{textDecoration:'underline',cursor:'pointer'}} onClick={gotoSignup}>Create an account now</div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    );
}

export default Login;
import React, { useState, useRef } from 'react';
import AmazingTable from 'components/amazingTable';
import NewProduct from './newProduct';
import SidePanel from 'components/sidePanel';
import ProductDetails from './productDetails';
import DetailsPanel from 'components/detailsPanel';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useStore } from 'contexts/store';
import { useLoaderData, useNavigate } from 'react-router-dom';

export async function loader({params}) {
    return params;
}

const productColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'name', label:'Name', display: true, datum: 'name'},
    {id: 'description', label:'Description', display: true, datum: 'description'},
    {id: 'sku', label:'SKU', display: true, datum: 'sku'},
    {id: 'upc', label:'UPC', display: true, datum: 'upc'}
];

function Products() {
    const { storeLoading, loadStore, products, createProduct, updateProduct, deleteProducts } = useStore();
    const [product, setProduct] = useState(null);
    const newProductPanel = useRef(null);

    
    const showProductDetails = (product) => {
        setProduct(product);
    }
    const hideProductDetails = () => {
        setProduct(null);
    }

    const saveProduct = (data) => {
        if (data.id) {
            updateProduct(data, () => setProduct(null));
        } else {
            createProduct(data, () => {
                newProductPanel.current.hide();
            })
        }
    }

    const deleteProductHandler = (products) => {
        deleteProducts(products.map(p => p.id));
    }
    
    return(
        <div>
            <TableSetupProvider tableName='product' defaultColumns={productColumns}>
                <AmazingTable 
                    rows={products}
                    onRefresh={loadStore} 
                    onCreate={() => newProductPanel.current.show()}
                    onDelete={deleteProductHandler}
                    onRowClick={showProductDetails}
                    loading={storeLoading} />
            </TableSetupProvider>
            <SidePanel title='New Product' ref={newProductPanel}>
                <NewProduct onSave={saveProduct} />
            </SidePanel>
            <DetailsPanel title={product && product.name} showing={product !== null} onHide={hideProductDetails}>
                {product && <ProductDetails product={product} onSave={saveProduct} onBack={hideProductDetails} />}
            </DetailsPanel>
        </div>
    )
}

export default Products;
import React, { useEffect, useState } from 'react';
import Toggle from 'components/toggle';
import { useAuth } from 'contexts/auth';
import {feature, hasFeature} from 'util/feature';
import { useCountries } from 'contexts/countries';
import { usePhone } from 'contexts/phone';
import 'style/memberPhoneNumber.scss';

const numberTypes = [
    {id: 1, label: 'Mobile'},
    {id: 2, label: 'Home'},
    {id: 3, label: 'Office'},
    {id: 4, label: 'Fax'},
    {id: 99, label: 'Other'}
];

function MemberPhoneNumber(props) {
    const { tenant } = useAuth();
    const { countries } = useCountries();
    const { initiateCall } = usePhone();
    const [type, setType] = useState('1');
    const [messaging, setMessaging] = useState(true);
    const [countryCode, setCountryCode] = useState('US')
    const [longCode, setLongCode] = useState('');

    useEffect(() => {
        if (props.phoneNumber) {
            setType(props.phoneNumber.typeId);
            setMessaging(props.phoneNumber.messaging);
            setCountryCode(props.phoneNumber.countryCode);
            setLongCode(props.phoneNumber.longCode);
        }
    }, [props.phoneNumber])

    const saveHandler = () => {
        let data = {
            typeId: type,
            typeLabel: numberTypes.find((t) => t.id == type).label,
            isoCode: countryCode,
            longCode: longCode,
            messaging: messaging
        }
        if (props.phoneNumber) {
            data.id = props.phoneNumber.id;
        }
        props.onSave(data);
    }

    const removeHandler = () => {
        props.onRemove(props.phoneNumber);
    }

    const callHandler = () => {
        initiateCall(countryCode + longCode);
    }
    return (
        <>
        <div className='details-row'>
            <div className='details-column'>
                <label>Phone number (ex. 6121112222)</label>
                <div><input type='tel' value={longCode} onChange={(evt) => setLongCode(evt.target.value)} /></div>
                <label>Number type</label>
                <select value={type} onChange={(evt) => setType(evt.target.value)}>
                    {numberTypes.map((t) => <option key={t.id} value={t.id}>{t.label}</option>)}
                </select>
            </div>
            <div className='details-column'>
                <label>Country code</label>
                {
                    <select value={countryCode} onChange={(evt) => setCountryCode(evt.target.value)}>
                        {countries.map((c) => <option key={c.isoCode} value={c.isoCode}>{`${c.label} (${c.countryCode})`}</option>)}
                    </select>
                }
                <label>Use for messaging</label>
                <Toggle checked={messaging} onChange={(val) => setMessaging(val)} />
            </div>
        </div>
        <div className='details-row'>
            <div>
                <button onClick={saveHandler}>Save</button>
                {(props.phoneNumber && props.phoneNumber.id) && <button className='secondary' onClick={removeHandler}>Remove</button>}
                {(hasFeature(tenant, feature.PHONE) 
                    && props.phoneNumber 
                    && props.phoneNumber.id 
                    && props.twilioNumber) && <button className='secondary' onClick={callHandler}>Call</button>}
            </div>
        </div>
        </>
    )
}

export default MemberPhoneNumber;
import React, { useState } from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';
import AmazingTable from 'components/amazingTable';
import { useAuth } from 'contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faList } from '@fortawesome/pro-regular-svg-icons';
import './Finance.scss';
import PaymentMethods from './paymentMethods';
import PaymentHistory from './paymentHistory';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from 'contexts/subscription';
import Loading from 'components/loading';
import { PaymentHistoryProvider } from 'contexts/paymentHistory';
import { PaymentMethodsProvider } from 'contexts/paymentMethods';
import { InvoicesProvider, useInvoices } from 'contexts/invoices';
import { TableSetupProvider } from 'contexts/tableSetup';

function Expenses() {
    const [view, setView] = useState('invoice');
    const { subscriptionLoading, subscription } = useSubscription();

    return (
        <div className='transactions-outer'>
            {subscriptionLoading ? <Loading /> : 
            <>
            <h3>Subscription: {subscription.annualRenewal ? 'Annual' : 'Monthy'} Renewal</h3>
            <div className='snapshot-area'>
                <div className='snapshot'>
                    <div className='total'>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Renewal Amount</div> 
                            <div className='total-ammount'>{formatCurrency(subscription.renewalAmount)}</div>
                        </div>
                    </div>
                    <div className='total'>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Renewal Date</div>
                            <div className='total-ammount'>{moment(subscription.expiration).format('YYYY-MM-DD')}</div>
                        </div>
                    </div>
                    <div className='total'>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Transaction Fee</div>
                            <div className='total-ammount'>{formatCurrency(subscription.transactionAmount)}</div>
                        </div>
                    </div>
                </div>
                <div className='snapshot'>
                    <div className='views'>
                        <div className={'view-selector' + (view === 'invoice' ? ' selected' : '')} role='button' onClick={() => setView('invoice')}>
                            <div className='view-button'>
                                <FontAwesomeIcon icon={faList} />
                            </div>
                            <div className='view-label'>
                                Invoices
                            </div>
                        </div>
                        <div className={'view-selector' + (view === 'payment' ? ' selected' : '')} role='button' onClick={() => setView('payment')}>
                            <div className='view-button'>
                                <FontAwesomeIcon icon={faCreditCard} />
                            </div>
                            <div className='view-label'>
                                Payments
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='transactions-table'>
                {view === 'invoice' &&
                    <>
                        <h4>Invoices</h4>
                        <InvoicesProvider><Invoices /></InvoicesProvider>
                    </>
                }
                {view === 'payment' &&
                    <>
                        <h4>Payment Methods (Credit Cards)</h4>
                        <PaymentMethodsProvider><PaymentMethods /></PaymentMethodsProvider>
                        <h4>Payment History</h4>
                        <PaymentHistoryProvider><PaymentHistory /></PaymentHistoryProvider>
                    </>
                }
            </div>
            </>
            }
        </div>
    )
}

export default Expenses;

function Invoices() {
    const { invoicesLoading, invoices, getInvoices } = useInvoices();
    const auth = useAuth();
    const navigate = useNavigate();

    const columns = [
        {id: "id", label:"ID", display: false, datum: "id"},
        {id: "created", label: "Created", display: true, formatter: (obj) => {return moment(obj.created).format("YYYY-MM-DD");}},
        {id: "dueDate", label: "Due", display: true, formatter: (obj) => {return moment(obj.dueDate).format("YYYY-MM-DD");}},
        {id: "amount", label: "Amount", display: true, formatter: (obj) => {return formatCurrency(obj.amount);}},
        {id: "paidDate", label: "Paid", display: true, formatter: (obj) => {return obj.paidDate ? moment(obj.paidDate).format("YYYY-MM-DD") : 'Not Paid';}}
    ];

    const openInvoice = (invoice) => {
        navigate(`/invoice/${auth.tenant.id}/${invoice.id}`);
    }

    return (
        <TableSetupProvider tableName='expenses' defaultColumns={columns}>
            <AmazingTable 
                rows={invoices}
                onRefresh={getInvoices}
                onRowClick={openInvoice}
                loading={invoicesLoading}
                /> 
        </TableSetupProvider>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useChatUsers } from '../../contexts/chatUsers';
import MiniList from 'components/MiniList';
import Toggle from '../../components/toggle';
import { useAuth } from '../../contexts/auth';
import Core from '../../util/core';
import PubSub from 'pubsub-js';
import Avatar from 'components/avatar';

function ChatRoomDetails(props) {
    const auth = useAuth();
    const chatUsers = useChatUsers();
    const [name, setName] = useState('');
    const [active, setActive] = useState(true);
    const [avatar, setAvatar] = useState('');
    const [webhook, setWebhook] = useState('');
    const [members, setMembers] = useState([]);
    const [roomType, setRoomType] = useState('public');

    useEffect(() => {
        if (props.channel) {
            setName(props.channel.name);
            setActive(props.channel.active);
            setAvatar(props.channel.avatar);
            setWebhook(props.channel.webhook);
            setMembers(props.channel.members);
            setRoomType(props.channel.type);
        } else {
            setName('');
            setActive(true);
            setAvatar('');
            setWebhook('');
            setMembers([]);
            setRoomType('public');
        }
    }, [props.channel])

    const save = () => {
        let data = {
            active,
            affiliate: auth.group.id,
            members: members.map(m => m.user_id),
        }
        if (roomType === 'public') {
            data.avatar = avatar;
            data.name = name;
        }
        if (props.channel) {
            data.id = props.channel.id;
        }
        const action = props.channel ? 'update_full_chat_channel' : 'create_full_chat_channel';
        Core.runAction(action, data, response => {
            PubSub.publish('success', 'Chat room saved');
            props.onSave();
        })
    }

    const addMember = (member) => {
        setMembers([...members, member]);
    }

    const removeMember = (member) => {
        let tmpMembers = [...members];
        const index = tmpMembers.findIndex(m => m.user_id === member.user_id);
        tmpMembers.splice(index, 1);
        setMembers(tmpMembers);
    }

    const memberListColumns = [
        {id: 'name', formatter: obj => {
                if (members.findIndex(m => m.user_id === obj.user_id) > -1) {
                    return (
                        <div className='tag-row'>
                            {obj.name}<FontAwesomeIcon icon={faTimesCircle} onClick={() => removeMember(obj)} role='button' className='selector' />
                        </div>
                    )
                }
                return (
                    <div className='tag-row add'>
                        {obj.name}<FontAwesomeIcon icon={faPlusCircle} onClick={() => addMember(obj)} role='button' className='selector' />
                    </div>
                )
            }
        }
    ]

    return (
        <div className='contact-preview'>
            {props.channel ?
                <>
                    <label>Webhook</label>
                    <div>{webhook}</div>
                </>
            :
                <>
                    <label>Room Type</label>
                    <select value={roomType} onChange={e => setRoomType(e.target.value)}>
                        <option value='public'>Open to all</option>
                        <option value='private'>Focused small group</option>
                    </select>
                </>
            }
            {roomType === 'public' && 
                <>
                    <label>Name (Required)</label>
                    <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength='32' />
                    <label>Avatar</label>
                    <Avatar 
                        width='100' 
                        height='100'
                        value={avatar} 
                        onChange={(image) => setAvatar(image)} />
                </>
            }
            {roomType === 'private' && 
                <>
                    <label>Members</label>
                    <MiniList 
                        columns={memberListColumns}
                        rows={chatUsers.users}
                        noRowsLabel='There are no available chat members'
                        members={members}
                    />
                </>
            }
            <label>Active</label><Toggle checked={active} onChange={val => setActive(val)} />
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default ChatRoomDetails;
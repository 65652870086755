import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import 'style/detailsPanel.scss';

const showStyle = {width:'100%', transition: 'width 0.5s'};
const hideStyle = {width: '0', transition: 'width 0.5s'};

function DetailsPanel({showing, onHide, title, children}) {
    const [isShowing, setIsShowing] = useState(false);
    
    useEffect(() => {
        setIsShowing(showing);
    }, [showing])

    const hide = () => {
        setIsShowing(false);
        onHide && onHide();
    }
    
    return (
        <div className='details-container' style={isShowing ? showStyle : hideStyle}>
            <div className='details-header'>
                <div>{title}</div>
                <div className='closer' onClick={hide}><FontAwesomeIcon icon={faTimes} /></div>
            </div>
            <div className='details-contents'>{children}</div>
        </div>
    );
}

export default DetailsPanel;
import React, { useEffect, useState } from 'react';
import { useSettings } from '../../../contexts/settings';
import Core from '../../../util/core';

function GreetingSetup(props) {
    const settings = useSettings();
    const [greetings, setGreetings] = useState([]);

    useEffect(() => {
        getGreetings();
    }, [])

    const getGreetings = () => {
        Core.runAction('get_greetings', {}, (response) => {
            setGreetings(response.greetings);
        })
    }

    const changeSetting = (key, value) => {
        let data = {...settings.tenant}
        data[key] = value;
        Core.runAction('update_tenant_settings', data, (response) => {
            settings.refreshTenantSettings();
        })
    }

    return (
        <div>
            {props.heading && <h3>{props.heading}</h3>}
            {props.description && <p>{props.description}</p>}
            {props.types && props.types.map((type, idx) => 
                <div key={idx}>
                    <label>{type.label}</label>
                    <select value={settings.tenant[type.key]} onChange={e => changeSetting(type.key, e.target.value)}>
                        <option value='0'>System Default</option>
                        {greetings.map(g => <option key={g.id} value={g.id}>{`${g.type} [${g.duration}]`}</option>)}
                    </select>
                </div>
            )}
        </div>
    )
}

export default GreetingSetup;
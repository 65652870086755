import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useToken } from './token';

const AuthContext = React.createContext();

function AuthProvider({children}) {
    const { runAction } = useCore();
    const { tokenLoading } = useToken();
    const [authLoading, setAuthLoading] = useState(true);
    const [auth, setAuth] = useState(null);
    const [chatStatus, setChatStatus] = useState('available');

    useEffect(() => {
        if (!tokenLoading) {
            authorize(null);
        }
    }, [tokenLoading])

    const authorize = (acctId) => {
        let data = {};
        if (acctId) {
            data = {account: acctId, newToken: true}
        }
        setAuthLoading(true);
        runAction('authorize', data, 
        (result) => {
            if (result) {
                setAuth(result);
                setChatStatus(result?.user.chatstatus);
                setAuthLoading(false);
            }
        });
    }

    const updateGroup = (settings) => {
        runAction('update_affiliate', settings, response => {
            authorize(null);
        });
    }

    const provider = {
        authLoading,
        user: auth?.user,
        tenant: auth?.tenant,
        group: auth?.affiliate,
        stripe: auth?.stripe,
        tous: auth?.tous,
        messages: auth?.messages,
        refresh: acctId => {
            authorize(acctId);
        },
        chatStatus,
        setChatStatus,
    }
    return <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('AuthContext must be used within an AuthContext provider')
    }
    return context;
}

export {AuthProvider, useAuth}
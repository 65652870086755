import React, { useRef } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserCircle, faQuestionCircle, faCog, faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import logo from 'assets/logo-white.png';
import { useAuth } from 'contexts/auth';
import SidePanel from 'components/sidePanel';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/iconButton';

/**
 * the header
 * @param {*} props 
 * @returns 
 */
function Header({onSettings, onHelp}) {
    const { tenant, group } = useAuth();
    const navigate = useNavigate();
    const supportPanelref = useRef(null);

    return(
        <div className='top-bar'>
            <div className='top-bar-inner'>
                <div className='top-bar-group'>
                    <div className='top-bar-logo'>
                        <img src={logo} alt='Middletwin logo' />
                    </div>
                </div>
                <div className='top-bar-group'>
                    <div className='top-bar-title'>{tenant && tenant.name}</div>
                </div>
                <div className='top-bar-group'>
                    <IconButton icon={faCog} label='Settings' onClick={onSettings} />
                    <IconButton icon={faQuestionCircle} label='Support' onClick={() => supportPanelref.current.show()} />
                    <div className='top-bar-divider'></div>
                    <IconButton icon={faUserCircle} label='Sign Out' onClick={() => navigate('/logout')} />
                </div>
            </div>
            <SidePanel title='Support' ref={supportPanelref}>
                <div className='settings-panel'>
                    <h4>Knowledge Base</h4>
                    <hr/>
                    <div>Access our self-serve online library of how-to articles to help solve problems on your own.</div>
                    <div>
                        <button className='linkButton' style={{marginLeft:'0',paddingLeft:'0'}} onClick={onHelp}>Middletwin Knowledge Base <FontAwesomeIcon icon={faExternalLink} /></button>
                    </div>
                    <div className='spacer'></div>
                    <h4>Contact Support</h4>
                    <hr/>
                    {(group && group.customSupport) ?
                        <>
                            <label>Hours</label>
                            <div>{group.supportHours}</div>
                            <div style={{marginTop:'10px'}}>Email: <a href={`mailto:${group.supportEmail}`}>{group.supportEmail}</a></div>
                            <div style={{marginTop:'10px'}}>Phone: <a href={`tel:${group.supportPhone}`}>{group.supportPhone}</a></div>
                        </>
                        :
                        <>
                            <label>Hours</label>
                            <div>9:00am - 4:00pm CST, Monday - Friday</div>
                            <div style={{marginTop:'10px'}}>Email: <a href='mailto:support@middletwin.com'>support@middletwin.com</a></div>
                            <div style={{marginTop:'10px'}}>Phone: <a href='tel:855-643-0696'>855-643-0696</a></div>
                        </>
                    }
                </div>
            </SidePanel>
        </div>
    )
}

export default Header;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useAuth } from './auth';
import { publish } from 'pubsub-js';

const TenantSettingsContext = React.createContext(null);

function TenantSettingsProvider({tenantId, children}) {
    const { runAction } = useCore();
    const { refresh } = useAuth();
    const [settingsLoading, setSettingsLoading] = useState(true);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSettings = () => {
        setSettingsLoading(true);
        runAction('get_tenant_settings', {tenantId}, 
            (response) => {
                if (response) {
                    setSettings(response.settings);
                }
                setSettingsLoading(false);
            });
    }

    const saveSettings = (settings) => {
        let data = {...settings}
        runAction('update_tenant_settings', data, (response) => {
            publish('success', 'Settings successfully saved');
            refresh(null);
        })
    }

    const provider = {
        settingsLoading,
        settings,
        getSettings,
        saveSettings
    }

    return <TenantSettingsContext.Provider value={provider}>{children}</TenantSettingsContext.Provider>
}

function useTenantSettings() {
    const context = React.useContext(TenantSettingsContext);
    if (!context) {
        throw new Error('useTenantSettings must be used within a TenantSettingsProvider');
    }
    return context;
}

export { TenantSettingsProvider, useTenantSettings }
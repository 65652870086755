export let feature = {};

function initFeatures() {
    feature.MEMBERSHIP = 1;
	feature.EVENT = 2;
    feature.STORE = 3;
    feature.VIDEO = 4;
	feature.ADMINISTRATOR = 7;
	feature.PHONE = 8;
}

export function hasFeature(tenant, feature) {
	if (tenant.features !== null) {
		let hasFeature = tenant.features.filter((f) => f.id === feature);
		return hasFeature.length > 0;
	}
	return false;
}

initFeatures();
import React, {useEffect, useState} from 'react';
import Toggle from '../../../components/toggle';
import TimePicker from 'components/timePicker';
import CurrencyInput from 'react-currency-input-field';
import Core from '../../../util/core';
import PubSub from 'pubsub-js';
import GreetingSetup from './greetingSetup';
import './ivr.scss';

function IVR(props) {
    const [ivr, setIvr] = useState(null);
    const [availability, setAvailability] = useState({});
    const [weekday, setWeekday] = useState('');
    const [available, setAvailable] = useState(false);
    const [startHour, setStartHour] = useState();
    const [endHour, setEndHour] = useState();
    const [availableMessage, setAvailableMessage] = useState('');
    const [optionMessage, setOptionMessage] = useState('');
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState(null);
    const [unavailableMessage, setUnavailableMessage] = useState('');
    const [unavalableMessaging, setUnavalableMessaging] = useState(false);
    const [recipients, setRecipients] = useState('');

    useEffect(() => {
        Core.runAction('get_ivr', {}, (response) => {
            if (response.ivrs.length > 0) {
                let ivr0 = response.ivrs[0];
                setAvailableMessage(ivr0.availableMessage);
                setOptionMessage(ivr0.optionMessage);
                setUnavailableMessage(ivr0.unavailableMessage);
                setUnavalableMessaging(ivr0.unavailableMessaging);
                setRecipients(ivr0.messageRecipients);
                setOptions(ivr0.availableOptions);
                setAvailability(ivr0.availability);
                setIvr(ivr0);
            }
        })
    }, []);

    const loadAvailability = (wday) => {
        setWeekday(wday);
        if (availability[wday]) {
            setAvailable(availability[wday].available);
            setStartHour(availability[wday].startHour);
            setEndHour(availability[wday].endHour);
        } else {
            setAvailable(false);
            setStartHour(8*60*60*1000);
            setEndHour(17*60*60*1000);
        }
    }

    const cancelAvailbilityDetails = () => {
        setWeekday('');
    }

    const setAvailabilityDetails = () => {
        let details = {weekday: weekday, available: available, startHour: startHour, endHour: endHour};
        let curAvailability = Object.assign({}, availability);
        curAvailability[weekday] = details;
        setAvailability(curAvailability);
        setWeekday('');
    }

    const addOption = () => {
        let opt = {
            record: false,
            choice: 1,
            forward: ''
        }
        setOption(opt);
    }

    const cancelOptionDetails = () => {
        setOption(null);
    }

    const onOptionChange = (key, val) => {
        let curOption = {...option};
        curOption[key] = val;
        setOption(curOption);
    }

    const setOptionDetails = () => {
        let curOptions = [...options];
        if (option.id) {
            const optionIndex = curOptions.findIndex(o => o.id === option.id);
            if (optionIndex !== -1) {
                curOptions.splice(optionIndex, 1, option);
            }
        } else {
            curOptions.push(option);
        }
        setOptions(curOptions);
        setOption(null);
    }

    const saveIVRSetup = () => {
        let data = {
            availableMessage: availableMessage,
            optionMessage: optionMessage,
            unavailableMessage: unavailableMessage,
            unavailableMessaging: unavalableMessaging,
            messageRecipients: recipients,
            availability: availability,
            availableOptions: options
        };
        if (ivr) {
            data.id = ivr.id;
            Core.runAction('update_ivr', data, response => {
                PubSub.publish('success', 'Your IVR settings have been updated')
            });
        } else {
            Core.runAction('create_ivr', data, response => {
                PubSub.publish('success', 'Your IVR settings have been saved')
            });
        }
    }

    return (
        <div className='communication-area ivr'>
            <GreetingSetup 
                heading={'Recorded Messages'}
                description={'Tell the system which recorded greeting message you want played whith your phone tree.'}
                types={
                    [
                        {label: 'Available Message (plays first)', key:'availableGreeting'},
                        {label: 'Options Message (plays second}', key:'optionsGreeting'},
                        {label: 'Unavailable Message', key:'unavailableGreeting'}
                    ]
                }
            />            
            <hr style={{margin:'50px 0'}}/>
            <h3>Availability</h3>
            <p>For each day of the week, specify if you are available, and the hours you want your available message and available options to be played.
            For the times that you do not indicate as available, your unavailable message will be played.</p>
            <p>Make sure to set the message that you want played when someone calls your number during your available time. 
                An example message would be <font style={{fontStyle:'italic'}}>Thank you for calling group name. Please choose 
                from one of the following options.</font></p>
            <div className='ivrWeekdays'>
                <div onClick={() => loadAvailability('Sun')} className={weekday==='Sun' ? 'selected' : ''}>Sun</div>
                <div onClick={() => loadAvailability('Mon')} className={weekday==='Mon' ? 'selected' : ''}>Mon</div>
                <div onClick={() => loadAvailability('Tue')} className={weekday==='Tue' ? 'selected' : ''}>Tue</div>
                <div onClick={() => loadAvailability('Wed')} className={weekday==='Wed' ? 'selected' : ''}>Wed</div>
                <div onClick={() => loadAvailability('Thu')} className={weekday==='Thu' ? 'selected' : ''}>Thu</div>
                <div onClick={() => loadAvailability('Fri')} className={weekday==='Fri' ? 'selected' : ''}>Fri</div>
                <div onClick={() => loadAvailability('Sat')} className={weekday==='Sat' ? 'selected' : ''}>Sat</div>
            </div>
            {weekday !== '' && 
                <div className='patternSettings'>
                    <ul>
                        <li><label>Available</label></li>
                        <li><Toggle checked={available} onChange={(newValue) => setAvailable(newValue)} /></li>
                        <li><label>Start Time</label></li>
                        <li><TimePicker value={startHour} onChange={(t) => setStartHour(t)}/></li>
                        <li><label>End Time</label></li>
                        <li><TimePicker value={endHour} onChange={(t) => setEndHour(t)} /></li>
                    </ul>
                    <div style={{textAlign:'center'}}>
                        <button onClick={cancelAvailbilityDetails}>Cancel</button>
                        <button onClick={setAvailabilityDetails}>Ok</button>
                    </div>
                </div>
            }
            <hr style={{margin:'50px 0'}}/>
            <div><h3>Available Options</h3></div>
            <p>Provide a way for callers to choose from options like what number they want to be connected with or to leave a message.</p> 
            <p>These must be included in your recorded options message. An example message would be <font style={{fontStyle:'italic'}}>press 
            one for Bob, two for Mary, or zero to leave a message.</font></p>
            <div style={{textAlign:'right'}}>
                <button onClick={addOption}>+ Add Option</button>
            </div>
            <div className='patternTableHeading'>
                <div>Choice</div>
                <div>What it does</div>
            </div>
            <div className='patterns' style={{height:'150px'}}>
                <ul>{options.map((o, i) => 
                    <li key={i} role='button' onClick={() => setOption(o)}><div>{o.choice}</div><div>{o.record ? 'Record' : `Forward To: ${o.forward}`}</div></li>)
                }</ul>
            </div>
            {option !== null && 
                <div className='patternSettings'>
                    <ul>
                        <li><div style={{display:'flex'}}>Forward <Toggle checked={option.record} onChange={(newValue) => onOptionChange('record', newValue)} /> Record</div></li>
                        <li><label>Option Choice (1, 2, etc...)</label></li>
                        <li><CurrencyInput allowDecimals={false} allowNegativeValue={false} value={option.choice} onValueChange={(value) => onOptionChange('choice', value)} /></li>
                        <li><label>Forwarding Number (+16123330909)</label></li>
                        <li><input type='text' value={option.forward} onChange={(evt) => onOptionChange('forward', evt.target.value)} /></li>
                    </ul>
                    <div style={{textAlign:'center'}}>
                        <button onClick={cancelOptionDetails}>Cancel</button>
                        <button onClick={setOptionDetails}>Ok</button>
                    </div>
                </div>
            }
            <hr />
            <div><h3>Unavailable Actions</h3></div>
            <p>Make sure to set the message that you want played when someone calls your number during your unavailable time. An example message 
            would be <font style={{fontStyle:'italic'}}>We're sorry, you have called us outside of our normal business hours of eight am to 
            five pm monday through friday central standard time.</font></p>
            <p>Note that if you enable Dial 1 to leave a message, the message <font style={{fontStyle:'italic'}}>If you wish to leave a message, dial one now.</font>
            will automatically be added. You should not include this in your message.</p>
            <p>A link to messages that are recorded will be emailed to the message recipients.</p>
            <div className='patternSettings' style={{border:'none'}}>
                <label>Dial 1 to leave a message</label>
                <div style={{display:'flex'}}>Off <Toggle checked={unavalableMessaging} onChange={(newValue) => setUnavalableMessaging(newValue)} /> On</div>
            </div>
            
            <hr />
            <div><h3>Voicemail Message Recipients</h3></div>
            <p>Provide the email addresses of the people that you would like to have all recorded voicemail messages sent to. Use a semicolon (;) to
            separate multiple email addresses.</p>
            <div className='patternSettings' style={{border:'none'}}>
                <label>Recipients</label>
                <div><input type='text' style={{width:'400px'}} value={recipients} onChange={(evt) => setRecipients(evt.target.value)} /></div>
            </div>
            
            <div style={{textAlign:'center',margin:'20px 0'}}>
                <button onClick={saveIVRSetup}>Save Phone Tree Setup</button>
            </div>
        </div>
    )
}

export default IVR;
import React, { useState } from 'react';
import PageEditor from './pageEditor';
import { usePageContent } from './context/pageContent';
import Toggle from 'components/toggle';
import ElementSettings from './elementSettings';
import { FilesProvider } from 'contexts/files';

function PageDetails({onSave, onHide}) {
    const { name, setName, type, sections, insertSection } = usePageContent();
    const [mobileView, setMobileView] = useState(false);

    const saveHandler = () => {
        const data = {name, type, content: JSON.stringify({sections})};
        onSave(data);
    }

    return (
        <div className='page-details'>
            <div className='page-editor-settings'>
                <div className='page-settings'>
                    <div className='page-name'>{name}</div>
                    <br />
                    <label>Page name</label>
                    <input type='text'
                        value={name}
                        onChange={(evt) => {
                            setName(evt.target.value);
                        }}
                    />
                    <p>Page Type: {type === 1 ? 'Web' : type === 2 ? 'Home' : 'Registration'}</p>
                    {type < 3 && 
                        <>
                            <label>Mobile view</label>
                            <Toggle checked={mobileView} onChange={value => setMobileView(value)}/>
                        </>
                    }
                </div>
                <div className='page-sections'>
                    <FilesProvider>
                        <ElementSettings />
                    </FilesProvider>
                    <button onClick={() => insertSection({elements: []})}>Add section</button>
                </div>
                <div className='button-container'>
                    <button onClick={saveHandler}>Save</button>
                </div>
            </div>
            <div className='page-editor-view' style={{ width: '75%',backgroundColor:'#ececec' }}>
                <PageEditor mobileView={mobileView} />
            </div>
        </div>
    )
}

export default PageDetails;
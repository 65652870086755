import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import logo from 'assets/logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelescope } from '@fortawesome/pro-regular-svg-icons';
import Copyright from 'components/copyright';

function ErrorPage() {
    const error = useRouteError();
    console.log(error);

    return (
        <div className='login-container'>
             <div className='logo-container'>
                <img src={logo} alt='Middletwin Logo' />
            </div>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-center-pane-inner'>
                        <h1>Hmmm, we are not sure what you are looking for.</h1>
                        <FontAwesomeIcon icon={faTelescope} style={{fontSize:'128px',margin:'auto',color:'#e66e50'}} />
                        <p>Maybe you want to try <Link to='/admin/dashboard'>this</Link> link.</p>
                        <p>
                            Error: <i>{error.statusText || error.message}</i>
                        </p>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export default ErrorPage;
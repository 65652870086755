import React, { useEffect, useState } from 'react';
import Core from '../../util/core';
import Checkbox from 'components/checkbox';
import PubSub from 'pubsub-js';
import { useAuth } from '../../contexts/auth';

function SAML() {
    const auth = useAuth();
    const [samlIdp, setSamlIdp] = useState(null);
    const [emailPattern, setEmailPattern] = useState('');
    const [entitySuffix, setEntitySuffix] = useState('');
    const [authComparison, setAuthComparison] = useState('none');
    const [authContext, setAuthContext] = useState('none');
    const [explicitSaml, setExplicitSaml] = useState(false);
    const [samlSsoUrl, setSamlSsoUrl] = useState('');
    const [samlIssuer, setSamlIssuer] = useState('');
    const [samlCertificate, setSamlCertificate] = useState('');
    const [metadataUrlSaml, setMetadataUrlSaml] = useState(false);
    const [samlMetadataUrl, setSamlMetadataUrl] = useState('');
    const [metadataXmlSaml, setMetadataXmlSaml] = useState(false);
    const [samlMetadataXml, setSamlMetadataXml] = useState('');
    const [spMetadataUrl, setSpMetadataUrl] = useState('');

    useEffect(() => {
        getSamlIdp();
    }, [])

    const getSamlIdp = () => {
        Core.runAction('get_samlidp', {groupId: auth.group.id}, response => {
            if (response.samlIdp) {
                setSamlIdp(response.samlIdp);
                setEmailPattern(response.samlIdp.emailPattern);
                setEntitySuffix(response.samlIdp.entitySuffix);
                setAuthComparison(response.samlIdp.authComparison);
                setAuthContext(response.samlIdp.authContext);
                // TODO: select the correct checkbox
                if (response.samlIdp.idpSsoUrl !== '') {
                    setSamlSsoUrl(response.samlIdp.idpSsoUrl);
                    setSamlIssuer(response.samlIdp.idpIssuer);
                    setSamlCertificate(response.samlIdp.idpCertificate);
                    setExplicitSaml(true);
                } else if (response.samlIdp.idpMetadataUrl !== '') {
                    setSamlMetadataUrl(response.samlIdp.idpMetadataUrl);
                    setMetadataUrlSaml(true);
                } else if (response.samlIdp.idpMetadataXml !== '') {
                    setSamlMetadataXml(response.samlIdp.idpMetadataXml);
                    setMetadataXmlSaml(true);
                }
            } else {
                setSamlIdp(null);
                setEmailPattern('');
                setEntitySuffix('');
                setAuthComparison('none');
                setAuthContext('none');
                setSamlSsoUrl('');
                setSamlIssuer('');
                setSamlCertificate('');
                setExplicitSaml(false);
                setSamlMetadataUrl('');
                setMetadataUrlSaml(false);
                setSamlMetadataXml('');
                setMetadataXmlSaml(false);
            }
            if (response.samlSp) {
                setSpMetadataUrl(response.samlSp.metadataUrl);
            } else {
                setSpMetadataUrl('');
            }
        });
    }

    const saveSamlIdp = () => {
        // build the params
        var params = {
            groupId: auth.group.id,
            alias: "",
            emailPattern: emailPattern,
            entitySuffix: entitySuffix,
            authComparison: authComparison,
            authContext: authContext,
            idpSsoUrl: "",
            idpIssuer: "",
            idpCertificate: "",
            idpMetadataUrl: "",
            idpMetadataXml: ""
        };
        // set the settings appropriate to the selected choice
        if (explicitSaml) {
            params.idpSsoUrl = samlSsoUrl;
            params.idpIssuer = samlIssuer;
            params.idpCertificate = samlCertificate;
        } else if (metadataUrlSaml) {
            params.idpMetadataUrl = samlMetadataUrl;
        } else if (metadataXmlSaml) {
            params.idpMetadataXml = samlMetadataXml;
        }
        // do the create/update
        let action = 'create_samlidp';       
        if (samlIdp !== null) {
            params.id = samlIdp.id;
            params.alias = samlIdp.alias;
            action = 'update_samlidp';
        }

        Core.runAction(action, params, response => {
            PubSub.publish('success', 'The SAML settings have been saved');
            getSamlIdp();
        });
    }

    const deleteSamlIdp = () => {
        Core.runAction('delete_samlidp', {id: samlIdp.id,}, response => {
            PubSub.publish('success', 'The SAML settings have been deleted');
            getSamlIdp();
        });
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div style={{textAlign:'center'}}><h3>SAML</h3></div>
			<p>This is optional. If you are a SAML 2.0 Identity provider, and you wish to use SAML for user authentication, 
			this is where you can configure this platform (SP) to use you (IDP) when authenticating your users.</p>
			
				<label>Email Pattern</label>
				<input type='text' style={{width:'100%'}} value={emailPattern} onChange={e => setEmailPattern(e.target.value)}
					placeholder='@mycompany.com' maxLength='64' />
			
				<label>Entity Suffix</label>
				<input type='text' style={{width:'100%'}} value={entitySuffix} onChange={e => setEntitySuffix(e.target.value)} maxlength='24' />
			
				<label>Authentication Comparison</label>
				<select value={authComparison} onChange={e => setAuthComparison(e.target.value)}>
					<option value='none'>None</option>
					<option value='exact'>Exact</option>
					<option value='minimum'>Minimum</option>
					<option value='maximum'>Maximum</option>
					<option value='better'>Better</option>
				</select>
			
				<label>Authentication Context</label>
				<select value={authContext} onChange={e => setAuthContext(e.target.value)}>
					<option value='none'>None</option>
					<option value='password'>Password</option>
					<option value='transportpassword'>PasswordProtectedTransport</option>
					<option value='internetprotocol'>InternetProtocol</option>
					<option value='internetprotocolpassword'>InternetProtocolPassword</option>
					<option value='kerberos'>Kerberos</option>
					<option value='mobileonefactorunregistered'>MobileOneFactorUnregistered</option>
					<option value='mobiletwofactorunregistered'>MobileTwoFactorUnregistered</option>
					<option value='mobileonefactorcontract'>MobileOneFactorContract</option>
					<option value='mobiletwofactorcontract'>MobileTwoFactorContract</option>
					<option value='previoussession'>PreviousSession</option>
					<option value='X509'>X509</option>
					<option value='pgp'>PGP</option>
					<option value='spki'>SPKI</option>
					<option value='xmldsig'>XMLDSig</option>
					<option value='smartcard'>Smartcard</option>
					<option value='smartcardpki'>SmartcardPKI</option>
					<option value='softwarepki'>SoftwarePKI</option>
					<option value='Telephony'>Telephony</option>
					<option value='nomadtelephony'>NomadTelephony</option>
					<option value='Personaltelephony'>PersonalTelephony</option>
					<option value='authenticatedtelephony'>AuthenticatedTelephony</option>
					<option value='secureremotepassword'>SecureRemotePassword</option>
					<option value='tlsclient'>TLSClient</option>
					<option value='timesynctoken'>TimeSyncToken</option>
				</select>
                <p>Only configure one configuration method below. Select the checkbox of the configuration method to use.</p>
				<div style={{clear:'both'}}><Checkbox checked={explicitSaml} onChange={val => setExplicitSaml(val)} /><label>Explicit Configuration</label></div>
                {explicitSaml &&
                    <div style={{clear:'both'}}>
                        <label>SSO Url</label>
                        <div><input type='text' value={samlSsoUrl} onChange={e => setSamlSsoUrl(e.target.value)} maxlength='1024' /></div>
                        <label>Issuer</label>
                        <div><input type='text' value={samlIssuer} onChange={e => setSamlIssuer(e.target.value)} maxlength='1024' /></div>
                        <label>Certificate</label>
                        <textarea value={samlCertificate} onChange={e => setSamlCertificate(e.target.value)} maxlength='2048'
                            placeholder='Copy+Paste certificate into here'></textarea>
                    </div>
                }

				<div style={{clear:'both'}}><Checkbox checked={metadataUrlSaml} onChange={val => setMetadataUrlSaml(val)} /><label>Metadata URL Configuration</label></div>
				{metadataUrlSaml && 
                    <div style={{clear:'both'}}>
                        <label>Metadata URL</label>
                        <div><input type='text' value={samlMetadataUrl} onChange={e => setSamlMetadataUrl(e.target.value)}
                            placeholder='https://www.mycompany.com/metadata' maxLength='2048' /></div>
                    </div>
                }

				<div style={{clear:'both'}}><Checkbox value={metadataXmlSaml} onChange={val => setMetadataXmlSaml(val)}/><label>Metadata XML Configuration</label></div>
                {metadataXmlSaml && 
				    <div style={{clear:'both'}}>
                        <label>Metadata XML</label>
                        <textarea value={samlMetadataXml} onChange={e => setSamlMetadataXml(e.target.value)}
                            placeholder='Copy+Paste xml into here'></textarea>
    				</div>
                }
			<p>SP Metadata: {spMetadataUrl.length > 0 ? spMetadataUrl : '(will display after save)'}</p>
			<div style={{textAlign:'center'}}>
				<button onClick={saveSamlIdp}>Save SAML Setup</button>
				<button onClick={deleteSamlIdp} disabled={samlIdp === null}>Delete SAML Setup</button>
			</div>
		</div>
    )
}

export default SAML;
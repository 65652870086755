import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const InventoryContext = React.createContext(null);

function InventoryProvider({productId, children}) {
    const { runAction } = useCore();
    const [inventoryLoading, setInventoryLoading] = useState(true);
    const [inventory, setInventory] = useState([]);

    useEffect(() => {
        loadInventory();
    }, [])

    const loadInventory = () => {
        setInventoryLoading(true);
        runAction('get_inventory', {productId}, (response) => {
            setInventory(response.inventories);
            setInventoryLoading(false);
        })
    }

    const createInventory = (data, callback) => {
        runAction('create_inventory', {...data, product:productId}, () => {
            loadInventory();
            callback();
        })
    }

    const updateInventory = (data, callback) => {
        
        runAction('update_inventory', {...data, product:productId}, () => {
            loadInventory();
            callback();
        })
    }

    const deleteInventories = (id) => {
        runAction('delete_inventory', {inventoryId: id}, () => {
            loadInventory();
        })
    }

    const provider = {
        inventoryLoading, loadInventory,
        inventory,
        createInventory, updateInventory, deleteInventories,
    }

    return <InventoryContext.Provider value={provider}>{children}</InventoryContext.Provider>
}

function useInventory() {
    const context = React.useContext(InventoryContext);
    return context;
}

export { InventoryProvider, useInventory }
import { faCheckCircle, faCircle as faSolidCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faOpenCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'style/progressIndicator.scss';

function ProgressIndicator({steps, activeStep}) {

    return (
        <div className='progress-indicator'>
            {steps.map((stp, idx) => <Step key={idx} step={stp} active={activeStep === idx} />)}
        </div>
    )
}

function Step({step, active}) {

    return (
        <div className={'step' + (active ? ' active' : '')}>
            <div className={'step-icon' + (step.complete ? ' complete' : '')}>
                <FontAwesomeIcon icon={step.complete ? faCheckCircle : step.state === 'todo' ? faSolidCircle : faOpenCircle} /></div>
            <div className='step-label'>{step.label}</div>
        </div>
    )
}

export default ProgressIndicator;
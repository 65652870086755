import React, { useEffect, useState } from 'react';
import 'style/MiniList.scss';

function MiniList(props) {
    const [noRowsLabel, setNoRowsLabel] = useState('There are no items');

    useEffect(() => {
        if (props.noRowsLabel) {
            setNoRowsLabel(props.noRowsLabel);
        }
    }, [props.noRowsLabel])

    const rowClickHandler = (item) => {
        if (props.onRowClick) {
            props.onRowClick(item);
        }
    }

    return (
        <div className='mini-list'>{
            props.rows.length > 0 ?
                props.rows.map((pn, idx) => 
                    <MiniListRow 
                        key={idx} 
                        columns={props.columns} 
                        data={pn}
                        onClick={rowClickHandler} />
                )
            : <div className='mini-list-row no-rows'>{noRowsLabel}</div>
        }</div>
    )
}

export default MiniList;

function MiniListRow(props) {
    return (
        <div className='mini-list-row' onClick={() => props.onClick(props.data)}>
            {props.columns.map((col, idx) => <div key={idx}>
                {col.datum ? props.data[col.datum] 
                    : col.formatter ? col.formatter(props.data) 
                    : col.builder ? col.builder(props.data) 
                    : '!NO DATUM, FORMATTER, OR BUILDER!'}
            </div>)}
        </div>
    )
}
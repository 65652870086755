import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const AffiliatesContext = React.createContext(null);

function AffiliatesProvider({children}) {
    const { runAction } = useCore();
    const [affiliates, setAffiliates] = useState([]);

    useEffect(() => {
        getAffiliates();
    }, [])

    const getAffiliates = () => {
        runAction('get_affiliates', {}, (response) => {
            if (response) {
                setAffiliates(response.affiliates);
            }
        });
    }

    const createAffiliate = (data, callback) => {
        runAction('create_affiliate', data, () => {
            publish('success', 'Successfully created new group.');
            getAffiliates();
            callback();
        });
    }

    const updateAffiliate = (data, callback) => {
        runAction('update_affiliate', data, () => {
            callback()
        })
    }

    const deleteAffiliate = (id) => {
        runAction('delete_affiliate', {group: id}, 
            (response) => {
                if (response) {
                    getAffiliates();
                }
            })
    }

    const provider = {
        affiliates,
        getAffiliates,
        createAffiliate,
        updateAffiliate,
        deleteAffiliate,
    }

    return <AffiliatesContext.Provider value={provider}>{children}</AffiliatesContext.Provider>
}

function useAffiliates() {
    const context = React.useContext(AffiliatesContext);
    if (!context) {
        throw new Error('useAffiliateds must be used within an AffiliatesProvider');
    }
    return context;
}

export { AffiliatesProvider, useAffiliates }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const PaymentMethodsContext = React.createContext(null);

function PaymentMethodsProvider({children}) {
    const { runAction } = useCore();
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        getPaymentMethods();
    }, [])

    const getPaymentMethods = () => {
        runAction('stripe_get_payment_methods', {}, (response) => {
            let pms = response.paymentmethods;
            setPaymentMethods(pms.map((pmJson) => JSON.parse(pmJson)));
        })
    }

    const provider = {
        paymentMethods,
        getPaymentMethods
    }

    return <PaymentMethodsContext.Provider value={provider}>{children}</PaymentMethodsContext.Provider>
}

function usePaymentMethods() {
    const context = React.useContext(PaymentMethodsContext);
    if (!context) {
        throw new Error('usePaymentMethods must be used within a PaymentMethodsProvider');
    }
    return context;
}

export { PaymentMethodsProvider, usePaymentMethods }
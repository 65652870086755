import React from 'react';
import moment from 'moment';
/**
 * the copyright
 */
function Copyright() {
	return (
		<div className='copyright-container'>
			<div className='arrContainer'>&copy; {moment().format('YYYY')} Wade Girard, All Rights Reserved</div>
			<div className='policiesContainer'>
				<a href='http://www.middletwin.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
				<a href='http://www.middletwin.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
			</div>
	    </div>
    );
}

export default Copyright;
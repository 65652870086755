import React, {useState, useEffect, useRef} from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import moment from 'moment';
import Core from '../../../util/core';
import PubSub from 'pubsub-js';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useAuth } from 'contexts/auth';

const greetingCols = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "created", label: "Created date", display: true, style:{width:'100px'}, formatter: function(obj) {return moment(obj.created).format("YYYY-MM-DD");}},
    {id: "type", label: "Type", display: true, style:{width:'100px'}, datum: "type"},
    {id: "url", label: "URL", display: false, datum: "url"},
    {id: "sid", label: "SID", display: false, datum: "sid"},
    {id: "duration", label: "Duration", display: true, style:{width:'100px'}, datum: "duration"}
];

function Greeting(props) {
    const [greetings, setGreetings] = useState([]);
    const [audioSrc, setAudioSrc] = useState('');
    const [newGreetingType, setNewGreetingType] = useState('voicemail');
    const mediaPlayer = useRef(null);
    const newGreetingPanel = useRef(null);
    const auth = useAuth();

    useEffect(() => {
        getGreetings();
    }, []);

    let getGreetings = () => {
        Core.runAction('get_greetings', {}, (response) => {
            setGreetings(response.greetings);
        })
    }

    let loadGreeting = (row) => {
        setAudioSrc(row.url);
        mediaPlayer.current.load();
    }

    let recordGreeting = () => {
        let data = {greeting: newGreetingType};
        Core.runAction('create_greeting', data, (response) => {
            PubSub.publish('notification', 'Answer your phone and follow the instructions. When you are finished click the tables refresh button to see your new greeting');
        });
        newGreetingPanel.hide();
    }

    let deleteGreeting = (greetings) => {
        let data = {id: greetings[0].id};
        Core.runAction('delete_greeting', data, (response) => {
            getGreetings();
        });
    }

    return (
        <div className='communication-area'>
            <TableSetupProvider tableName='greeting' defaultColumns={greetingCols}>
                <AmazingTable 
                    rows={greetings}
                    onRefresh={getGreetings}
                    onRowClick={loadGreeting}
                    onCreate={() => newGreetingPanel.current.show()}
                    onDelete={deleteGreeting}
                    />
            </TableSetupProvider>
            <div style={{display:'flex',justifyContent:'center'}}>
                <audio style={{margin:'auto'}} controls ref={mediaPlayer}>
                    <source src={audioSrc} />
                </audio>
            </div>
            <SidePanel title='Record Greeting' ref={newGreetingPanel}>
                <div style={{margin:'10px'}}>
                    <p>Provide a name for the greeting you would like to record 
                        then click the Record Greeting button. The phone number '{auth.tenant.phone}'
                        will receive a call, follow the instructions on the phone to complete
                        the recording.</p>
                    <input type='text' value={newGreetingType} onChange={(evt) => setNewGreetingType(evt.target.value)} maxLength='24' />
                        
                    <div className='buttonsHolder'>
                        <button onClick={recordGreeting}>Record Greeting</button>
                    </div>
                </div>
            </SidePanel>
        </div>
    )
}

export default Greeting;
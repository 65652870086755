import AmazingTable from 'components/amazingTable';
import Modal from 'components/modal';
import { useFlow } from 'contexts/flow';
import { TableSetupProvider } from 'contexts/tableSetup';
import React, { useState } from 'react';
import moment from 'moment';
import DetailsPanel from 'components/detailsPanel';
import FlowDetails from './flowDetails';

function Flows() {
    const { flowsLoading, flows, loadFlows, createFlow } = useFlow();
    const [showNewFlowDialog, setShowNewFlowDialog] = useState(false);
    const [newFlowName, setNewFlowName] = useState('');
    const [flow, setFlow] = useState(null);

    const flowColumns = [
        { id: 'id', label: 'ID', display: false, datum: 'id' },
        {
            id: 'created',
            label: 'Created',
            display: true,
            formatter: (obj) => {
                return moment(new Date(obj.created)).format('YYYY-MM-DD');
            },
        },
        { id: 'name', label: 'Name', display: true, datum: 'name' },
    ]

    const createFlowHandler = () => {
        createFlow(newFlowName);
        setShowNewFlowDialog(false);
        setNewFlowName('');
    }

    const rowClickHandler = (row) => {
        setFlow(row);
    }

    const hideFlowDetailsHandler = () => {
        setFlow(null);
    }

    const saveFlowHandler = () => {

    }

    return (
        <>
            <TableSetupProvider tableName='flows' defaultColumns={flowColumns}>
                <AmazingTable 
                    rows={flows}
                    onRefresh={loadFlows}
                    onCreate={() => setShowNewFlowDialog(true)}
                    onCreateLabel='Add flow'
                    onRowClick={rowClickHandler}
                    loading={flowsLoading} />
            </TableSetupProvider>
            <Modal 
                title='Create new flow' 
                showing={showNewFlowDialog}
                buttons={[
                    {   type: 'primary', 
                        handler: () => {createFlowHandler()},
                        disabled: newFlowName.length === 0,
                        label:'Create flow'
                    }
                ]} >
                <div>What would you like to name this flow</div>
                <input type='text' value={newFlowName} onChange={evt => setNewFlowName(evt.target.value)} maxLength={64} />
            </Modal>
            <DetailsPanel title={flow && flow.name} showing={flow !== null} onHide={hideFlowDetailsHandler}>
                {flow && 
                    <FlowDetails
                        flowId={flow.id}
                        onHide={hideFlowDetailsHandler}
                        onSave={saveFlowHandler}
                    />
                }
            </DetailsPanel>
        </>
    )
}

export default Flows;
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import 'style/waitButton.scss';

/**
 * the wait button
 */
function WaitButton(props) {
	return(
        <div className='wait-button-container'>
            {props.waitMode
                ? <button><FontAwesomeIcon icon={faSpinner} spin/></button>
                : <button onClick={() => props.onClick()}>{props.label}</button>
            }
        </div>
    )
}

export default WaitButton;
import React, {useState, useRef} from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { useClasses } from 'contexts/classes';
import { TableSetupProvider } from 'contexts/tableSetup';
import Toggle from 'components/toggle';

const classColumns = [
    {id: "id", label:"Number", display: true, style:{width:'50px'}, datum: "id"},
    {id: "section", label: "Section", display: true, style:{width:'50px'}, datum: "section"},
    {id: "description", label: "Description", display: true, datum: "description"},
    {id: "hidden", label: "Hidden", display: true, formatter: obj => obj.hidden ? 'Yes' : 'No'}
];

function Classes() {
    const { classes, saveClass, deleteClass } = useClasses();
    const [editingClass, setEditingClass] = useState();
    const sidePanel = useRef(null);

    const showClassDialog = (val) => {
        setEditingClass(val);
        sidePanel.current.show();
    }

    const handleClassUpdate = (key, val) => {
        let data = {...editingClass};
        data[key] = val;
        setEditingClass(data);
    }

    const saveClassHandler = () => {
        let data = {...editingClass};
        saveClass(data, () => {
            sidePanel.current.hide();
        });
    }

    const deleteClassesHandler = (classes) => {
        let classIds = classes.map((c) => c.id);
        let data = {ids: classIds}
        deleteClass(data);
    }

    const hidePanelHandler = () => {
        setEditingClass(null);
    }

    return (
        <div className='communication-area'>
            <TableSetupProvider tableName='classes' defaultColumns={classColumns}>
                <AmazingTable 
                    rows={classes}
                    onCreate={() => showClassDialog({id: 100, section: 'A', description: 'New class', hidden:false, isNew: true})}
                    onCreateLabel='Add class'
                    onDelete={deleteClassesHandler}
                    onRowClick={(row) => showClassDialog(row)}
                />
            </TableSetupProvider>
            <SidePanel title='Class' ref={sidePanel} onHide={hidePanelHandler}>
                {editingClass && 
                    <div>
                        <div className="contact-preview">
                            <label>Class number</label>
                            <input type='number' value={editingClass.id} onChange={e => handleClassUpdate('id', e.target.value)} min='100' step='100' />
                            <label>Venue section/location/area</label>
                            <select value={editingClass.section} onChange={e => handleClassUpdate('section', e.target.value)}>
                                <option value='0'>-- Choose One --</option>
                                <option value='A'>A</option>
                                <option value='B'>B</option>
                                <option value='C'>C</option>
                                <option value='D'>D</option>
                                <option value='E'>E</option>
                                <option value='F'>F</option>
                                <option value='G'>G</option>
                                <option value='H'>H</option>
                                <option value='I'>I</option>
                                <option value='J'>J</option>
                                <option value='K'>K</option>
                                <option value='L'>L</option>
                                <option value='M'>M</option>
                                <option value='N'>N</option>
                                <option value='O'>O</option>
                                <option value='P'>P</option>
                                <option value='Q'>Q</option>
                                <option value='R'>R</option>
                                <option value='S'>S</option>
                                <option value='T'>T</option>
                                <option value='U'>U</option>
                                <option value='V'>V</option>
                                <option value='W'>W</option>
                                <option value='X'>X</option>
                                <option value='Y'>Y</option>
                                <option value='Z'>Z</option>
                            </select>
                            <label>Description</label>
                            <input type="text" value={editingClass.description} onChange={e => handleClassUpdate('description', e.target.value)} maxLength="64" />
                            <label>Hidden from pre-registration</label>
                            <Toggle checked={editingClass.hidden} onChange={newValue => handleClassUpdate('hidden', newValue)} />
                        </div>
                        <div className="buttonsHolder">
                            <button onClick={saveClassHandler}>Save</button>
                        </div>
                    </div>
                }
            </SidePanel>
        </div>
    )
}

export default Classes;
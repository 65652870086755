import React, { useEffect, useRef, useState } from 'react';
import Header from './header';
import {permission, hasPermission} from 'util/permission';
// the apps
import TermsOfUse from './termsOfUse';
import AccountMessages from './accountMessages';
// settings
import SidePanel from 'components/sidePanel';
import Notifications from 'components/notifications';
import { startup, shutdown } from './socket';
// contexts
import { useAuth } from 'contexts/auth';
import { SettingsProvider } from 'contexts/settings';
import Loading from 'components/loading';
import { AppsProvider, useApps } from 'contexts/apps';
import AppMenu from './appMenu';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import UserSetup from './settings/userSetup';
import 'style/container.scss';
import Modal from 'components/modal';
import { useToken } from 'contexts/token';

function Container() {
    const { authLoading, tous, messages, user, tenant, group } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [tousToAccept, setTousToAccept] = useState([]);
    const [messagesToAcknowledge, setMessagesToAcknowlege] = useState([]);

    useEffect(() => {
        if (!authLoading) {
            if (tous && tous.length > 0) {
                setTousToAccept(tous);
            }
            if (messages && messages.length > 0) {
                setMessagesToAcknowlege(messages);
            }
            if (tenant && tenant.subdomain === '' && hasPermission(user, permission.ACCOUNTADMIN)) {
                // setup
                navigate('/setup');
            } else {
                if (tenant) {
                    if (!pathname.startsWith('/admin/') || pathname === '/admin/') {
                        if (hasPermission(user, permission.ACCOUNTADMIN)) {
                            navigate('/admin/dashboard');
                        } else if (hasPermission(user, permission.MEMBERSHIP)) {
                            navigate('/admin/membership');
                        } else if (hasPermission(user, permission.EVENTADMIN)) {
                            navigate('/admin/events/shows');
                        } else if (hasPermission(user, permission.REGISTRATION)) {
                            navigate('/admin/events/shows');
                        } else if (hasPermission(user, permission.SCHEDULE)) {
                            navigate('/admin/events/shows');
                        } else if (hasPermission(user, permission.BALLOTING)) {
                            navigate('/admin/events/shows');
                        } else if (hasPermission(user, permission.STOREADMIN)) {
                            navigate('/admin/store');
                        } else if (hasPermission(user, permission.FINANCE)) {
                            navigate('/admin/finance');
                        } else if (hasPermission(user, permission.PHONE)) {
                            navigate('/admin/phone');
                        } else if (hasPermission(user, permission.VIDEO)) {
                            navigate('/admin/video');
                        }
                    }
                    startup();
                } else if (group) {
                    if (!pathname.startsWith('/group/')) {
                        navigate('/group/dashboard');
                    }
                } else {
                    navigate('/admin/accounts');
                }
            }
            return () => {
                shutdown();
            };
        }
    }, [authLoading])
   
    const hasAcceptedTous = () => {
        setTousToAccept([]);
    }

    const hasDeclinedTous = () => {
        navigate('/logout');
    }

    const hasAsknowledgedMessages = () => {
        setMessagesToAcknowlege([]);
    }

    return(
        <div>
            {tousToAccept.length > 0 && 
                <TermsOfUse 
                    tous={tousToAccept} 
                    onAccept={hasAcceptedTous} 
                    onDecline={hasDeclinedTous} />}
            {(tousToAccept.length === 0 && messagesToAcknowledge.length > 0) && 
                <AccountMessages 
                    messages={messagesToAcknowledge} 
                    onAcknowledge={hasAsknowledgedMessages} 
                    onReadLater={hasAsknowledgedMessages} /> }
            <AppsProvider>
                <MainApp />
            </AppsProvider>
        </div>
    )
}

function MainApp() {
    const { apps } = useApps();
    const { tenant, refresh } = useAuth();
    const { expiring, setExpiring, expired } = useToken();
    const navigate = useNavigate();
    const settingsPanel = useRef(null);
    
    useEffect(() => {
        if (expired) {
            navigate('/logout');
        }
    }, [expired])

    const helpHandler = () => {
        window.open("http://www.middletwin.com/knowledge-base/");
    }

    return (
        <>
            {apps.length > 0 ? 
                <div className='main-container'>
                    <SettingsProvider>
                        <Header 
                            onSettings={() => settingsPanel.current.show()}
                            onHelp={helpHandler} />
                        <div className='app-container'>
                            <div className='app-menu-container'>
                                <AppMenu />
                            </div>
                            <div className='app-area'>
                                <Outlet />
                            </div>
                        </div>
                        <SidePanel title='Settings' ref={settingsPanel}>
                            <UserSetup />
                        </SidePanel>
                    </SettingsProvider>
                    <Notifications />
                    <Modal 
                        title='Are you still there?' 
                        showing={expiring} 
                        onHide={() => setExpiring(false)}
                        buttons={[
                            {
                                type: 'primary', 
                                handler: () => {refresh(tenant ? tenant.id : null);},
                                label:'Keep me logged in'}
                        ]}>
                        <p>Your session is about to expire. You will be automatically logged out in 60 seconds.</p>
                    </Modal>
                </div>
            : <Loading />}
        </>
    )
}

export default Container;
import React from 'react';
import { isSupported } from 'twilio-video';
import { VideoProvider } from '../../contexts/video';
import RoomList from './roomList';
import LocalTracks from './localTracks';
import Room from './room';
import RoomCreator from './roomCreator';

function Video() {

    return (
        <div style={{margin: '20px 40px'}}>
            {isSupported ? 
            <VideoProvider>
                <div className='video-container'>
                    <LocalTracks />
                    <div className='room'>
                        
                        <RoomList />
                        <RoomCreator />
                        <Room />
                    </div>
                </div>
            </VideoProvider>
            : <p>Your browser is not supported for video.</p>}
        </div>
    )
}

export default Video;
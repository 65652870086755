import React, {useEffect, useState} from 'react';

function NewProduct(props) {
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [upc, setUpc] = useState('');

    useEffect(() => {
        setName('')
    }, [])

    const save = () => {
        let data = {
            name: name,
            sku: sku,
            upc: upc,
            description: 'New Product'
        }
        props.onSave(data);
    }

    return (
        <div>
            <p>Tell us a little bit about this product to begin</p>
			<div className='contact-preview'>
				<label>Name</label>
				<input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
				<label>SKU (leave blank if none)</label>
				<input type='text' value={sku} onChange={evt => setSku(evt.target.value)} maxLength='64' />
				<label>UPC (leave blank if none)</label>
				<input type='text' value={upc} onChange={evt => setUpc(evt.target.value)} maxLength='64' />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default NewProduct;
import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import { formatCurrency } from '_base';
import SubscriptionInvoiceItems from './subscriptionInvoiceItems';
import SidePanel from 'components/sidePanel';
import DatePicker from 'react-datepicker';
import { useSubscriptionInvoices } from 'contexts/subscriptionInvoices';
import { SubscriptionInvoiceItemsProvider } from 'contexts/subscriptionInvoiceItems';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "created", label: "Created", display: true, formatter: (obj) => {return moment(obj.created).format("YYYY-MM-DD");}},
    {id: "dueDate", label: "Due", display: true, formatter: (obj) => {return moment(obj.dueDate).format("YYYY-MM-DD");}},
    {id: "amount", label: "Amount", display: true, formatter: (obj) => {return formatCurrency(obj.amount);}},
    {id: "paidDate", label: "Paid", display: true, formatter: (obj) => {return obj.paidDate ? moment(obj.paidDate).format("YYYY-MM-DD") : 'Not Paid';}}
];

function SubscriptionInvoices() {
    const { invoicesLoading, invoices, getInvoices, deleteInvoice } = useSubscriptionInvoices();
    const [invoice, setInvoice] = useState(null);
    const invoicePanelRef = useRef(null);

    const removeInvoice = (rows) => {
        const invoiceId = rows[0].id;
        deleteInvoice(invoiceId);
    }

    const editInvoice = (invoices) => {
        setInvoice(invoices[0]);
        invoicePanelRef.current.show();
    }

    const saveHandler = () => {
        setInvoice(null);
        invoicePanelRef.current.hide();
    }

    return (
        <>
            <TableSetupProvider tableName='invoices' defaultColumns={columns}>
                <AmazingTable 
                    rows={invoices}
                    onRefresh={getInvoices}
                    laoding={invoicesLoading}
                    onCreate={() => invoicePanelRef.current.show()}
                    onCreateLabel='Add invoice'
                    onDelete={removeInvoice}
                    onEdit={editInvoice}
                    onRowClick={row => setInvoice(row)} />
            </TableSetupProvider>
            {invoice && 
                <SubscriptionInvoiceItemsProvider invoiceId={invoice.id}>
                    <SubscriptionInvoiceItems invoiceId={invoice.id} />
                </SubscriptionInvoiceItemsProvider>}
            <SidePanel title='Invoice' ref={invoicePanelRef}>
                <SubscriptionInvoice invoice={invoice} onSave={saveHandler} />
            </SidePanel>
        </>
    )
}

export default SubscriptionInvoices;

function SubscriptionInvoice({invoice, onSave}) {
    const { saveInvoice } = useSubscriptionInvoices();
    const [dueDate, setDueDate] = useState(null);
    const [paidDate, setPaidDate] = useState(null);

    useEffect(() => {
        if (invoice) {
            setDueDate(invoice.dueDate);
            setPaidDate(invoice.paidDate);
        }
    }, [invoice])

    const save = () => {
        let data = {dueDate, paidDate};
        if (invoice) {
            data.id = invoice.id;
        }
        saveInvoice(data, () => {
            onSave();
        });
    }

    return (
        <div className='contact-preview'>
            <label>Due Date</label>
            <DatePicker selected={dueDate ? moment(dueDate).toDate() : null} onChange={date => setDueDate(date.getTime())}/>
            <label>Paid Date</label>
            <DatePicker selected={paidDate ? moment(paidDate).toDate() : null} onChange={date => setPaidDate(date.getTime())}/>
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';

const MAX = 1600;

function CharacterCounter(props) {
    const [charCount, setCharCount] = useState(MAX);

    useEffect(() => {
        // set the character count
        let val = props.text.length;
        let msgNum = Math.floor((val / 160)) + 1;
        setCharCount((MAX - val) + " (" + msgNum + " of 10) ");
    }, [props.text])


    return (
        <div className='messageCharacterCounter'>{charCount}</div>
    )
}

export default CharacterCounter;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import moment from 'moment';
import { publish } from 'pubsub-js';

const ReceiptsContext = React.createContext(null);

function ReceiptsProvider({tenantId, children}) {
    const { runAction } = useCore();
    const [month, setMonth] = useState(moment().startOf('month'));
    const [receiptsLoading, setReceiptsLoading] = useState(true);
    const [receipts, setReceipts] = useState([]);
    const [status, setStatus] = useState('3');

    useEffect(() => {
        getReceipts();
    }, [month, status])

    const getReceipts = () => {
        setReceiptsLoading(true);
        const startDate = month.valueOf();
		const endDate = moment(month).endOf('month').valueOf();
        let data = {tenant: tenantId, start: startDate, end: endDate}
        if (status !== '-1') {
            data.status = status;
        }
        runAction('get_receipts', data,
            (response) => {
                if (response) {
                    setReceipts(response.receipts);
                }
                setReceiptsLoading(false);
            })
    }

    const nextMonth = () => {
        setMonth(moment(month).add(1, 'months'));
    }

    const prevMonth = () => {
        setMonth(moment(month).subtract(1, 'months'));
    }

    const createReceipt = (receipt, callback) => {
        const data = {tenant: tenantId, ...receipt}
        runAction('create_receipt', data, (response) => {
            if (response) {
                callback();
            }
        });
    }

    const updateReceipts = (receiptsArr) => {
        const data = {tenant: tenantId, receipts: receiptsArr};
        runAction('update_receipts', data, (response) => {
            if (response) {
                getReceipts();
            }
        })
    }

    const deleteReceipts = (ids) => {
        let data = {
            tenant: tenantId,
            receipts: ids
		};
        runAction('delete_receipts', data, (response) => {
            if (response) {
                publish('success', `Successfully removed ${response.deletedCount} receipt(s)`);
                getReceipts();
            }
        })
    }

    const provider = {
        receiptsLoading,
        receipts,
        month,
        getReceipts,
        nextMonth,
        prevMonth,
        createReceipt,
        updateReceipts,
        deleteReceipts,
        status, setStatus,
    }

    return <ReceiptsContext.Provider value={provider}>{children}</ReceiptsContext.Provider>
}

function useReceipts() {
    const context = React.useContext(ReceiptsContext);
    if (!context) {
        throw new Error('useReceipts must be used within a ReceiptsProvider');
    }
    return context;
}

export { ReceiptsProvider, useReceipts }
import React, { useState, useEffect } from 'react'
import Toggle from 'components/toggle'
import WaitMusic from 'components/waitMusic';
import Loading from 'components/loading';

import { useTenantSettings } from 'contexts/tenantSettings';
import { useStripeSettings } from 'contexts/stripeSettings';

function AccountSettings() {
    const { settingsLoading, settings, getSettings, saveSettings } = useTenantSettings();
    const { createStripeAccount, createStripeCustomer } = useStripeSettings();
    const [archive, setArchive] = useState(false);
    const [voice, setVoice] = useState(false);
    const [record, setRecord] = useState(false);
    const [transcribe, setTranscribe] = useState(false);
    const [useMiddletwinAccount, setUseMiddletwinAccount] = useState(false);
    const [stripeAccountId, setStripeAccountId] = useState('');
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [fromEmail, setFromEmail] = useState('');
    const [memberEmail, setMemberEmail] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const [hideCallerId, setHideCallerId] = useState(false);
    const [useVoiceAvailable, setUseVoiceAvailable] = useState(true);
    const [voiceWaitDelay, setVoiceWaitDelay] = useState(15);
    const [waitMusic, setWaitMusic] = useState(null);

    useEffect(() => {
        if (!settingsLoading) {
            setArchive(settings.archive);
            setVoice(settings.voice);
            setRecord(settings.record);
            setTranscribe(settings.transcribe);
            setUseMiddletwinAccount(settings.useMiddletwinAccount);
            setStripeAccountId(settings.stripeAccountId);
            setStripeCustomerId(settings.stripeCustomerId);
            setFromEmail(settings.fromEmail);
            setMemberEmail(settings.memberEmail);
            setStoreEmail(settings.storeEmail);
            setHideCallerId(settings.hideCallerId);
            setUseVoiceAvailable(settings.useVoiceAvailable);
            setVoiceWaitDelay(settings.voiceWaitDelay);
            setWaitMusic(settings.waitMusic);
        }    
    }, [settingsLoading]);

    const initializeStripeAccount = () => {
        createStripeAccount(getSettings);
    }

    const initializeStripeCustomer = () => {
        createStripeCustomer(getSettings);
    }

    const waitMusicChangeHandler = (music) => {
        setWaitMusic(music.id);
        // TODO: must tell music player to .load() after changing URL
    }
    
    const saveHandler = () => {
        const data = {archive, voice, record, transcribe, useMiddletwinAccount, stripeAccountId, stripeCustomerId,
            fromEmail, storeEmail, hideCallerId, useVoiceAvailable, voiceWaitDelay, waitMusic};
        saveSettings(data);
    }

    return (
        <div className="communication-area selected">
            {!settingsLoading ?
                <div>
                    <h4>Add-Ons</h4>
                    <fieldset>
                        <table>
                            <tbody>
                                <tr><td><Toggle checked={archive} onChange={(newValue) => setArchive(newValue)} /></td><td>Archive</td></tr>
                                <tr><td><Toggle checked={voice} onChange={(newValue) => setVoice(newValue)} /></td><td>Voice</td></tr>
                                <tr><td><Toggle checked={record} onChange={(newValue) => setRecord(newValue)} /></td><td>Record</td></tr>
                                <tr><td><Toggle checked={transcribe} onChange={(newValue) => setTranscribe(newValue)} /></td><td>Transcribe</td></tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <hr/>
                    <label>Stripe</label>
                    <fieldset>
                        <table>
                            <tbody>
                                <tr><td><Toggle checked={useMiddletwinAccount} onChange={(newValue) => setUseMiddletwinAccount(newValue)} /></td><td>Use Middletwin Account</td></tr>
                            </tbody>
                        </table>
                        <label>Stripe Account Id</label>
                        <div><input type="text" placeholder='Stripe account Id' value={stripeAccountId} onChange={e => setStripeAccountId(e.target.value)} /></div>
                        <div style={{marginTop: '10px'}}>
                            <button onClick={initializeStripeAccount} disabled={stripeAccountId !== ''}>Initialize</button>
                        </div>
                        <label>Stripe Customer Id</label>
                        <div><input type="text" placeholder='Stripe customer Id' value={stripeCustomerId} onChange={e => setStripeCustomerId(e.target.value)}/></div>
                        <div style={{marginTop: '10px'}}>
                            <button onClick={initializeStripeCustomer} disabled={stripeCustomerId !== ''}>Initialize</button>
                        </div>
                    </fieldset>
                    <hr/>
                    <label>Email Addresses</label>
                    <fieldset>
                        <label>From Email (needs to be verified in SES)</label>
                        <div><input type="text" value={fromEmail} onChange={e => setFromEmail(e.target.value)} /></div>
                        <label>Membership (new and renewing)</label>
                        <div><input type="text" value={memberEmail} onChange={e => setMemberEmail(e.target.value)} /></div>
                        <label>Store (purchase notifications)</label>
                        <div><input type="text" value={storeEmail} onChange={e => setStoreEmail(e.target.value)} /></div>
                    </fieldset>
                    <hr/>
                    <label>Voice Setup</label>
                    <fieldset>
                        <table>
                            <tbody>
                                <tr><td><Toggle checked={hideCallerId} onChange={setHideCallerId} /></td><td>Hide Caller ID</td></tr>
                                <tr><td><Toggle checked={useVoiceAvailable} onChange={setUseVoiceAvailable} /></td><td>Use Voice Available</td></tr>
                            </tbody>
                        </table>
                        <label>Voice Wait Delay</label>
                        <div><input type='number' value={voiceWaitDelay} onChange={e => setVoiceWaitDelay(e.target.value)}/></div>
                        <label>Wait Music</label>
                        <WaitMusic value={waitMusic} onChange={waitMusicChangeHandler}/>
                    </fieldset>
                    <div>
                        <button onClick={saveHandler}>Save Account Settings</button>
                    </div>
                </div>
            : <Loading />}
        </div>
    )
}

export default AccountSettings;
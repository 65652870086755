import React, {useState, useEffect, useRef} from 'react';
import Availability from './availability';
import IVR from './ivr';
import PubSub from 'pubsub-js';
import Core from '../../../util/core';
import { formatPhoneNumber } from '../../../_base';
import './phoneSetup.scss'

import SidePanel from 'components/sidePanel';
import GreetingSetup from './greetingSetup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '../../../contexts/auth';
import { useSettings } from '../../../contexts/settings';

function PhoneSetup() {
    const settings = useSettings();
    const auth = useAuth();
    const [twilioNumber, setTwilioNumber] = useState(null);
    const [tenantNumber, setTenantNumber] = useState('');
    
    const sidePanel = useRef(null);

    useEffect(() => {
        if (auth.tenant.twilioNumber === null || auth.tenant.twilioNumber.released !== null || auth.tenant.twilioNumber.created === null) {
            // nothing to do really
        } else {
            setTwilioNumber(auth.tenant.twilioNumber.endpoint);
        }
        setTenantNumber(auth.tenant.phone)
    }, [auth.tenant]);

    const updateSettings = (setting) => {
        let data = {...settings.tenant, ...setting}
        Core.runAction('update_tenant_settings', data, (response) => {
            //PubSub.publish('success', 'Settings successfully saved');
            settings.refreshTenantSettings();
        })
    }

    const saveTenantPhoneNumber = () => {
        Core.runAction('set_forward', {phone: tenantNumber, countryCode: 'US'}, response => {
            sidePanel.current.hide();
            PubSub.publish('success', 'Phone number updated');
        });
    }

    const setVoiceMode = (mode) => {
        let data = {mode};
        Core.runAction('set_voice_mode', data, response => {
            if (response.success) {
                updateSettings({voiceMode: mode});
                PubSub.publish('success', 'Voice settings changed.' + (mode === '1' ? ' IMPORTANT! Check your settings and ensure they are correct' : ''));
            }
        })
    }

    return (
        <div style={{padding:'50px'}}>
            <div className='communication-area-outer'>
                <div className='communication-area-inner'>
                    <div className="communication-area">
                        {`When someone calls ${twilioNumber} I want the calls to`}
                        <div className='patternSettings' style={{border:'none'}}>
                            <ul style={{listStyle:'none',padding:0}}>
                                <li>
                                    <select value={settings.tenant.voiceMode} onChange={(e) => setVoiceMode(e.target.value)}>
                                        <option value="0">Forward to the forwarding number</option>
                                        <option value="1">Use Phone Tree</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                        {settings.tenant.voiceMode === 0 &&
                        <>
                            <label>Forwarding number <FontAwesomeIcon icon={faEdit} onClick={() => sidePanel.current.show()} /></label>
                            <div>{formatPhoneNumber(tenantNumber)}</div>
                            <hr style={{margin:'50px 0'}} />
                            <GreetingSetup 
                                heading={'Greeting Messages'}
                                description={'Setup the greeting messages that callers hear.'}
                                types={
                                    [
                                        {label: 'Voicemail message', key: 'voicemailGreeting'},
                                        {label: 'Incoming call message', key: 'incomingCallGreeting'},
                                    ]
                                }
                            />
                            <hr style={{margin:'50px 0'}} />
                            <Availability />
                        </>}
                        {settings.tenant.voiceMode === 1 && <IVR />}
                    </div>
                </div>
            </div>
            <SidePanel title='Change Main Account Phone Number' ref={sidePanel}>
                <div>
                    <p>If you do not setup an IVR then the main account phone number (this numnber) is the number that all incoming 
                        calls will be forwarded to.</p>
                    <label>Phone Number (ex. 6121112222)</label>
                    <input type='text' value={tenantNumber} onChange={(evt) => setTenantNumber(evt.target.value)} />
                    <button onClick={saveTenantPhoneNumber}>Save Change</button>
                </div>
            </SidePanel>
        </div>
    )
}

export default PhoneSetup;
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowAltCircleLeft, faArrowAltCircleRight} from '@fortawesome/pro-regular-svg-icons';
import { usePagination } from 'contexts/pagination';

function Pagination() {
    const { page, maxPages, onPreviousPage, onNextPage } = usePagination();

    return (
        <div className='table-pagination'>
            <div className={'pagination' + (page === 1 ? ' disabled' : '')} onClick={onPreviousPage}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'20px',marginRight:'5px'}} />
            </div>
            <div className='page-number'>{page}</div>
            <div className={'pagination' + (page === maxPages ? ' disabled' : '')} onClick={onNextPage}>
                <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'20px',marginLeft:'5px'}} />
            </div>
        </div>
    )
}

export default Pagination;
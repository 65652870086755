import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const FlowContext = React.createContext(null);

function FlowProvider({children}) {
    const { runAction } = useCore();
    const [flowsLoading, setFlowsLoading] = useState(true); 
    const [flows, setFlows] = useState([]);

    useEffect(() => {
        loadFlows();
    }, [])

    const loadFlows = () => {
        setFlowsLoading(true);
        runAction('get_flows', {}, response => {
            setFlows(response.flows);
            setFlowsLoading(false);
        })
    }

    const createFlow = (name) => {
        runAction('create_flow', {name}, response => {
            loadFlows();
        })
    }

    const createFlowTemplate = (data, callback) => {
        runAction('create_flow_template', data, () => {
            loadFlows();
            callback();
        })
    }

    const updateFlowTemplate = (data, callback) => {
        runAction('update_flow_template', data, () => {
            loadFlows();
            callback();
        })
    }

    const deleteFlowTemplates = (ids) => {
        runAction('delete_flow_templates', {ids}, () => {
            loadFlows();
        })
    }

    const provider = {
        flowsLoading,
        flows,
        loadFlows,
        createFlow,
        createFlowTemplate,
        updateFlowTemplate,
        deleteFlowTemplates,
    }

    return <FlowContext.Provider value={provider}>{children}</FlowContext.Provider>
}

function useFlow() {
    const context = React.useContext(FlowContext);
    if (!context) {
        throw new Error('useFlow must be used within a FlowProvider');
    }
    return context;
}

export { FlowProvider, useFlow }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ShiftsContext = React.createContext(null);

function ShiftsProvider({eventId, children}) {
    const { runAction } = useCore();
    const [allShifts, setAllShifts] = useState([]);

    useEffect(() => {
        getAllShifts();
    }, [eventId])

    const getAllShifts = () => {
        runAction('get_all_shifts', {eventId}, (response) => {
            setAllShifts(response.shifts);
        })
    }

    const getScheduleShifts = (scheduleId) => {
        return allShifts.filter(shft => shft.schedule == scheduleId);
    }

    const getWorkerShifts = (workerId) => {
        return allShifts.filter(shft => shft.worker == workerId);
    }

    const saveShift = (data, callback) => {
        const action = (data.id) ? 'update_shift' : 'create_shift';
        runAction(action, data, () => {
            getAllShifts();
            callback();
        });
    }

    const deleteShifts = (ids) => {
        runAction('delete_shift', {ids}, () => {
            getAllShifts();
        })
    }

    const provider = {
        allShifts,
        getAllShifts,
        getScheduleShifts,
        getWorkerShifts,
        saveShift,
        deleteShifts,
    }

    return <ShiftsContext.Provider value={provider}>{children}</ShiftsContext.Provider>
}

function useShifts() {
    const context = React.useContext(ShiftsContext);
    if (!context) {
        throw new Error('useShifts must be used within a ShiftsProvider');
    }
    return context;
}

export { ShiftsProvider, useShifts }
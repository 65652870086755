import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import { formatCurrency } from '_base';
import SidePanel from 'components/sidePanel';
import CurrencyInput from 'react-currency-input-field';
import { useSubscriptionInvoiceItems } from 'contexts/subscriptionInvoiceItems';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "subscriptionInvoiceId", label: "Invoice Id", display: false, datum: 'subscriptionInvoiceId'},
    {id: "created", label: "Created", display: true, formatter: (obj) => {return moment(obj.created).format("YYYY-MM-DD");}},
    {id: "amount", label: "Amount", display: true, formatter: (obj) => {return formatCurrency(obj.amount);}},
    {id: "description", label: "Description", display: true, datum: 'description'}
];

function SubscriptionInvoiceItems() {
    const { itemsLoading, items, getItems, deleteItem } = useSubscriptionInvoiceItems();
    const [item, setItem] = useState(null);
    const itemPanelRef = useRef(null);

    const removeItem = (rows) => {
        const itemId = rows[0].id;
        deleteItem(itemId);
    }

    const editItem = (item) => {
        setItem(item);
        itemPanelRef.current.show()
    }

    const saveHandler = () => {
        setItem(null);
        itemPanelRef.current.hide()
        getItems();
    }

    return (
        <>
            <TableSetupProvider tableName='invoiceitems' defaultColumns={columns}>
                <AmazingTable 
                    loading={itemsLoading}
                    rows={items}
                    onRefresh={getItems}
                    onCreate={() => itemPanelRef.current.show()}
                    onCreateLabel='Add Item'
                    onDelete={removeItem}
                    onRowClick={editItem} />
            </TableSetupProvider>
            <SidePanel title='Invoice Item' ref={itemPanelRef}>
                <SubscriptionInvoiceItem item={item} onSave={saveHandler} />
            </SidePanel>
        </>
    )
}

export default SubscriptionInvoiceItems;

function SubscriptionInvoiceItem({item, onSave}) {
    const { saveItem } = useSubscriptionInvoiceItems();
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (item) {
            setAmount(item.amount);
            setDescription(item.description)
        }
    }, [item])

    const save = () => {
        let data = {amount, description}
        if (item) {
            data.id = item.id;
        }
        saveItem(data, () => {
            onSave();
        })
    }

    return (
        <div className='contact-preview'>
            <label>Amount</label>
            <CurrencyInput prefix='$' value={amount} onValueChange={(value) => setAmount(value)} />
            <label>Description</label>
            <textarea value={description} onChange={e => setDescription(e.target.value)} />
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}
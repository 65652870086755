import moment from 'moment';
import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import AccountMessage from './AccountMessage';
import 'style/accountMessages.scss';
import { useAccountMessages } from 'contexts/accountMessages';
import { TableSetupProvider } from 'contexts/tableSetup';

const messagesColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'message', label: 'Message', display: true, datum: 'message'},
    {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created).format('YYYY-MM-DD hh:mm:ss a')},
    {id: 'delivered', label: 'Delivered', display: true, formatter: obj => moment(obj.delivered).format('YYYY-MM-DD hh:mm:ss a')},
    {id: 'acknowledged', label: 'Acknowledged', display: true, formatter: obj => moment(obj.acknowledged).format('YYYY-MM-DD hh:mm:ss a')}
]

function AccountMessages() {
    const { messagesLoading, getMessages, messages, deleteMessages, saveMessage } = useAccountMessages();
    
    const [message, setMessage] = useState(null);
    const messagePanel = useRef(null);

    const deleteMessagesHandler = (msgs) => {
        const ids = msgs.map(m => m.id);
        deleteMessages(ids);
    }
    // acknowledge_tenant_message
    // create_tenant_messages
    const createMessage = () => {
        setMessage(null);
        messagePanel.current.show();
    }

    const editMessage = (msg) => {
        setMessage(msg);
        messagePanel.current.show();
    }

    const saveHandler = (data) => {
        saveMessage(data, () => {
            messagePanel.current.hide();
        })
    }

    return (
        <>
            <TableSetupProvider tableName='acctmessages' defaultColumns={messagesColumns}>
                <AmazingTable
                    rows={messages}
                    onRefresh={getMessages}
                    onDelete={deleteMessagesHandler}
                    onCreate={createMessage}
                    onCreateLabel='Add message'
                    onRowClick={editMessage}
                    loading={messagesLoading} />
            </TableSetupProvider>
            <SidePanel title='Message' ref={messagePanel}>
                <AccountMessage message={message} onSave={saveHandler} />
            </SidePanel>
        </>
    )
}

export default AccountMessages;
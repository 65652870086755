import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useAuth } from './auth';
import { publish } from 'pubsub-js';

const TenantsContext = React.createContext(null);

function TenantsProvider({children}) {
    const { runAction } = useCore();
    const { group } = useAuth();
    const [tenantsLoading, setTenantsLoading] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [available, setAvailable] = useState(0);

    useEffect(() => {
        getTenants();
    }, []);

    const getTenants = (search = {}) => {
        const _limit = search.limit ? search.limit : limit;
        const _offset = search.offset ? search.offset : offset;
        let data = {limit: _limit, offset: _offset};
        if (search.google) {
            data.google = search.google;
        }
        if (search.state) {
            data.state = search.state;
        }
        if (group) {
            data.group = group.id;
        }
        setTenantsLoading(true);
        runAction('get_tenants', data, (response) => {
            if (response) {
                setTenants(response.tenants);
                setAvailable(response.available);
                setLimit(_limit);
                setOffset(_offset);
                setTenantsLoading(false);
            }
        });
    }

    const createTenant = (data, callback) => {
        runAction('signup', data, response => {
            if (response.success) {
                publish('success', 'Successfully created new account.');
                getTenants();
                callback();
            } else {
                publish('error', 'Error' + response.errorMessage);
            }
        });
    }

    const deleteTenant = (id) => {
        runAction('delete_tenant', {id},
            (response) => {
                if (response) {
                    getTenants();
                }
            })
    }

    const importTenants = (map, objects, callback) => {
        const data = {'map': map, 'objects': objects};
        runAction('import_tenants', data, 
            (result) => {
                if (result) {
                    publish('success', result.message);
                    getTenants();
                    callback();
                }
            });
    }

    const provider = {
        tenantsLoading,
        tenants,
        available,
        getTenants,
        deleteTenant,
        createTenant,
        importTenants,
    }

    return <TenantsContext.Provider value={provider}>{children}</TenantsContext.Provider>
}

function useTenants() {
    const context = React.useContext(TenantsContext);
    if (!context) {
        throw new Error('useTenants must be used within a TenantsProvider');
    }
    return context;
}

export { TenantsProvider, useTenants }
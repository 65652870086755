import React, { useEffect, useState } from 'react';

const TagPillBoxContext = React.createContext(null);

function TagPillBoxProvider({tags, onChange, children}) {
    const [selectedTags, setSelectedTags] = useState(tags);

    useEffect(() => {
        onChange(selectedTags);
    }, [selectedTags])

    // tags
    const addTag = (tag) => {
        let _tags = [...selectedTags];
        if (-1 === _tags.findIndex(t => t.id === tag.id)) {
            _tags.push(tag);
            setSelectedTags(_tags);
        }
    }

    const removeTag = (tag) => {
        let _tags = [...selectedTags];
        const idx = _tags.findIndex(t => t.id === tag.id);
        _tags.splice(idx, 1);
        setSelectedTags(_tags);
    }

    const provider = {
        selectedTags,
        addTag,
        removeTag,
    }

    return <TagPillBoxContext.Provider value={provider}>{children}</TagPillBoxContext.Provider>
}

function useTagPillBox() {
    const context = React.useContext(TagPillBoxContext);
    if (!context) {
        throw new Error('useTagPillBox must be used within a TagPillBoxProvider.');
    }
    return context;
}

export { TagPillBoxProvider as PillBoxProvider, useTagPillBox as usePillBox }
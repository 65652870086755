import React, { useState } from 'react'

function Password(props) {
    const [password, setPassword] = useState('');
    const [strength, setStrength] = useState(0);
    const [verify, setVerify] = useState('');

    const passwordChangeHandler = (evt) => {
        let pw = evt.target.value;
        let st = 0;
        if (pw.length > 8) {
            st += 1; 
        }
        if (pw.match(/[!@#$%^&*]/g)) {
            st += 1;
        }
        if (pw.match(/[0-9]/g)) {
            st += 1;
        }
        setPassword(pw);
        setStrength(st)
    }

    const verifyPassword = (evt) => {
        setVerify(evt.target.value);
    }

    const saveHandler = () => {
        props.onSave(password);
        setPassword('');
        setVerify('')
        setStrength('Poor');
    }

    return(
        <div>
            <label>New Password</label>
            <div><input type='password' value={password} onChange={passwordChangeHandler} /></div>
            <div className='strength-indicator'>
                <div className={'strength-level' + (strength > 0 ? ' on' : '')}></div>
                <div className={'strength-level' + (strength > 1 ? ' on' : '')}></div>
                <div className={'strength-level' + (strength > 2 ? ' on' : '')}></div>
            </div>
            <label>Verify New Password</label>
            <div><input type='password' value={verify} onChange={verifyPassword} /></div>
            <div className='buttonsHolder'>
                <button onClick={saveHandler} className='secondary' disabled={password.length === 0 || (password !== verify)}>Save Password</button>
            </div>
        </div>
    )
}

export default Password;
import React from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';
import Loading from 'components/loading';
import { useSubscriptionTypes } from 'contexts/subscriptionTypes';
import { useSubscription } from 'contexts/subscription';

function Subscription() {
    const { types } = useSubscriptionTypes()
    const { subscription } = useSubscription();

    const getSubscriptionType = () => {
        if (types.length > 0 && subscription) {
            const type = types.find(t => t.id === subscription.subscriptionTypeId);
            if (type) {
                return type.label;
            }
        }
        return 'Unknown';
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            {subscription ?
            <div>
                <p>This is your current subscription setup. If your membership count goes over the limit of your current subscription, the subscription will be updated to reflect
                the change and you will be billed a pro-rated amount for the remainder of your subscription. If you need to make any changes or have any questions please contact
                customer support. You will receive notifications when it is time for your subscription to renew and when it renews.</p>
                <label>Subscription</label>
                <fieldset>
                    <label>Subscription Type</label>
                    <div>{getSubscriptionType()}</div>
                    <label>Subscription Renewal Amount</label>
                    <div>{formatCurrency(subscription.renewalAmount)}</div>
                    <label>Subscription Transaction Amount</label>
                    <div>{formatCurrency(subscription.transactionAmount)}</div>
                    <label>Subscription Renewal Date (yyyy-mm-dd)</label>
                    <div>{moment(subscription.expiration).format('YYYY-MM-DD')}</div>
                    <label>Subscription Renewal Type</label>
                    <div>{subscription.annualRenewal ? 'Annually' : 'Monthy'}</div>
                    <label>Last Subscription Update (yyyy-mm-dd)</label>
                    <div>{moment(subscription.updated).format('YYYY-MM-DD')}</div>
                </fieldset>
            </div>
            : <Loading />
            }
        </div>
    )   
}

export default Subscription;
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import 'style/termsOfUse.scss';
import { useCore } from 'contexts/core';

function TermsOfUse(props) {
    const { runAction } = useCore();
    const [touList, setTouList] = useState(props.tous);

    const acceptHandler = (touId) => {
        let data = {tou: touId}
        runAction('accept_tou', data, response => {
            if (touList.length === 1) {
                props.onAccept();
            }
            // remove the accepted tou from the tou list
            let touListCopy = [...touList];
            const touIndex = touListCopy.findIndex(tou => tou.id === touId);
            if (touIndex >= 0) {
                touListCopy.splice(touIndex, 1);
                setTouList(touListCopy);
            }
        })
    }

    const ignoreClick = (evt) => {
        evt.preventDefault();
    }

    const declineHandler =  () => {
        props.onDecline();
    }

    return (
        <div className='terms-of-use' onClick={ignoreClick}>
            <div className='inner-area'>
                <div className='inner-title-area'>
                    <h1>Terms of use</h1>
                </div>
                <div className='inner-center-area'>
                    <p>In order to continue to use this system you must accept all the terms of use listed below.</p>
                    <div className='tou-list-area'>
                        <ul>
                            {touList.map(tou => 
                                <li key={tou.id}>
                                    <div>
                                        {tou.text} 
                                        <a href={tou.text} target='_blank' rel='noopener noreferrer'>
                                            <FontAwesomeIcon icon={faExternalLink} />
                                        </a>
                                    </div>
                                    <button onClick={() => acceptHandler(tou.id)}>Accept</button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='inner-button-area'>
                    <button onClick={declineHandler}>Decline</button>
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse;
import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './ChatMessages.scss';
import { useChatUsers } from '../../contexts/chatUsers';
import { useChannel } from '../../contexts/channel';

function ChatMessages(props) {
    const channel = useChannel();
    const messageArea = React.useRef(null);

    useEffect(() => {
        messageArea.current.scrollTop = messageArea.current.scrollHeight;
    }, [channel.messages])

    return (
        <div className='rightPaneContent' ref={messageArea}>
            {channel.messages.map(msg => <div key={msg.id} className='chat-list-item'><ChatBubble message={msg}/></div>)}
        </div>
    )
}

export default ChatMessages;

function ChatBubble(props) {
    const chatUsers = useChatUsers();

    const replaceURLWithHTMLLinks = (text) => {
        const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
        return text.replace(exp,"<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>"); 
    }

    const downloadFile = () => {
        window.open(props.media.mediaUrl);
    }

    const getAvatar = () => {
        const msgSender = chatUsers.getUser(props.message.sender);
        if (msgSender) {
            if (msgSender.avatar === null || msgSender.avatar.length === 0) {
                return <div className='char'>{msgSender.name !== null && msgSender.name.length > 0 ? msgSender.name.charAt(0) : '?'}</div>
            } else {
                return <img src={msgSender.avatar} />
            }
        }
        return <div className='char'>?</div>;
    }

    const getUserName = () => {
        const msgSender = chatUsers.getUser(props.message.sender);
        if (msgSender) {
            return msgSender.name;
        }
        return 'Unknown';
    }

    return (
        <div className='channelChatBubble'>
            <div className='avatar'>
                {getAvatar()}
            </div>
            <div className='chatInfo'>
                <div className='username'>{getUserName()}</div>
                <div className='time'>{moment(props.message.created).format('hh:mm a')}</div>
                <div className='messageContent' dangerouslySetInnerHTML={{__html: replaceURLWithHTMLLinks(props.message.message)}}></div>
                <div className='messageMedia' data-dojo-attach-point='messageMedia'>
                    {props.message.mediaUrl &&
                        <>
                            {props.message.contentType.startsWith('image') 
                                ? <img src={props.message.mediaUrl}/>
                                : <div className='filedownload' onClick={downloadFile}><FontAwesomeIcon icon={faFile} /><br/><div style={{fontSize:'20px'}}>Download</div></div>
                            }
                        </>
                    }
                </div>
                <div className='mediaName'>{props.message.mediaName}</div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const MemberTypesContext = React.createContext(null);

function MemberTypesProvider({children}) {
    const { runAction } = useCore();
    const [loadingMemberTypes, setLoadingMemberTypes] = useState(true);
    const [memberTypes, setMemberTypes] = useState([]);

    useEffect(() => {
        loadMemberTypes();
    }, [])

    const loadMemberTypes = () => {
        setLoadingMemberTypes(true);
        runAction('get_all_membertypes', {}, 
            (result) => {
                if (result) {
                    setMemberTypes(result.memberTypes);
                    setLoadingMemberTypes(false);
                }
            });
    }

    const saveMemberType = (data, callback) => {
        const action = data.id >= 0 ? 'update_membertype' : 'create_membertype';
        runAction(action, data, response => {
            loadMemberTypes();
            callback();
        });
    }

    const deleteMemberType = (typeId) => {
        const data = {ids: [typeId]};
        runAction('delete_membertype', data, () => {
            loadMemberTypes();
        })
    }

    const provider = {
        loadingMemberTypes,
        memberTypes,
        loadMemberTypes,
        saveMemberType,
        deleteMemberType
    }

    return <MemberTypesContext.Provider value={provider}>{children}</MemberTypesContext.Provider>
}

function useMemberTypes() {
    const context = React.useContext(MemberTypesContext);
    if (!context) {
        throw Error('useMemberTypes must be used with a MemberTypesProvider');
    }
    return context;
}

export { MemberTypesProvider, useMemberTypes }
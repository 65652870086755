import React, { createRef, useEffect, useState } from 'react';

function CsvExport({csvData, filename, onComplete}) {
    const anchorRef = createRef(null);
    const [encodedUri, setEncodedUri] = useState(null);

    useEffect(() => {
        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        setEncodedUri(encodeURI(csvContent));
    }, [])

    useEffect(() => {
        if (encodedUri) {
            anchorRef.current.click();
            onComplete();
        }
    }, [encodedUri])

    return (
        <a ref={anchorRef} style={{display:'none'}} href={encodedUri} download={filename} />
    )
}

export default CsvExport;
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { MatchingMembersProvider, useMatchingMembers } from 'contexts/matchingMembers';
import Loading from 'components/loading';


function Tag({tag, onSave, onShowMembers}) {
    const [id] = useState(tag.id || null);
    const [label, setLabel] = useState(tag.text);
    const [assignMembersMode, setAssignMembersMode] = useState(false);
    const [assignMembersLetter, setAssignMembersLetter] = useState('A');

    const save = () => {
        let data = {
            text: label
        }
        if (id !== null) {
            data.id = id;
        }
        onSave(data);
    }

    return (
        <div className='settings-panel'>
            <label>Tag label</label>
            <input type='text' style={{width:'350px'}} value={label} onChange={evt => setLabel(evt.target.value)} maxLength='64' />
            
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
            </div>
            {tag.id && 
                <>
                    <div className='spacer'/>
                    <h4>Members with this tag</h4>
                    <hr/>
                    <p>Easily assign member to this tag or view members already assigned this tag.</p>
                    {!assignMembersMode && <button onClick={() => setAssignMembersMode(true)}>Assign members</button>}
                    <button className='secondary' onClick={onShowMembers}>View members</button>
                    {assignMembersMode &&
                        <>
                            <h5>Assign members</h5>
                            <AlphaPad onChange={setAssignMembersLetter}/>
                            <MatchingMembersProvider lastinitial={assignMembersLetter}>
                                <MemberPillBox tagId={tag.id} letter={assignMembersLetter}/>
                            </MatchingMembersProvider>
                        </>
                    }
                </>
            }
        </div>
    )
}

function AlphaPad({onChange}) {
    const alphaPadRef = useRef(null);

    const selectLetter = (event) => {
        const letter = event.target.innerText;
        onChange(letter);
        // unset all highlights
        alphaPadRef.current.querySelectorAll('div').forEach(elem => elem.classList.remove('selected'));
        event.target.classList.add('selected');
    }

    return (
        <div className='alpha-pad' ref={alphaPadRef}>
            <div role='button' className='letter selected' onClick={selectLetter}>A</div>
            <div role='button' className='letter' onClick={selectLetter}>B</div>
            <div role='button' className='letter' onClick={selectLetter}>C</div>
            <div role='button' className='letter' onClick={selectLetter}>D</div>
            <div role='button' className='letter' onClick={selectLetter}>E</div>
            <div role='button' className='letter' onClick={selectLetter}>F</div>
            <div role='button' className='letter' onClick={selectLetter}>G</div>
            <div role='button' className='letter' onClick={selectLetter}>H</div>
            <div role='button' className='letter' onClick={selectLetter}>I</div>
            <div role='button' className='letter' onClick={selectLetter}>J</div>
            <div role='button' className='letter' onClick={selectLetter}>K</div>
            <div role='button' className='letter' onClick={selectLetter}>L</div>
            <div role='button' className='letter' onClick={selectLetter}>M</div>
            <div role='button' className='letter' onClick={selectLetter}>N</div>
            <div role='button' className='letter' onClick={selectLetter}>O</div>
            <div role='button' className='letter' onClick={selectLetter}>P</div>
            <div role='button' className='letter' onClick={selectLetter}>Q</div>
            <div role='button' className='letter' onClick={selectLetter}>R</div>
            <div role='button' className='letter' onClick={selectLetter}>S</div>
            <div role='button' className='letter' onClick={selectLetter}>T</div>
            <div role='button' className='letter' onClick={selectLetter}>U</div>
            <div role='button' className='letter' onClick={selectLetter}>V</div>
            <div role='button' className='letter' onClick={selectLetter}>W</div>
            <div role='button' className='letter' onClick={selectLetter}>X</div>
            <div role='button' className='letter' onClick={selectLetter}>Y</div>
            <div role='button' className='letter' onClick={selectLetter}>Z</div>
        </div>
    )
}

function MemberPillBox({tagId, letter}) {
    const { matchesLoading, matches, addTagToMember, removeTagFromMember } = useMatchingMembers();

    const clickHandler = (member, isSelected) => {
        if (isSelected) {
            removeTagFromMember(member.id, tagId);
        } else {
            addTagToMember(member.id, tagId);
        }
    }

    return (
        <div className='pill-box-outer'>
            <div className='pill-box-inner'>
                {matchesLoading ? <Loading /> :
                matches.length > 0 ? 
                    matches.map(match => {
                        const isSelected = match.tags.findIndex(mt => mt.id === tagId) > -1;
                        return <Pill pill={{label: match.fname + ' ' + match.lname}} selected={isSelected} onClick={() => clickHandler(match, isSelected)} />
                    })
                    : <div className='empty'>There are no members with the last initial {letter}</div>
                }
            </div>
        </div>
    )
}

function Pill({pill, selected, onClick}) {

    return (
        <div role='button' className={'pill' + (selected ? ' selected' : '')} onClick={onClick}>
            <div className='pill-label'>{pill.label}</div>
            {selected && <FontAwesomeIcon icon={faCheck} />}
        </div>
    )
}

export default Tag;
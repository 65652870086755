let parameterMap = [];
/**
 * build a parameter map of the url parameters
 * @returns
 */
function buildParameterMap() {
	let hrefParts = window.location.href.split("?");
	if (hrefParts.length === 2) {
		let parameterPairs = hrefParts[1].split("&");
		for (let i = 0; i < parameterPairs.length; i++) {
			let pair = parameterPairs[i].split("=");
			parameterMap.push({key: pair[0], value: pair[1]});
		}
	}
}
/**
 * get a url parameter
 */
export function getParameter(key, defaultVal) {
	let value = null;
	// look for a matching parameter
	for (let i = 0; i < parameterMap.length; i++) {
		let param = parameterMap[i];
		if (param.key === key) {
			value = param.value;
		}
	}
	
	if (value === null && typeof defaultVal !== "undefined") {
		value = defaultVal;
	}
	return value;
}
/**
 * get the subdomain
 */
export function getSubdomain() {
	let subdomain = getParameter("subdomain", "app");
	let hostname = window.location.hostname;
	if (hostname !== "localhost" && hostname.endsWith("middletwin.com")) {
		subdomain = hostname.slice(0, hostname.indexOf("."));
	} else if (hostname === 'localhost') {
		//subdomain='midwestmopars';
		subdomain='totalclub';
	}
	return subdomain;
};
/**
 * parse a jwt into json
 */
export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
};

export function formatCurrency(num) {
	return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatPhoneNumber(pn) {
	var resp = pn;
	
	if (pn !== null && typeof pn !== "undefined") {
		if (pn.length === 10) {
			var match = pn.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				resp = '(' + match[1] + ') ' + match[2] + '-' + match[3];
			}
		} else if (pn.length > 10) {
			if (pn.startsWith('+1')) {
				resp = '(' + pn.substring(2, 5) + ') ' + pn.substring(5, 8) + '-' + pn.substring(8);
			}
		}
	}
	return resp;
};

export function convertToUnixTime(time) {
    let hour = time.split(':')[0];
    let minute = time.split(':')[1];
    return 21600000 + hour * 3600000 + minute * 60000;
};

//build the parameter map
buildParameterMap();
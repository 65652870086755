import { faEdit, faEnvelope, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AmazingTable from 'components/amazingTable';
import Modal from 'components/modal';
import { useEmailTemplates } from 'contexts/emailTemplates';
import { useFlow } from 'contexts/flow';
import { TableSetupProvider } from 'contexts/tableSetup';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'style/flows.scss';

const daysInFlow = [1, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30];

function FlowDetails({flowId}) {
    const { flows, createFlowTemplate, updateFlowTemplate, deleteFlowTemplates } = useFlow();
    const { templates } = useEmailTemplates();
    const [flow, setFlow] = useState(null);
    const [name, setName] = useState('');
    const [showAddTemplateDialog, setShowAddTemplateDialog] = useState(false);
    const [templateIdToAdd, setTemplateIdToAdd] = useState(0);
    const [templateDay, setTemplateDay] = useState(0);

    const [editingFlowTemplate, setEditingFlowTemplate] = useState(null);
    const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);

    const memberFlowColumns = [
        { id: 'id', label: 'ID', display: false, datum: 'id' },
        {id: 'start', label: 'Started', display: true, 
            formatter: obj => {return moment(obj.flowStart).format('YYYY-MM-DD');}},
        {id: 'lastTemplate', label: 'Last template sent', display: true, 
            formatter: obj => {return obj.lastFlowTemplateId === 0 ? 'N/A' : templates.find(t => t.id === obj.lastFlowTemplateId).name;}},
        {id: 'member', label: 'Member', display: true, datum: 'memberId'}
    ];

    useEffect(() => {
        const thisFlow = flows.find(f => f.id === flowId);
        setFlow(thisFlow);
        setName(thisFlow.name);
    }, [flows])

    useEffect(() => {
        if (!showAddTemplateDialog) {
            setTemplateIdToAdd(0);
            setTemplateDay(0);
            setEditingFlowTemplate(null);
        }
    }, [showAddTemplateDialog])

    const saveTemplateHandler = () => {
        const data = {
            flowId: flow.id,
            templateId: templateIdToAdd,
            flowDay: templateDay
        }
        if (editingFlowTemplate === null) {
            createFlowTemplate(data, () => {
                setShowAddTemplateDialog(false);
            });
        } else {
            updateFlowTemplate({...data, id: editingFlowTemplate.id}, () => {
                setShowAddTemplateDialog(false);
            });
        }
    }

    const onEditHandler = (flowTemplate) => {
        setEditingFlowTemplate(flowTemplate);
        setTemplateIdToAdd(flowTemplate.templateId);
        setTemplateDay(flowTemplate.flowDay);
        setShowAddTemplateDialog(true);
    }

    const onDeleteHandler = (flowTemplate) => {
        setEditingFlowTemplate(flowTemplate);
        setShowDeleteTemplateModal(true);
    }

    const removeTemplate = () => {
        deleteFlowTemplates([editingFlowTemplate.id]);
    }

    return (
        <div className='contact-details'>
            <div className='contact-settings-panel'>
                <div className='contact-name'>{name}</div>
                <br />
                <label>Name</label>
                <input type='text'
                    value={name}
                    onChange={(evt) => {
                        setName(evt.target.value);
                    }}
                />
            </div>
            <div className='contact-communication-panel' style={{ width: '75%' }}>
                {flow &&
                <div className='flow-details-outer'>
                    <div className='flow-details-templates'>
                        {flow?.templates.length === 0
                            ? <div className='no-templates-message'>Click Add template to add a template to this flow</div>
                            : <div className='flow-diagram'>{
                                flow.templates.map(ft => 
                                {
                                    const template = templates.find(t => t.id === ft.templateId);
                                    return <div className='step' key={ft.id}>
                                            <div className='step-icon'><FontAwesomeIcon icon={faEnvelope}/></div>
                                            <div className='step-label'>{`Day ${ft.flowDay}`}<br/>{template.name}</div>
                                            <div className='step-actions'>
                                                <div className='step-action' role='button' onClick={() => onEditHandler(ft)}><FontAwesomeIcon icon={faEdit}/></div>
                                                <div className='step-action' role='button' onClick={() => onDeleteHandler(ft)}><FontAwesomeIcon icon={faTrash}/></div>
                                            </div>
                                        </div>
                                })
                                }</div>
                        }
                        <button onClick={() => setShowAddTemplateDialog(true)}>Add template</button>
                    </div>
                    <div className='flow-details-members'>
                        {flow.members.length === 0
                            ? <div className='no-members-message'>No members have be through this flow yet<br/>Member flow information will be here when available</div>
                            : <div>
                                <TableSetupProvider tableName='flowMembers' defaultColumns={memberFlowColumns}>
                                    <AmazingTable rows={flow.members} />
                                </TableSetupProvider>
                            </div>
                        }
                    </div>
                </div>
                }
            </div>
            <Modal 
                title='Add flow template' 
                showing={showAddTemplateDialog} 
                onHide={() => setShowAddTemplateDialog(false)}
                buttons={[
                    {   type: 'primary', 
                        handler: () => {saveTemplateHandler()},
                        disabled: templateIdToAdd === 0 || templateDay === 0,
                        label:'Save flow template'
                    }
                ]}
                >
                <p>Select the template you would like to add to this flow and the number <br/>of days (Flow day) after the flow begins to have the template sent.</p>
                <label>Email template</label>
                <select value={templateIdToAdd} onChange={evt => setTemplateIdToAdd(evt.target.value)}>
                    <option value={0}>--Select email template--</option>
                    {templates.map(t => <option key={t.id} value={t.id} disabled={flow?.templates.findIndex(ft => ft.templateId === t.id) !== -1}>{t.name}</option>)}
                </select>
                <label>Flow day</label>
                <select value={templateDay} onChange={evt => setTemplateDay(evt.target.value)}>
                    <option value={0}>--Select flow day--</option>
                    {daysInFlow.map(day => <option key={day} value={day} disabled={flow?.templates.findIndex(ft => ft.flowDay === day) !== -1}>{`Day ${day}`}</option>)}
                </select>
            </Modal>
            <Modal 
                title='Delete flow template' 
                showing={showDeleteTemplateModal} 
                onHide={() => {setShowDeleteTemplateModal(false); setEditingFlowTemplate(null);}}>
                <div>{editingFlowTemplate && `Are you sure you want to delete the flow template for day ${editingFlowTemplate.flowDay}?`}</div>
                <div className='button-container'>
                    <button onClick={() => {removeTemplate(); setShowDeleteTemplateModal(false);}}>Yes, Delete</button>
                </div>
            </Modal>
        </div>
    )
}

export default FlowDetails;
import React, { useEffect, useRef, useState } from 'react';
import SidePanel from 'components/sidePanel';
import { useTenant } from 'contexts/tenant';

function PhoneSetup(props) {
    const { tenant, updateNumber, releaseNumber } = useTenant();
    const [twilioNumber, setTwilioNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const confirmPanel = useRef(null);

    useEffect(() => {
        if (tenant) {
            if (tenant.twilioNumber && tenant.twilioNumber.endpoint) {
                setTwilioNumber(tenant.twilioNumber.endpoint);
            } else {
                setTwilioNumber('');
            }
            setPhoneNumber(tenant.phone);
        }
    }, [tenant])

    const changePhoneNumberHandler = (evt) => {
        setPhoneNumber(evt.target.value);
        props.handleSettingsChange('phoneNumber', evt.target.value);
    }

    const updateNumberHandler = () => {
        updateNumber(twilioNumber, () => {
            props.handleSettingsChange('twilioNumber', {endpoint: twilioNumber});
        });
    }
    const confirmReleaseNumber = () => {
        confirmPanel.current.show();
    }

    const releaseNumberHandler = () => {
        confirmPanel.current.hide();
        releaseNumber(() => {
            setTwilioNumber('');
            props.handleSettingsChange('twilioNumber', {endpoint: null});
        });
    }

    return (
        <div >
            <label>Twilio Number</label>
            <p>This is the number that the account will use for sending/receiving text messages. It is important that the number be in correct
            format (e.g. +16129998876). Beginning with a plus symbol, the country code, and the remaining digits for the number, no other characters.
            This number MUST already be in the twilio account and configured properly in order for this to work.</p>
            
            <input type='text' value={twilioNumber} onChange={evt => setTwilioNumber(evt.target.value)} maxLength='48' />
            
            <div style={{marginTop: '10px'}}>
                <button onClick={updateNumberHandler} disabled={twilioNumber.length !== 12}>Save Twilio Number</button>
                <button className="linkButton" onClick={confirmReleaseNumber} disabled={twilioNumber.length===0}>Release Twilio Number</button>
            </div>
            <label>Voice Number</label>
            <p></p>
            <input type='text' value={phoneNumber} onChange={changePhoneNumberHandler} maxLength='64' />
            <SidePanel title='Confirm' ref={confirmPanel}>
                <div>
                <p>You are about to release the number for this account. If you proceed:</p>
                    <ul style={{listStyle:'disc'}}>
                    <li>Incoming text messages to this number will no longer be captured.</li>
                    <li>Incoming phone calls will be rejected.</li>
                    <li>The account users will not be able to send text messages.</li>
                    <li>The account users will no longer be able to use this number for voice.</li>
                    <li>The next time a user logs into this account they will be forced to setup a new number.</li>
                    <li>The number can be re-purchased for $50 for up to 10 days after its release upon request.</li>
                    </ul>
                    <p>This is an extremely disruptive action to the account. Are you sure you want to continue?</p>
                    <button onClick={releaseNumberHandler}>Proceed</button><button onClick={() => confirmPanel.current.hide()}>Cancel</button>
                </div>
            </SidePanel>
        </div>
    )
}

export default PhoneSetup;
import React, { useEffect, useState } from 'react';

const PaginationContext = React.createContext(null);

function PaginationProvider({children, currentPage, maxPages, previousPageHandler, nextPageHandler}) {
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (currentPage) {
            setPage(currentPage);
        }
    }, [currentPage])

    const onPreviousPage = () => {
        if (isNaN(page)) {
            if (previousPageHandler) {
                previousPageHandler();
            }
        } else if (page > 1) {
            const newPage = page - 1;
            setPage(newPage);
            if (previousPageHandler) {
                previousPageHandler(newPage);
            }
        }
    }
    const onNextPage = () => {
        if (maxPages === -1 || page < maxPages) {
            const newPage = page + 1;
            setPage(newPage);
            if (nextPageHandler) {
                nextPageHandler(newPage);
            }
        }
    }

    const provider = {
        page, setPage,
        maxPages,
        onPreviousPage,
        onNextPage,
    }

    return <PaginationContext.Provider value={provider}>{children}</PaginationContext.Provider>
}

function usePagination() {
    const context = React.useContext(PaginationContext);
    if (!context) {
        throw new Error('usePagination must be used within a PaginationProvider');
    }
    return context;
}

export { PaginationProvider, usePagination }
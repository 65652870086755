import React, {useState} from 'react';
import AmazingTabs from 'components/amazingTabs';
import Income from './Income';
import Invoices from './Expenses';
import { SubscriptionProvider } from 'contexts/subscription';
import { ReceiptsProvider } from 'contexts/receipts';
import { useAuth } from 'contexts/auth';
import { StripeIntentsProvider } from 'contexts/stripeIntents';

const tabs = [
    {id: 'income', label: 'Income'},
    {id: 'expenses', label: 'Expenses'},
]

function Finance() {
    const { tenant } = useAuth();
    const [tab, setTab] = useState('income');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Finance</div>
                </div>
            </div>
                <AmazingTabs tabs={tabs}  selected={tab} onClick={tab => setTab(tab.id)} />
                {tab === 'income' && 
                    <ReceiptsProvider tenantId={tenant.id}>
                        <StripeIntentsProvider>
                            <Income />
                        </StripeIntentsProvider>
                    </ReceiptsProvider> }
                {tab === 'expenses' && <SubscriptionProvider><Invoices /></SubscriptionProvider> }
        </div>
    )
}

export default Finance;
import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { useMakesAndModels } from 'contexts/makesAndModels';
import { TableSetupProvider } from 'contexts/tableSetup';

const makeColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "label", label: "Label", display: true, datum: "label"},
];

const modelColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "label", label: "Label", display: true, datum: "label"},
];

function MakesAndModels(props) {
    const { makes, models, getMakes, getModels, saveMake, saveModel } = useMakesAndModels();
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const makePanel = useRef(null);
    const modelPanel = useRef(null);


    const createMake = () => {
        setMake(null);
        makePanel.current.show();
    }

    const editMake = (selectedMake) => {
        setMake(selectedMake);
        makePanel.current.show();
    }

    useEffect(() => {
        if (make) {
            getModels(make.id);
        }
    }, [make])

    const getModelsHandler = () => {
        if (make) {
            getModels(make.id);
        }
    }

    const createModel = () => {
        setModel(null);
        modelPanel.current.show();
    }

    const editModel = (m) => {
        setModel(m);
        modelPanel.current.show();
    }

    const saveMakeHandler = (label) => {
        let data = {eventId: props.eventId, label: label};
        if (make) {
            data.id = make.id;
        }
        saveMake(data, () => {
            setMake(null);
            makePanel.current.hide();
        });
    }

    const saveModelHandler = (label) => {
        let data = {makeId: make.id, label: label}
        if (model) {
            data.id = model.id;
        }
        saveModel(data, () => {
            setModel(null);
            modelPanel.current.hide();
        });
    }

    return (
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <div style={{width:'95%'}}>
                <label>Makes</label>
                <TableSetupProvider tableName='makes' defaultColumns={makeColumns}>
                    <AmazingTable
                        rows={makes}
                        onRefresh={getMakes}
                        onRowClick={(m) => setMake(m)}
                        onCreate={createMake}
                        onCreateLabel='Add make'
                        onEdit={editMake} />
                </TableSetupProvider>
                <SidePanel title='Make' ref={makePanel}>
                    <MakeDetails make={make} onSave={saveMakeHandler} />
                </SidePanel>
            </div>
            <div style={{width:'95%'}}>
                <label>Models (select a Make to add/edit models for the make)</label>
                <TableSetupProvider tableName='models' defaultColumns={modelColumns}>
                    <AmazingTable
                        rows={models}
                        onRefresh={getModelsHandler}
                        onRowClick={editModel}
                        onCreate={createModel}
                        onCreateLabel='Add model'
                        createDisabled={make === null} />
                </TableSetupProvider>
                <SidePanel title='Model' ref={modelPanel}>
                    <ModelDetails model={model} onSave={saveModelHandler} />
                </SidePanel>
            </div>
        </div>
    )
}

export default MakesAndModels;

function MakeDetails(props) {
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (props.make) {
            setLabel(props.make.label);
        } else {
            setLabel('');
        }
    }, [props.make])

    const save = () => {
        props.onSave(label);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Label</label>
                <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength='64' />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

function ModelDetails(props) {
    const [label, setLabel] = useState('');

    useEffect(() => {
        if (props.model) {
            setLabel(props.model.label);
        } else {
            setLabel('');
        }
    }, [props.model])

    const save = () => {
        props.onSave(label);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Label</label>
                <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength='64' />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}
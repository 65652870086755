import React, { useEffect, useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import {formatCurrency} from '_base'
import './Finance.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBallotCheck, faBuilding, faChartLine, faEnvelopeOpenDollar, faUsdCircle } from '@fortawesome/pro-regular-svg-icons';
import BankAccounts from './bankAccounts';
import { PaginationProvider } from 'contexts/pagination';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useStripeIntents } from 'contexts/stripeIntents';
import SidePanel from 'components/sidePanel';
import { StripeCustomerProvider } from 'contexts/stripeCustomer';
import PaymentDetails from './paymentDetails';
import { useAuth } from 'contexts/auth';
import { TransfersProvider } from 'contexts/transfers';
import Transfers from './transfers';

const intentsCols = [
    {id: 'id', label: 'ID', display: true, datum: 'id'},
    {id: 'descriptor', label: 'Description', display: true, datum: 'description'},
    {id: 'date', label: 'Date', display: true, formatter: obj => moment(obj.created*1000).format('YYYY-MM-DD')},
    {id: 'amount', label: 'Amount', display: true, formatter: obj => formatCurrency(obj.amount/100)},
    {id: 'status', label: 'Status', display: true, formatter: obj => <div className={`status ${obj.status}`}>{obj.status}</div>},
    {id: 'customerName', label: 'Customer name', display: true, datum: 'customerName'},
    {id: 'customerEmail', label: 'Customer email', display: true, datum: 'customerEmail'},
]

function Income() {
    const { intentsLoading, intents, getIntents, month, nextMonth, prevMonth, downloadCSV, refunds } = useStripeIntents();
    const [view, setView] = useState('list');
    const paymentDetailsPanel = useRef(null);
    const [payment, setPayment] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (intents && intents.length > 0) {
            let td = [];
            intents.forEach(intent => {
                const refund = refunds.find(r => r.payment_intent === intent.id);

                td.push({
                    id: intent.id, 
                    description: intent.description, 
                    created: intent.created, 
                    amount: intent.amount, 
                    status: refund ? 'refunded' : intent.status,
                    customerName: intent.customer.name,
                    customerEmail: intent.customer.email
                });
            })
            setTableData(td);
        }
    }, [intents, refunds]);

    const nextPageHandler = () => {
        nextMonth();
    }

    const prevPageHandler = () => {
        prevMonth();
    }

    const showPaymentDetails = (data) => {
        paymentDetailsPanel.current.show();
        const intent = intents.find(i => i.id === data.id);
        const refund = refunds.find(r => r.payment_intent === data.id);
        if (refund) {
            intent.status = 'refunded';
        }

        setPayment(intent);
    }

    const exportHandler = (filter, cols) => {
        downloadCSV();
    }

    return (
        <div className='transactions-outer'>
            <h3>Summary - {moment(month).format('MMMM YYYY')}</h3>
            <div className='snapshot-area'>
                <div className='snapshot'>
                    <div className='total'>
                        <div className='total-icon'><FontAwesomeIcon icon={faChartLine}/></div>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Sales</div>
                            <div className='total-ammount'>{formatCurrency(intents.reduce((a, b) => {return a+((b.status==='succeeded') ? b.amount : 0)}, 0)/100)}</div>
                        </div>
                    </div>
                    <div className='total'>
                        <div className='total-icon'><FontAwesomeIcon icon={faBallotCheck}/></div>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Fees</div>
                            <div className='total-ammount'>{formatCurrency(intents.reduce((a, b) => {return a+((b.status==='succeeded') ? b.application_fee_amount : 0)}, 0)/100)}</div>
                        </div>
                    </div>
                    <div className='total'>
                        <div className='total-icon'><FontAwesomeIcon icon={faUsdCircle}/></div>
                        <div className='total-label-and-ammount'>
                            <div className='total-label'>Disbursement</div>
                            <div className='total-ammount'>{formatCurrency(intents.reduce((a, b) => {return a+((b.status==='succeeded') ? (b.amount-b.application_fee_amount) : 0)}, 0)/100)}</div>
                        </div>
                    </div>
                </div>
                <div className='snapshot'>
                    <div className='views'>
                        <div className={'view-selector' + (view === 'list' ? ' selected' : '')} role='button' onClick={() => setView('list')}>
                            <div className='view-button'>
                                <FontAwesomeIcon icon={faBallotCheck} />
                            </div>
                            <div className='view-label'>
                                View transactions
                            </div>
                        </div>
                        <div className={'view-selector' + (view === 'transfers' ? ' selected' : '')} role='button' onClick={() => setView('transfers')}>
                            <div className='view-button'>
                                <FontAwesomeIcon icon={faEnvelopeOpenDollar} />
                            </div>
                            <div className='view-label'>
                                View Transfers
                            </div>
                        </div>
                        <div className={'view-selector' + (view === 'bank' ? ' selected' : '')} role='button' onClick={() => setView('bank')}>
                            <div className='view-button'>
                                <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className='view-label'>
                                View banking
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='transactions-table'>
                {view === 'list' &&
                    <>
                        <TableSetupProvider tableName='intents' defaultColumns={intentsCols}>
                            <PaginationProvider
                                currentPage={moment(month).format('MMM YYYY')}
                                maxPages={-1}
                                nextPageHandler={nextPageHandler} 
                                previousPageHandler={prevPageHandler}>
                                <AmazingTable 
                                    hasPagination={true}
                                    rows={tableData}
                                    onRefresh={getIntents}
                                    loading={intentsLoading}
                                    onRowClick={showPaymentDetails}
                                    onExport={exportHandler}
                                     />
                            </PaginationProvider>
                        </TableSetupProvider>
                        <SidePanel title='Details' ref={paymentDetailsPanel}>
                            {payment &&
                                <PaymentDetails intent={payment} onHide={() => paymentDetailsPanel.current.hide()} />
                            }
                        </SidePanel>
                    </>
                }
                {view === 'transfers' &&
                    <TransfersProvider>
                        <Transfers />
                    </TransfersProvider>
                }
                {view === 'bank' &&
                    <BankAccounts />
                }
            </div>
        </div>
    )
}

export default Income;
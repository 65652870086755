import React, { useRef, useState } from 'react';
import './EditableDiv.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, faItalic, faUnderline } from '@fortawesome/pro-regular-svg-icons';

/**
 * Details: https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
 * 
 * 
 */

function EditableDiv({value, onChange, onKeyUp}) {
    const divRef = useRef(null);
    const valueRef = useRef(value);
    const [isBold, setIsBold] = useState(false);

    const handleChange = () => {
        const html = divRef.current.innerHTML;
        onChange(html);
    }

    const handleKeyUp = (e) => {
        if (onKeyUp) {
            onKeyUp(e);
        }
    }

    const performAction = (command) => {
        document.execCommand(command, false, null);
        divRef.current.focus();
    }

    return (
        <div className='middletwin-editor'>
            <div className='editor-toolbar'>
                <button className={isBold ? 'selected' : ''} onClick={() => {performAction('bold'); setIsBold(!isBold);}}><FontAwesomeIcon icon={faBold} /></button>
                <button onClick={() => performAction('italic')}><FontAwesomeIcon icon={faItalic} /></button>
                <button onClick={() => performAction('underline')}><FontAwesomeIcon icon={faUnderline} /></button>

                <button onClick={() => performAction('justifyCenter')}><FontAwesomeIcon icon={faAlignCenter} /></button>
                <button onClick={() => performAction('justifyFull')}><FontAwesomeIcon icon={faAlignJustify} /></button>
                <button onClick={() => performAction('justifyLeft')}><FontAwesomeIcon icon={faAlignLeft} /></button>
                <button onClick={() => performAction('justifyRight')}><FontAwesomeIcon icon={faAlignRight} /></button>
            </div>
            <div className='editable-div-outer'>
                <div className='editable-div'
                    contentEditable 
                    role='textarea'
                    onInput={handleChange} 
                    onBlur={handleChange}
                    onKeyUp={handleKeyUp}
                    ref={divRef}
                    dangerouslySetInnerHTML={{__html: valueRef.current}}></div>
            </div>
        </div>
    )
}

export default EditableDiv;
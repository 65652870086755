import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from 'util/stripeForm';
import { useCore } from 'contexts/core';

function Payment(props) {
    const { runAction } = useCore();
    const [promise, setPromise] = useState(null);

    useEffect(() => {
        let data = {};
        runAction('stripe_get_payment_methods', data, response => {
            if (response.paymentmethods.length > 0) {
                props.onNext(true);
            } else {
                setPromise(loadStripe(props.stripe));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const stripeFormFinishedHandler = (paymentMethodId) => {
        attachCard(paymentMethodId);
    }

    const attachCard = (paymentMethodId) => {
        let data = {paymentMethodId: paymentMethodId};
        runAction('stripe_attach_payment_method', data, (response) => {
            props.onNext(true);
        })
    }

    return (
        <div>
            <div className='setupInstructions'>
                <p>A valid payment method is required for all accounts. Please provide a credit card to use for payments.</p>
            </div>
            <div className='setupPane' style={{top:'150px'}}>
                <div className='setupPaneTitle'>You can manage your billing and payment method in the account settings at any time.</div>
                <div style={{width:'70%',margin:'50px auto',textAlign:'center'}}>
                    {promise && 
                        <Elements stripe={promise}>
                            <StripeForm onFinished={stripeFormFinishedHandler} />
                        </Elements>
                    }
                </div>
                <div className='setupPaneFooter'>
                    <button className='linkButton' onClick={props.onBack}>Back</button>
                    <button className='linkButton' onClick={() => props.onNext(false)}>Skip</button>
                </div>
            </div>
        </div>
    )
}

export default Payment;
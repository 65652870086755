import React, { useEffect, useRef, useState } from 'react';
import SidePanel from 'components/sidePanel';
import ApiKeyDetails from './apiKeyDetails';
import AmazingTable from 'components/amazingTable';
import { useApiKeys } from 'contexts/apiKeys';
import { ApiKeyProvider } from 'contexts/apiKey';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "key", style: {width:'300px'}, label: "Key", display: true, datum: "key"}
];

function APIKeys(props) {
    const { keys, getKeys, deleteKey } = useApiKeys();
    const [key, setKey] = useState(null);

    const keyPanel = useRef(null);

    useEffect(() => {
        if (key) {
            keyPanel.current.show();
        } else {
            keyPanel.current.hide();
        }
    }, [key])

    const addKey = () => {
        setKey(null);
        keyPanel.current.show();
    }

    const editKey = (key) => {
        console.log(key);
        setKey(key);
    }

    const removeKey = (key) => {
        const keyId = key[0].key;
        deleteKey(keyId);
    }

    const keyDetailsDoneHandler = () => {
        setKey(null);
        getKeys();
    }

    return (
        <div>
            <label>API Access</label>
            <p>From here you can manage keys that allow API level access to this account for developing your own solutions to integrate with Middletwin.</p>
            <TableSetupProvider tableName='apikeys' defaultColumns={columns}>
                <AmazingTable 
                    rows={keys}
                    onRefresh={getKeys}
                    onCreate={addKey}
                    onCreateLabel='Add API key'
                    onDelete={removeKey}
                    onRowClick={editKey}
                />
            </TableSetupProvider>
            <SidePanel title='API Key' ref={keyPanel}>
                {key &&
                <ApiKeyProvider tenantId={props.tenantId} apiKey={key}>
                    <ApiKeyDetails onDone={keyDetailsDoneHandler} />
                </ApiKeyProvider>
                }
            </SidePanel>
        </div>
    )
}

export default APIKeys;
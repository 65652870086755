import React from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const PhoneContext = React.createContext(null);

function PhoneProvider({children}) {
    const { runAction } = useCore();

    const initiateCall = (toNumber) => {
        runAction('initiate_call', {toNumber}, response => {
            publish('notification', 'The call has been initiated');
        });
    }

    const provider = {
        initiateCall,
    }

    return <PhoneContext.Provider value={provider}>{children}</PhoneContext.Provider>
}

function usePhone() {
    const context = React.useContext(PhoneContext);
    if (!context) {
        throw new Error('usePhone must be used within a PhoneProvider');
    }
    return context;
}

export { PhoneProvider, usePhone }
import React, { useEffect, useState } from 'react';
import { parseJwt } from '_base';
import moment from 'moment';

const TokenContext = React.createContext(null);

const MINUTE = 60000;

function TokenProvider({children}) {
    const [tokenLoading, setTokenLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [expiring, setExpiring] = useState(false);
    const [expired, setExpired] = useState(false);
    let logoutTimer = null;

    useEffect(() => {
        if (localStorage.token) {
			//console.log('found a token in session storage');
			const _token = localStorage.token;
			if (validateToken(_token)) {
				setToken(_token);
			}
		}
        setTokenLoading(false);
    }, [])

    useEffect(() => {
        if (token) {
            const parsedToken = parseJwt(token);
            const expiresIn = moment(parsedToken.exp * 1000).diff(moment(), 'seconds');
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
            setExpiring(false);
            setExpired(false);
            //console.log('token expires at: ', moment(parsedToken.exp * 1000).format('YYYY-MM-DD HH:mm:ss'));
            //console.log(expiresIn);
            setTimeout(() => {
                setExpiring(true);
                logoutTimer = setTimeout(() => {
                    setExpired(true);
                }, MINUTE);
            }, (expiresIn * 1000) - MINUTE);
        }
    }, [token])

    const validateToken = (tok) => {
		if (tok) {
			const parsedToken = parseJwt(tok);
			if (moment(parsedToken.exp * 1000).isAfter(moment())) {
				return true;
			}
		}
		return false;
    }

    const updateToken = (newToken) => {
        if (validateToken(newToken)) {
            localStorage.token = newToken;
            setToken(newToken);
        }
    }

    const clearToken = () => {
        delete (localStorage.token);
		setToken(null);
    }

    const provider = {
        tokenLoading,
        token,
        updateToken,
        clearToken,
        expiring, setExpiring,
        expired,
    }

    return <TokenContext.Provider value={provider}>{children}</TokenContext.Provider>
}

function useToken() {
    const context = React.useContext(TokenContext);
    if (!context) {
        throw new Error('useToken must be used within a TokenProvider');
    }
    return context;
}

export { TokenProvider, useToken }
import React, { useEffect, useState } from 'react';
import ValidateEmail from './validateEmail';
import { FlowProvider } from 'contexts/flow';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { CampaignsProvider } from 'contexts/campaigns';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Flows from './flows';
import { CountriesProvider } from 'contexts/countries';
import Campaigns from './campaigns';
import Templates from './templates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faEnvelope, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import AmazingTabs from 'components/amazingTabs';

export async function loader({params}) {
    return params;
}

function Email() {
    return (
        <FlowProvider>
            <EmailTemplatesProvider>
                <CampaignsProvider>
                    <EmailApp />
                </CampaignsProvider>
            </EmailTemplatesProvider>
        </FlowProvider>
    )
}

const tabs = [
    {id: 'campaigns', label: 'Campaigns'},
    {id: 'templates', label: 'Message Templates'},
    //{id: 'flows', label: 'Message Flows'},
    {id: 'validate', label: 'Sender Address Validation'}
]

function EmailApp() {
    const navigate = useNavigate();
    const loaderData = useLoaderData();
    const [view, setView] = useState('campaigns');

    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : 'campaigns');
        }
    }, [loaderData])

    const getTitle = () => {
        return view === 'campaigns' ? 'Campaigns' : 
            view === 'templates' ? 'Message Templates' : 
            view === 'flows' ? 'Message Flows' : 'Sender Address Verification';
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Email {view && ': ' + getTitle()}</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={view} onClick={newView => navigate('/admin/email/' + newView.id)}></AmazingTabs>
            {view === 'campaigns' && (
                <Campaigns />
            )}
            {view === 'templates' && (
                <Templates />
            )}
            {view === 'flows' && (
                <Flows />
            )}
            {view === 'validate' && (
                <CountriesProvider>
                    <ValidateEmail />
                </CountriesProvider>
            )}
        </div>
    );
}

export default Email;

import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import moment from 'moment';
import { publish } from 'pubsub-js';

const StripeIntentsContext = React.createContext(null);

function StripeIntentsProvider({children}) {
    const { runAction, runCSVAction } = useCore();
    const [month, setMonth] = useState(moment().startOf('month'));
    const [intentsLoading, setIntentsLoading] = useState(true);
    const [intents, setIntents] = useState([]);
    const [refundsLoading, setRefundsLoading] = useState(true);
    const [refunds, setRefunds] = useState([]);

    useEffect(() => {
        getRefunds();
    }, [])

    useEffect(() => {
        getIntents();
    }, [month])

    const getIntents = () => {
        setIntentsLoading(true);
        const startDate = month.valueOf()/1000;
		const endDate = moment(month).endOf('month').valueOf()/1000;
        runAction('get_payment_intents', {start: startDate, end: endDate}, response => {
            const paymentIntents = response.intents;
            setIntents(paymentIntents.map((piJson) => JSON.parse(piJson)));
            setIntentsLoading(false);
        })
    }

    const getRefunds = () => {
        setRefundsLoading(true);
        runAction('stripe_get_refunds', {}, response => {
            setRefunds(response.refunds.map(refundJson => JSON.parse(refundJson)));
            setRefundsLoading(false);
        })
    }

    const nextMonth = () => {
        setMonth(moment(month).add(1, 'months'));
    }

    const prevMonth = () => {
        setMonth(moment(month).subtract(1, 'months'));
    }

    const downloadCSV = () => {
        const startDate = month.valueOf()/1000;
		const endDate = moment(month).endOf('month').valueOf()/1000;
        runCSVAction('get_payment_intents', {start: startDate, end: endDate, format:'csv'}, response => {
            const blob = new Blob([response], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            window.location.assign(url);
        });
    }

    const refund = (data, callback) => {
        runAction('stripe_create_refund', data, () => {
            publish('success', 'A refund request has been made. The request can take 5-10 days to be processed.');
            getRefunds();
            callback();
        })
    }

    const provider = {
        intentsLoading,
        intents,
        getIntents,
        month,
        nextMonth,
        prevMonth,
        downloadCSV,
        refund,
        refundsLoading, refunds
    }

    return (
        <StripeIntentsContext.Provider value={provider}>{children}</StripeIntentsContext.Provider>
    )
}

function useStripeIntents() {
    const context = React.useContext(StripeIntentsContext);
    if (!context) {
        throw new Error('useStripeIntents must be used within a StripeIntentsProvider');
    }
    return context;
}

export { StripeIntentsProvider, useStripeIntents }
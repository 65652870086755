import React, { useState } from 'react';
import logo from 'assets/logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSignOut, faArrowRight, faCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import Portal from './steps/portal';
import Payment from './steps/payment';
import Payouts from './steps/payouts';
import Finished from './steps/finished';
import Notifications from 'components/notifications';
import './setup.scss';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';

let steps = [
    {label: 'About', title: 'About Your Group', complete: false},
    {label: 'Portal', title: 'Portal Configuration', complete: false},
    {label: 'Payment', title: 'Account Payment', complete: false},
    {label: 'Payouts', title: 'Purchase Payouts', complete: false},
    {label: 'Finished', title: 'Finished', complete: false}
];

function Setup() {
    const { stripe } = useAuth();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const onBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    }
    const onNext = (complete) => {
        if (complete) {
            steps[step-1].complete = true;
        }
        if (step < steps.length) {
            setStep(step + 1);
        } else {
            // go to container
            //navigate('/container');
            logout();
        }
    }
    const support = () => {
        window.open("https://www.middletwin.com/knowledge-base/");
    }
    const logout = () => {
        navigate('/logout');
    }
    
    return (
        <div className='setup'>
            <div className='outerContainer'>
                <div className='leftContainer'>
                    <div className='logoContainer'>
                        <img src={logo} alt='Middletwin logo' />
                    </div>
                    <div className='listContainer'>
                        <ul>
                            <li><div className='setupStep'><FontAwesomeIcon icon={step === 1 ? faArrowRight : (steps[0].complete ? faCheckCircle : faCircle)} /> Portal</div></li>
                            <li><div className='setupStep'><FontAwesomeIcon icon={step === 2 ? faArrowRight : (steps[1].complete ? faCheckCircle : faCircle)} /> Payment</div></li>
                            <li><div className='setupStep'><FontAwesomeIcon icon={step === 3 ? faArrowRight : (steps[2].complete ? faCheckCircle : faCircle)} /> Payouts</div></li>
                            <li><div className='setupStep'><FontAwesomeIcon icon={step === 4 ? faArrowRight : (steps[3].complete ? faCheckCircle : faCircle)} /> Finished</div></li>
                        </ul>
                    </div>
                    <div className='actionsContainer'>
                        <ul>
                            <li onClick={support}>
                                <div className='actionItem'><FontAwesomeIcon icon={faQuestionCircle} /> Support</div>
                            </li>
                            <li onClick={logout}>
                                <div className='actionItem'><FontAwesomeIcon icon={faSignOut} /> Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='rightContainer'>
                    <div className='mainTitle'>Initial Account Setup</div>
                    <div className='setupItemTitle'>{steps[step-1].title}</div>
                    <div className='setupItemContainer'>
                        
                        {
                            step === 1 ? <Portal onBack={onBack} onNext={onNext} /> :
                            step === 2 ? <Payment onBack={onBack} onNext={onNext} stripe={stripe} /> :
                            step === 3 ? <Payouts onBack={onBack} onNext={onNext} /> :
                            step === 4 ? <Finished onBack={onBack} onNext={onNext} /> :
                            <div>Something went wrong...</div>
                        }
                        
                    </div>
                </div>
            </div>
            <Notifications />
        </div>
    )
}

export default Setup;
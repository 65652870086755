import React, { useEffect, useState } from 'react';

const TableSetupContext = React.createContext(null);

function TableSetupProvider({tableName, defaultColumns, children}) {
    const [columns, setColumns] = useState(defaultColumns);

    useEffect(() => {
        let storedColumns = localStorage.getItem(tableName + '_columns');
        if (storedColumns) {
            const columnJson = (JSON.parse(storedColumns));
            const newColumns = columns.map(c => {
                const storedColumn = columnJson.find(stored => stored.id === c.id);
                if (storedColumn) {
                    c.display = storedColumn.display;
                }
                return c;
            });
            setColumns(newColumns);
        }
    }, [])

    const updateColumns = (newColumns) => {
        localStorage.setItem(tableName + '_columns', JSON.stringify(newColumns));
        setColumns(newColumns);
    }

    const provider = {
        columns,
        updateColumns,
    }

    return <TableSetupContext.Provider value={provider}>{children}</TableSetupContext.Provider>
}

function useTableSetup() {
    const context = React.useContext(TableSetupContext);
    if (!context) {
        throw new Error('useTableSetup must be used within a TableSetupProvider');
    }
    return context;
}

export { TableSetupProvider, useTableSetup }
import React, { useEffect, useState } from 'react';
import RadioItem from './radioItem';
import './radioGroup.scss';

function RadioGroup(props) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (props.options) {
            setOptions(props.options);
        }
    }, [props.options])

    const selectHandler = (opt) => {
        props.onChange(opt.value);
    }

    return (
        <div className='radioGroup'>
            <ul>
                {options.map((opt, idx) => <RadioItem key={idx} option={opt} selected={(props.value === opt.value)} onClick={selectHandler} />)}
            </ul>
        </div>
    )
}

export default RadioGroup;
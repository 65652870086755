import React, { useEffect } from 'react';
import { useCore } from 'contexts/core';

function Payouts(props) {
    const { runAction } = useCore();

    useEffect(() => {
        runAction('stripe_get_account', {}, response => {
            try {
                let acct = JSON.parse(response.accountJson);
                if (acct && acct.external_accounts && acct.external_accounts.data && acct.external_accounts.data.length > 0) {
                    props.onNext(true);
                }
            } catch (err) {
                console.error(err);
            }
        })
    }, [props])

    const linkBankAccount = () => {
        runAction('stripe_link_account', {}, response => {
            window.open(response.accountLinkUrl);
        });
    }

    return (
        <div>
            <div className='setupInstructions'>
                <p>Receive payouts for purchases.</p>
            </div>
            <div className='setupPane' style={{top:'150px'}}>
                <div className='setupPaneTitle'>Link your bank account.</div>
                <div style={{width:'70%',margin:'50px auto',textAlign:'center'}}>
                    <p>We use Stripe for all payout processing. We do not store any banking or payment method information in our system.
                        The way this works is by you linking your groups bank account with Stripe. When payments are processed they are
                        set to be deposited directly into your groups bank account. You will not be able to 
                        accept payments until this is set up.
                    </p>
                    <p>Click the Link Bank Account button to begin or click skip to set this up on the settings panel later.</p>
                    <button onClick={linkBankAccount}>Link Bank Account</button>
                </div>
                <div className='setupPaneFooter'>
                    <button className='linkButton' onClick={props.onBack}>Back</button>
                    <button className='linkButton' onClick={() => props.onNext(false)}>Skip</button>
                </div>
            </div>
        </div>
    )
}

export default Payouts;
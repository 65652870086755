import React from 'react';

const colors = [
    {color: '#000000', label: 'Black'},
    {color: '#0058FF', label: 'Blue'},
    {color: '#550202', label: 'Brown'},
    {color: '#C5C5C5', label: 'Gray'},
    {color: '#21AA3E', label: 'Green'},
    {color: '#FF9708', label: 'Orange'},
    {color: '#FCCDD1', label: 'Pink'},
    {color: '#9D00FF', label: 'Purple'},
    {color: '#FF002E', label: 'Red'},
    {color: '#FFFFFF', label: 'White'},
    {color: '#FFF500', label: 'Yellow'},
];

function ColorChooser({value, onChange}) {
    return (
        <div className='color-chooser'>
            <div className='color-list'>
                {colors.map((col, idx) => 
                    <div key={idx} className={'color' + (value === col.color ? ' selected' : '')} onClick={() => onChange(col.color)}>
                        <div className='swatch' style={{backgroundColor:col.color}}></div>
                        {col.label}
                    </div>
                )}
                
            </div>
        </div>
    )
}

export default ColorChooser;
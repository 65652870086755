import React, {useState} from 'react';
import WaitButton from 'components/waitButton';
import Core from 'util/core';
import PubSub from 'pubsub-js';

function PasswordReset(props) {
    const [resetting, setResetting] = useState(false);

    const resetPassword = () => {
        setResetting(true);
        let data = {'username': props.username};
        Core.runNoAuthAction('reset_password', data, (response, error) => {
            setResetting(false);
            if (response) {
                PubSub.publish('success', 'An email has been sent with your new password.');
                props.onBack();
            } else {
                PubSub.publish('warning', 'We are unable to change your password at this time:' + error);
            }
        });
    }

    return (
        <div>
            <p>Click the password reset button below to have a temporary generated password created and sent 
                to {props.username}. Make sure to check your spam or junk folder. This generated password will
                replace your current password. 
            </p>
            <WaitButton onClick={resetPassword} label='Reset Password Now' waitMode={resetting} />
            {!resetting &&
                <button onClick={props.onBack}>Go Back</button>
            }
        </div>
    )
}

export default PasswordReset;
import React, { useEffect, useRef, useState } from 'react';
import Core from '../../util/core';
import SidePanel from 'components/sidePanel';
import AmazingTable from '../../components/amazingTable';
import moment from 'moment';
import Toggle from '../../components/toggle';
import { useAuth } from '../../contexts/auth';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'created', label: 'Created', display: true, formatter: obj => {return moment(new Date(obj.created)).format('ddd, MMM Do, YYYY')}},
    {id: 'active', label: 'Active', display: true, formatter: obj => {return obj.active ? 'Active' : 'Inactive'}},
    {id: 'text', lable: 'Link', display: true, builder: obj => {return <a href={obj.text} target='_blank' rel='noopener noreferrer'>{obj.text}</a>}}
];

function TermsOfUse() {
    const auth = useAuth();
    const settingsPanel = useRef(null);
    const [tous, setTous] = useState([]);
    const [tou, setTou] = useState(null);

    useEffect(() => {
        getTous();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTous = () => {
        Core.runAction('get_tou', {affiliate: auth.group.id}, response => {
            setTous(response.tous);
        });
    }

    const addTou = () => {
        setTou({active: true, text: ''});
        settingsPanel.current.show();
    }

    const editTou = (row) => {
        setTou(row);
        settingsPanel.current.show();
    }

    const save = () => {
        let data = {...tou};
        let action = tou.id ? 'update_tou' : 'create_tou';
        Core.runAction(action, data, () => {
            setTou(null);
            settingsPanel.current.hide();
            getTous();
        });
    }

    return (
        <div className='settingsSection'>
            <div style={{textAlign:'center'}}><h3>Terms of Use</h3></div>
            <p>Manage your own group terms of use that accounts in your group must accept.</p>
            <TableSetupProvider tableName='termsofuse' defaultColumns={columns}>
                <AmazingTable 
                    rows={tous}
                    onRefresh={getTous}
                    onCreate={addTou}
                    onRowClick={editTou}
                />
            </TableSetupProvider>
            <SidePanel title='Settings' ref={settingsPanel}>
                {tou && 
                    <div>
                        <label>Active</label>
                        <div style={{display: 'flex'}}>Inactive <Toggle checked={tou.active} onChange={val => setTou({...tou, active:val})}/> Active</div>
                        <label>Terms of Use Link</label>
                        <div><input type='text' style={{width:'100%'}} value={tou.text} onChange={e => setTou({...tou, text:e.target.value})}
                            placeholder='(1024 characters max)' maxLength='1024' /></div>
                    </div>
                }
                <div>
                    <button onClick={save}>Save</button>
                </div>
            </SidePanel>
        </div>
    )
}

export default TermsOfUse;
import React, { useEffect, useState } from 'react';
import RadioGroup from './radioGroup';
import CharacterCounter from '../messages/characterCounter';
import Toggle from '../../../components/toggle';
import Phone from './phone';

const opts = [
	{value: 'incomingSms', label: 'Incoming text'},
	{value: 'incomingSmsNumber', label: 'Incoming text from an unknown number'},
    {value: 'incomingVoice', label: 'Incoming call'},
	{value: 'incomingVoiceAny', label: 'Incoming call from an unknown number'}
];

const smsResponseOpts = [
    {value: 'replyWith', label: 'Reply with message below'},
    {value: 'email', label: 'Send email (put email address below)'}
];

const voiceResponseOpts = [
    {value: 'forwardTo', label: 'Forward call'},
    {value: 'sayMessage', label: 'Say message below'}
];

function Workflow(props) {
    const [request, setRequest] = useState('incomingSms');
    const [requestValue, setRequestValue] = useState('');
    const [requestPhoneValue, setRequestPhoneValue] = useState({countryCode: '', phone: ''});
    const [response, setResponse] = useState('replyWith');
    const [responseValue, setResponseValue] = useState('Thank you for your message.');
    const [responsePhoneValue, setResponsePhoneValue] = useState({countryCode: '', phone: ''});
    const [active, setActive] = useState(true);

    useEffect(() => {
        if (props.workflow) {
            setRequest(props.workflow.request);
            if (props.workflow.request === 'incomingVoice') {
                setRequestPhoneValue(JSON.parse(props.workflow.requestValue));
            } else {
                setRequestValue(props.workflow.requestValue);
            }
            setResponse(props.workflow.response);
            if (props.workflow.response === 'forwardTo') {
                setResponsePhoneValue(JSON.parse(props.workflow.responseValue));
            } else {
                setResponseValue(props.workflow.responseValue);
            }
            setActive(props.workflow.active);
        }
    }, [props.workflow])

    const requestChangeHandler = (val) => {
        if (request.indexOf('Sms') > -1 && val.indexOf('Sms') === -1) {
            setResponse('forwardTo');
        } else if (request.indexOf('Sms') === -1 && val.indexOf('Sms') > -1) {
            setResponse('replyWith');
        }
        setRequest(val);

    }

    const saveHandler = () => {
        let data = {request, requestValue, response, responseValue, active};
        if (data.request === 'incomingVoice') {
            data.requestValue = JSON.stringify(requestPhoneValue);
        }
        if (data.response === 'forwardTo') {
            data.responseValue = JSON.stringify(responsePhoneValue);
        }
        props.onSave(data);
    }

    return (
        <div>
            <label>Incoming Type</label>
            <RadioGroup options={opts} value={request} onChange={requestChangeHandler} />
            <label>Incoming Value</label>
            <div className='composeMessageSection'>
                {request.indexOf('Sms') > -1 ?
                <div>
                    <input type='text' value={requestValue} onChange={e => setRequestValue(e.target.value)}
                            placeholder='Keyword... (Leave blank for all)' maxLength='128' />
                </div>
                :
                <div>
                    <Phone value={requestPhoneValue} onChange={val => setRequestPhoneValue(val)}/>
                </div>
                }
            </div>
            <label>Response Type</label>
            <RadioGroup options={request.indexOf('Sms') > -1 ? smsResponseOpts : voiceResponseOpts} value={response} onChange={val => setResponse(val)} />
            <label>Response Value</label>
            <div className='composeMessageSection'>
                {response === 'forwardTo' ?
                <div>
                    <Phone value={responsePhoneValue} onChange={val => setResponsePhoneValue(val)} />
                </div>
                :
                <div className='textAreaContainer'>
                    <textarea placeholder={response === 'email' ? 'Email address...':'Type message...'} maxLength='1600'
                        value={responseValue} onChange={e => setResponseValue(e.target.value)}></textarea>
                    <CharacterCounter text={responseValue} />
                </div>
                }
            </div>
            <label>Active</label>
            <Toggle checked={active} onChange={val => setActive(val)} />
            <button onClick={saveHandler}>Save</button>
        </div>
    )
}

export default Workflow;
import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import 'style/mailingLabels.scss';
import { useCore } from 'contexts/core';

export async function loader({params}) {
    return params;
}

function MailingLabels() {
    const { runAction } = useCore();
    const loaderData = useLoaderData();
    const [members, setMembers] = useState(null);
    const [participants, setParticipants] = useState(null);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        if (loaderData.type === 'participant') {
            let data = {'tenantUUID': loaderData.tenant, 'event': loaderData.event};
            runAction('get_all_participants', data, 
                (result) => {
                    if (result) {
                        setParticipants(result.participants);
                    }
                });
        } else {
            let data = {'tenantUUID': loaderData.tenant, 'type': loaderData.type, 'format': 'json'};
            runAction('get_all_members', data, 
                (result) => {
                    if (result) {
                        setMembers(result.members);
                    }
                });
        }
    }
    
    const renderMemberPages = () => {
        let pages = [];
        let start = 0, end = 30;
        while (start < members.length) {
            let pageMembers = members.slice(start, end);
            let labels = pageMembers.map((m , idx) => 
                    <div key={idx} className='mailing-label'>
                        <div className='content'>
                            Member#:{m.id}<br/>
                            {m.name}<br/>
                            {m.address}<br/>
                            {m.city + ', ' + m.state + ' ' + m.zip}
                        </div>
                    </div>
                );
            pages.push(<div className='mailing-labels-inner'>{labels}</div>);    
            start += 30;
            end += 30;
        }
        return pages;
    }

    const renderParticipantPages = () => {
        let pages = [];
        let start = 0, end = 30;
        while (start < participants.length) {
            let pageMembers = participants.slice(start, end);
            let labels = pageMembers.map((p , idx) => 
                    <div key={idx} className='mailing-label'>
                        <div className='content'>
                            {p.name}<br/>
                            {p.address}<br/>
                            {p.address2 !== '' ? p.address2 : null}
                            {p.city + ', ' + p.state + ' ' + p.zip}
                        </div>
                    </div>
                );
            pages.push(<div className='mailing-labels-inner'>{labels}</div>)
            start += 30;
            end += 30;
        }
        return pages;
    }

    return(
        <div className='mailing-labels-page'>
            <div className='mailing-labels-outer'>
                {members ? renderMemberPages() : participants ? renderParticipantPages() : null}
            </div>
        </div>
    )
}

export default MailingLabels;
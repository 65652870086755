import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const MatchingMembersContext = React.createContext(null);

function MatchingMembersProvider({lastinitial, children}) {
    const { runAction } = useCore();
    const [matchesLoading, setMatchesLoading] = useState(true);
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        getMatches();
    }, [lastinitial])

    const getMatches = () => {
        setMatchesLoading(true);
        runAction('get_matching_members', {lastinitial}, response => {
            setMatches(response.members);
            setMatchesLoading(false);
        })
    }

    const addTagToMember = (memberId, tagId) => {
        runAction('create_tag_member', {member: memberId, tag: tagId}, () => {
            getMatches();
        });
    }

    const removeTagFromMember = (memberId, tagId) => {
        runAction('delete_tag_member', {member: memberId, tag: tagId}, () => {
            getMatches();
        });
    }

    const provider = {
        matchesLoading,
        matches,
        addTagToMember,
        removeTagFromMember,
    }

    return <MatchingMembersContext.Provider value={provider}>{children}</MatchingMembersContext.Provider>
}

function useMatchingMembers() {
    const context = React.useContext(MatchingMembersContext);
    if (!context) {
        throw new Error('useMatchingMembers must be used within a MatchingMembersProvider');
    }
    return context;
}

export { MatchingMembersProvider, useMatchingMembers }

import React, { useState } from 'react';
import Patterns from './Patterns';
import Flagged from './Flagged';
import FlaggedLog from './FlaggedLog';
import AmazingTabs from 'components/amazingTabs';

const tabs = [{id: 'patterns', label:'Patterns'}, {id: 'flagged', label: 'Flagged'},{id: 'log', label: 'Log'}];

function Monitoring() {
    const [tab, setTab] = useState('patterns');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Monitoring</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={tab} onClick={val => setTab(val.id)}/>
            {tab === 'patterns' && <Patterns />}
            {tab === 'flagged' && <Flagged />}
            {tab === 'log' && <FlaggedLog />}
        </div>
    )
}

export default Monitoring;
import React, {useState, useRef} from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment'
import {formatCurrency} from '_base';
import SidePanel from 'components/sidePanel';
import EventTicket from './eventTicket';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { useEventTickets } from 'contexts/eventTickets';
import { TableSetupProvider } from 'contexts/tableSetup';

const eventTicketColumns = [
    {id: "id", label: "ID", display: false, datum: "id"},
    {id: "description", label: "Description", display: true, datum: "description"},
    {id: "startdate", label: "Start", display: true, style: {width:'100px'}, formatter: function(obj) {return moment(obj.startdate).format("YYYY-MM-DD");}},
    {id: "enddate", label: "End", display: true, style: {width:'100px'}, formatter: function(obj) {return moment(obj.enddate).format("YYYY-MM-DD");}},
    {id: "quantity", label: "Quantity", display: true, style: {width:'100px'}, datum: "quantity"},
    {id: "price", label: "Price", display: true, style: {width:'100px'}, formatter: function(obj) {return formatCurrency(obj.price);}}
];

function TicketManager() {
    const { eventTickets, getEventTickets, saveEventTicket, deleteEventTickets } = useEventTickets();
    const [eventTicket, setEventTicket] = useState(null);
    const [showEventTicketPanel, setShowEventTicketPanel] = useState(false);
    const eventTicketPanel = useRef(null);

    //
    // event ticket
    //
    const selectEventTicket = (row) => {
        setEventTicket(row);
        eventTicketPanel.current.show();
        setShowEventTicketPanel(true);
    }
    const handleCreateEventTicket = () => {
        setEventTicket(null);
        eventTicketPanel.current.show();
        setShowEventTicketPanel(true);
    }
    const handleSaveEventTicket = (et) => {
        eventTicketPanel.current.hide();
        let data = {...et};
        if (eventTicket) {
            data.id = eventTicket.id;
        }
        saveEventTicket(data);
    }
    const handleDeleteEventTicket = (etix) => {
        const ids = etix.map(e => e.id);
        deleteEventTickets(ids);
    }
    const handleHideEventTicket = () => {
        setEventTicket(null);
        setShowEventTicketPanel(false);
    }

    return(
        <div className='communication-area'>
            <label>Tickets</label>
            <TableSetupProvider tableName='eventticket' defaultColumns={eventTicketColumns}>
                <AmazingTable 
                    rows={eventTickets}
                    onRefresh={getEventTickets}
                    onRowClick={selectEventTicket}
                    onCreate={handleCreateEventTicket}
                    onCreateLabel='Add ticket'
                    onDelete={handleDeleteEventTicket} />
            </TableSetupProvider>
            <SidePanel title='Event Ticket' ref={eventTicketPanel} onHide={handleHideEventTicket}>
                {showEventTicketPanel &&
                    <EmailTemplatesProvider>
                        <EventTicket 
                            eventTicket={eventTicket} 
                            onSave={handleSaveEventTicket} />
                    </EmailTemplatesProvider>
                }
            </SidePanel>
        </div>
    )
}

export default TicketManager;
import React, { useEffect, useState } from 'react';
import Loading from 'components/loading';
import { useLoaderData } from 'react-router-dom';
import { ClassesProvider } from 'contexts/classes';
import DefaultDashCard from './defaultDashCard';
import T134DashCard from './t134dashCard';
import T166DashCard from './t166dashCard';
import { useCore } from 'contexts/core';

export async function loader({params}) {
    return params;
}

function DashCard({}) {
    const loaderData = useLoaderData();
    const { runNoAuthAction } = useCore();
    const [loading, setLoading] = useState(true);
    const [entryData, setEntryData] = useState({});
    const [dashCard, setDashCard] = useState(0);

    useEffect(() => {
        setDashCard(loaderData.dashCardId);
        let data = {tenantUUID: loaderData.tenant, event: loaderData.event, car_number: loaderData.car};
        runNoAuthAction('get_entry', data, (response) => {
            setEntryData(response);
            setLoading(false);
        })
    }, []);

    return (
        <div>
            {loading 
                ? <Loading /> 
                : (dashCard == 0 ? 
                    <DefaultDashCard tenantId={loaderData.tenant} eventId={loaderData.event} entryData={entryData} />
                    : dashCard == 9999 ? 
                        <ClassesProvider eventId={loaderData.event}>
                            <T134DashCard entryData={entryData}/>
                        </ClassesProvider>
                    : dashCard == 9998 ?
                        <ClassesProvider eventId={loaderData.event}>
                            <T166DashCard entryData={entryData}/>
                        </ClassesProvider>
                    : null
                )
            }
        </div>
    )
}

export default DashCard;

import React, { useEffect, useState } from 'react';
import { useSchedules } from 'contexts/schedules';
import ColorChooser from 'components/colorChooser';

function ScheduleDetails({schedule, eventId, onSave}) {
    const { saveSchedule } = useSchedules();
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const [instructions, setInstructions] = useState('');
    const [shareType, setShareType] = useState(1);
    const [notificationEmail, setNotificationEmail] = useState('');

    useEffect(() => {
        if (schedule) {
            setName(schedule.name);
            setColor(schedule.color);
            setInstructions(schedule.instructions);
            setShareType(schedule.shareType);
            setNotificationEmail(schedule.notificationEmail);
        }
    }, [schedule])

    const save = () => {
        let data = {
            eventId: eventId, 
            name, 
            color, 
            instructions,
            shareType,
            notificationEmail,
        }
        if (schedule) {
            data.id = schedule.id;
        }
        saveSchedule(data, () => {
            setName('');
            setColor('');
            setInstructions('');
            setShareType(1);
            setNotificationEmail('');
            onSave();
        });
    }

    return (
        <div className='contact-preview'>
            <label>Name</label>
            <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength='64' />
            <label>Color</label>
            <ColorChooser value={color} onChange={col => setColor(col)} />
            <label>Instructions</label>
            <textarea style={{width:'340px',height:'100px'}} value={instructions} onChange={e => setInstructions(e.target.value)} />
            <label>Sharing (who can sign up for shifts)</label>
            <select value={shareType} onChange={(evt) => setShareType(evt.target.value)}>
                <option value='1'>Private</option>
                <option value='2'>Members only</option>
                <option value='3'>Public</option>
            </select>
            <label>Signup notification email</label>
            <input type='text' value={notificationEmail} onChange={(evt) => setNotificationEmail(evt.target.value)} maxLength={64}/>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default ScheduleDetails;
import React, {useState} from 'react';

function NewCategory(props) {
    const [name, setName] = useState('');

    const save = () => {
        let data = {
            name: name
        }
        props.onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
				<label>Name</label>
				<input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default NewCategory
import React, {useState, useRef, useEffect} from 'react';
import AmazingTable from 'components/amazingTable';
import AmazingTabs from 'components/amazingTabs';
import moment from 'moment';
import NewAccount from './newAccount';
import AccountDetails from './accountDetails';
import DetailsPanel from 'components/detailsPanel';
import SidePanel from 'components/sidePanel';
import NewGroup from './newGroup';
import GroupDetails from './groupDetails';
import DataImport from 'components/dataImport';

import { useAuth } from 'contexts/auth';
import { PaginationProvider } from 'contexts/pagination';
import { TenantProvider } from 'contexts/tenant';
import { CountriesProvider } from 'contexts/countries';
import { TimezonesProvider } from 'contexts/timezones';
import { useTenants } from 'contexts/tenants';
import { useAffiliates } from 'contexts/affiliates';
import { TableSetupProvider } from 'contexts/tableSetup';

const importFields = [
    {id: 'name', label: 'Company Name', defaults: ['all'], hint: '64 max'},
    {id: 'address1', label: 'Address 1', defaults: ['all'], hint: '128 max'},
    {id: 'address2', label: 'Address 2', defaults: ['all'], hint: '128 max'},
    {id: 'city', label: 'City', defaults: ['all'], hint: '64 max'},
    {id: 'state', label: 'State', defaults: ['all'], hint: '32 max'},
    {id: 'zip', label: 'Zip Code', defaults: ['all'], hint: '16 max'},
    {id: 'isoCountry', label: 'Country', defaults: ['all'], hint: '2 max'},
    {id: 'timezone', label: 'Timezone', defaults: ['all'], hint: '64'},
    {id: 'contact', label: 'Contact', defaults: ['all'], hint: '64 max'},
    {id: 'phone', label: 'Phone', defaults: ['all'], hint: '24 max'},
    {id: 'email', label: 'Email', defaults: ['all'], hint: '128 max'},
    {id: 'middletwinNumber', label: 'Middletwin Number', defaults: ['all']},
    {id: 'uniqueId', label: 'Unique Id', defaults:['all']}
];

function statusFormatter(obj) {
    // status
    var status = 'Unknown';
    switch(obj.status) {
    case 1: status = 'Active'; break;
    case 2: status = 'Inactive'; break;
    case 3: status = 'Review'; break;
    case 4: status = 'Trial'; break;
    case 5: status = 'Default'; break;
    case 6: status = 'Initial'; break;
    case 7: status = 'Initial (No Email)'; break;
    case 8: status = 'Building'; break;
    case 9: status = 'Delete'; break;
    case 90: status = 'Comp'; break;
    default: break;
    }
    return status;
}

const accountColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'name', label: 'Name', display: true, datum: 'name'},
    {id: 'created', label: 'Created', display: true, formatter: (obj) => {return moment(obj.created).format('YYYY-MM-DD');}},
    {id: 'status', label: 'Status', display: true, formatter: statusFormatter},
    {id: 'contact', label: 'Contact', display: true, datum: 'contact'},
    {id: 'email', label: 'Email', display: true, datum: 'email'},
    {id: 'subdomain', label: 'Subdomain', display: true, datum: 'subdomain'},
    {id: 'affiliateName', label: 'Group', display: true, datum: 'affiliateName'}
];
const accountFilters = [
    {id: 'filter_none', label: 'All', state: null, isDefault: true},
    {id: 'filter_active', label: 'Active', state: 1},
    {id: 'filter_inactive', label: 'Inactive', state: 2},
    {id: 'filter_review', label: 'Review', state: 3},
    {id: 'filter_trial', label: 'Trial', state: 4},
    {id: 'filter_default', label: 'Default', state: 5},
    {id: 'filter_comp', label: 'Comp', state: 90}
];
const groupColumns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'name', label: 'Name', display: true, datum: 'name'},
    {id: 'created', label: 'Created', display: true, formatter: (obj) => {return moment(obj.created).format('YYYY-MM-DD');}},
    {id: 'admin', label: 'Admin', display: true, datum: 'admin'},
    {id: 'adminEmail', label: 'Admin Email', display: true, datum: 'adminEmail'}
];
const tabs = [
    {id: 'accounts', label:'Accounts'}, {id: 'groups', label: 'Groups'}
]

function Accounts() {
    const { group } = useAuth();
    const { tenantsLoading, tenants, getTenants, createTenant, deleteTenant, importTenants, limit, offset, available } = useTenants();
    const { affiliates, getAffiliates, createAffiliate, deleteAffiliate } = useAffiliates();
    const [tab, setTab] = useState('accounts');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    
    const newAccountPanel = useRef(null);
    const newGroupPanel = useRef(null);
    const importPanel = useRef(null);

    const onPrevPage = () => {
        if (offset !== 0) {
            getTenants({offset: offset - limit});
        }
    }
    const onNextPage = () => {
        if (offset + limit) {
            getTenants({offset: offset + limit});
        }
    }
    
    const tabHandler = (tab) => {
        setTab(tab.id);
    }

    const deleteAccountHandler = (accounts) => {
        // note, this gets an array of accounts, only work with the first one
        // because the handler can only delete one account
        if (window.confirm('You are about to permanently delete the account ' + accounts[0].name + ' and all of its data, are you sure you want to continue? This cannot be undone.')) {
            deleteTenant(accounts[0].id);
        }
    }

    const deleteGroupHandler = (groups) => {
        // note, this gets an array of groups, only work with the first one
        // because the handler can only delete one group
        if (window.confirm('You are about to permanently delete the group ' + groups[0].name + ' and all of its accounts and data, are you sure you want to continue? This cannot be undone.')) {
            deleteAffiliate(groups[0].id);
        }
    }
    const selectAccount = (account) => {
        setSelectedAccount(account);
    }
    const selectGroup = (group) => {
        setSelectedGroup(group);
    }
    const accountDetailsHideHandler = () => {
        setSelectedAccount(null);
    }
    const accountDetailsFinishedHandler = () => {
        getTenants();
        setSelectedAccount(null);
    }
    const groupDetailsHideContainer = () => {
        setSelectedGroup(null);
    }
    const groupDetailsFinishedHandler = () => {
        setSelectedGroup(null);
        getAffiliates();
    }
    const saveNewAccount = (data) => {
        createTenant(data, () => {
            newAccountPanel.current.hide();
        });
    }
    const saveNewGroup = (data) => {
        createAffiliate(data, () => {
            newGroupPanel.current.hide();
        });
    }
    const handleImport = (map, objects) => {
        importTenants(map, objects, () => {
            importPanel.current.hide();
        });
    }
    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Accounts</div>
                </div>
            </div>
            <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>
                {`${available} Remaining`}
            </div>
            {!group && 
            <AmazingTabs tabs={tabs} selected={tab} onClick={tabHandler} /> }
            {tab === 'accounts' &&
                <TableSetupProvider tableName='accounts' defaultColumns={accountColumns}>
                    <PaginationProvider 
                        maxPages={100} 
                        previousPageHandler={onPrevPage} 
                        nextPageHandler={onNextPage}>
                        <AmazingTable 
                            hasPagination={true}
                            filters={accountFilters}
                            rows={tenants}
                            loading={tenantsLoading}
                            onTableChange={getTenants}
                            onRefresh={getTenants}
                            onCreate={() => newAccountPanel.current.show()}
                            onCreateLabel='Add account'
                            onImport={() => importPanel.current.show()}
                            onDelete={deleteAccountHandler}
                            onRowClick={selectAccount} />
                    </PaginationProvider>
                </TableSetupProvider>
            }
            {tab === 'groups' &&
                <TableSetupProvider tableName='groups' defaultColumns={groupColumns}>
                <AmazingTable 
                    rows={affiliates}
                    onRefresh={getAffiliates}
                    onCreate={() => newGroupPanel.current.show()}
                    onCreateLabel='Add group'
                    onDelete={deleteGroupHandler}
                    onRowClick={selectGroup} />
                </TableSetupProvider>
            }
            <DetailsPanel title='Account Details' showing={selectedAccount !== null} onHide={accountDetailsHideHandler}>
                {selectedAccount &&
                    <TenantProvider tenantId={selectedAccount.id}>
                        <AccountDetails onHide={accountDetailsFinishedHandler} groups={affiliates} />
                    </TenantProvider>
                }
            </DetailsPanel>
            <DetailsPanel title='Group Details' showing={selectedGroup !== null} onHide={groupDetailsHideContainer}>
                {selectedGroup && <GroupDetails group={selectedGroup} onHide={groupDetailsFinishedHandler} />}
            </DetailsPanel>
            <SidePanel title='New Account' ref={newAccountPanel}>
                <CountriesProvider>
                    <TimezonesProvider>
                        <NewAccount onSave={saveNewAccount} />
                    </TimezonesProvider>
                </CountriesProvider>
            </SidePanel>
            <SidePanel title='New Group' ref={newGroupPanel}>
                <NewGroup onSave={saveNewGroup} />
            </SidePanel>
            <DataImport 
                title='Import Accounts' 
                ref={importPanel}
                targetFields={importFields}
                onImport={handleImport} />
        </div>
    )
}

export default Accounts;
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePillBox } from 'contexts/tagPillbox';
import { useTags } from 'contexts/tags';
import React, { useEffect, useState } from 'react';
import 'style/pillbox.scss';

function PillBox({filter = ''}) {
    const { tags } = useTags();
    const [filteredTags, setFilteredTags] = useState([]);
    const { selectedTags, addTag, removeTag } = usePillBox();

    useEffect(() => {
        if (filter.length > 0) {
            let _filtered = [];
            tags.forEach(tag => {
                if (tag.text.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                    _filtered.push(tag);
                }
            })
            setFilteredTags(_filtered);
        } else {
            setFilteredTags([...tags]);
        }
    }, [filter, tags]);

    const pillClickHandler = (tag, isSelected) => {
        if (!isSelected) {
            addTag(tag);
        } else {
            removeTag(tag);
        }
    }

    return (
        <div className='pill-box-outer'>
            <div className='pill-box-inner'>
                {filteredTags.map(t => {
                    const isSelected = selectedTags.findIndex(st => st.id === t.id) > -1;
                    return <Pill pill={{id: t.id, label: t.text}} selected={isSelected} onClick={() => pillClickHandler(t, isSelected)} />
                })}
            </div>
        </div>
    )
}

function Pill({pill, selected, onClick}) {

    return (
        <div role='button' className={'pill' + (selected ? ' selected' : '')} onClick={onClick}>
            <div className='pill-label'>{pill.label}</div>
            {selected && <FontAwesomeIcon icon={faCheck} />}
        </div>
    )
}

export default PillBox
import { usePageContent } from 'routes/pages/context/pageContent';
import React, { useEffect } from 'react';
import Section from './section';

function PageEditor({mobileView}) {
    const { sections, type } = usePageContent();

    useEffect(() => {
        console.log('sections changed');
    }, [sections])

    useEffect(() => {
        console.log('type:', type)
    }, [type])

    return (
        <div className={'browser-container' + (mobileView ? ' mobile' : '') + (type > 2 ? ' printer' : '') + (type === 4 ? ' landscape' : '')}>
            <div className={'page-editor' + (mobileView ? ' mobile' : '') + (type > 2 ? ' printer' : '') + (type === 4 ? ' landscape' : '')}>
                {sections.length === 0 ? <div className='empty-message'>Click add section button to add sections</div> : sections.map((elem, idx) => 
                    <Section 
                        key={idx} 
                        section={elem}
                        selected={false}
                        sectionIndex={idx}
                        />)
                }
            </div>
        </div>
    )
}

export default PageEditor;
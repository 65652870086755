import { faBrowser, faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'style/newPageSelector.scss';

const newWebPage = {
    name: 'New web page',
    type: 1,
    content: JSON.stringify({sections: [
        {elements: [{type: 'text', settings: {elementWidth:100, elementHeight:100}}, {type: 'image', settings: {elementWidth:100, elementHeight:100}}]},
        {elements: [{type: 'text', settings: {elementWidth:100, elementHeight:100}}, {type: 'image', settings: {elementWidth:100, elementHeight:100}}]},
        {elements: [{type: 'image', settings: {elementWidth:100, elementHeight:100}}, {type: 'text', settings: {elementWidth:100, elementHeight:100}}]},
    ]})
}

const newRegistrationPage = {
    name: 'New registration page',
    type: 3,
    content: JSON.stringify({sections: [
        {elements: [
            {type: 'image', settings: {elementWidth:100, imageWidth:500, imageHeight:140, positionTop: .5, positionLeft: 0}}]},
        {elements: [
            {type: 'text', settings: {text: 'Year:', fontSize:32}},
            {type: 'registrationYear', settings: {text:'{year}', fontSize:32, fontWeight: 'bold'}},
            {type: 'text', settings: {text: 'Number:', fontSize:32}},
            {type: 'registrationNumber', settings: {text:'{number}', fontSize:32, fontWeight: 'bold'}},
        ]},
        {elements: [
            {type: 'text', settings: {text: 'Make:', fontSize:32}},
            {type: 'registrationMake', settings: {text:'{make}', fontSize:32, fontWeight: 'bold'}},
            {type: 'text', settings: {text: 'Model:', fontSize:32}},
            {type: 'registrationModel', settings: {text:'{model}', fontSize:32, fontWeight: 'bold'}},
        ]},
        {elements: [
            {type: 'text', settings: {text: 'Name:', fontSize:32}},
            {type: 'registrationName', settings: {text:'{name}', fontSize:32, fontWeight: 'bold'}},
            {type: 'text', settings: {text: 'Location:', fontSize:32}},
            {type: 'registrationLocation', settings: {text:'{location}', fontSize:32, fontWeight: 'bold'}},
        ]},
        {elements: [
            {type: 'registrationQRCode', settings: {text:'{vote}', fontSize:64, fontWeight: 'bold'}}
        ]},
        {elements: [
            {type: 'text', settings: {text: 'Scan the code above with your smart phone to vote for this car', fontSize:18}},
        ]}
    ]})
}

function NewPageSelector({onSelect}) {

    const onPageClick = (pageType) => {
        if (pageType === 1) {
            onSelect(newWebPage);
        } else if (pageType === 2) {
            onSelect(newRegistrationPage);
        }
    }

    return (
        <div className='newPageSelector'>
            <ul>
                <li onClick={() => onPageClick(1)}>
                    <div className='newPage'>
                        <div className='newPageIcon'><FontAwesomeIcon icon={faBrowser}/></div>
                        <div className='newPageLabel'>Web</div>
                    </div>
                </li>
                <li onClick={() => onPageClick(2)}>
                    <div className='newPage'>
                        <div className='newPageIcon'><FontAwesomeIcon icon={faFileAlt} /></div>
                        <div className='newPageLabel'>Registration</div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default NewPageSelector;
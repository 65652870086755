import React, { useEffect, useRef, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';


function AmazingSearch({onSearch}) {
    const [google, setGoogle] = useState('');
    const search = useRef(null);

    useEffect(() => {
        search.current.addEventListener('search', onSearchHandler);
    }, [])

    const searchButtonHandler = () => {
        if (onSearch) {
            onSearch(google);
        }
    }

    const onSearchHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log('onSearchHandler')
        if (onSearch) {
            onSearch(event.target.value);
        }
    }

    return (
        <div className='amazing-search-outer'>
            <div className='amazing-search-inner'>
                <input 
                    type="search" 
                    placeholder='Search' 
                    value={google} onChange={e => setGoogle(e.target.value)} 
                    ref={search} />
                <div className='search-button' role='button' onClick={searchButtonHandler}><FontAwesomeIcon icon={faSearch} /></div>
            </div>
        </div>
    )
}

export default AmazingSearch;
import React from 'react';
import { useSettings } from '../../contexts/settings';

function SignupOptions() {
    const settings = useSettings();

    const updateSetting = (key, value) => {
        let groupSettings = {...settings.group};
        groupSettings[key] = value;
        settings.updateGroupSettings(groupSettings);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
			<div style={{textAlign:'center'}}><h3>Account self signup</h3></div>
			<p>You can have people in your organization sign up for new accounts on their own. To do this, simply have them use the
			link provided here or give them the code to use to sign up using the mobile app. The administrator for the group will 
			receive an email for each new account sign up. You can also determine what state you would like new accounts to be created as:</p>
            <label>Signup Link (Read Only)</label>
            <div style={{fontSize:'bigger'}}>{`${window.location.origin}/signup?code=${settings.group.code}`}</div>
            <label>Signup Code (Read Only)</label>
            <div style={{fontSize:'bigger'}}>{settings.group.code}</div>
            <label>New Account State</label>
            <select value={settings.group.newAccountState} onChange={e => updateSetting('newAccountState', e.target.value)}>
                <option value='3'>Review - Limited use until approved by you</option>
                <option value='4'>Trial - 14-day trial period where all features work</option>
                <option value='1'>Active - No limitations, everything works</option>
                <option value='2'>Inactive - Not active</option>
            </select>
		</div>
    )
}

export default SignupOptions;
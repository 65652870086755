import React, { useState } from 'react';
import Toggle from '../../components/toggle';
import TermsOfUse from './termsOfUse';
import { useSettings } from '../../contexts/settings';

function GroupInfo() {
    const settings = useSettings();
    const [name, setName] = useState(settings.group.name);
    const [admin, setAdmin] = useState(settings.group.admin);
    const [adminEmail, setAdminEmail] = useState(settings.group.adminEmail);
    const [customSupport, setCustomSupport] = useState(settings.group.customSupport);
    const [supportPhone, setSupportPhone] = useState(settings.group.supportPhone);
    const [supportEmail, setSupportEmail] = useState(settings.group.supportEmail);
    const [supportHours, setSupportHours] = useState(settings.group.supportHours);

    const save = () => {
        let groupSettings = {...settings.group, name, admin, adminEmail, customSupport, supportPhone, supportEmail, supportHours};
       settings.updateGroupSettings(groupSettings);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div>
                <div style={{textAlign:'center'}}><h3>Name &amp; Administrator</h3></div>
                <label>Name*</label>
                <div><input type='text' style={{width:'300px'}} value={name} onChange={e => setName(e.target.value)}
                        placeholder='(64 characters max)' maxLength='64'/></div>
                <label>Administrator*</label>
                <div><input type='text' style={{width:'300px'}} value={admin} onChange={e => setAdmin(e.target.value)}
                        placeholder='(64 characters max)' maxLength='64'/></div>
                <label>Administrator Email*</label>
                <div><input type='text' style={{width:'300px'}} value={adminEmail} onChange={e => setAdminEmail(e.target.value)}
                        placeholder='(128 characters max)' maxLength='128'/></div>
            </div>
            <TermsOfUse />
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Custom Support</h3></div>
                <p>If you intend to provide support for Middletwin, you can enable custom support here. When custom
                support is enabled, the phone, email, and hours that you provide will be displayed to the user in the 
                Contact Us area of Support within the portal and mobile app.</p>
                <label>Use Custom Support</label> 
                <div><Toggle checked={customSupport} onChange={val => setCustomSupport(val)} /></div>
                <label>Support Phone Number</label>
                <input type='text' style={{width:'100%'}} value={supportPhone} onChange={e => setSupportPhone(e.target.value)}
                        placeholder='(64 characters max)' maxLength='64' />
                <label>Support Email Address</label>
                <input type='text' style={{width:'100%'}} value={supportEmail} onChange={e => setSupportEmail(e.target.value)}
                        placeholder='(64 characters max)' maxLength='64' />
                <label>Support Hours</label>
                <input type='text' style={{width:'100%'}} value={supportHours} onChange={e => setSupportHours(e.target.value)}
                        placeholder='ex: 8AM - 5PM CST (64 characters max)' maxLength='64' />
            </div>
            <button onClick={save}>Save</button>
		</div>
    )
}

export default GroupInfo;
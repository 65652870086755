import React from 'react';
import { useCore } from './core';

const TagCreatorContext = React.createContext(null);

function TagCreatorProvider({children}) {
    const { runAction } = useCore();

    const createTag = (tagText, callback) => {
        const data = {
            text: tagText, 
            members: []
        };
        runAction('create_tag', data, response => {
            callback({...data, id: response.id});
        });
    }

    const provider = {
        createTag,
    }

    return <TagCreatorContext.Provider value={provider}>{children}</TagCreatorContext.Provider>
}

function useTagCreator() {
    const context = React.useContext(TagCreatorContext);
    if (!context) {
        throw new Error('useTagCreator must be used within a TagCreatorProvider');
    }
    return context;
}

export { TagCreatorProvider, useTagCreator }
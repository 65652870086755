import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useAuth } from './auth';

const PermissionsContext = React.createContext(null);

function PermissionsProvider({context, resourceId, children}) {
    const { runAction } = useCore();
    const { tenant } = useAuth();
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        getPermissions();
    }, [])

    const getPermissions = () => {
        let data = {context};
        if (context === 'account' && !tenant) {
            console.log('no tenant');
            data.tenantId = resourceId;
        }
        runAction('get_permissions', data,
            (response) => {
                if (response) {
                    setPermissions(response.permissions);
                }
            });
    }

    const provider = {
        permissions
    }

    return <PermissionsContext.Provider value={provider}>{children}</PermissionsContext.Provider>
}

function usePermissions() {
    const context = React.useContext(PermissionsContext);
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionProvider');
    }
    return context;
}

export { PermissionsProvider, usePermissions }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const InvoicesContext = React.createContext(null);

function InvoicesProvider({children}) {
    const { runAction } = useCore();
    const [invoices, setInvoices] = useState([]);
    const [invoicesLoading, setInvoicesLoading] = useState(true);

    useEffect(() => {
        getInvoices();
    }, []);

    const getInvoices = () => {
        setInvoicesLoading(true);
        runAction('get_sub9_invoices', {}, response => {
            setInvoices(response.invoices);
            setInvoicesLoading(false);
        })
    }

    const provider = {
        invoicesLoading,
        invoices,
        getInvoices
    }

    return <InvoicesContext.Provider value={provider}>{children}</InvoicesContext.Provider>
}

function useInvoices() {
    const context = React.useContext(InvoicesContext);
    if (!context) {
        throw new Error('useInvoices must be used within an InvoicesProvider');
    }
    return context;
}

export { InvoicesProvider, useInvoices }
import { useCore } from 'contexts/core';
import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Loading from 'components/loading';
import { usePage } from 'contexts/page';
import { RegPageProvider, useRegPage } from './context/page';
import './regCard.scss';

export async function loader({params}) {
    return params;
}

function RegistrationCard() {
    const loaderData = useLoaderData();
    const { runAction } = useCore();
    const { pages } = usePage();
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [year, setYear] = useState('');
    const [carNumber, setCarNumber] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (loaderData && loaderData.tenant && loaderData.event && loaderData.car) {
            let data = {tenantUUID: loaderData.tenant, event: loaderData.event, car_number: loaderData.car};
            runAction('get_entry', data, (response) => {
                setYear(response.year);
                setCarNumber(response.car_number);
                setMake(response.make);
                setModel(response.model);
                setName(response.fname + ' ' + response.lname);
                setLocation(response.city + ', ' + response.state);
                setLoading(false);
            })
        }
    }, [loaderData])

    useEffect(() => {
        if (pages.length > 0) {
            setPage(pages.find(p => p.id === parseInt(loaderData.page)));
        }
    }, [pages])

    return(
        <div>
            {loading 
                ? <Loading /> 
                : page ? <RegPageProvider page={page}><PageView data={{tenant: loaderData.tenant, event: loaderData.event, year, carNumber, make, model, name, location}}/></RegPageProvider> : <div>Err</div>
            }
        </div>
    )
}

function PageView({data}) {
    const { sections } = useRegPage();

    return (
        <div className={'print-container'}>
            <div className={'page-editor'}>
                {sections.map((section, idx) => 
                    <div key={idx} className='page-section'>
                        {section.elements.map((element, eidx) => 
                            <Element key={eidx} element={element} data={data}/>
                        )}
                    </div>
                    )}
            </div>
        </div>
    )
}

function Element({element, data}) {
    const [elementStyle, setElementStyle] = useState({});
    const [contentStyle, setContentStyle] = useState({});

    useEffect(() => {
        const settings = {element};
        if (settings) {
            setElementStyle({...elementStyle,
                width: settings.elementWidth + '%',
                height: settings.elementHeight + '%',
                borderTop: settings.borderTop  + 'px',
                borderRight: settings.borderRight  + 'px',
                borderBottom: settings.borderBottom  + 'px',
                borderLeft: settings.borderLeft  + 'px',
                borderColor: settings.borderColor,
                borderRadius: settings.borderRadius  + 'px'});
            setContentStyle({...contentStyle,
                width: settings.contentWidth + '%',
                fontSize: settings.fontSize + 'px',
                fontWeight: settings.fontWeight,
                color: settings.textColor,
                backgroundColor: settings.backgroundColor,
                justifySelf: settings.wrapAlign, 
                textAlign: settings.textAlign,
                marginTop: settings.marginTop + 'px',
                marginRight: settings.marginRight + 'px',
                marginBottom: settings.marginBottom + 'px',
                marginLeft: settings.marginLeft + 'px',
                paddingTop: settings.paddingTop + 'px',
                paddingRight: settings.paddingRight + 'px',
                paddingBottom: settings.paddingBottom + 'px',
                paddingLeft: settings.paddingLeft + 'px',
            });
        }
    }, [])

    return (
        <div className='page-element' style={elementStyle}>
            <div className='page-content' style={contentStyle}>
            {element.type === 'text' &&
                <div className='element-text' dangerouslySetInnerHTML={{__html: element.settings.text}}></div>
            }
            {element.type === 'image' &&
                <div style={{
                    width: element.settings.imageWidth + 'px',
                    height: element.settings.imageHeight + 'px',
                    backgroundImage:`url(${element.settings.imageData})`,
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:'center',
                    backgroundSize:'cover'}} />
            }
            {element.type === 'registrationYear' && <div className='element-text'>{data.year}</div>}
            {element.type === 'registrationNumber' && <div className='element-text'>{data.carNumber}</div>}
            {element.type === 'registrationMake' && <div className='element-text'>{data.make}</div>}
            {element.type === 'registrationModel' && <div className='element-text'>{data.model}</div>}
            {element.type === 'registrationName' && <div className='element-text'>{data.name}</div>}
            {element.type === 'registrationLocation' && <div className='element-text'>{data.location}</div>}
            {element.type === 'registrationQRCode' && <QRCode size='100' value={`https://app.middletwin.com/vote/${data.tenant}/${data.event}/${data.carNumber}`}/>}
        </div></div>
    )
}

export default RegistrationCard;
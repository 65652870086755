import React, { useState } from 'react';
import MFACode from './mfaCode';
import Core from 'util/core';

function MFA(props) {
    const [error, setError] = useState(null);
    const [enteredCode, setEnteredCode] = useState('');

    const mfaCodeChangeHandler = (val) => {
        setEnteredCode(val);
    }

    const mfaCodeEnterHandler = () => {
        if (enteredCode.length === 6) {
            verify();
        }
    }

    const verify = () => {
        let data = {
            "userid": props.userId,
            "code": enteredCode};
        Core.runNoAuthAction('verify_code', data, 
            (result, error) => {
                if (result) {
                    if (result.device) {
                        let deviceCode = result.device;
                        localStorage.deviceCode = deviceCode;
                        props.onMfaComplete();
                    } else {
                        setError('No device code returned');
                    }
                } else {
                    setError(error);
                }
            });
    }

    return(
        <div className="mfa-code-container">
            <p style={{textAlign:'center',fontSize:'20px'}}>
                This appears to be a new device. A temporary verification code has 
                been sent your {props.mobile ? `mobile device ending in ${props.mobile}`: 'email address'}. Enter it here and click Verify to continue.</p>
            <div className='input-container'>
                <MFACode onChange={mfaCodeChangeHandler} onEnter={mfaCodeEnterHandler}/>
            </div>
            <div className='password-stuff'>
                <div>
                    <button onClick={verify} disabled={enteredCode.length!==6}>Verify</button>
                </div>
                
            </div>
            {error ? <div className='input-error'>{error}</div> : null}
        </div>
    );
}

export default MFA;
import React, { useEffect, useState } from 'react';

const RegPageContext = React.createContext(null);

function RegPageProvider({page, children}) {
    const [name, setName] = useState('');
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (page) {
            setName(page.name);
            try {
                const content = JSON.parse(page.content);
                setSections(content.sections);
            } catch (err) {
                console.error(err);
            }
        }
    }, [page])

    const provider = {
        name,
        sections,
    }

    return <RegPageContext.Provider value={provider}>{children}</RegPageContext.Provider>
}

function useRegPage() {
    const context = React.useContext(RegPageContext);
    if (!context) {
        throw new Error('usePage must be used within a PageProvider');
    }
    return context;
}

export { RegPageProvider, useRegPage }
import Loading from 'components/loading';
import { StripePayoutProvider, useStripePayouts } from 'contexts/stripePayout';
import React, { useEffect, useState } from 'react';
import Core from 'util/core';
import moment from 'moment';
import { formatCurrency } from '_base';
import { TableSetupProvider } from 'contexts/tableSetup';
import AmazingTable from 'components/amazingTable';

function BankAccounts() {
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        getAccount();
    }, [])

    const getAccount = () => {
        Core.runAction('stripe_get_account', {}, (response) => {
            let account = JSON.parse(response.accountJson);
            setAccount(account);
            setLoading(false);
        })
    }

    const linkBankAccount = () => {
        Core.runAction('stripe_link_account', {}, response => {
            window.open(response.accountLinkUrl);
        });
    }

    return (
        <div>
            <div><label>Linked Bank Accounts</label></div>
            <ul style={{width:'unset',listStyle:'none',padding:'0'}}>
                {loading ? <li>Loading...</li> 
                    : account && account.external_accounts.data.length > 0
                ? account.external_accounts.data.map((a, idx) => 
                    <li key={idx}>
                        <div>{a.bank_name + " [***" + a.last4 + "]"}</div>
                        <StripePayoutProvider bankId={a.id}>
                            <Payouts />
                        </StripePayoutProvider>
                    </li>
                    )
                : <li><button onClick={linkBankAccount}>Link Bank Account</button></li>}
            </ul>
            <p>To change a linked bank account, log into your stripe account and set up a new bank link.</p>
        </div>
    )
}

function Payouts() {
    const { payoutsLoading, payouts, downloadCSV } = useStripePayouts();

    const columns = [
        {id: 'id', label: 'ID', display: 'true', datum: 'id'},
        {id: 'descriptor', label: 'Description', display: true, datum: 'description'},
        {id: 'created', label: 'Created Date', display: true, formatter: obj => moment(obj.created*1000).format('YYYY-MM-DD')},
        {id: 'arrival', label: 'Arrival Date', display: true, formatter: obj => moment(obj.arrival_date*1000).format('YYYY-MM-DD')},
        {id: 'amount', label: 'Amount', display: true, formatter: obj => formatCurrency(obj.amount/100)},
        {id: 'status', label: 'Status', display: true, formatter: obj => <div className={`status ${obj.status}`}>{obj.status}</div>},
    ]

    const exportHandler = (filter, cols) => {
        downloadCSV();
    }

    return (
        <>
            <h4>Payouts from Stripe to your bank account</h4>
            <TableSetupProvider tableName='payouts' defaultColumns={columns}>
                <AmazingTable 
                    rows={payouts}
                    loading={payoutsLoading}
                    onExport={exportHandler}
                />
            </TableSetupProvider>
        </>
    )
}

export default BankAccounts;
import React, {useEffect, useState} from 'react';
import { useLoaderData } from 'react-router-dom';
import Core from 'util/core';

export async function loader({params}) {
    return params;
}

function Roster() {
    const loaderData = useLoaderData();
    const [members, setMembers] = useState([]);

    useEffect(() => {
        let data = {tenantUUID: loaderData.tenant, type: loaderData.type, format: 'json'}
        Core.runAction('get_all_members', data, (response) => {
            setMembers(response.members);
        });
    });

    return (
        <div>
            <div className='roster-outer'>
                <table className='roster-table' data-dojo-attach-point='roster'>
                    <tbody>
                        <tr><th>Id</th><th>Name</th><th>Address</th><th>City</th><th>State</th><th>Zip</th><th>Email</th><th>Phone</th></tr>
                        {members.map((m) => <tr key={m.id}>
                            <td>{m.id}</td>
                            <td>{m.name}</td>
                            <td>{m.address}</td>
                            <td>{m.city}</td>
                            <td>{m.state}</td>
                            <td>{m.zip}</td>
                            <td>{m.email_address}</td>
                            <td>{m.phoneNumbers.length > 0 ? m.phoneNumbers[0].longCode : ''}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Roster;
import React, { useState } from 'react';

function NewGroup(props) {
    const [name, setName] = useState('');
    const [admin, setAdmin] = useState('');
    const [adminEmail, setAdminEmail] = useState('');

    const save = () => {
        let data = {name, admin, adminEmail};
        props.onSave(data);
    }

    return (
        <div className='contact-preview'>
            <label>Name</label>
            <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
            <label>Administrator</label>
            <input type='text' value={admin} onChange={evt => setAdmin(evt.target.value)} maxLength='64' />
            <label>Email</label>
            <input type='text' value={adminEmail} onChange={evt => setAdminEmail(evt.target.value)} maxLength='64' />
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default NewGroup;
import React, { useRef } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import moment from 'moment';
import Core from 'util/core';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from 'util/stripeForm';
import { useAuth } from 'contexts/auth';
import { usePaymentMethods } from 'contexts/paymentMethods';
import { TableSetupProvider } from 'contexts/tableSetup';

const pmCols = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "created", label: "Created", display: true, formatter: (obj) => {return moment(new Date(obj.created*1000)).format("YYYY-MM-DD");}},
    {id: "brand", label: "Card Type", display: true, formatter: (obj) => {return obj.card.brand;}},
    {id: "exp", label: "Expiration", display: true, formatter: (obj) => {return obj.card.exp_month + '/' + obj.card.exp_year;}},
    {id: "last4", label: "Last 4 digits", display: true, formatter: (obj) => {return obj.card.last4;}}
];

function PaymentMethods() {
    const auth = useAuth();
    const { paymentMethods, getPaymentMethods } = usePaymentMethods();
    const stripeDialog = useRef(null);

    const attachCard = (paymentMethodId) => {
        let data = {paymentMethodId: paymentMethodId};
        Core.runAction('stripe_attach_payment_method', data, (response) => {
            getPaymentMethods();
        })
    }

    const detachCard = (cards) => {
        let data = {paymentMethodId: cards[0].id};
        Core.runAction('stripe_detach_payment_method', data, (response) => {
            getPaymentMethods();
        })
    }

    const stripeFormFinishedHandler = (paymentMethodId) => {
        if (paymentMethodId) {
            attachCard(paymentMethodId);
        }
    }

    return (
        <div>
            <TableSetupProvider tableName='paymentmethods' defaultColumns={pmCols}>
                <AmazingTable 
                    rows={paymentMethods}
                    onCreate={() => stripeDialog.current.show()}
                    onCreateLabel='Add credit card'
                    onEdit={(rows) => attachCard(rows[0].id)}
                    onRefresh={getPaymentMethods}
                    onDelete={detachCard}
                />
            </TableSetupProvider>
            <SidePanel title='Credit Card' ref={stripeDialog}>
                <div style={{width:'370px'}}>
                    <Elements stripe={loadStripe(auth.stripe)}>
                        <StripeForm onFinished={stripeFormFinishedHandler} />
                    </Elements>
                </div>
            </SidePanel>
        </div>
    )
}

export default PaymentMethods;
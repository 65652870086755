import React from 'react';
import { faCircle, faPencil, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OpenChatRoom(props) {

    const getAvitar = () => {
        if (props.room.avatar === null || props.room.avatar.length === 0) {
            return <div className='char'>{props.room.name !== null && props.room.name.length > 0 ? props.room.name.charAt(0) : '?'}</div>
        } else {
            return <img src={props.room.avatar} />
        }
    }

    const editHandler = (evt) => {
        evt.preventDefault();
        props.onEdit();
    }

    return (
        <div className={'chat-room-item' + (!props.room.active ? ' inactive' : '')} onClick={props.onClick}>
            <div className='status'>
                <FontAwesomeIcon icon={faCircle} ally-label='status' />
            </div>
            <div className='avatar group'>
                {getAvitar()}
            </div>
            {props.count && <div className='count'>{props.room.unread}</div>}
            <div className='name'>{props.room.name}</div>
            {props.onEdit && <div className='editBtn' onClick={editHandler} role='button'><FontAwesomeIcon icon={faPencil} /></div>}
            {props.activity && <div className='activity'><FontAwesomeIcon icon={faPencilAlt} /></div>}
        </div>
    )
}

export default OpenChatRoom;
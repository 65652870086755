import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import Core from '../../util/core';
import AmazingTable from '../../components/amazingTable';
import SidePanel from 'components/sidePanel';
import AuditDetails from './AuditDetails';
import { TableSetupProvider } from 'contexts/tableSetup';

const logColumns = [
    {id: 'id', label: 'ID', display: true, datum: 'id'},
    {id: 'date', label: 'Date', display: true, formatter: obj => moment(obj.date).format('YYYY-MM-DD hh:mm:ss a')},
    {id: 'tenantName', label: 'Account Name', display: true, datum: 'tenantName'}
]

function FlaggedLog() {
    const auth = useAuth();
    const [audits, setAudits] = useState([]);
    const [audit, setAudit] = useState(null);
    const detailsPanel = useRef(null);

    useEffect(() => {
        getAudits();
    }, [])
// get_flagged_log_details
    const getAudits = () => {
        Core.runAction('get_flagged_log', {groupId: auth.group.id}, response => {
            setAudits(response.audits);
        })
    }

    const getAuditDetail = (adit) => {
        setAudit(adit);
        detailsPanel.current.show();
    }

    return (
        <>
            <TableSetupProvider tableName='flaggedlog' defaultColumns={logColumns}>
                <AmazingTable 
                    onRefresh={getAudits}
                    rows={audits}
                    onRowClick={getAuditDetail}
                />
            </TableSetupProvider>
            <SidePanel title='Details' ref={detailsPanel}>
                <AuditDetails audit={audit} />
            </SidePanel>
        </>
    )
}

export default FlaggedLog;
import React, { useState } from 'react';
import Checkbox from 'components/checkbox';
import {isSupported, getIcon} from 'util/contentType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { publish } from 'pubsub-js';
import Loading from 'components/loading';
import { useCore } from 'contexts/core';
import { useToken } from 'contexts/token';
import 'style/fileUpload.scss';

function FileUpload(props) {
    const { runAction } = useCore();
    const { token } = useToken();
    const [notify, setNotify] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [uploadedMedia, setUploadedMedia] = useState([]);
    
    const handleDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    }

    const handleAttachmentDrop = (evt) => {
        evt.preventDefault();
        if (loading) {
            return;
        }

        var files = evt.dataTransfer.files; // FileList object.
        for (let i = 0, f; f = files[i]; i++) {
            const name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                // set the loading state to true
                setLoading(true);
                // create the form
                var formData = new FormData();
                formData.append("file", f);
                // create the upload request
                var xhr = new XMLHttpRequest();
                xhr.onload = (resp) => fileUploaded(resp);
                xhr.onerror = () => fileUploadError();
                xhr.open("POST", "/dart/upload");
                xhr.setRequestHeader("X-Dart-Token", token);
                // send the form
                xhr.send(formData);
            } else {
                setError(f.name + " is not a supported file type.");
            }
        }
    }

    const fileUploaded = (resp) => {
        setLoading(false);
        if (resp.target.status === 200) {
            var response = JSON.parse(resp.target.response);
            if (response.media) {
                setUploadedMedia(response.media);
            }
            if (props.onUpload) {
                props.onUpload();
            }
            if (notify) {
                notifyMembers();
            }
        } else {
            if (resp.target.status === 413) {
                setError('Files must be less than 2 MB in size.');
            } else {
                setError(resp.target.status);
            }
        }
    }

    const fileUploadError = () => {
        setLoading(false);
        setError('Files failed to upload.');
    }

    const notifyMembers = () => {
        let data = {notificationTypeId: "1"};
        runAction('create_member_notifications', data, 
            (response) => {
                publish('success', 'Member notificiations have been sent')
            });
    }

    return(
        <div>
            <div>
                <p style={{width: '460px'}}>Drag and Drop files into the area below to upload them. Files are uploaded as soon as they are dropped.</p>
            </div><div>
                <table><tbody>
                    <tr><td>
                        <Checkbox onChange={(val) => setNotify(val)} /> Notify all active members of new file
                    </td></tr>
                    <tr>
                        <td><div onDragOver={handleDragOver} onDrop={handleAttachmentDrop} 
                                className="dnd-box">Drop files Here</div></td>
                    </tr>
                    <tr><td colSpan="2">
                        <ul className="file-list">
                            {
                                uploadedMedia.map((m) => <li id={m.id} key={m.id}><FontAwesomeIcon icon={getIcon(m.contentType)} /><span> {m.name}</span></li>)
                            }
                        </ul>
                    </td></tr>
                </tbody></table>
            </div>
            {loading && <Loading />}
            {error && <div>{error}</div>}
        </div>
    )
}

export default FileUpload;
import React, { useEffect, useState } from 'react';
import Core from '../../../util/core';

function Phone(props) {
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        getCountryCodes();
    }, [])

    useEffect(() => {
        if (props.value) {
            setCountryCode(props.value.countryCode);
            setPhone(props.value.phone);
        }
    }, [props.value])

    const getCountryCodes = () => {
        Core.runAction('get_countries', {}, response => {
            setCountries(response.countries);
        });
    }

    const countryCodeChangeHandler = (evt) => {
        const value = {countryCode: evt.target.value, phone};
        setCountryCode(value.countryCode);
        props.onChange(value);
    }

    const phoneChangeHandler = (evt) => {
        const value = {countryCode, phone: evt.target.value};
        setPhone(value.phone);
        props.onChange(value)
    }

    return (
        <div className='phoneWiget'>
            <table><tbody>
                <tr><td><label>Country Code*</label></td><td><label>Phone Number</label></td></tr>
                <tr><td>
                    <select style={{width:'150px'}} value={countryCode} onChange={countryCodeChangeHandler}>
                        {countries.map(c => <option key={c.isoCode} value={c.isoCode}>{c.label + '(+' + c.countryCode + ')'}</option>)}
                    </select>
                </td><td>
                    <input type='text' style={{textAlign:'center',width:'250px'}} maxLength='15' placeholder='Phone Number' 
                        value={phone} onChange={phoneChangeHandler} />
                </td></tr>
            </tbody></table>
        </div>
    )
}

export default Phone;
import React, {useState} from 'react';
import { useVideo } from '../../contexts/video';
import Core from '../../util/core';

function RoomCreator() {
    const video = useVideo();
    const [roomName, setRoomName] = useState('');

    const createRoom = () => {
        Core.runAction('get_room', {name: roomName}, response => {
            if (response && response.success) {
                video.updateRoomSid(response.roomSid);
                video.updateRoomName(response.name);
            }
        });
    }

    return (
        <div>
            <label>Or start a meeting</label>
            <div>
                <input type='text' value={roomName} onChange={e => setRoomName(e.target.value)} placeholder='Meeting name' />
                <button onClick={createRoom} disabled={roomName.length === 0}>Start meeting</button>
            </div>
        </div>
    )
}

export default RoomCreator;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const VoterContext = React.createContext(null);

function VotersProvider({all=false, eventId, batch='', children}) {
    const { runAction } = useCore();
    const [votersLoading, setVotersLoading] = useState(true);
    const [voters, setVoters] = useState([]);
    const [generatingVoters, setGeneratingVoters] = useState(false);
    const [generatedVoters, setGeneratedVoters] = useState([]);

    useEffect(() => {
        loadVoters();
    }, [])

    const loadVoters = () => {
        setVotersLoading(true);
        runAction('get_voters', {eventId, batch, all}, (response) => {
            setVoters(response.voters);
            setVotersLoading(false);
        });
    }

    const generateVoters = (numVoters, batchName) => {
        setGeneratingVoters(true);
        runAction('generate_voters', {eventId, numVoters, batch: batchName}, (response) => {
            setGeneratedVoters(response.voters);
            setGeneratingVoters(false);
            loadVoters();
        })
    }

    const deleteVoters = (voterIds) => {
        let data = {ids: voterIds}
        runAction('delete_voters', data, (response) => {
            publish('notification', `${response.count} voters have been successfully removed.`);
            loadVoters();
        })
    }

    const provider = {
        votersLoading,
        voters,
        loadVoters,
        deleteVoters,
        generatingVoters,
        generatedVoters,
        generateVoters,
    }

    return <VoterContext.Provider value={provider}>{children}</VoterContext.Provider>
}

function useVoters() {
    const context = React.useContext(VoterContext);
    if (!context) {
        throw new Error('useVoters must be used within a VotersProvider');
    }
    return context;
}

export { VotersProvider, useVoters }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const TagsContext = React.createContext(null);

function TagsProvider({children}) {
    const { runAction } = useCore();
    const [loadingTags, setLoadingTags] = useState(true);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        loadTags();
    }, [])

    const loadTags = () => {
        setLoadingTags(true);
        runAction('get_all_tags', {}, (response) => {
            setTags(response.tags);
            setLoadingTags(false);
        })
    }

    const searchTags = ({google}) => {
        if (google.length === 0) {
            loadTags();
        } else {
            let data = {text: google};
            runAction('get_matching_tags', data, (response) => {
                setTags(response.tags);
            })
        }
    }

    const saveTag = (data) => {
        const action = data.id ? 'update_tag' : 'create_tag';
        runAction(action, data, () => loadTags());
    }

    const deleteTags = (tagIds) => {
        let data = {ids: tagIds}
        runAction('delete_tag', data, (response) => {
            publish('notification', `${response.count} tags have been successfully removed.`);
            loadTags();
        })
    }

    const provider = {
        loadingTags,
        tags,
        loadTags,
        searchTags,
        saveTag,
        deleteTags,
    }

    return <TagsContext.Provider value={provider}>{children}</TagsContext.Provider>
}

function useTags() {
    const context = React.useContext(TagsContext);
    if (!context) {
        throw Error('useTags must be used with a TagsProvider');
    }
    return context;
}

export { TagsProvider, useTags }
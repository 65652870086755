import Modal from 'components/modal';
import { useTagCreator } from 'contexts/tagCreator';
import { useTags } from 'contexts/tags';
import React, { useState } from 'react';

function TagCreator({showing, onHide}) {
    const { createTag } = useTagCreator();
    const { loadTags } = useTags();
    const [tagText, setTagText] = useState('');

    const quickAddHandler = () => {
        createTag(tagText, (data) => {
            loadTags();
            onHide();
            setTagText('');
        });
    }

    return (
        <Modal 
            title='Add tag' 
            showing={showing} 
            onHide={onHide}
            buttons={[
                {
                    type: 'primary',
                    handler: () => quickAddHandler(),
                    label: 'Save tag',
                    disabled: tagText.length == 0,
                },
                {
                    type: 'secondary',
                    handler: () => onHide(),
                    label: 'Cancel'
                }
            ]}>
            <div>
                <p>Enter label for tag and click <span style={{fontWeight:'bold'}}>Save tag</span> button</p>
                <input type='text' style={{width:'400px'}} maxLength='32' value={tagText} onChange={(evt) => setTagText(evt.target.value)} />
            </div>
        </Modal>
    )
}

export default TagCreator
import React, {useState} from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';

function Voting(props) {
    const [startingNumber, setStartingNumber] = useState(0);
    const auth = useAuth();
    const navigate = useNavigate();

    const generateVoteCodes = () => {
        navigate(`/votecodes/${auth.tenant.uuid}/${props.eventId}/${startingNumber}`);
    }

    return (
        <div className='communication-area'>
            <p>Use this to generate 30 voting codes that you can add to registration cards that do not have the voting code pre-printed on them.
            Show participants can scan these code to vote for the registration. This vote is identified as a the peoples choice in the results.
            </p>
            <p>Use Avery label 5160.</p>
            <label>Starting registration number</label>
            <div style={{display:'flex',justifyContent:'space-between',height:'40px'}}>
                <CurrencyInput allowDecimals={false} allowNegativeValue={false} value={startingNumber} onValueChange={(value) => setStartingNumber(value)} />
                <button onClick={generateVoteCodes}>Generate Codes</button>
            </div>
        </div>
    )
}

export default Voting;
import React, { useEffect, useState } from 'react';
import { publish } from 'pubsub-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClipboard} from '@fortawesome/pro-regular-svg-icons';
import Checkbox from 'components/checkbox'
import { useFiles } from 'contexts/files';
import Toggle from 'components/toggle';

function FileDetails({selectedFile, onSave}) {
    const { folders, updateMedia } = useFiles();
    const [currentFile, setCurrentFile] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setCurrentFile(selectedFile);
    }, [selectedFile])

    const handleChange = (event) => {
		let _selectedFile = Object.assign({}, currentFile);
        _selectedFile[event.target.name] = event.target.value;
		setCurrentFile(_selectedFile);
    }

    const toggleShare = (val) => {
        let _selectedFile = Object.assign({}, currentFile);
        _selectedFile.share = val;
        setCurrentFile(_selectedFile);
    }

    const saveHandler = () => {
        let data = {id: currentFile.id, name: currentFile.name, share: currentFile.share, parent: currentFile.parent};
        updateMedia(data, () => {
            setCurrentFile(null);
            onSave()
        });
    }

    const copyUrlToClipboard = () => {
        if (!navigator.clipboard) {
            publish('warning', 'Unable to copy key to clipboard');
        } else {
            navigator.clipboard.writeText(currentFile.url);
            setCopied(true);
            setTimeout(() => setCopied(false), 5*1000);
        }
    }

    return (
        <>
            {currentFile &&
                <div className='contact-preview'>
                    <label>File name</label>
                    <input type='text' name="name" value={currentFile.name} onChange={handleChange} maxLength="64" />
                    <label>Folder</label>
                    <select name='parent' value={currentFile.parent} onChange={handleChange}>
                        <option value='0'>/</option>
                        {folders.map(f => <option key={f.id} value={f.id}>/{f.label}</option>)}
                    </select>
                    <label>Shared</label>
                    <div><Toggle checked={currentFile.share} onChange={toggleShare}/></div>
                    <span>
                        <label>Public URL</label>
                        <div style={{fontSize:'12px'}}>{currentFile.url}</div>
                        <FontAwesomeIcon icon={faClipboard} onClick={copyUrlToClipboard} />
                        <span style={{fontSize:'12px',marginLeft:'5px'}}>{copied ? 'Copied' : '<- Copy to clipboard'}</span>
                    </span>
                    <div style={{marginTop:'10px'}}>
                        <button onClick={saveHandler}>Save</button>
                    </div>
                </div>
            }
        </>
    )
}

export default FileDetails;
import { useCountries } from 'contexts/countries';
import React from 'react';

function Countries(props) {
    const { countries } = useCountries();

    return (
        <select name={props.name} onChange={props.onChange} value={props.value}>
            {
                countries.map((ct) => <option id={ct.isoCode} key={ct.isoCode}>{ct.label}</option>)
            }
        </select>
    )
}

export default Countries
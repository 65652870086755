import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ApiKeysContext = React.createContext(null);

function ApiKeysProvider({tenantId, children}) {
    const { runAction } = useCore();
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        getKeys();
    }, [])

    const getKeys = () => {
        runAction('get_api_keys', {tenant: tenantId}, response => {
            setKeys(response.keys);
        });
    }

    const deleteKey = (key) => {
        runAction('delete_api_key', {key}, response => {
            getKeys();
        });
    }

    const provider = {
        keys,
        getKeys,
        deleteKey
    }

    return <ApiKeysContext.Provider value={provider}>{children}</ApiKeysContext.Provider>
}

function useApiKeys() {
    const context = React.useContext(ApiKeysContext);
    if (!context) {
        throw new Error('useApiKeys must be used within an ApiKeysProvider');
    }
    return context;
}

export { ApiKeysProvider, useApiKeys }
import React, { useState } from 'react';
import { useCore } from './core';

const AnalyticsContext = React.createContext(null);

function AnalyticsProvider({children}) {
    const { runAction } = useCore();
    const [count, setCount] = useState(null);
    const [tenantCount, setTenantCount] = useState(null);

    const getCount = () => {
        runAction('get_analytics_count', {}, 
            (result) => {
                if (result) {
                    setCount(result.count);
                    setTenantCount(result.tenantCount);
                }
            });
    }

    const provider = {
        getCount,
        count, 
        tenantCount,
    }

    return <AnalyticsContext.Provider value={provider}>{children}</AnalyticsContext.Provider>
}

function useAnalytics() {
    const context = React.useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
}

export { AnalyticsProvider, useAnalytics }
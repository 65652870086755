import React, { useRef, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faImage, faComments, faHeadset, faMicrophone, faMusic, faCogs, faArrows } from '@fortawesome/pro-regular-svg-icons';
import { MessagesProvider, useMessages } from '../../../contexts/messages';
import './messaging.scss';
import { CommunicationsProvider } from '../../../contexts/communications';
import MemberMessages from './memberMessages';
import MemberIcon from './memberIcon';
import SidePanel from 'components/sidePanel';
import FindContact from './findContact';

function Messaging() {
    return (
        <MessagesProvider>
            <MessagingApp />
        </MessagesProvider>
    )
}

function MessagingApp() {
    const { members } = useMessages();
    const [selectedMembers, setSelectedMembers] = useState([]);
    const newMessagePanel = useRef(null);

    const selectMemberHandler = (mbr) => {
        const idx = selectedMembers.findIndex(m => {return m.id === mbr.id});
        if (idx === -1) {
            setSelectedMembers([mbr, ...selectedMembers]);
        }
    }

    const onCloseHandler = (mbr) => {
        const idx = selectedMembers.findIndex(m => {return m.id === mbr.id});
        if (idx != -1) {
            setSelectedMembers(selectedMembers.toSpliced(idx, 1));
        }
    }

    const newMessageHandler = () => {
        newMessagePanel.current.show();
    }

    const selectContactHandler = (mbr) => {
        selectMemberHandler(mbr);
        newMessagePanel.current.hide();
    }

    return (
        <div className='messagesContainerOuter'>
            <div className='messagesContainerInner'>
                <div className='messagesButtonsOuter'>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faComments} /></button>
                    </div>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faHeadset} /></button>
                    </div>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faMicrophone} /></button>
                    </div>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faMusic} /></button>
                    </div>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faArrows} /></button>
                    </div>
                    <div className='messagesButtonContainer'>
                        <button><FontAwesomeIcon icon={faCogs} /></button>
                    </div>
                </div>
                <div className='messagesCurrentAppContainer'>
                    <div className='messagesActionsContainer'>
                        <div className='messagesActions'></div>
                        <div className='messagesActions'>
                            <button onClick={newMessageHandler}>+ New Message</button>
                        </div>
                    </div>
                    <div className='messagesApp'>
                        <div className='messagesListContainer'>
                            <ul>
                                {members.map(mbr => 
                                    <li key={mbr.id} onClick={() => selectMemberHandler(mbr)} >
                                        <Member member={mbr} />
                                    </li>
                                )}
                            </ul>
                        </div>
                        {selectedMembers.length > 0 &&
                            selectedMembers.map(mbr => 
                                <CommunicationsProvider key={mbr.id} member={mbr}>
                                    <MemberMessages onClose={() => onCloseHandler(mbr)} />
                                </CommunicationsProvider>
                            )
                        }
                    </div>
                </div>
            </div>
            <SidePanel title='New Message' ref={newMessagePanel}>
                <FindContact onSelectContact={selectContactHandler} />
            </SidePanel>
        </div>
    )
}

function Member({member}) {

    const createIcon = () => {
        const ltrs = ((member.fname.length > 0 ? member.fname[0] : '') + (member.lname.length > 0 ? member.lname[0] : '')).toUpperCase();
        return ltrs;
    }

    const formatMessageTime = () => {
        let when = moment(member.when);
        let now = moment();
        let duration = moment.duration(now.diff(when));
        if (parseInt(duration.asHours()) < 24) {
            return when.format('hh:mm a');
        } else if (parseInt(duration.asDays()) < 7) {
            return when.format('dddd');
        } else {
            return when.format('MMM d, yyyy');
        }
    }

    const formatMessage = () => {
        if (member.messageType === 'mms') {
            return <span style={{fontSize:'24px'}}><FontAwesomeIcon icon={faImage}/></span>;
        } else if (member.messageType === 'call') {
            return <span style={{fontSize:'24px'}}><FontAwesomeIcon icon={faPhone} /></span>;
        } else {
            return member.message;
        }
    }

    return (
        <>
            <MemberIcon member={member} />
            <div className='memberInfoContainer'>
                <div className='firstRow'>
                    <div className='memberName'>
                        {`${member.fname} ${member.lname}`}
                    </div>
                    <div className='messageTime'>
                        {formatMessageTime()}
                    </div>
                </div>
                <div className='secondRow'>
                    <div className='messageText'>
                        {formatMessage()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Messaging;
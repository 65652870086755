import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const TenantContext = React.createContext(null);

function TenantProvider({tenantId, children}) {
    const { runAction } = useCore();
    const [tenant, setTenant] = useState(null);

    useEffect(() => {
        getTenant();
    }, []);
    
    const getTenant = () => {
        runAction('get_tenant', {tenant: tenantId}, 
            (response) => {
                if (response) {
                    setTenant(response.tenant);
                }
            })
    }

    const saveTenant = (data, callback) => {
        runAction('update_tenant', data, 
            (response) => {
                if (response) {
                    callback();
                }
            });
    }

    const updateNumber = (twilioNumber, callback) => {
        let data = {tenant: tenantId, number: twilioNumber};
        runAction('update_number', data, 
            () => {
                publish('success', 'Twilio number updated.');
                callback();
            })
    }

    const releaseNumber = (callback) => {
        let data = {tenant: tenantId};
        runAction('release_number', data, () => {
            callback();
        });
    }

    const provider = {
        tenant,
        saveTenant,
        updateNumber,
        releaseNumber,
    }

    return <TenantContext.Provider value={provider}>{children}</TenantContext.Provider>
}

function useTenant() {
    const context = React.useContext(TenantContext);
    if (!context) {
        throw new Error('useTenant must be used within a TenantProvider');
    }
    return context;
}

export { TenantProvider, useTenant }
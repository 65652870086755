import React, { useRef } from 'react'
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import {formatCurrency} from '_base'
import SidePanel from 'components/sidePanel'
import NewReceipt from './newReceipt';
import { PaginationProvider } from 'contexts/pagination';
import { useReceipts } from 'contexts/receipts';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "date", label: "Date", display: true, formatter: (obj) => {return moment(obj.date).format("YYYY-MM-DD");}},
    {id: "description", label: "Description", display: true, datum: "description"},
    {id: "amount", label: "Amount", display: true, formatter: (obj) => {return formatCurrency(obj.amount/100)}},
    {id: "fee", label: "Fee", display: true, formatter: (obj) => {return formatCurrency(obj.fee/100)}},
    {id: "paid", label: "paid", display: true, formatter: (obj) => {return formatCurrency((obj.amount - obj.fee)/100)}},
    {id: "status", label: "Status", display: true, datum: "status"},
    {id: "bt_resp_code", label: "Response Code", display: false, datum: "bt_resp_code"},
    {id: "bt_resp_text", label: "Response Text", display: true, datum: "bt_resp_text"},
    {id: "bt_setl_code", label: "Settlement Code", display: false, datum: "bt_setl_code"},
    {id: "bt_setl_text", label: "Settlement Text", display: true, datum: "bt_setl_text"},
    {id: "contact", label: "Member", display: true, datum: "contact"},
    {id: "payoutStatus", label: "Payout Status", display: true, datum: "payoutStatus"}
];

function Receipts(props) {
    const { receiptsLoading, receipts, month, getReceipts, nextMonth, prevMonth, updateReceipts, deleteReceipts } = useReceipts();
    
    const sidePanel = useRef(null);

    const nextPageHandler = () => {
        nextMonth();
    }

    const prevPageHandler = () => {
        prevMonth();
    }

    const createHandler = () => {
        sidePanel.current.show();
    }

    const editHandler = (receipts) => {
        const receiptsArr = receipts.map(r => {return {'id': r.id, 'description':r.description, 'amount':r.amount, 'fee':r.fee, paid: true, 'status': '1', 'contact': r.contact}});
        updateReceipts(receiptsArr);
    }

    const deleteHandler = (receipts) => {
        let receiptIds = receipts.map(r => r.id);
        deleteReceipts(receiptIds);
    }

    const newReceiptSaveHander = () => {
        getReceipts();
        sidePanel.current.hide()
    }

    const getTotal = () => {
        let total = 0;
        receipts.forEach((r) => total += r.amount);
        return total/100;
    }

    return (
        <div>
            <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>
                {formatCurrency(getTotal())}
            </div>
            <TableSetupProvider tableName='receipts' defaultColumns={columns}>
                <PaginationProvider 
                    currentPage={moment(month).format('MMM YYYY')}
                    maxPages={-1} 
                    nextPageHandler={nextPageHandler} 
                    previousPageHandler={prevPageHandler}>
                    <AmazingTable 
                        hasPagination={true}
                        rows={receipts}
                        onCreate={createHandler}
                        onCreateLabel='Add receipt'
                        onEdit={editHandler}
                        onDelete={deleteHandler}
                        loading={receiptsLoading} />
                </PaginationProvider>
            </TableSetupProvider>
            <SidePanel title='New Receipt' ref={sidePanel}>
                <NewReceipt tenantId={props.tenantId} onSave={newReceiptSaveHander} />
            </SidePanel>
        </div>
    )
}

export default Receipts;
import React, { useEffect, useState } from 'react';

function Element({element, settings, selected, onEdit}) {
    const [elementStyle, setElementStyle] = useState({});
    const [contentStyle, setContentStyle] = useState({});
    const isImage = (settings && settings.url) || (settings && settings.imageData !== '');

    useEffect(() => {
        console.log('element settings:', settings);
        if (settings) {
            setElementStyle({...elementStyle,
                width: settings.elementWidth + '%',
                height: settings.elementHeight + '%',
                borderTop: settings.borderTop  + 'px',
                borderRight: settings.borderRight  + 'px',
                borderBottom: settings.borderBottom  + 'px',
                borderLeft: settings.borderLeft  + 'px',
                borderColor: settings.borderColor,
                borderRadius: settings.borderRadius  + 'px'});
            setContentStyle({...contentStyle,
                width: settings.contentWidth + '%',
                fontSize: settings.fontSize + 'px',
                fontWeight: settings.fontWeight,
                color: settings.textColor,
                backgroundColor: settings.backgroundColor,
                justifySelf: settings.wrapAlign, 
                textAlign: settings.textAlign,
                marginTop: settings.marginTop + 'px',
                marginRight: settings.marginRight + 'px',
                marginBottom: settings.marginBottom + 'px',
                marginLeft: settings.marginLeft + 'px',
                paddingTop: settings.paddingTop + 'px',
                paddingRight: settings.paddingRight + 'px',
                paddingBottom: settings.paddingBottom + 'px',
                paddingLeft: settings.paddingLeft + 'px',
                //borderStyle: settings.border,
                });
        }
    }, [settings])

    useEffect(() => {
        console.log('element style:', elementStyle);
    }, [elementStyle])

    return (
        <div className={'page-element' + (selected ? ' selected' : '')} style={elementStyle}>
            <div className='page-content' style={contentStyle}>
                {settings 
                    ? (settings?.url && settings.url !== '0') ? <img alt='element photo' src={settings.url} />
                    : settings?.imageData ? <div style={{width:settings.imageWidth + 'px',height: settings.imageHeight + 'px', backgroundImage:`url(${settings.imageData})`,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover'}} />
                    : settings?.text ? <div className='element-text' dangerouslySetInnerHTML={{__html: settings.text}}></div>
                    : <div className='empty-message'>-Edit to add {isImage ? 'image' : 'content'}-</div>
                    : <div className='empty-message'>-Edit to add content-</div>
                }
            </div>
        </div>
    )
}

export default Element;
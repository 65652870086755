import React, { useEffect, useState, useRef } from 'react';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import Activitydetails from './activityDetails';
import { useActivities } from 'contexts/activities';
import { TableSetupProvider } from 'contexts/tableSetup';
import { EmailTemplatesProvider } from 'contexts/emailTemplates';
import { FlowProvider } from 'contexts/flow';
import CampaignDetails from 'routes/admin/email/campaignDetails';
import { CampaignsProvider, useCampaigns } from 'contexts/campaigns';
import { convertToUnixTime } from '_base';
import Modal from 'components/modal';

const typeFormatter = (obj) => {
    let typeValue = '';
    switch(obj.type) {
    case 1: typeValue="Club Meeting"; break;
    case 2: typeValue="Club Event"; break;
    case 3: typeValue="Other"; break;
    default: typeValue='Unknown'; break;
    }
    return typeValue;
};

const tableColumns = [
    {id: "id", label:"ID", display: false, datum: "id"},
    {id: "description", label: "Description", display: false, datum: "description"},
    {id: "title", label: "Title", display: true, datum: "title"},
    {id: "type", label: "Type", display: true, formatter: typeFormatter},
    {id: "date", label: "Date", style:{width:'100px'}, display: true, formatter: (obj) => {return moment(new Date(obj.date)).format('YYYY-MM-DD');}},
    {id: "startTime", label: "Start Time", style:{width:'100px'}, display: true, formatter: (obj) => {return moment().hour(obj.start.split(':')[0]).minute(obj.start.split(':')[1]).format('h:mm a');}},
    {id: "endTime", label: "End Time", style:{width:'100px'}, display: true, formatter: (obj) => {return moment().hour(obj.end.split(':')[0]).minute(obj.end.split(':')[1]).format('h:mm a');}},
    {id: 'campaignId', label: "Campaign ID", style:{width:'100px'}, display: false, datum: 'campaignId'}
];

const typeOptions = [
    {id: 1, value: "Club Meeting"},
    {id: 2, value: "Club Event"},
    {id: 3, value: "Other"}
]

function Activities() {
    const { loadingActivities, activities, getActivities, saveActivity, deleteActivity,
        startDate, setStartDate, endDate, setEndDate} = useActivities();
    const [activity, setActivity] = useState(null);
    const detailsPanel = useRef(null);
    const reminderPanel = useRef(null);
    const [showReminderModal, setShowReminderModal] = useState(false);

    const deleteHandler = (selectedActivities) => {
        const activitiyId = selectedActivities[0].id;
        deleteActivity(activitiyId);
    }

    const saveActivityHandler = (data) => {
        saveActivity(data, (response) => {
            detailsPanel.current.hide();
            if (data.createReminder) {
                if (response.id) {
                    data.id = response.id;
                }
                setActivity(data);
                reminderPanel.current.show();
            }
        });
    }

    const createHandler = () => {
        let row = {
            title: '',
            description: '',
            type: 1,
            date: Date.now(),
            start: '12:00:00',
            end: '12:00:00',
            error: null
          }
        setActivity(row);
        detailsPanel.current.show()
    }
    
    const rowClickHandler = (row) => {
        setActivity(row);
        detailsPanel.current.show();
    }

    const hideActivity = () => {
        setActivity(null);
    }

    const hideReminder = () => {
        setActivity(null);
    }

    const copyActivityHandler = (rows) => {
        const activityId = rows[0].id;
        const activityToCopy = activities.find((a) => a.id === activityId);
        if (activityToCopy) {
            const newActivity = {
                title: activityToCopy.title + ' (copy)',
                description: activityToCopy.description,
                type: activityToCopy.type,
                date: activityToCopy.date,
                start: convertToUnixTime(activityToCopy.start),
                end: convertToUnixTime(activityToCopy.end)
            }
            saveActivity(newActivity, () => {});
        }
    }

    return(
        <>
            <TableSetupProvider tableName='activities' defaultColumns={tableColumns}>
                <AmazingTable 
                    dates={[{label: 'Start', date: startDate, onChange: setStartDate}, {label: 'End', date: endDate, onChange: setEndDate}]}
                    rows={activities}
                    onCreate={createHandler}
                    onCreateLabel='Add activity'
                    onDelete={deleteHandler}
                    onRefresh={getActivities}
                    onRowClick={rowClickHandler}
                    onCopy={copyActivityHandler}
                    loading={loadingActivities} />
            </TableSetupProvider>
            <SidePanel title='Activity Details' ref={detailsPanel} onHide={hideActivity}>
                {activity &&
                    <div>
                        <Activitydetails onSave={saveActivityHandler} types={typeOptions} act={activity}/>
                    </div>
                }
            </SidePanel>
            <SidePanel title='Reminder' ref={reminderPanel} onHide={hideReminder}>
                {activity &&
                    <CampaignsProvider>
                        <ReminderPanel activity={activity} onHide={() => {reminderPanel.current.hide(); hideReminder(); setShowReminderModal(true);}} />
                    </CampaignsProvider>
                }
            </SidePanel>
            <Modal
                title='Activity Reminder'
                showing={showReminderModal}
                onHide={() => setShowReminderModal(false)}
                buttons={[
                    {type: 'primary', handler:() => setShowReminderModal(false), label: 'Got it'}
                ]}
            >
                <p>Go to Email/Campaigns to make changes to activitiy reminders.</p>
            </Modal>
        </>
    )
}

function ReminderPanel({activity, onHide}) {
    const { createReminder } = useActivities();
    const { loadingCampaigns, campaigns } = useCampaigns();
    const [campaign, setCampaign] = useState(null);

    useEffect(() => {
        if (!loadingCampaigns) {
            if (activity.campaignId !== 0) {
                setCampaign(campaigns.find(c => c.id === activity.campaignId));
            }
        }
    }, [loadingCampaigns])

    const saveCampaignHandler = (campaignData) => {
        let data = {...campaignData, activityId: activity.id};
        createReminder(data, onHide)
    }

    return (
        <div>
            <p>Create a reminder email campaign for the {activity.title} activity.</p>
            <EmailTemplatesProvider>
                <FlowProvider>
                    {!loadingCampaigns &&
                        <CampaignDetails campaign={campaign} onSave={saveCampaignHandler} initialName={'Reminder for ' + activity.title}/>
                    }
                </FlowProvider>
            </EmailTemplatesProvider>
        </div>
    )
}

export default Activities;
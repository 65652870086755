import React, { useEffect, useState } from 'react';
import { useSchedules } from 'contexts/schedules';
import moment from 'moment';
import { useWorkers } from 'contexts/workers';
import { useShifts } from 'contexts/shifts';

function WorkerDetails({worker, eventId}) {
    const { saveWorker } = useWorkers();
    const { getSchedule } = useSchedules();
    const { getWorkerShifts } = useShifts();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [shirt, setShirt] = useState('-');
    const [notes, setNotes] = useState('');

    useEffect(() => {
        if (worker) {
            setFname(worker.fname);
            setLname(worker.lname);
            setEmail(worker.email);
            setPhone(worker.phone);
            setShirt(worker.shirt);
            setNotes(worker.notes);
        } else {
            reset();
        }
    }, [worker])

    const reset = () => {
        setFname('');
        setLname('');
        setEmail('');
        setPhone('');
        setShirt('-');
        setNotes('');
    }

    const save = () => {
        let data = {fname, lname, email, phone, shirt, notes, eventId: eventId};
        if (worker) {
            data.id = worker.id;
        }
        saveWorker(data, () => reset());
    }

    const formatStartTime = (start) => {
        let ampm = "AM";
		if (start >= 12) {
			ampm = "PM";
			if (start > 12) {
				start -= 12;
			}
		}
		return start + ":00 " + ampm;
    }

    return (
        <div className='contact-preview'>        
            <label>First name</label>
            <input type='text' value={fname} onChange={evt => setFname(evt.target.value)} maxLength='64' />
            <label>Last name</label>
            <input type='text' value={lname} onChange={evt => setLname(evt.target.value)} maxLength='64' />
            <label>Email</label>
            <input type='text' value={email} onChange={evt => setEmail(evt.target.value)} maxLength='64' />
            <label>Phone</label>
            <input type='text' value={phone} onChange={evt => setPhone(evt.target.value)} maxLength='64' />
            <label>Shirt size</label>
            <select value={shirt} onChange={evt => setShirt(evt.target.value)}>
                <option value='-'>None</option>
                <option value='s'>Small</option>
                <option value='m'>Medium</option>
                <option value='l'>Large</option>
                <option value='xl'>X-Large</option>
                <option value='2xl'>2X-Large</option>
                <option value='3xl'>3X-Large</option>
                <option value='s-white'>Small event shirt - White</option>
                <option value='m-white'>Medium event shirt - White</option>
                <option value='l-white'>Large event shirt - White</option>
                <option value='xl-white'>X-Large event shirt - White</option>
                <option value='2xl-white'>2X-Large event shirt - White</option>
                <option value='3xl-white'>3X-Large event shirt - White</option>
                <option value='s-gray'>Small event shirt - Gray</option>
                <option value='m-gray'>Medium event shirt - Gray</option>
                <option value='l-gray'>Large event shirt - Gray</option>
                <option value='xl-gray'>X-Large event shirt - Gray</option>
                <option value='2xl-gray'>2X-Large event shirt - Gray</option>
                <option value='3xl-gray'>3X-Large event shirt - Gray</option>
                <option value='s-black'>Small event shirt - Black</option>
                <option value='m-black'>Medium event shirt - Black</option>
                <option value='l-black'>Large event shirt - Black</option>
                <option value='xl-black'>X-Large event shirt - Black</option>
                <option value='2xl-black'>2X-Large event shirt - Black</option>
                <option value='3xl-black'>3X-Large event shirt - Black</option>
            </select>
			<label>Notes</label>
            <textarea value={notes} onChange={evt => setNotes(evt.target.value)}></textarea>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
            {worker &&
                <>
                    <hr/>
                    <label>Shifts</label>
                    {getWorkerShifts(worker.id).map(shift => {
                        const sched = getSchedule(shift.schedule);
                        return (
                            <div key={shift.id}>
                                {sched.name} {moment(shift.shiftDate).format('MM/DD/YY')}: {formatStartTime(shift.start)} - {shift.duration} Hours
                            </div>
                        )
                        })
                    }
                </>
            } 
        </div>
    )
}

export default WorkerDetails;
import React, {useEffect, useRef, useState} from 'react';
import Password from './password';
import Core from 'util/core';
import PasswordReset from './passwordReset';

function Username (props) {
    const [error, setError] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [username, setUsername] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, [])

	const keyUpHandler = (event) => {
		if (event.keyCode && event.keyCode === 13) {
			if (!isVerified) {
				verifyUsername();
			}
		}
    }
    const loginHandler = (token) => {
        props.onLogin(token);
    }
    const forgotPasswordHandler = () => {
        setPasswordReset(true);
    }
    const changeEmail = () => {
        setUsername('');
        setIsVerified(false);
    }
    /**
	 * verify the member username
	 */
	const verifyUsername = () => {
		let data = {'username': username};
        Core.runNoAuthAction('lookup_user', data, 
            (result, err) => {
                if (result) {
					setIsVerified(true);
                    setError(null);
				} else {
					setIsVerified(false)
                    setError(err);
				}
            });
	}
    return (
        
            <div>
                <div>
                    <label>Email Address</label>
                    <div className='input-container'>
                        {!isVerified ?
                        <input type='text' name='username' value={username} onChange={(evt) =>setUsername(evt.target.value)} 
                            onKeyUp={keyUpHandler} disabled={isVerified} ref={inputRef} />
                        : 
                            <div className='verified-username-container'>
                                <div className='verified-username'>{username}<button className="linkButton" style={{padding:'0'}} onClick={changeEmail}>Change</button></div>
                            </div>
                        }
                    </div>
                </div>
                {isVerified ?
                    passwordReset ? 
                        <PasswordReset username={username} onBack={() => setPasswordReset(false)} />
                    :
                        <Password 
                            username={username} 
                            onLogin={loginHandler} 
                            onForgotPassword={forgotPasswordHandler} 
                            onChangeEmail={changeEmail} />
                :
                    <div style={{marginTop:'10px'}}>
                        <button onClick={verifyUsername}>Next</button>
                    </div>
                }
                <div className='input-error'>
                    {error}
                </div>
            </div>
    )
}

export default Username;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const TimezonesContext = React.createContext(null);

function TimezonesProvider({children}) {
    const { runAction } = useCore();
    const [timezones, setTimezones] = useState([]);
    
    useEffect(() => {
        getTimezones();
    }, [])

    const getTimezones = () => {
        runAction('get_timezones', {},
            (response) => {
                if (response) {
                    setTimezones(response.timezones);
                }
            });
    }

    const provider = {
        timezones
    }

    return <TimezonesContext.Provider value={provider}>{children}</TimezonesContext.Provider>
}

function useTimezones() {
    const context = React.useContext(TimezonesContext);
    if (!context) {
        throw new Error('useTimezones must be used within a TimezonesProvider');
    }
    return context;
}

export { TimezonesProvider, useTimezones }
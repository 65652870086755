import React, { useState } from 'react';
import { useSetupAccount } from 'contexts/setupAccount';

function Email({onComplete}) {
    const { email, setEmail, validateEmail, emailValidated, verifyEmail, emailVerified, resetEmail } = useSetupAccount();
    const [code, setCode] = useState('');    

    const next = () => {
        if (!emailValidated) {
            validateEmail();
        } else if (!emailVerified) {
            verifyEmail(code);
        } else {
            onComplete();
        }
    }

    return (
        <div className='setupSlideBody'>
            <p>A valid email address is required and is used as your username to login to your account. 
                Enter your email address below then click the &quot;Verify Email Address&quot; button.
                We will send you an email with a verification code that you will need to provide in 
                the next step in order to proceed with the signup process.</p>
            <ul>
                <li>
                    <label>Email address (required)</label>
                    <input type='text' placeholder='Email address'
                        value={email} onChange={(evt) => setEmail(evt.target.value.toLocaleLowerCase())} maxLength='64' disabled={emailValidated} />
                </li>
                {emailValidated && !emailVerified &&
                    <li>
                        <label>Verification code (required)</label>
                        <input type='text' 
                            value={code} onChange={(evt) => setCode(evt.target.value)} placeholder='Verification code' />
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <button className='linkButton' onClick={resetEmail}>Change email address</button>
                            <button className='linkButton' onClick={validateEmail}>Resend code</button>
                        </div>
                    </li>
                }
            </ul>
            <div style={{textAlign:'center'}}>
                <button className='bigButton' onClick={next} disabled={email.length === 0}>
                    {emailVerified ? 'Go to Account Password' : 'Verify email address'}</button>
            </div>
        </div>
    )
}

export default Email;
import React, { useEffect, useState } from 'react'
import Checkbox from 'components/checkbox';
import CurrencyInput from 'react-currency-input-field';
import Models from './models';
import RegistrationSearch from './registrationSearch';
import PubSub from 'pubsub-js';
import { MakesAndModelsProvider } from 'contexts/makesAndModels';
import { useCore } from 'contexts/core';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';

function RegistrationDetails({classes, registration, event, onSave, onDelete}) {
    const { runAction } = useCore();
    const { tenant } = useAuth();
    const navigate = useNavigate();
    const [processed, setProcessed] = useState(false);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [typedmodel, setTypedmodel] = useState('');
    const [trim, setTrim] = useState('');
    const [color, setColor] = useState('');
    const [engine, setEngine] = useState('');
    const [car_class, setCar_class] = useState('');
    const [car_number, setCar_number] = useState('');
    const [additionalPasses, setAdditionalPasses] = useState(0);
    const [originalCarNumber, setOriginalCarNumber] = useState(null);
    const [notes, setNotes] = useState('');
    const [manualAssign, setManualAssign] = useState(false);

    useEffect(() => {
        let startingClass = '';
        if (classes && classes.length > 0) {
            startingClass = classes[0].id;
        }

        if (registration) {
            setProcessed(registration.processed);
            setFname(registration.fname);
            setLname(registration.lname);
            setAddress1(registration.address1);
            setAddress2(registration.address2);
            setCity(registration.city);
            setState(registration.state);
            setZip(registration.zip);
            setCountry(registration.country);
            setPhone(registration.phone);
            setEmail(registration.email);
            setYear(registration.year);
            setMake(registration.make);
            setModel(registration.model);
            setTypedmodel('');
            setTrim(registration.trim);
            setColor(registration.color);
            setEngine(registration.engine);
            setCar_class(registration.car_class);
            setCar_number(registration.car_number);
            setAdditionalPasses(registration.additionalPasses);
            setOriginalCarNumber(registration.car_number);
            setNotes(registration.notes);
        }
    }, [registration, classes])

    const setEntry = (entry) => {
        setProcessed(false);
        setFname(entry.fname);
        setLname(entry.lname);
        setAddress1(entry.address1);
        setAddress2(entry.address2);
        setCity(entry.city);
        setState(entry.state);
        setZip(entry.zip);
        setCountry(entry.country);
        setPhone(entry.phone);
        setEmail(entry.email);
        setYear(entry.year);
        setMake(entry.make);
        setModel(entry.model);
        setTypedmodel('');
        setTrim(entry.trim);
        setColor(entry.color);
        setEngine(entry.engine);
        setCar_class('');
        setCar_number('');
        setAdditionalPasses(0);
        setOriginalCarNumber('');
        setNotes('');
    }

    const getLocation = (evt) => {
        let zip = evt.target.value;
        if (zip.length === 5) {
            let data = {zip: zip};
            runAction('get_location', data, (response) => {
                if (response) {
                    setCity(response.city);
                    setState(response.state);
                }
            });
        }
    }

    const updateMakeAndModel = (make, model) => {
        setMake(make);
        setModel(model);
    }
    
    const carNumberKeyUpHandler = (val) => {
        if (val > 100) {
            let flooredVal = Math.floor(val / 100);
            let clazz = flooredVal * 100;
            let car_class = classes.find((c) => c.id === clazz);
            
            if (car_class) {
                setCar_class(car_class.id)
                setCar_number(val);
            }
        } else {
            setCar_number(val);
        }
    }

    const dashCard = (evt) => {
        const path = '/dashcard/' + tenant.uuid + '/' + event.id + '/' + car_number;
        navigate(path);
    }

    const saveHandler = () => {
        let data = {
            processed, fname, lname, address1, address2, city, state, zip, country, phone, email, originalCarNumber,
            year, make, model, typedmodel, trim, color, engine, car_class, car_number, additionalPasses, notes, event: event.id
        };
        if (data.car_class === '') {
            PubSub.publish('warning', 'Please choose a class for this registration');
        } else {
            onSave(data);
        }
    }

    const deleteHandler = () => {
        onDelete(car_number);
    }

    return (
        <div className='contact-preview'>
            {!originalCarNumber && 
                <RegistrationSearch eventId={event.id} onSelectEntry={setEntry} />
            }
            <label>Processed</label>
            <Checkbox checked={processed} 
                onChange={(val) => setProcessed(val)} />
            <hr />
            <label>First name</label>
            <input type='text' value={fname} 
                onChange={(evt) => setFname(evt.target.value)} maxLength='24' />
            <label>Last name</label>
            <input type='text' value={lname} 
                onChange={(evt) => setLname(evt.target.value)} maxLength='24' />
            <label>Address</label>
            <input type='text' value={address1} 
                onChange={(evt) => setAddress1(evt.target.value)} maxLength='48' />
            <label>City</label>
            <input type='text' value={city} 
                onChange={(evt) => setCity(evt.target.value)} maxLength='48' />
            <label>State/Province</label>
            <input type='text' value={state} 
                onChange={(evt) => setState(evt.target.value)} maxLength='2' />
            <label>Postal Code</label>
            <input type='text' value={zip} 
                onChange={(evt) => setZip(evt.target.value)} placeholder='12345' 
                maxLength='10' />
            <label>Country</label>
            <input type='text' value={country} 
                onChange={(evt) => setCountry(evt.target.value)} maxLength='64' />
            <label>Phone</label>
            <input type='text' value={phone} 
                onChange={(evt) => setPhone(evt.target.value)} maxLength='12' />
            <label>Email</label>
            <input type='text' value={email} 
                onChange={(evt) => setEmail(evt.target.value)} maxLength='48' />
            <hr />
            <label>Year</label>
            <input type='text' value={year} 
                onChange={(evt) => setYear(evt.target.value)} maxLength='24' />
            {event.typedMakeAndModel ?
                <>
                    <label>Make</label>
                    <input type='text' value={make} onChange={evt => setMake(evt.target.value)} maxLength='24' />
                    <label>Model</label>
                    <input type='text' value={model} onChange={evt => setModel(evt.target.value)} maxLength='64' />
                </>
                :
                <MakesAndModelsProvider eventId={event.id}>
                    <div style={{height:'15px'}}></div>
                    <Models eventId={event.id} make={make} model={model} onChange={updateMakeAndModel} />
                </MakesAndModelsProvider>
            }
            <label>Other</label>
            <input type='text' value={typedmodel} 
                onChange={(evt) => setTypedmodel(evt.target.value)} placeholder='Strangebird' maxLength='64' />
            <label>Trim</label>
            <input type='text' value={trim} 
                onChange={(evt) => setTrim(evt.target.value)} placeholder='Coupe' maxLength='64' />
            <label>Color</label>
            <input type='text' value={color} 
                onChange={(evt) => setColor(evt.target.value)} placeholder='Plum Crazy' maxLength='24' />
            <label>Engine</label>
            <input type='text' value={engine} 
                onChange={(evt) => setEngine(evt.target.value)} placeholder='426 Hemi' maxLength='10' />
            <hr />
            <label>Class</label>
            <select value={car_class} onChange={(evt) => setCar_class(evt.target.value)}>
                <option value=''>Choose a class...</option>
                {classes.map((c) => <option key={c.id} value={c.id}>{c.id + ":" + c.description}</option>)}
            </select>
            <label>Number</label>
            <CurrencyInput 
                allowDecimals={false} 
                allowNegativeValue={false}
                disableGroupSeparators={true} 
                value={car_number}
                onValueChange={carNumberKeyUpHandler}
                placeholder='(Auto Assigned)'  
                disabled={!manualAssign}
                maxLength='5' />
            <label>Manually Assign Number</label>
            <Checkbox checked={manualAssign} onChange={(val) => setManualAssign(val)} />
            <hr/>
            <label>Additional Passes</label>
            <CurrencyInput 
                allowDecimals={false} 
                allowNegativeValue={false}
                disableGroupSeparators={true} 
                value={additionalPasses}
                onValueChange={setAdditionalPasses}
                placeholder='0-10'  
                maxLength='2' />
            <hr />
            <label>Notes (1024 characters max)</label>
            <textarea value={notes} onChange={(evt) => setNotes(evt.target.value)} cols='75' rows='3' maxLength='1024'></textarea>
            <div className='buttonsHolder' style={{marginTop:'5px'}}>
                {car_number !== '' && <button onClick={dashCard}>Dash Card</button>}
                <button onClick={saveHandler}>Save</button>
                {car_number !== '' && <button onClick={deleteHandler}>Delete</button>}
            </div>
        </div>
    )
}

export default RegistrationDetails;

import React, { useState, useEffect } from 'react';


function CharacterCounter({text, max=1600}) {
    const [charCount, setCharCount] = useState(max);

    useEffect(() => {
        // set the character count
        let val = text.length;
        let msgNum = Math.floor((val / 160)) + 1;
        setCharCount((max - val) + " (" + msgNum + " of 10) ");
    }, [text])


    return (
        <div className='messageCharacterCounter'>{charCount}</div>
    )
}

export default CharacterCounter;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const FilesContext = React.createContext(null);

function FilesProvider({children}) {
    const { runAction } = useCore();
    const [filesLoading, setFilesLoading] = useState(true);
    const [folders, setFolders] = useState([]);
    const [media, setMedia] = useState([]);

    useEffect(() => {
        getMedia();
    }, [])

    const getMedia = () => {
        setFilesLoading(true);
        runAction('get_media', {}, 
            (response) => {
                if (response) {
                    //setBucket(response.bucket);
                    setFolders(response.folders);
                    setMedia(response.media);
                    setFilesLoading(false);
                }
            });
    }

    const updateMedia = (data, callback) => {
        runAction('update_media', data, 
            (response) => {
                if (response) {
                    getMedia();
                    callback();
                }
            });
    }

    const saveFolder = (data, callback) => {
        const action = data.id ? 'update_folder' : 'create_folder';
        runAction(action, data, response => {
            publish('success', 'Folder saved');
            getMedia();
            callback();
        })
    }

    const deleteFiles = (ids) => {
        const data = {ids};
        runAction('delete_media', data,
            (response) => {
                if (response) {
                    getMedia();
                }
            });
    }

    const deleteFolder = (folderId) => {
        runAction('delete_folder', {id: folderId}, response => {
            publish('success', `Folder deleted, ${response.filesMoved} file(s) moved`);
            getMedia();
        })
    }

    const moveToFolder = (filesIds, folderId, callback) => {
            const data = {ids: filesIds, folderId: folderId}
            runAction('move_to_folder', data, response => {
                publish('success', 'Files moved')
                getMedia();
                callback();
            })
        
    }

    const provider = {
        filesLoading,
        folders,
        media,
        getMedia,
        updateMedia,
        saveFolder,
        deleteFiles,
        deleteFolder,
        moveToFolder
    }

    return <FilesContext.Provider value={provider}>{children}</FilesContext.Provider>
}

function useFiles() {
    const context = React.useContext(FilesContext);
    if (!context) {
        throw new Error('useFiles must be used within a FilesProvider');
    }
    return context;
}

export { FilesProvider, useFiles }
import React, {useState, useEffect, useRef} from 'react'
import { useSettings } from '../../../contexts/settings';
import Core from '../../../util/core';


function WaitMusic(props) {
    const settings = useSettings();
    const [music, setMusic] = useState([]);
    const [musicUrl, setMusicUrl] = useState('');
    const mediaPlayer = useRef(null);

    useEffect(() => {
        Core.runAction('get_wait_music', {}, (response) => {
            setMusic(response.music);
        })
    }, []);

    const loadWaitMusic = (evt) => {
        let selectionId = parseInt(evt.target.value, 10);
        let selection = music.find((m) => m.id === selectionId);
        if (selection) {
            setMusicUrl(selection.url);
            mediaPlayer.current.load();
            updateSettings(selectionId);
        }
    }

    const updateSettings = (musicId) => {
        let data = {...settings.tenant, waitMusic: musicId}
        Core.runAction('update_tenant_settings', data, (response) => {
            //PubSub.publish('success', 'Settings successfully saved');
            settings.refreshTenantSettings();
        })
    }

    return (
        <div className="communication-area" style={{margin:'40px'}}>
            <p>Setup the music that callers hear while they are waiting to be connected to you.</p>
            <select onChange={loadWaitMusic} value={settings.tenant.waitMusic}>
                {music.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
            </select>
            <div style={{display:'flex',justifyContent:'center'}}>
                <audio style={{margin:'20px auto'}} controls ref={mediaPlayer}>
                    <source src={musicUrl} />
                </audio>
            </div>
        </div>
    )
}

export default WaitMusic
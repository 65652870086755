import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { usePermissions } from './permissions';

const ApiKeyContext = React.createContext(null);

function ApiKeyProvider({tenantId, apiKey, children}) {
    const { runAction } = useCore();
    const { permissions } = usePermissions();
    const [apikey, setApikey] = useState(null);
    const [secret, setSecret] = useState(null);
    const [keyPermissions, setKeyPermissions] = useState([]);

    useEffect(() => {
        if (apiKey) {
            setApikey(apiKey.key);
            let perms = [...permissions];
            perms.forEach(p => {
                p.enabled = apiKey.permissions.findIndex((kp) => kp.id === p.id) > -1;
            })
            setKeyPermissions(perms);
        } else {
            setApikey(null);
            let perms = [...permissions];
            perms.forEach(p => p.enabled = false);
            setKeyPermissions(perms);
        }
        setSecret(null);
    }, [apiKey])

    const saveKey = (callback) => {
        let perms = [];
        keyPermissions.forEach(p => {
            if (p.enabled) {
                perms.push(p.id);
            }
        })
        let data = {
            permissions: perms,
            tenant: tenantId //TODO: affiliate keys?
        }
        let action = 'create_api_keys';
        if (apikey) {
            action = 'update_api_key_permissions';
            data.key = apikey;
        }
        runAction(action, data, response => {
            if (response.apikey) {
                setApikey(response.apikey);
                setSecret(response.seckey)
            } else {
                callback();
            }
        })
    }

    const provider = {
        apikey,
        secret,
        keyPermissions, setKeyPermissions,
        saveKey
    }

    return <ApiKeyContext.Provider value={provider}>{children}</ApiKeyContext.Provider>
}

function useApiKey() {
    const context = React.useContext(ApiKeyContext);
    if (!context) {
        throw new Error('useApiKey must be used within an ApiKeyProvider');
    }
    return context;
}

export { ApiKeyProvider, useApiKey }
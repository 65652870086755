import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const LoginsContext = React.createContext(null);

function LoginsProvider({type, resourceId, children}) {
    const { runAction } = useCore();
    const [loginsLoading, setLoginsLoading] = useState(true);
    const [logins, setLogins] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        getLogins();
    }, [])

    const getLogins = () => {
        setLoginsLoading(true);
        if (type === 'admin') {
            getUsers();
            return;
        }
        let data = {type, includePermissions: true};
        switch(type) {
            case 'account': data.tenant = resourceId; break;
            case 'group': data.affiliate = resourceId; break;
            default: break;
        }
        runAction('get_logins', data, 
            (response) => {
                if (response) {
                    setLogins(response.logins);
                }
                setLoginsLoading(false);
            })
    }

    const getUsers = () => {
        runAction('get_users', {}, 
            (response) => {
                if (response) {
                    setLogins(response.users);
                }
                setLoginsLoading(false);
            })
    }

    const saveLogin = (data, callback) => {
        const action = data.id ? 'update_login' : 'create_login';
        data.type = type;
        switch(type) {
            case 'account': data.tenant = resourceId; break;
            case 'group': data.affiliate = resourceId; break;
            default: break;
        }
        setSaving(true);
        runAction(action, data,
            (response) => {
                if (response) {
                    setSaving(false);
                    publish('success', 'User login saved');
                    getLogins();
                    callback();
                }
            });
    }

    const deleteLogins = (logins) => {
        let data = {type, id: logins[0].user_id};
        switch(type) {
            case 'account': data.tenant = resourceId; break;
            case 'group': data.affiliate = resourceId; break;
            default: break;
        }
        runAction('delete_login', data,
            (response) => {
                if (response) {
                    getLogins();
                }
            })
    }

    const provider = {
        loginsLoading,
        logins,
        saving,
        getLogins,
        saveLogin,
        deleteLogins,
    }

    return <LoginsContext.Provider value={provider}>{children}</LoginsContext.Provider>
}

function useLogins() {
    const context = React.useContext(LoginsContext);
    if (!context) {
        throw new Error('useLogins must be used within a LoginsProvider');
    }
    return context;
}

export { LoginsProvider, useLogins }
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLink, faCircleNotch} from '@fortawesome/pro-regular-svg-icons';
import { publish } from 'pubsub-js';
import { useSetupAccount } from 'contexts/setupAccount';

function Finish({onComplete}) {
    const [display, setDisplay] = useState(0);
    const { signup } = useSetupAccount();

    const signupHandler = () => {
        setDisplay(1);
        
        signup((response, error) => {
            if (response) {
                switch (response.status) {
                    case 1: setDisplay(2); break;
                    case 2:
                    case 3: setDisplay(3); break;
                    case 4: setDisplay(4); break;
                    default: setDisplay(5); break;
                }
            } else {
                setDisplay(6);
                publish('error', 'Error:' + error);
            }
        });
    }

    return (
        <div className='setupSlideBody'>
            {display === 0 && 
                <div style={{textAlign:'center'}}>
                    <p style={{fontSize:'24px'}}>One last thing! In order to create an account you must accept our terms of use.</p>
                    <a href='http://www.middletwin.com/terms-of-use/' target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faExternalLink} /> Click Here to view terms of use</a>
                    <div style={{height:'100px'}}></div>
                    <button className='bigButton' onClick={signupHandler}>Accept Terms of Use and Create Account</button>
                </div>
            }
            {display === 1 &&
                <div style={{textAlign:'center',fontSize:'80px',margin:'20px 0'}}>
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                    <p style={{fontSize:'28px'}}>We are busy creating your account, this can take up to a minute to complete, so please be patient.</p>
                </div>
            }
            {display === 2 &&
                <div>
                    <p>Congratulations! You now have a Middletwin account. Your new account is currently being reviewed by an administrator, when your account has been approved 
                        you will receive an email to let you know that you can login.</p>
                    <p>Let us know if you have any questions by emailing our support team at <a href='mailto:support@middletwin.com'>support@middletwin.com</a>.</p>
                </div>
            }
            { display === 3 &&
                <div>
                    <p>Congratulations! You now have a Middletwin account. Your new account is a trial account. This means that you can do everything you normally would 
                    for the next 30 days. After your trial period, you will need to provide a payment method to continue to use your account.</p>
                    <p>After logging in for the first time you will be taken through our setup process where you will configure</p>
                    <ul>
                        <li>Your organizations portal</li>
                        <li>A payment method for your account</li>
                        <li>A link to your organizations bank for payouts (uses Stripe - can be setup later)</li>
                    </ul>
                    <p>Let us know if you have any questions by emailing our support team at <a href='mailto:support@middletwin.com'>support@middletwin.com</a>.</p>
                </div>
            }
            {display === 4 &&
                <div>
                    <p>Congratulations! You have given us all the details we needed to create an account for you. 
                        When your new account is ready, might take a few minutes, you will get an email. 
                        You will be able to use the email address and password that you already provided to login to the account.</p>
                    <p>Let us know if you have any questions by emailing our support team at <a href='mailto:support@middletwin.com'>support@middletwin.com</a>.</p>
                </div>
            }
            {display === 5 &&
                <div>
                    <p>Oops! Your account was created, however it appears that there may be a misconfiguration in the account. Please contact us at 
                    <a href='mailto:support@middletwin.com'>support@middletwin.com</a> to get this fixed. Note: Please include your name and email when contacting us.</p>
                    <p>Don't worry, we all make mistakes from time to time, this one is on us :-)</p>
                </div>
            }
            {display === 6 &&
                <div>
                    <p>Oops! Your account was not created, see the error message for more details. You can correct the issue and try again or contact us at 
                    <a href='mailto:support@middletwin.com'>support@middletwin.com</a> to get this fixed. Note: Please include your name and email when contacting us.</p>
                </div>
            }
            {display > 1 &&
                <div style={{textAlign:'center'}}>
                    <button className='bigButton' onClick={onComplete}>Finish</button>
                </div>
            }
        </div>
    )
}

export default Finish;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const VenueContext = React.createContext(null);

function VenueProvider({venue, children}) {
    const { runAction } = useCore();
    const [elements, setElements] = useState([]);
    const [selections, setSelections] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState(venue.backgroundImage);
    

    useEffect(() => {
        getElements();
    }, [])

    const getElements = () => {
        runAction('get_elements', {venue: venue.id}, (response) => {
            setElements(response.elements)
        });
    }

    const saveVenue = (venueName) => {
        const data = {id: venue.id, name: venueName, backgroundImage}
        runAction('update_venue', data, () => {
            let elemData = {venue: venue.id, elements: elements};
            runAction('update_elements', elemData, () => {
                publish('success', 'Venue saved');
                getElements();
            })
        })
    }

    const provider = {
        elements, setElements,
        saveVenue,
        selections, setSelections,
        backgroundImage, setBackgroundImage,
    }

    return <VenueContext.Provider value={provider}>{children}</VenueContext.Provider>
}

function useVenue() {
    const context = React.useContext(VenueContext);
    if (!context) {
        throw new Error('useVenue must be used within a venue context provider');
    }
    return context;
}

export { VenueProvider, useVenue }
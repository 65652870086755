import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Core from '../../util/core';
import AmazingTable from '../../components/amazingTable';
import SidePanel from 'components/sidePanel';
import ServiceDetails from './serviceDetails';
import { TableSetupProvider } from 'contexts/tableSetup';

export const formatFrequency = (frequency) => {
    const minute = (1000 * 60)
    const hour = (1000 * 60 * 60);
    if (frequency <= minute) {
        return (frequency / 1000) + ' Seconds';
    }
    if (frequency <= hour) {
        return (frequency / minute) + ' Minutes';
    }
    return (frequency / hour) + ' Hours';
}

const serviceColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'type', label: 'Service Id', display: false, datum: 'type'},
    {id: 'label', label: 'Service Type', display: true, datum: 'label'},
    {id: 'lastrun', label: 'Last Run', display: true, formatter: obj => moment(obj.lastRun).format('YYYY-MM-DD hh:mm:ss')},
    {id: 'frequency', label: 'Frequency', display: true, formatter: obj => formatFrequency(obj.frequency)},
    {id: 'enabled', label: 'Enabled', display: true, formatter: obj => obj.enabled ? 'Yes' : 'No'}
];

function Services(props) {
    const [types, setTypes] = useState([]);
    const [services, setServices] = useState([]);
    const [service, setService] = useState(null);
    const servicePanel = useRef(null);

    useEffect(() => {
        Core.runAction('get_service_types', {}, response => {
            const serviceSort = (a, b) => {
                return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
            }
            setTypes(response.types.sort(serviceSort));
        })
    }, [])

    useEffect(() => {
        getServices();
    }, [])

    const getServices = () => {
        Core.runAction('get_services', {}, response => {
            const serviceSort = (a, b) => {
                return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
            }
            setServices(response.processes.sort(serviceSort));
        })
    }

    const createService = () => {
        setService(null);
        servicePanel.current.show();
    }

    const removeServices = (svcs) => {
        let data = {ids: svcs.map(svc => svc.id)};
        Core.runAction('remove_services', data, response => {
            getServices();
        })
    }

    const editService = (svc) => {
        setService(svc);
        servicePanel.current.show();
    }

    const updateService = (svc) => {
        const action = svc.id ? 'update_service' : 'create_service';
        Core.runAction(action, svc, response => {
            setService(null);
            servicePanel.current.hide();
            getServices();
        })
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Services</div>
                </div>
            </div>
            <TableSetupProvider tableName='service' defaultColumns={serviceColumns}>
                <AmazingTable 
                    rows={services}
                    onRefresh={getServices}
                    onCreate={createService}
                    onDelete={removeServices}
                    onRowClick={editService}
                />
            </TableSetupProvider>
            <SidePanel title='Service' ref={servicePanel}>
                <ServiceDetails service={service} types={types} onSave={updateService} />
            </SidePanel>
        </div>
    )
}

export default Services;
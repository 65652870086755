import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const PaymentHistoryContext = React.createContext(null);

function PaymentHistoryProvider({memberId=null, children}) {
    const { runAction } = useCore();
    const [historyLoading, setHistoryLoading] = useState(true);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        getPaymentHistory();
    }, [])

    const getPaymentHistory = () => {
        setHistoryLoading(true);
        const data = memberId ? {memberId} : {};
        runAction('stripe_get_payment_history', data, (response) => {
            let charges = response.paymenthistory;
            setHistory(charges.map((chargeJson) => JSON.parse(chargeJson)));
            setHistoryLoading(false);
        })
    }

    const provider = {
        historyLoading,
        history,
        getPaymentHistory
    }

    return <PaymentHistoryContext.Provider value={provider}>{children}</PaymentHistoryContext.Provider>
}

function usePaymentHistory() {
    const context = React.useContext(PaymentHistoryContext);
    if (!context) {
        throw new Error('usePaymentHistory must be used within a paymentHistoryProvider');
    }
    return context;
}

export { PaymentHistoryProvider, usePaymentHistory }
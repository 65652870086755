import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import Toggle from 'components/toggle';
import moment from 'moment';
import PricingTeirs from './pricingTeirs';
import PubSub from 'pubsub-js';
import { PricingTeirsProvider } from 'contexts/pricingTeirs';

function MemberTypeDetails({memberType, onSave}) {
    const [id, setId] = useState(null);
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [purchase, setPurchase] = useState(true);
    const [childrenAllowed, setChildrenAllowed] = useState(false);
    const [anniversaryRenewal, setAnniversaryRenewal] = useState(false);
    const [anniversaryDate, setAnniversaryDate] = useState('');
    const [teiredPricing, setTeiredPricing] = useState(false);

    useEffect(() => {
        if (memberType) {
            setId(memberType.id);
            setLabel(memberType.label);
            setDescription(memberType.description);
            setPrice(memberType.price);
            setPurchase(memberType.purchase);
            setChildrenAllowed(memberType.allowChildren);
            setAnniversaryRenewal(memberType.anniversaryRenewal);
            setAnniversaryDate(memberType.anniversaryDate ? moment(memberType.anniversaryDate).format('MM/DD') : '');
            setTeiredPricing(memberType.teiredPricing);
        }
    }, [memberType])

    const save = () => {
        let data = {
            label,
            description,
 			price,
 			purchase,
 			allowChildren: childrenAllowed,
 			anniversaryRenewal: anniversaryRenewal,
 			anniversaryDate: anniversaryDate,
 			teiredPricing: teiredPricing,
        }
        // check the anniversary date
        if (data.anniversaryRenewal) {
            let adate = anniversaryDate;
            let matches = adate.match(/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])/g);
            if (matches && matches.length === 1) {	
                let adateParts = adate.split('/');
                if (adateParts.length === 2) {
                    data.anniversaryDate = moment().month(adateParts[0] - 1).date(adateParts[1]).valueOf();
                }
            } else {
                PubSub.publish('warning', 'Please enter an anniversary date in the form of month (01-12) / date (01-31).');
                return;
            }
        } else {
            data.anniversaryDate = null;
        }
        if (id !== null) {
            data.id = id;
        }
        onSave(data);
    }

    return(
        <div className='settings-panel'>
            <div className='contact-settings-panel'>
                <h4>Basic Info</h4>
                <hr/>
                <h5>Name</h5>
                <input type='text' style={{width:'340px'}} value={label} onChange={evt => setLabel(evt.target.value)} maxLength='64' />
                <h5>Description</h5>
                <textarea style={{width:'340px',height:'100px'}} value={description} onChange={evt => setDescription(evt.target.value)} />
                <h5>Price</h5>
                <CurrencyInput prefix='$' style={{width:'340px'}} value={price} onValueChange={(value) => setPrice(value)} />
                <div className='toggle-setting'>
                    <Toggle checked={purchase} onChange={(val) => setPurchase(val)} /><h5>Can be Purchased</h5>
                </div>
                <div className='toggle-setting'>
                    <Toggle checked={childrenAllowed} onChange={(val) => setChildrenAllowed(val)} /><h5>Members can add others</h5>
                </div>
                <div className='spacer' />
                <h4>Annual Renewal</h4>
                <hr/>
                <p>To have all memberships of this type renew on the same day annually, you must enable annual renewal and setup pricing tiers.</p>
                <div className='toggle-setting'>
                    <Toggle checked={anniversaryRenewal} onChange={(val) => setAnniversaryRenewal(val)} /><h5>Annual renewal</h5>
                </div>
                <h5>Annual renewal day (MM/DD)</h5>
                <input type='text' style={{width:'340px'}} 
                    value={anniversaryDate} 
                    onChange={evt => setAnniversaryDate(evt.target.value)} 
                    disabled={!anniversaryRenewal} 
                    placeholder='MM/DD' 
                    maxLength='5' />
                <div className='spacer' />

                <h4>New member rules</h4>
                <hr/>
                <div className='toggle-setting'>
                    <Toggle checked={teiredPricing} onChange={val => setTeiredPricing(val)} /><h5>Use new member rules</h5>
                </div>
                <p>You will need to set up a rule for every month of the year, 
                        this will override the price above for new member signups.
                        The price above will apply to renewals.</p>
                <h5>Rules</h5>
                {id ? <PricingTeirsProvider memberTypeId={id}><PricingTeirs /></PricingTeirsProvider> 
                    : <div>Save Member Type before adding rules.</div>
                }
                    
            </div>
            <div className='button-container'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default MemberTypeDetails;
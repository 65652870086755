import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const EventContext = React.createContext(null);

function EventProvider({eventId, children}) {
    const [eventLoading, setEventLoading] = useState(true);
    const [event, setEvent] = useState();
    const [venue, setVenue] = useState();
    const { runAction } = useCore();

    useEffect(() => {
        loadEvent();
    }, [])

    const loadEvent = () => {
        setEventLoading(true);
        runAction('get_event', {event: eventId}, response => {
            setEvent(response.event);
            setVenue(response.venue);
            setEventLoading(false);
        })
    }

    const updateEvent = (data, callback) => {
        runAction('update_event', data, response => {
            publish('success', 'Event saved.');
            callback();
        });
    }

    const provider = {
        loadEvent,
        eventLoading,
        event, venue,
        updateEvent,
    }

    return <EventContext.Provider value={provider}>{children}</EventContext.Provider>
}

function useEvent() {
    const context = React.useContext(EventContext);
    if (!context) {
        throw new Error('useEvent must be used within an EventProvider');
    }
    return context;
}

export { EventProvider, useEvent }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import moment from 'moment';

const AuditLogContext = React.createContext(null);

function AuditLogProvider({children}) {
    const { runAction } = useCore();
    const [auditLogLoading, setAuditLogLoading] = useState(true);
    const [auditLog, setAuditLog] = useState();
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        getAuditLog();
    }, [date])

    const getAuditLog = () => {
        setAuditLogLoading(true);
        const data = {
            from: moment(date).hour(0).minute(0).second(0).valueOf(), 
            to: moment(date).hour(23).minute(59).second(59).valueOf()}
        runAction('get_audit_log', data, response => {
            setAuditLog(response.data);
            setAuditLogLoading(false);
        });
    }

    const provider = {
        auditLogLoading,
        auditLog,
        getAuditLog,
        date,
        setDate
    }

    return <AuditLogContext.Provider value={provider}>{children}</AuditLogContext.Provider>
}

function useAuditLog() {
    const context = React.useContext(AuditLogContext);
    if (!context) {
        throw new Error('useAuditLog must be used within an AuditLogProvider');
    }
    return context;
}

export { AuditLogProvider, useAuditLog }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const GroupContext = React.createContext(null);

function GroupProvider({children, id}) {
    const { runAction } = useCore();
    const [data, setData] = useState(null);

    useEffect(() => {
        getSettings();
    }, [])

    const getSettings = () => {
        const data = {uuid: id};
        runAction('get_affiliates', data, response => {
            setData(response.affiliate);
        })
    }

    const setSettings = (settings) => {
        runAction('update_affiliate', settings, response => {
            getSettings();
        });
    }

    const provider = {
        data,
        getSettings,
        setSettings,
    };

    return <GroupContext.Provider value={provider} >{children}</GroupContext.Provider>
}

function useGroup() {
    const context = React.useContext(GroupContext);
    if (!context) {
        throw Error('useGroup must be used with a GroupProvider');
    }
    return context;
}

export { GroupProvider, useGroup };
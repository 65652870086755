import { useEvents } from 'contexts/events';
import { SchedulesProvider, useSchedules } from 'contexts/schedules';
import { ShiftsProvider, useShifts } from 'contexts/shifts';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

function CopySchedule({eventId, onCopy}) {
    const { events } = useEvents();
    const { copySchedule } = useSchedules();
    const [sourceEventId, setSourceEventId] = useState(0);
    const [sourceSchedule, setSourceSchedule] = useState(null);
    const [name, setName] = useState('');
    const [shiftDates, setShiftDates] = useState([]);

    useEffect(() => {
        setSourceSchedule(null);
        setName('');
    }, [sourceEventId])

    useEffect(() => {
        if (sourceSchedule != null) {
            setName(sourceSchedule.name);
        }
    }, [sourceSchedule]);

    const copy = () => {
        const data = {
            eventId,
            sourceEventId,
            sourceScheduleId: sourceSchedule.id,
            name,
            shiftDates,
        }
        copySchedule(data, () => {
            setSourceEventId(0);
            setSourceSchedule(null);
            setName('');
            setShiftDates([]);
            onCopy();
        })
    }

    return (
        <div className='contact-preview'>
            <p>New: select an event and then select a schedule from the chosen event to create a new schedule that is a copy within this event. You can also specify replacement dates for the shifts in the schedule.</p>
            <p>Workers will not be copied.</p>
            <label>Event to copy schedule from</label>
            <select value={sourceEventId} onChange={e => setSourceEventId(e.target.value)} >
                <option value={0}>-Select Event-</option>
                {events.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
            </select>
            {sourceEventId != 0 &&
                <>
                    <label>Schedule to copy</label>
                    <SchedulesProvider eventId={sourceEventId}>
                        <ScheduleList value={sourceSchedule} onChange={setSourceSchedule} />
                    </SchedulesProvider>
                    {sourceSchedule != null &&
                        <>
                            <label>New schedule name</label>
                            <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength='64' />
                            <ShiftsProvider eventId={sourceEventId}>
                                <ShiftDates scheduleId={sourceSchedule.id} value={shiftDates} onChange={setShiftDates} />
                            </ShiftsProvider>
                            <div className='buttonsHolder'>
                                <button onClick={copy}>Copy</button>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    )
}

function ScheduleList({value, onChange}) {
    const { schedules } = useSchedules();

    const onChangeHandler = (event) => {
        const schedId = event.target.value;
        if (schedId != 0) {
            const schedule = schedules.find(sched => sched.id == schedId);
            onChange(schedule);
        } else {
            onChange(null);
        }
    }

    return (
        <select value={value === null ? 0 : value?.id} onChange={onChangeHandler}>
            <option value={0}>-Select Schedule-</option>
            {schedules.map(sched => 
                <option key={sched.id} value={sched.id}>{sched.name}</option>
            )}
        </select>
    )
}

function ShiftDates({scheduleId, value, onChange}) {
    const { allShifts, getScheduleShifts } = useShifts();
    
    useEffect(() => {
        if (allShifts.length > 0) {
            const shifts = getScheduleShifts(scheduleId);
            const scheduleShiftDates = [];
            shifts.forEach(shift => {
                const shiftDate = moment(shift.shiftDate).format('YYYY-MM-DD');
                if (scheduleShiftDates.findIndex(o => o.source === shiftDate) === -1) {
                    scheduleShiftDates.push({source: shiftDate, target: shiftDate});
                }
            })
            onChange(scheduleShiftDates);
        }
    }, [allShifts])

    const setTargetDate = (index, date) => {
        const obj = {...value[index]};
        obj.target = moment(date).format('YYYY-MM-DD');
        onChange(value.toSpliced(index, 1, obj));
    }

    return (
        <>
            {value.map((sd, idx) => 
                <span key={idx}>
                    <label>Replace shift date {sd.source} with</label>
                    <DatePicker selected={moment(sd.target).toDate()} onChange={date => setTargetDate(idx, date)} />
                </span>
            )}
        </>
    )
}

export default CopySchedule;
import React, {useEffect, useState} from 'react';
import { publish } from 'pubsub-js';
import { useAuth } from 'contexts/auth';
import { CountriesProvider } from 'contexts/countries';
import Countries from 'components/countries';
import 'style/options.scss';
import { useTenant } from 'contexts/tenant';

function Account() {
    const { refresh } = useAuth();
    const { tenant, saveTenant } = useTenant();
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('')
    const [isoCountry, setIsoCountry] = useState('');

    useEffect(() => {
        if (tenant) {
            setName(tenant.name);
            setContact(tenant.contact);
            setEmail(tenant.email);
            setPhone(tenant.phone);
            setAddress1(tenant.address1);
            setAddress2(tenant.address2);
            setCity(tenant.city);
            setState(tenant.state);
            setZip(tenant.zip);
            setIsoCountry(tenant.isoCountry);
        }
    }, [tenant])


    const saveHandler = () => {
        const data = {tenant_id: tenant.id, status: tenant.status, 
            name, contact, email, phone, address1, address2, city, state, zip, isoCountry}
        saveTenant(data, () => {
            publish('success', 'Settings successfully saved');
            refresh(null);
        })
    }

    return (
        <div className='settings-panel' style={{padding:'20px 40px'}}>
            
            <h4>Name and Contact</h4>
            <hr/>
            <h5>Name</h5>
            <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength="64"/>
            <h5>Contact</h5>
            <input type='text' value={contact} onChange={e => setContact(e.target.value)} maxLength="64"/>
            <h5>Email address</h5>
            <input type='email' value={email} onChange={e => setEmail(e.target.value)} maxLength="64"/>
            <h5>Phone</h5>
            <input type='tel' value={phone} onChange={e => setPhone(e.target.value) }/>
        
            <h4>Location</h4>
            <hr/>
            <h5>Address</h5>
            <input type='text' value={address1} onChange={e => setAddress1(e.target.value)} maxLength="64" style={{width:'200px'}}/>
            <input type='text' value={address2} onChange={e => setAddress2(e.target.value)} maxLength="64" style={{width:'200px'}}/>
            <h5>City</h5>
            <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength="64"/>
            <h5>State</h5>
            <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength="64"/>
            <h5>Postal code</h5>
            <input type='text' value={zip} onChange={e => setZip(e.target.value)} maxLength="64"/>
            <h5>Country</h5>
            <CountriesProvider>
                <Countries value={isoCountry} onChange={e => setIsoCountry(e.target.value)} />
            </CountriesProvider>
            
            <div className='button-container'>
                <button onClick={saveHandler}>Save</button>
            </div>
        </div>
    )
}

export default Account;
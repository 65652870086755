import { faCircle, faEllipsisH, faPlus, faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import PubSub from 'pubsub-js';

function FocusedChatRoom(props) {
    const auth = useAuth();
    const [user, setUser] = useState(props.room);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        let chatActivitySub = PubSub.subscribe('chatactivity', (topic, message) => {
            const senderId = parseInt(message.senderId);
            if (senderId === user.user_id) {
                setIsTyping(message.body === 'typing');
            }
        });
        return () => {
            PubSub.unsubscribe(chatActivitySub);
        }
    }, [isTyping])

    useEffect(() => {
        if (props.room.members && props.room.members.length > 0) {
            props.room.members.forEach(usr => {
                if (usr.user_id !== auth.user.user_id) {
                    setUser(usr);
                }
            });
        }
    }, [props.room]);

    const getAvitar = () => {
        if (user.avatar === null || user.avatar.length === 0) {
            return <div className='char'>{user.name !== null && user.name.length > 0 ? user.name.charAt(0) : '?'}</div>
        } else {
            return <img src={user.avatar} />
        }
    }

    const editHandler = (evt) => {
        evt.preventDefault();
        props.onEdit();
    }

    return (
        <div className={'chat-room-item' + (!props.room.active ? ' inactive' : '')} onClick={props.onClick}>
            <div className={'status ' + user.status}>
		        <FontAwesomeIcon icon={faCircle} ally-label='status' />
            </div>
            <div className='avatar'>
                {getAvitar()}
            </div>
            {props.count && <div className='count'>{props.room.unread}</div>}
            {props.notification && <div className='notification'><FontAwesomeIcon icon={faCircle} /></div>}
            <div className='name'>{user.name}</div>
            {props.onEdit && <div className='editBtn' onClick={editHandler}><FontAwesomeIcon icon={faPencil} role='button' /></div>}
            {isTyping && <div className='activity'><FontAwesomeIcon icon={faEllipsisH} /></div>}
        </div>
    )
}

export default FocusedChatRoom;
import React, {useState} from 'react';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function Promotion({promo, onSave}) {
    const [id] = useState(promo.id || null)
    const [name, setName] = useState(promo.name);
    const [code, setCode] = useState(promo.code);
    const [amount, setAmount] = useState(promo.amount);
    const [mustspend, setMustspend] = useState(promo.mustspend);
    const [start, setStart] = useState(promo.startdate);
    const [end, setEnd] = useState(promo.enddate);

    const save = () => {
        let data = {
            name: name,
            code: code,
            amount: amount,
            mustspend: mustspend,
            startdate: start,
            enddate: end
        }
        if (id !== null) {
            data.id = id;
        }
        onSave(data)
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Name</label>
                <input type='text' value={name} onChange={evt => setName(evt.target.value)} placeholder='Required' maxLength='64' />
                <label>Code</label>
                <input type='text' value={code} onChange={evt => setCode(evt.target.value)} placeholder='Required' maxLength='12' />
                <label>Discount Amount</label>
                <CurrencyInput prefix='$' value={amount} onValueChange={(value) => setAmount(value)} />
                <label>Required Spending Amount</label>
                <CurrencyInput prefix='$' value={mustspend} onValueChange={(value) => setMustspend(value)} />
                <label>Start</label>
                <DatePicker selected={moment(start).toDate()} onChange={d => setStart(d.getTime())}/>
                <label>End</label>
                <DatePicker selected={moment(end).toDate()} onChange={d => setEnd(d.getTime())}/>
            </div>
            <div className='buttonsHolder'>
                <button onClick={save}>Save</button>
            </div>
        </div>
    )
}

export default Promotion;
import React, { useEffect } from 'react';
import { useToken } from 'contexts/token';
import { useNavigate } from 'react-router-dom';
import Container from 'container/container';
import { AuthProvider } from 'contexts/auth';
import { AppsProvider } from 'contexts/apps';
import Loading from 'components/loading';
import 'style/main.scss';

function AdminRoot() {
    const { tokenLoading, token } = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (!tokenLoading) {
            if (!token) {
                navigate('/login');
            } else {
                //navigate('/admin');
            }
        }
    }, [tokenLoading])

    return (
        <div>
            {tokenLoading ? <Loading /> :
                <AuthProvider>
                    <AppsProvider>
                        <Container />
                    </AppsProvider>
                </AuthProvider>
            }
        </div>
    )
}

export default AdminRoot
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const TransfersContext = React.createContext(null);

function TransfersProvider({children}) {
    const { runAction } = useCore();
    const [loadingTransfers, setLoadingTransfers] = useState(true);
    const [transfers, setTransfers] = useState([]);

    useEffect(() => {
        getTransfers();
    }, [])

    const getTransfers = () => {
        setLoadingTransfers(true);
        runAction('stripe_get_transfers', {}, response => {
            const accountTransfers = response.transfers;
            setTransfers(accountTransfers.map((acctTransfer) => JSON.parse(acctTransfer)));
            setLoadingTransfers(false);
        });
    }
    
    const provider = {
        loadingTransfers,
        transfers,
        getTransfers,
    }

    return <TransfersContext.Provider value={provider}>{children}</TransfersContext.Provider>
}

function useTransfers() {
    const context = React.useContext(TransfersContext);
    if (!context) {
        throw new Error('useTransfers must be used within a TransfersContext');
    }
    return context;
}

export { TransfersProvider, useTransfers }
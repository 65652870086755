import AmazingTable from 'components/amazingTable';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useTransfers } from 'contexts/transfers';
import React from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';

const transferColumns = [
    {id: 'id', label: 'ID', display: true, datum: 'id'},
    {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created*1000).format('YYYY-MM-DD')},
    {id: 'amount', label: 'Amount', display: true, formatter: obj => formatCurrency(obj.amount/100)},
]

function Transfers() {
    const { loadingTransfers, transfers } = useTransfers();

    return (
        <TableSetupProvider tableName='transfers' defaultColumns={transferColumns}>
            <AmazingTable 
                loading={loadingTransfers}
                rows={transfers}
            />
        </TableSetupProvider>
    )
}

export default Transfers
import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';
import { useCore } from './core';

const ChannelContext = React.createContext(null);

function ChannelProvider({children, channel}) {
    const { runAction } = useCore();
    const auth = useAuth();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        getMessages();
    }, [channel])

    const getMessages = () => {
        let data = {affiliate: auth.group.id, channel: channel.id, user: auth.user.user_id, limit: 100, offset: 0}
        runAction('get_chat_messages', data, response => {
            // response.limit
            setMessages(response.messages);
        })
    }

    const provider = {
        channel,
        messages,
        getMessages
    }

    return <ChannelContext.Provider value={provider}>{children}</ChannelContext.Provider>
}

function useChannel() {
    const context = React.useContext(ChannelContext);
    if (!context) {
        throw new Error('useChannel must be used within a ChannelProvider');
    }
    return context;
}

export { ChannelProvider , useChannel }
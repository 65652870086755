import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import Toggle from '../../components/toggle';

function Pattern(props) {
    const auth = useAuth();
    const [name, setName] = useState('');
    const [pattern, setPattern] = useState('');
    const [holdMessage, setHoldMessage] = useState(false);
    const [rejectMessage, setRejectMessage] = useState(false);

    useEffect(() => {
        if (props.pattern) {
            setName(props.pattern.name);
            setPattern(props.pattern.pattern);
            setHoldMessage(props.pattern.holdMessage);
            setRejectMessage(props.pattern.rejectMessage);
        } else {
            setName('');
            setPattern('');
            setHoldMessage(false);
            setRejectMessage(false);
        }
    }, [props.pattern])

    const save = () => {
        let data = {groupid: auth.group.id, name, pattern, holdMessage, rejectMessage};
        if (props.pattern) {
            data.id = props.pattern.id;
        }
        props.onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Name</label>
                <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
                <label>Pattern</label>
                <input type='text' value={pattern} onChange={evt => setPattern(evt.target.value)} maxLength='128' />
                <label>Hold Message</label>
                <Toggle checked={holdMessage} onChange={val => setHoldMessage(val)} />
                <label>Reject Message</label>
                <Toggle checked={rejectMessage} onChange={val => setRejectMessage(val)} />
            </div>
            <div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
        </div>
    )
}

export default Pattern;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const WorkersContext = React.createContext(null);

function WorkersProvider({eventId, children}) {
    const { runAction } = useCore();
    const [workersLoading, setWorkersLoading] = useState(true);
    const [workers, setWorkers] = useState([]);

    useEffect(() => {
        getWorkers();
    }, [])

    const getWorkers = () => {
        setWorkersLoading(true);
        let data = {eventId}
        runAction('get_all_volunteers', data, (response) => {
            setWorkers(response.workers);
            setWorkersLoading(false);
        });
    }

    const getWorker = (workerId) => {
        return workers.find(w => w.id === workerId);
    }

    const saveWorker = (data, callback) => {
        let action = data.id ? 'update_volunteer' : 'create_volunteer';
        runAction(action, data, response => {
            getWorkers();
            callback();
        })
    }

    const deleteWorkers = (ids) => {
        runAction('delete_volunteer', {ids}, () => {
            getWorkers();
        })
    }

    const provider = {
        workersLoading,
        eventId,
        workers,
        reload: () => getWorkers(),
        getWorker,
        saveWorker,
        deleteWorkers,
    }

    return <WorkersContext.Provider value={provider}>{children}</WorkersContext.Provider>
}

function useWorkers() {
    const context = React.useContext(WorkersContext);
    if (!context) {
        throw new Error('useWorker must be used within a WorkerContext provider');
    }
    return context;
}

export { WorkersProvider, useWorkers }
import React, { useEffect, useState } from 'react';
import Core from '../../util/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '../../contexts/auth';
import Loading from '../../components/loading';

function GroupDashboard() {
    const auth = useAuth();
    const [stats, setStats] = useState(null);
    const [inboundMsgTotal, setInboundMsgTotal] = useState(0);
    const [outboundMsgTotal, setOutboundMsgTotal] = useState(0);

    useEffect(() => {
        if (auth.group) {
            getStats();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.group])

    const getStats = () => {
        Core.runAction('get_affiliate_stats', {affiliateId: auth.group.id}, response => {
            setStats({...response});
            let inboundTotal = 0, outboundTotal = 0;
            response.messageStats.forEach(stat => {
                if (stat.type === 'comm.sms.inbound') {
                    inboundTotal += stat.value;
                } else {
                    outboundTotal += stat.value;
                }
            });
            setInboundMsgTotal(inboundTotal);
            setOutboundMsgTotal(outboundTotal);
        });
    }

    const accountDetails = () => {}

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Dashboard</div>
                </div>
            </div>
            {stats ?
                <div className='analytic-dashboard'>
                    <div className='analytic-panels_row'>
                        <div className='analytic-group'>
                            <div className='analytic-title'><FontAwesomeIcon icon={faUsers} /> Account</div>
                            <div className='analytic live' onClick={accountDetails}>
                                <div className='analytic-data'>{stats.activeAccounts}</div>
                                <div className='analytic-label'>Active</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.inactiveAccounts}</div>
                                <div className='analytic-label'>Inactive</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.trialAccounts}</div>
                                <div className='analytic-label'>Trial</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.reviewAccounts}</div>
                                <div className='analytic-label'>Review</div>
                            </div>
                        </div>
                        <div className='analytic-group'>
                            <div className='analytic-title'><FontAwesomeIcon icon={faUsers} /> Totals</div>
                            <div className='analytic live' onClick={accountDetails}>
                                <div className='analytic-data'>{stats.tenants}</div>
                                <div className='analytic-label'>Accounts</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.users}</div>
                                <div className='analytic-label'>Users</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.addnlLogins}</div>
                                <div className='analytic-label'>Additional Logins</div>
                            </div>
                        </div>
                        <div className='analytic-group'>
                            <div className='analytic-title'><FontAwesomeIcon icon={faUsers} /> Add-ons</div>
                            <div className='analytic live' onClick={accountDetails}>
                                <div className='analytic-data'>{stats.voiceCount}</div>
                                <div className='analytic-label'>Voice</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.recordCount}</div>
                                <div className='analytic-label'>Record</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{stats.transcribeCount}</div>
                                <div className='analytic-label'>Transcribe</div>
                            </div>
                        </div>
                        <div className='analytic-group'>
                            <div className='analytic-title'><FontAwesomeIcon icon={faUsers} /> Messaging</div>
                            <div className='analytic-subtitle'>(*in the last 7 days)</div>
                            <div className='analytic live' onClick={accountDetails}>
                                <div className='analytic-data'>{inboundMsgTotal}</div>
                                <div className='analytic-label'>Inbound</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{outboundMsgTotal}</div>
                                <div className='analytic-label'>Outbound</div>
                            </div>
                            <div className='analytic'>
                                <div className='analytic-data'>{outboundMsgTotal > 0 ? Math.round((outboundMsgTotal / 7)) : 0}</div>
                                <div className='analytic-label'>Avg Outbound</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loading />
            }
        </div>
    )
}

export default GroupDashboard;
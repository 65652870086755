import React, { useEffect, useRef } from 'react';

function Participant(props) {
    const tracksDiv = useRef(null);

    useEffect(() => {
        setup();
        return () => {
            teardown();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.participant])

    const teardown = () => {
       /* props.participant.tracks.forEach(publication => {
            const attachedElements = publication.track.detach();
            attachedElements.forEach(elem => elem.remove());
            //publication.unpublish();
            publication.track.stop();
        });*/
    }

    const setup = () => {
        props.participant.on('disconnected', participant => {
            console.log(`the participant has disconnected ${participant.identity}`);
        });
        props.participant.on('networkQualityLevelChanged', (qualityLevel, qualityStats) => {
            console.log(`network quality level changed`);
        });
        props.participant.on('reconnected', () => {
            console.log(`participant is reconnected`);
        });
        props.participant.on('reconnecting', () => {
            console.log(`participant is reconnecting`);
        });
        props.participant.on('trackDimensionsChanged', track => {
            console.log(`participant track dimensions have changed`);
        });
        props.participant.on('trackStarted', track => {
            console.log(`participant track has started`);
            tracksDiv.current.appendChild(track.attach());
        });
        props.participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
                const track = publication.track;
                tracksDiv.current.appendChild(track.attach());
            }
        })
    }

    return (
        <React.Fragment>
            <div>{props.participant.identity}</div>
            <div style={{border:'1px solid'}}>
                <div style={{width:'640px',height:'480px'}} ref={tracksDiv}></div>
            </div>
        </React.Fragment>
    )
}

export default Participant;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SubscriptionInvoiceItemsContext = React.createContext(null);

function SubscriptionInvoiceItemsProvider({invoiceId, children}) {
    const { runAction } = useCore();
    const [itemsLoading, setItemsLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getItems();
    }, [])

    const getItems = () => {
        setItemsLoading(true);
        runAction('get_sub9_invoice_items', {invoiceId}, response => {
            setItems(response.items);
            setItemsLoading(false);
        })
    }

    const saveItem = (invoiceItem, callback) => {
        const action = invoiceItem.id ? 'update_sub9_invoice_item' : 'create_sub9_invoice_item';
        const data = {subscriptionInvoiceId: invoiceId, ...invoiceItem}
        runAction(action, data, () => {
            getItems();
            callback();
        })
    }

    const deleteItem = (itemId) => {
        runAction('delete_sub9_invoice_item', {id: itemId}, () => {
            getItems();
        })
    }

    const provider = {
        itemsLoading,
        items,
        getItems,
        saveItem,
        deleteItem
    }

    return <SubscriptionInvoiceItemsContext.Provider value={provider}>{children}</SubscriptionInvoiceItemsContext.Provider>
}

function useSubscriptionInvoiceItems() {
    const context = React.useContext(SubscriptionInvoiceItemsContext);
    if (!context) {
        throw new Error('useSubscriptionInvoiceItems must be used within a SubscriptionInvoiceItemsProvider');
    }
    return context;
}

export { SubscriptionInvoiceItemsProvider, useSubscriptionInvoiceItems }
import React, {useState} from 'react';
import AmazingTabs from 'components/amazingTabs';
import Logins from '../logins/logins';
import GroupInfo from './groupInfo';
import SignupOptions from './signupOptions';
import WelcomeEmail from './welcomeEmail';
import VCard from './vcard';
import SAML from './SAML';
import AccountControls from './accountControls';
import AccountTheme from './accountTheme';
import SMSConsent from './smsConsent';
import { useAuth } from '../../contexts/auth';
import { LoginsProvider } from 'contexts/logins';

const tabs = [
    {id: 'logins', label:'Logins'}, 
    {id: 'group', label: 'Group Info'}, 
    {id: 'accounts', label: 'Account Controls'}, 
    {id: 'theme', label: 'Account Look'},
    {id: 'signup', label: 'Signup Options'},
    {id: 'welcome', label: 'Welcome Email'},
    //{id: 'vcard', label: 'VCard'},
    {id: 'consent', label: 'SMS Positive Consent'},
    {id: 'saml', label: 'SAML'}
]

function GroupAdmin(props) {
    const auth = useAuth()
    const [tab, setTab] = useState('logins');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Administration</div>
                </div>
            </div>
            <AmazingTabs tabs={tabs} selected={tab} onClick={tab => setTab(tab.id)} />
            {tab === 'logins' && <LoginsProvider type='group' resourceId={auth.group.id} ><Logins type='group' resourceId={auth.group.id} /></LoginsProvider>}
            {tab === 'group' && <GroupInfo />}
            {tab === 'accounts' && <AccountControls />}
            {tab === 'theme' && <AccountTheme />}
            {tab === 'signup' && <SignupOptions />}
            {tab === 'welcome' && <WelcomeEmail />}
            {tab === 'vcard' && <VCard />}
            {tab === 'consent' && <SMSConsent />}
            {tab === 'saml' && <SAML />}
        </div>
    )
}

export default GroupAdmin;
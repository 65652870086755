import React, { useEffect, useState } from 'react';
import Purchase from './purchase';
import AmazingTabs from 'components/amazingTabs';
import PhoneSetup from './phoneSetup/phoneSetup';
import Greeting from './phoneSetup/greeting';
import WaitMusic from './phoneSetup/waitMusic';
import Misc from './phoneSetup/misc';
import Workflows from './workflows/workflows';
import Messages from './messages/messages';
import { formatPhoneNumber } from '../../_base';
import { useAuth } from '../../contexts/auth';
import Messaging from './messaging/messaging';

const tabs = [
    {id: 'settings', label: 'Call Handling'},
    {id: 'greetings', label: 'Recorded Greetings'},
    {id: 'holdMusic', label: 'Hold Music'},
    {id: 'other', label: 'Other Settings'},
    {id: 'workflows', label: 'Automatic Routing'},
]

function PhoneApp() {
    const { tenant } = useAuth();
    const [twilioNumber, setTwilioNumber] = useState(null);
    const [setupMode, setSetupMode] = useState(false);
    const [tab, setTab] = useState('workflows');

    useEffect(() => {
        if (tenant.twilioNumber === null || tenant.twilioNumber.released !== null || tenant.twilioNumber.endpoint === null) {
            // nothing to do really
        } else {
            setTwilioNumber(tenant.twilioNumber.endpoint);
        }
    }, [])

    const setupPhoneNumber = () => {
        setSetupMode(true);
    }

    return(
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Phone</div>
                </div>
            </div>
            {twilioNumber ?
                <>
                    <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'300px',textAlign:'center'}}>
                        {`Your phone number is ${formatPhoneNumber(twilioNumber)}`}
                    </div>
                    <AmazingTabs tabs={tabs} selected={tab} onClick={tab => setTab(tab.id)} />
                    {tab === 'workflows' && <Workflows />}
                    {tab === 'settings' && <PhoneSetup />}
                    {tab === 'greetings' && <Greeting />}
                    {tab === 'other' && <Misc />}
                    {tab === 'holdMusic' && <WaitMusic />}
                </>
            :
                setupMode ? <Purchase onBack={() => setSetupMode(false)} /> :
                <div style={{margin:'40px'}}>
                    <p>In order to use phone you will need to setup a phone number. Adding a phone number to your
                        account will add $5 to your monthly subscription and will be billed to the payment method you have set 
                        up for your account. To proceed click the buy now button.
                    </p>
                    <button onClick={setupPhoneNumber}>Buy Now</button>
                </div>
            }
        </div>
    )
}

export default PhoneApp;
import React, { useRef, useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import moment from 'moment';
import NewEmailTemplate from './newEmailTemplate';
import DetailsPanel from 'components/detailsPanel';
import TemplateDetails from './templateDetails';
import { publish } from 'pubsub-js';
import { useEmailTemplates } from 'contexts/emailTemplates';
import { TableSetupProvider } from 'contexts/tableSetup';
import Modal from 'components/modal';

const emailColumns = [
    { id: 'id', label: 'ID', display: false, datum: 'id' },
    { id: 'name', label: 'Name', display: true, datum: 'name' },
    {
        id: 'deleted',
        label: 'Status',
        display: true,
        formatter: (obj) => {
            return obj.deleted ? 'Inactive' : 'Active';
        },
    },
    {
        id: 'created',
        label: 'Created',
        display: true,
        formatter: (obj) => {
            return moment(new Date(obj.created)).format('YYYY-MM-DD');
        },
    },
    { id: 'version', label: 'Version', display: true, datum: 'version' },
];


function Templates() {
    const { loadingTemplates, templates, loadTemplates, createTemplate, saveTemplate, deleteTemplates, sendTemplate } = useEmailTemplates();
    const [template, setTemplate] = useState(null);
    const [newTemplate, setNewTemplate] = useState(false);
    const [showDeleteTemplatesModal, setShowDeleteTemplatesModal] = useState(false);
    const [templatesToDelete, setTemplatesToDelete] = useState([]);
    const newTemplatePanel = useRef(null);

    const createTemplateHandler = () => {
        setNewTemplate(true);
        newTemplatePanel.current.show();
    };

    const hideInventory = () => {
        setNewTemplate(false);
    };

    const copyTemplateHandler = (rows) => {
        const templateId = rows[0].id;
        const templateToCopy = templates.find((t) => t.id === templateId);
        if (templateToCopy) {
            const newTemplate = {
                name: templateToCopy.name + ' (copy)',
                trigger: 0,
                version: 2,
                template: templateToCopy.template,
                design: templateToCopy.design,
                settings: templateToCopy.settings,
            };
            createTemplate(newTemplate, () => publish('success', 'Template copied successfully'));
        }
    }

    const confirmDeleteTemplates = (rows) => {
        setTemplatesToDelete(rows);
        setShowDeleteTemplatesModal(true);
    }

    const removeTemplates = () => {
        const rowIds = templatesToDelete.map((r) => r.id);
        deleteTemplates(rowIds);
        setTemplatesToDelete([]);
    };

    const createNewTemplate = (data) => {
        createTemplate(data, () => newTemplatePanel.current.hide());
    };

    const saveTemplateHandler = (data, caller, func) => {
        saveTemplate(data, () => {
            if (caller === 'save') {
                hideTemplateDetailsHandler();
            } else {
                func();
            }
        })
    };

    const sendTemplateHandler = (data) => {
        sendTemplate(data);
    };

    const templateRowClickHandler = (row) => {
        // eslint-disable-next-line eqeqeq
        if (row.version == 1) {
            publish(
                'notification',
                'Version 1 email templates are no longer supported. Please navigate to the old User Interface to edit version 1 templates.'
            );
        } else {
            setTemplate(row);
        }
    };

    const hideTemplateDetailsHandler = () => {
        setTemplate(null);
    };

    return (
        <div>
            <TableSetupProvider tableName='emailtemplates' defaultColumns={emailColumns}>
                <AmazingTable
                    rows={templates}
                    onCreate={createTemplateHandler}
                    onCreateLabel='Add template'
                    onCopy={copyTemplateHandler}
                    onDelete={confirmDeleteTemplates}
                    onRefresh={loadTemplates}
                    onRowClick={templateRowClickHandler}
                    loading={loadingTemplates}
                />
            </TableSetupProvider>
            <SidePanel title='New Email Template' ref={newTemplatePanel} onHide={hideInventory}>
                {newTemplate && <NewEmailTemplate onSave={createNewTemplate} />}
            </SidePanel>
            <DetailsPanel title={template && template.name} showing={template !== null} onHide={hideTemplateDetailsHandler}>
                {template && 
                    <TemplateDetails
                        template={template}
                        onHide={hideTemplateDetailsHandler}
                        onSave={saveTemplateHandler}
                        onSend={sendTemplateHandler}
                    />
                }
            </DetailsPanel>
            <Modal 
                title='Delete templates' 
                showing={showDeleteTemplatesModal} 
                onHide={() => {setShowDeleteTemplatesModal(false); setTemplatesToDelete([]);}}>
                <div>Are you sure you want to delete the selected templates?</div>
                <div className='button-container'>
                    <button onClick={() => {removeTemplates(); setShowDeleteTemplatesModal(false);}}>Yes, Delete</button>
                </div>
            </Modal>
        </div>
    );
}

export default Templates;

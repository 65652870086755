import React, {useEffect} from 'react';
import Element from './element';

function Section({section, selected}) {

    useEffect(() => {
        console.log('this section changed')
    }, [section])

    return (
        <div className={'page-section' + (selected ? ' selected' : '')}>
            
            {section.elements.length === 0 ? <div className='empty-message'>Edit to add elements</div> : section.elements?.map((elem, idx) => 
                <Element 
                    key={idx}  
                    element={elem}
                    settings={elem.settings}
                    selected={false}
                    />
            )}
            
        </div>
    )
}

export default Section;
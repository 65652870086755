import React, {useState} from 'react';
import Core from 'util/core';

function MFASetup(props) {
    const [error, setError] = useState(null);
    const [mfaType, setMfaType] = useState(null);
    const [mobile, setMobile] = useState('');
    
    const save = () => {
        if (mfaType === 'mobile' && mobile.length < 10) {
            setError('Please provide a mobile phone number capable of receiving text messages');
            return;
        }
        let data = {
            "mobile": mobile,
			"countryCode": 'US', //mobile.countryCode,
			"userid": props.userId,
            "mfaType": mfaType
        };
        Core.runNoAuthAction('save_mobile', data, 
            (result, error) => {
                if (result) {
                    props.onMfaSetupComplete();
                } else {
                    setError(error);
                }
            });
    }

    return(
        <div className='mfa-code-container'>
            <p style={{textAlign:'center',fontSize:'20px'}}>
            For added security, we require a second form of verification 
            to verify your identity. Please choose how you would like to 
            have a verification code sent to you.
            </p>
            <input type='radio' id='mobileRadio' name='mfaType' onChange={() => setMfaType('mobile')} />
            <label htmlFor='mobileRadio'>Mobile Phone</label>
            <div className='input-container'>
                <input type='text' name='mobile' value={mobile} onChange={(evt) => setMobile(evt.target.value)} />
            </div>
            <input type='radio' id='emailRadio' name='mfaType' onChange={() => setMfaType('email')}/>
            <label htmlFor='emailRadio'>Email Address</label>
            <div className='input-container'>
                <input type='text' name='email' value={props.email} disabled />
            </div>
            <div className='password-stuff'>
                <div>
                    <button onClick={save} disabled={mfaType===null}>Save and Continue</button>
                </div>
                
            </div>
            {error ? <div className='input-error'>{error}</div> : null}
        </div>
    );
}

export default MFASetup;
import React, { useState } from 'react';
import { useSettings } from '../../contexts/settings';

function WelcomeEmail() {
    const settings = useSettings();
    const [subject, setSubject] = useState(settings.group.welcomeEmailSubject);
    const [body, setBody] = useState(settings.group.welcomeEmailBody);

    const save = () => {
        const groupSettings = {...settings.group, welcomeEmailSubject: subject, welcomeEmailBody: body};
        settings.updateGroupSettings(groupSettings);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div style={{textAlign:'center'}}><h3>Account self signup</h3></div>
			<p>You can customize the email that is sent to users when they sign up. The login details will appear after the body of the email.</p>
			<p>You can use the following place holders in your message</p>
			<ul style={{width:'90%',listStyle:'circle'}}>
				<li>&#36;{`{account.name}`} - the customer/business name of the account</li>
				<li>&#36;{`{account.contact}`} - the account contact name</li>
				<li>&#36;{`{account.email}`} - the email address of the account</li>
				<li>&#36;{`{account.address}`} - the address line of the account</li>
				<li>&#36;{`{account.city}`} - the city of the account</li>
				<li>&#36;{`{account.state}`} - the state of the account (two letter)</li>
				<li>&#36;{`{account.zip}`} - the zip code of the account</li>
				<li>&#36;{`{account.phone}`} - the Middletwin number of the account</li>
				<li>&#36;{`{username}`} - the username for the account login</li>
				<li>&#36;{`{server}`} - the link to the server to login to</li>  
			</ul>
			<p>When the message is sent, these place holders will be replaced with the values from the account record. Note that &#36;{`{username}`} and
			&#36;{`{server}`} will automatically be added after your welcome body if you do not specify them in the body text.</p>
            <label>Subject</label>
            <input type='text' style={{width:'100%'}} value={subject} onChange={e => setSubject(e.target.value)} maxLength='128' />
            <label>Body</label>
            <textarea style={{width:'100%',height:'200px'}} value={body} onChange={e => setBody(e.target.value)} maxLength='2048'></textarea>
			<button onClick={save}>Save</button>
		</div>
    )
}

export default WelcomeEmail;
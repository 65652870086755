import React, { useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const SetupAccountInfoContext = React.createContext(null);

function SetupAccountProvider({children}) {
    const { runNoAuthAction } = useCore();
    const [email, setEmail] = useState('');
    const [emailValidated, setEmailValidated] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');

    const validateEmail = () => {
        if (email==='mttest') {
            setEmailValidated(true);
            setEmailVerified(true);
            return;
        }
        let data = {verifyEmail: true, email: email}
        runNoAuthAction('account_check', data, (response, error) => {
            if (response) {
                setEmailValidated(true);
                if (response.verificationRequired) {
                    publish('success', `Heads up! We just sent an email with a verification code to ${email}. 
                        Please get the code from the email and enter it into the verification code field to continue.`);
                } else {
                    setEmailVerified(true);
                    publish('success', 'Great News! This email address has already been verified. Click continue to proceed to the next step.');
                }
            } else if (error) {
                publish('error', 'Error: ' + error);
            }
        })
    }

    const verifyEmail = (code) => {
        let data = {email: email, code: code};
        runNoAuthAction('verify_email', data, (response, error) => {
            if (response) {
                setEmailVerified(true);
                
            } else {
                publish('error', 'Error:' + error);
            }
        })
    }

    const signup = (callback) => {
        const data = {email, password, name, contact: name, company, address1, address2, city, state: region, zip: postalCode, phone, groupCode: ''};
        runNoAuthAction('signup', data, callback);
    }

    const resetEmail = () => {
        setEmail('');
        setEmailValidated(false);
        setEmailVerified(false);
    }

    const provider = {
        email, setEmail,
        validateEmail, verifyEmail,
        emailValidated, emailVerified,
        resetEmail,
        password, setPassword,
        name, setName,
        company, setCompany,
        address1, setAddress1,
        address2, setAddress2,
        city, setCity,
        region, setRegion,
        postalCode, setPostalCode,
        phone, setPhone,
        signup,
    }

    return <SetupAccountInfoContext.Provider value={provider}>{children}</SetupAccountInfoContext.Provider>
}

function useSetupAccount() {
    const context = React.useContext(SetupAccountInfoContext);
    if (!context) {
        throw new Error('useSetupAccount must be used within a SetupAccountProvider');
    }
    return context;
}

export { SetupAccountProvider, useSetupAccount }
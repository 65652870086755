import React, { useEffect, useRef, useState } from 'react';
import {isSupported} from 'util/contentType';
import 'style/imageManager.scss';
// ========================================
// the images context
// ========================================
const ImagesContext = React.createContext(null);

function ImagesProvider({imageArray, onChange, children}) {
    const [images, setImages] = useState(imageArray);

    useEffect(() => {
        onChange(images);
    }, [images])

    const provider = {
        images,
        setImages
    }

    return <ImagesContext.Provider value={provider}>{children}</ImagesContext.Provider>
}

function useImages() {
    const context = React.useContext(ImagesContext);
    if (!context) {
        throw new Error('useImages must be used within an ImagesProvider');
    }
    return context;
}

// ========================================
// the image manager
// ========================================
function ImageManager(props){
    const [preview, setPreview] = useState(null);
    
    return (
        <ImagesProvider imageArray={props.images} onChange={props.onChange}>
            <div className='image-manager'>
                <ImageDropArea />
                <ImagePreview preview={preview} onRemove={() => setPreview(null)}></ImagePreview>
                <ImageThumbnails onClick={(idx) => setPreview(idx)}></ImageThumbnails>
            </div>
        </ImagesProvider>
    )
}

export default ImageManager;

// ========================================
// the image drop area
// ========================================
function ImageDropArea(props) {
    const {images, setImages} = useImages();
    const imgCanvasRef = useRef(null);
    let fileQueue = [];

    const handleDragOver = (evt) => {
        evt.preventDefault();
	    evt.dataTransfer.dropEffect = 'copy';
    }
    const handleMediaDrop = (evt) => {
        evt.preventDefault();
        let files = evt.dataTransfer.files; // FileList object.
        for (let i = 0, f; f = files[i]; i++) {
            let name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                fileQueue.push(f);
            } else {
                //topic.publish("showDialogMessage", f.name + " is not a valid file type.");
            }
        }
        processNextFile();
    }
    const processNextFile = () => {
        if (fileQueue.length > 0) {
            // get the file item
            let file = fileQueue.shift();
            // create the image
            let img = new Image();
            img.onload = () => placeImageOnCanvas(img);
            img.src = URL.createObjectURL(file);
        }
    }
    const placeImageOnCanvas = (imageObj) => {
        let imgCanvas = imgCanvasRef.current;
        var context = imgCanvas.getContext('2d');
        context.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        
        var imageAspectRatio = imageObj.width / imageObj.height;
        var canvasAspectRatio = imgCanvas.width / imgCanvas.height;
        var renderableHeight, renderableWidth, xStart, yStart;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if(imageAspectRatio < canvasAspectRatio) {
            renderableHeight = imgCanvas.height;
            renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
            xStart = (imgCanvas.width - renderableWidth) / 2;
            yStart = 0;
        }
        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        else if(imageAspectRatio > canvasAspectRatio) {
            renderableWidth = imgCanvas.width
            renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
            xStart = 0;
            yStart = (imgCanvas.height - renderableHeight) / 2;
        }

        // Happy path - keep aspect ratio
        else {
            renderableHeight = imgCanvas.height;
            renderableWidth = imgCanvas.width;
            xStart = 0;
            yStart = 0;
        }
        context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
        // add the image to the images array
        let image = imgCanvas.toDataURL("image/png", 1.0);
        let _images = [...images];
        _images.push({imageData: image, label: ''});
        setImages(_images);
        processNextFile();
    }

    return (
        <div>
            <div className='mediaDropZone'
                onDragOver={handleDragOver} onDrop={handleMediaDrop}>Drop Media Here</div>
            <div>Images should be PNG format and no larger than 400x400.</div>
            <div className='image-canvas'>
                <canvas width='400' height='400' ref={imgCanvasRef}></canvas>
            </div>
        </div>
    )
}

// ========================================
// the image preview
// ========================================
function ImagePreview(props) {
    const [imgPreview, setImgPreview] = useState({});
    const [imgLabel, setImgLabel] = useState('');
    const { images, setImages } = useImages();

    useEffect(() => {
        if (props.preview !== null) {
            setImgPreview({backgroundImage:`url( ${images[props.preview].imageData} )`})
            setImgLabel(images[props.preview].label);
        } else {
            setImgPreview({});
            setImgLabel('');
        }
    }, [props.preview])

    const removeImage = () => {
        if (props.preview !== null) {
            let _images = [...images];
            _images.splice(props.preview, 1);
            setImages(_images);
            props.onRemove();
        }
    }

    const handleLabelChange = (e) => {
        const newValue = e.target.value;
        setImgLabel(newValue)
        let _images = [...images];
        let _image = _images[props.preview];
        _image.label = newValue;
        _images.splice(props.preview, 1, _image)
        setImages(_images);
    }

    return (
        <div  >
            <div style={{textAlign:'center'}}>Media Preview</div>
            <div className='image-preview' style={imgPreview}>
            {props.preview !== null &&
                <div className='delete-button'><button onClick={removeImage}>Delete</button></div>
            }
            </div>
            <input type='text' maxLength='64' value={imgLabel} onChange={handleLabelChange} />
        </div>
    )
}

// ========================================
// the image thumbnails
// ========================================
function ImageThumbnails(props) {
    const { images } = useImages();

    return (
        <div className='thumbnails-container'>
            {images.length > 0 && images.map((img, idx) => 
            <div key={idx} 
                className='thumbnail-image' 
                style={{backgroundImage:`url( ${img.imageData} )`}} 
                onClick={() => props.onClick(idx)}></div>)}
        </div>
    )
}
import React, { useRef, useState } from 'react';
import Loading from 'components/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faExternalLink, faTrash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import FileUpload from './fileUpload';
import FolderDetails from './folderDetails';
import SidePanel from 'components/sidePanel';
import FileDetails from './fileDetails';
import MoveToFolder from './moveToFolder';
import moment from 'moment';
import { useFiles } from 'contexts/files';
import 'style/files.scss';
import IconButton from 'components/iconButton';
import Modal from 'components/modal';

function Files() {
    const { filesLoading, folders, media, getMedia, deleteFiles, deleteFolder, moveToFolder } = useFiles();
    const [openFolder, setOpenFolder] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const uploadPanel = useRef(null);
    const fileDetailsPanel = useRef(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const folderDetailsPanel = useRef(null);

    const [selectedForMove, setSelectedForMove] = useState([]);
    const moveToFolderPanel = useRef(null);
    const [showDeleteFilesModal, setShowDeleteFilesModal] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const toggleFolderOpen = (folderId) => {
        setOpenFolder(openFolder === folderId ? null : folderId);
    }

    const confirmDeleteFiles = (evt, medias) => {
        const ids = medias.map(m => m.id);
        setFilesToDelete(ids);
        setShowDeleteFilesModal(true);
        evt.stopPropagation();
    }

    const deleteFileHandler = (evt, medias) => {
        deleteFiles(filesToDelete);
        setFilesToDelete([]);
    }

    const selectFileHandler = (evt, media) => {
        setSelectedFile(media);
        fileDetailsPanel.current.show();
        evt.stopPropagation();
    }

    const selctedFileSaveHandler = () => {
        setSelectedFile(null);
        fileDetailsPanel.current.hide();
    }

    const deleteFolderHandler = (evt, folderId) => {
        deleteFolder(folderId);
        evt.stopPropagation();
    }

    const selectFolderHandler = (evt, folder) => {
        setSelectedFolder(folder);
        folderDetailsPanel.current.show();
        evt.stopPropagation();
    }

    const selectedFolderSaveHandler = () => {
        setSelectedFolder(null);
        folderDetailsPanel.current.hide();
    }

    const selectMedia = (mediaId) => {
        const mediaIndex = selectedForMove.findIndex(elem => elem === mediaId);
        if (mediaIndex === -1) {
            setSelectedForMove(selectedForMove.concat(mediaId));
        } else {
            let newArr = [...selectedForMove];
            newArr.splice(mediaIndex, 1);
            setSelectedForMove(newArr);
        }
    }

    const openUrl = (url) => {
        window.open(url, '_blank', 'noopener noreferrer');
    }

    const mediaList = (folderId) => {
        // eslint-disable-next-line eqeqeq
        const filtered = media.filter(m => m.parent == folderId);
        const leftPadding = folderId !== '0' ? '20px' : '0';
        // eslint-disable-next-line eqeqeq
        const isShowing = openFolder == folderId || folderId === '0' ? 'table-row' : 'none';
        return filtered.map(m =>
            <tr className={'file-item' + ((selectedForMove.indexOf(m.id) > -1) ? ' selected' : '')} key={m.id} 
                    style={{display: isShowing}} 
                    onClick={() => selectMedia(m.id)} role='button'>
                <td className='file-item-left' style={{paddingLeft: leftPadding}}>
                    <div className='file-icon'><FontAwesomeIcon icon={faFile} /></div>
                    <div className='file-name'>{m.name}</div>
                </td>
                    <td>{moment(m.created).format('YYYY-MM-DD')}</td>
                <td>
                <div className='file-item-right'>
                    <IconButton icon={faExternalLink} onClick={() => openUrl(m.url)} label='Open'/>
                    <IconButton icon={faTrash} onClick={(e) => confirmDeleteFiles(e, [m])} label='Delete'/>
                    <IconButton icon={faEye} onClick={(e) => selectFileHandler(e, m)} label='Details' />
                </div>
                </td>
            </tr>)
    }

    const uploadHandler = () => {
        getMedia();
    }

    const continueMoveHandler = (toFolderId) => {
        moveToFolder(selectedForMove, toFolderId, () => setSelectedForMove([]));
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Files</div>
                </div>
            </div>
            {filesLoading 
             ? <Loading />
             : 
                <>
                    <div className='file-actions'>
                        <button onClick={() => uploadPanel.current.show()}>Upload File</button>
                        <button className='secondary' onClick={() => folderDetailsPanel.current.show()}>New Folder</button>
                        <button className='secondary' onClick={() => moveToFolderPanel.current.show()} disabled={selectedForMove.length === 0}>Move to Folder</button>
                    </div>
                    <table className='files-container' cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr><th>Name</th><th>Date</th><th>Actions</th></tr>
                        </thead>
                        <tbody>
                        {folders.map(f =>
                            <>
                            <tr key={f.id} className='file-item'>
                                <td className='file-item-left'>
                                    <div className='file-icon' style={{color:f.color}} onClick={() => toggleFolderOpen(f.id)}>
                                        <FontAwesomeIcon icon={openFolder === f.id ? faFolderOpen : faFolder}/>
                                    </div>
                                    <div className='file-name'>{f.label}</div>
                                </td>
                                <td>{moment(f.created).format('YYYY-MM-DD')}</td>
                                <td>
                                    <div className='file-item-right'>
                                        <IconButton icon={faTrash} onClick={(e) => deleteFolderHandler(e, f.id)} label='Delete' />
                                        <IconButton icon={faEye} onClick={(e) => selectFolderHandler(e, f)} label='Details' />
                                    </div>
                                </td>
                            </tr>
                            {mediaList(f.id)}
                            </>
                        )}
                        {media.length === 0
                            ? <tr><td>Click the upload files button to begin uploading files</td></tr>
                            : mediaList('0')
                        }
                        </tbody>
                    </table>
                    <SidePanel title='Upload File' ref={uploadPanel}>
                        <FileUpload onUpload={uploadHandler} />
                    </SidePanel>
                    <SidePanel title='File Details' ref={fileDetailsPanel}>
                        <FileDetails selectedFile={selectedFile} onSave={selctedFileSaveHandler}/>
                    </SidePanel>
                    <SidePanel title='Folder Details' ref={folderDetailsPanel}>
                        <FolderDetails selectedFolder={selectedFolder} onSave={selectedFolderSaveHandler}/>
                    </SidePanel>
                    <SidePanel title='Move to Folder' ref={moveToFolderPanel}>
                        <MoveToFolder onContinue={continueMoveHandler} />
                    </SidePanel>
                </>
             }
             <Modal 
                title='Delete file' 
                showing={showDeleteFilesModal} 
                onHide={() => {setShowDeleteFilesModal(false); setFilesToDelete([]);}}>
                <div>Are you sure you want to delete these files?</div>
                <div className='button-container'>
                    <button onClick={() => {deleteFileHandler(); setShowDeleteFilesModal(false);}}>Yes, Delete</button>
                </div>
            </Modal>
        </div>
    )
}

export default Files;
import React, { useEffect } from 'react';
import Copyright from 'components/copyright';
import logo from 'assets/logo-white.png';
import { Link } from 'react-router-dom';
import { useToken } from 'contexts/token';
import 'style/login.scss';

function Logout() {
    const { clearToken } = useToken();

    useEffect(() => {
        clearToken();
    }, [])

    return (
        <div className='login-container'>
            <div className='logo-container'>
                <img src={logo} alt='Middletwin Logo' />
            </div>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-center-pane-inner'>
                        <h1>Logged out</h1>
                        <p>Thank you for using Middletwin.
                        You have been loggout out. You can close this browser tab or <Link to='/login'>login again</Link></p>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export default Logout;
import React from 'react';
import { publish } from 'pubsub-js';
import { useSetupAccount } from 'contexts/setupAccount';

function Basic({onComplete}) {
    const {name, setName, company, setCompany, address1, setAddress1, address2, setAddress2, 
        city, setCity, region, setRegion, postalCode, setPostalCode, phone, setPhone } = useSetupAccount();

    const next = () => {
        let data = {
            contact: name,
            name: company,
            address1: address1,
            address2: address2,
            city: city,
            state: region,
            zip: postalCode,
            phone: phone
        }
        if (validate(data)) {
            onComplete();
        }
    }

    const validate = (data) => {
        let message = null;
        if (data.contact.trim().length === 0 || 
                data.name.trim().length === 0 ||
                data.address1.trim().length === 0 || 
                data.city.trim().length === 0 || 
                data.state.trim().length === 0 || 
                data.zip.trim().length === 0 || 
                data.phone.trim().length === 0) {
            message = 'One or more of the required fields are missing. Please fix and try again.';
        }
        if (message !== null) {
            publish('error', 'Error:' + message);
            return false;
        }
        return true;
    }

    return (
        <div className='setupSlideBody'>
            <p>We need your organization name and address so that we can contact you. We will never sell any of your information.</p>
            <ul>
                <li>
                    <label>Your Name*</label>
                    <input type='text' placeholder='Your Name*'
                        value={name} onChange={(evt) => setName(evt.target.value)} maxLength='64' />
                </li>
                <li>
                    <label>The name of your organization*</label>
                    <input type='text' placeholder='Organization Name*'
                        value={company} onChange={(evt) => setCompany(evt.target.value)} maxLength='64' />
                </li>
                <li>
                    <label>The address for your organization*</label>
                    <div>
                    <input type='text' placeholder='Address Line 1*'
                        value={address1} onChange={(evt) => setAddress1(evt.target.value)} maxLength='64' />
                    <input type='text' placeholder='Address Line 2'
                        value={address2} onChange={(evt) => setAddress2(evt.target.value)} maxLength='64' />
                    </div>
                </li>
                <li className='row'>
                    <input type='text' placeholder='City*'
                        value={city} onChange={(evt) => setCity(evt.target.value)} maxLength='64' />
                    <input type='text' placeholder='State*'
                        value={region} onChange={(evt) => setRegion(evt.target.value)} maxLength='2' />
                    <input type='text' placeholder='Zip*'
                        value={postalCode} onChange={(evt) => setPostalCode(evt.target.value)} maxLength='16' />
                </li>
                <li>
                    <label>Phone number*</label>
                    <input type='text' placeholder='Phone number*'
                        value={phone} onChange={(evt) => setPhone(evt.target.value)} maxLength='64' />
                </li>
            </ul>
            <div style={{textAlign:'center'}}>
                <button className='bigButton' onClick={next}>Go to Finish</button>
            </div>
        </div>
    )
}

export default Basic
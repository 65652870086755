import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import TimePicker from './timePicker';
import moment from "moment";

function DateAndTimePicker({value, onChange}) {
    const [dateValue, setDateValue] = useState(moment().toDate());
    const [timeValue, setTimeValue] = useState(10*60*60*1000);

    useEffect(() => {
        if (value) {
            let theMoment = moment(value);
            setDateValue(theMoment.toDate());
            const timeValue = (theMoment.hour() + theMoment.minute()/60)*60*60*1000
            setTimeValue(timeValue);
        }
    }, [])

    useEffect(() => {
        const theMoment = moment(dateValue);
        const hours = timeValue/1000/60/60;
        theMoment.hour(Math.floor(hours)).minute((hours - Math.floor(hours))*60).second(0).millisecond(0);
        onChange(theMoment.valueOf())
    }, [dateValue, timeValue])

    const handleDateChange = (date) => {
        setDateValue(date);
    }

    const handleTimeChange = (newTime) => {
        setTimeValue(newTime);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <DatePicker selected={dateValue} onChange={handleDateChange}/>
            <TimePicker interval={30} value={timeValue} onChange={handleTimeChange} />
        </div>
    )
}

export default DateAndTimePicker;
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { useEmailTemplates } from '../../../contexts/emailTemplates';

function EventTicket(props) {
    const { templates } = useEmailTemplates();
    const [description, setDescription] = useState(props.eventTicket ? props.eventTicket.description : 'My event ticket');
    const [startDate, setStartDate] = useState(props.eventTicket ? moment(props.eventTicket.startdate).toDate() : moment().toDate());
    const [endDate, setEndDate] = useState(props.eventTicket ? moment(props.eventTicket.enddate).toDate() : moment().add(1, 'months').toDate());
    const [quantity, setQuantity] = useState(props.eventTicket ? props.eventTicket.quantity : 100);
    const [price, setPrice] = useState(props.eventTicket ? props.eventTicket.price : 10);
    const [emailTemplateId, setEmailTemplateId] = useState(props.eventTicket ? props.eventTicket.emailTemplateId : 0);

    const save = () => {
        let data = {
            description: description,
            startdate: startDate.getTime(),
            enddate: endDate.getTime(),
            quantity: quantity,
            price: price,
            emailTemplateId: emailTemplateId,
        };
        if (props.onSave) {
            props.onSave(data);
        }
    }

    return (
        <div>
			<div className='contact-preview'>
				<label>Description</label>
				<input type='text' value={description} onChange={(evt) => setDescription(evt.target.value)} maxLength='64' />
				<label>Sale start date</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>
				<label>Sale end date</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}/>
				<label>Quantity</label>
				<CurrencyInput allowDecimals={false} allowNegativeValue={false} value={quantity} onValueChange={(value) => setQuantity(value)} />
                <label>Price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
                <label>Confirmation Email Template</label>
                <select value={emailTemplateId} onChange={e => setEmailTemplateId(e.target.value)}>
                    <option value="0">None</option>
                    {templates.map(et => <option key={et.id} value={et.id}>{et.name}</option>)}
                </select>
			</div>
			<div className='buttonsHolder'>
				<button onClick={save}>Save</button>
			</div>
		</div>
    )
}

export default EventTicket;
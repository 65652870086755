import React, { useState } from 'react';
import { useAttendees } from 'contexts/attendees';

function Attendees({onFinished}) {
    const { attendees, sendNotification } = useAttendees();
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const sendNotificationHandler = () => {
        sendNotification(title, body, () => {
            setTitle('');
            setBody('');
            onFinished();
        });
    }

    return (
        <div>
            {`There are ${attendees.length} attendees with the mobile app who have notifications enabled`}
            <hr />
            <div style={{display: 'flex', flexFlow: 'column'}}>
                <label>Notification Title</label>
                <input type='text' value={title} onChange={e => setTitle(e.target.value)} />
                <label>Notification Body</label>
                <input type='text' value={body} onChange={e => setBody(e.target.value)} />
                <button onClick={sendNotificationHandler}>Send Notification</button>
            </div>
        </div>
    )
}

export default Attendees;
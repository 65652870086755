import React, {useState, useEffect} from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Core from './core';
import PubSub from 'pubsub-js';

function StripeForm(props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [acceptTOU, setAcceptTOU] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        let data = {save: true};
        Core.runAction('create_payment_intent', data, (response) => {
            if (response) {
                setClientSecret(response.clientSecret);
            }
        })
    }, [])

    const handleChange = async(event) => {
        // listen for changes
        setDisabled(event.empty);
        setError(event.error ? event.error.message : null);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        if (succeeded) {
            return;
        }
        if (!acceptTOU) {
            PubSub.publish('warning', 'You must accept the Middletwin terms of use in order to proceed.');
            return;
        }
        setProcessing(true);

        const payload = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        })

        setProcessing(false);
        if (payload.error) {
            setError(`Saving Failed: ${payload.error.message}`)
        } else {
            setError(null);
            setSucceeded(true);
            props.onFinished(payload.setupIntent.id);
        }
    }

    return (
        <div>
            <CardElement id='card-element' options={cardStyle} onChange={handleChange} />
            {succeeded && <div>Credit Card Saved</div>}
            <div style={{marginTop: '20px'}}>
                <input type='checkbox' value={acceptTOU} onChange={(evt) => setAcceptTOU(evt.target.checked)} /> 
                    I Accept the Middletwin <a href='https://www.middletwin.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>terms of use</a>
            </div>
            <div style={{marginTop:'10px'}}>
                <button disabled={processing || disabled} onClick={handleSubmit}>
                    <span>
                        {processing ? (<div>Saving...</div>) : succeeded ? 'Done' : 'Save Credit Card'}
                    </span>
                </button>
            </div>
            {error && <div role='alert'>{error}</div>}
        </div>
    )
}

export default StripeForm

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const PromotionContext = React.createContext(null);

function PromotionProvider({productId, children}) {
    const { runAction } = useCore();
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        loadPromotions();
    }, [])

    const loadPromotions = () => {
        runAction('get_promotion', {productId}, (response) => {
            setPromotions(response.promotions)
        })
    }

    const createPromotion = (data, callback) => {
        runAction('create_promotion', {...data, product: productId}, () => {
            loadPromotions();
            callback();
        });
    }

    const updatePromotion = (data, callback) => {
        runAction('update_promotion', {...data, product: productId}, () => {
            loadPromotions();
            callback();
        })
    }

    const deletePromotion = (promotionId) => {
        runAction('delete_promotion', {promotionId}, () => {
            loadPromotions();
        })
    }

    const provider = {
        promotions, loadPromotions,
        createPromotion, updatePromotion, deletePromotion,
    }

    return <PromotionContext.Provider value={provider}>{children}</PromotionContext.Provider>
}

function usePromotion() {
    const context = React.useContext(PromotionContext);
    return context;
}

export { PromotionProvider, usePromotion }
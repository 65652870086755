import React from 'react';
import { useGroup } from 'contexts/group';
import Toggle from 'components/toggle';
import CurrencyInput from 'react-currency-input-field';
import { useChatDatabases } from 'contexts/chatDatabases';

function GroupSettings() {
    const { chatDatabases, createChatDatabase, changeChatDatabase } = useChatDatabases();
    const group = useGroup();

    const updateSetting = (key, val) => {
        let settings = {...group.data};
        settings[key] = val;
        //console.log(settings);
        group.setSettings(settings);
    }

    const createChatDatabaseHandler = () => {
        createChatDatabase(group.data.name, group.data.id);
    }

    const changeChatDatabaseHandler = (evt) => {
        changeChatDatabase(group.data.id, evt.target.value, () => {
            group.getSettings();
        });
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Account Limit</h3></div>
                <p>The maximum amount of accounts that this group can have.</p>
                <label>Account Limit</label>
                <CurrencyInput 
                        allowDecimals={false} 
                        allowNegativeValue={false} 
                        value={group.data.tenantLimit} 
                        onValueChange={(value) => updateSetting('tenantLimit', value)} />
            </div>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Group Chat</h3></div>
                <p>Here you can enable group chat for this group.</p>
                    <label>Active</label>
                    <div style={{display:'flex'}}>Off <Toggle checked={group.data.chatEnabled} onChange={val => updateSetting('chatEnabled', val)}/> On</div>
                    <label>Database</label>
                    <select value={group.data.chatDb} onChange={changeChatDatabaseHandler}>
                        <option value=''>-- None --</option>
                        {chatDatabases.map(cb => <option key={cb.id} value={cb.id}>{cb.name}</option>)}
                    </select>
                <div style={{textAlign:'center'}}>
                    <button onClick={createChatDatabaseHandler}>Create New Chat Database</button>
                </div>
            </div>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Invoice</h3></div>
                <p>If this group is invoiced you should enable this invoice setting and provide the email address that invoices should be sent to.</p>
                <label>Invoice</label>
                <div style={{display:'flex'}}>Disabled <Toggle checked={group.data.invoice} onChange={val => updateSetting('invoice', val)} /> Enabled</div>
                <label>Email</label>
                <input type='text' value={group.data.invoiceEmail} onChange={e => updateSetting('invoiceEmail', e.target.value)}/>
            </div>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Monitoring</h3></div>
                <p>Monitoring the accounts that you manage can be tedious process. To make it easier we have built a monitoring tool that you can use to alert you
		        when a message contains information that you might be interested in, like PII, and allows you to examine the contents of the message to assure 
                adherence to your policies.</p>
                <label>Monitoring</label>
                <div style={{display:'flex'}}>Disabled <Toggle checked={group.data.monitoring} onChange={val => updateSetting('monitoring', val)} /> Enabled</div>
                <label>Email</label>
                <input type='text' value={group.data.monitoringEmail} onChange={e => updateSetting('monitoringEmail', e.target.value)}/>
            </div>
        </div>
    )
}

export default GroupSettings;
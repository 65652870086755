import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSync, faTimes } from '@fortawesome/pro-regular-svg-icons';
import Core from 'util/core';

function System() {
    const [processes, setProcesses] = useState([]);

    useEffect(() => {
        refresh();
    }, [])

    const refresh = () => {
        Core.runNoAuthAction('get_services', {}, response => {
            setProcesses(response.processes);
        });
    }

    const getStatus = (proc) => {
        var status = false;
        if ((Date.now() - proc.lastRun) < (proc.frequency * 2)) {
            status = true;
        }
        return <span style={{color: status ? 'green' : 'red'}}><FontAwesomeIcon icon={status ? faCheck : faTimes}/></span>
    }

    return (
        <div style={{width:'100%'}}>
            <div style={{width:'650px',margin:'auto'}}>
                <h1>Current Status</h1>
                <p>Middletwin publish's up-to-the-minute information on the status of our systems and services. Check back here any time to get current
                system status information. Contact <a href='mailto:support@middletwin.com'>customer support</a> to report any service disruptions you 
                may be experiencing that are not listed here.</p>
                <div style={{fontWeight:'bold',textAlign:'center',margin:'10px'}}>{moment().format('YYYY-MM-DD hh:mm:ss')}</div>
                <div style={{textAlign:'center'}}>
                    <button onClick={refresh}><FontAwesomeIcon icon={faSync}/> Refresh</button>
                </div>
                <div style={{backgroundColor:'lightgray',padding:'10px',borderRadius:'5px',border:'1px solid gray',marginTop:'10px'}}>
                    <div style={{backgroundColor:'white',borderRadius:'5px',border:'1px solid gray'}}>
                        <table>
                            <thead>
                                <tr><th>Service</th><th>Status</th><th>Last Run</th></tr>
                            </thead>
                            <tbody>
                                {processes.map(p => <tr key={p.id}><td>{p.label}</td><td>{getStatus(p)}</td><td>{moment(p.lastRun).format('YYYY-MM-DD hh:mm:ss')}</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default System;
import React, { useState } from 'react';
import Core from '../util/core';
import { useCore } from './core';

const VideoContext = React.createContext();

function VideoProvider({children}) {
    const { runAction } = useCore();
    const [localTracks, setLocalTracks] = useState(null);
    const [roomSid, setRoomSid] = useState('');
    const [roomName, setRoomName] = useState('');
    const [token, setToken] = useState('');

    const getToken = () => {
        if (roomSid.length === 0) {
            throw new Error('invalid or missing room sid');
        }
        runAction('get_token', {roomSid: roomSid}, response => {
            if (response.success) {
                setToken(response.token);
            } else {
                throw new Error(response.errorMessage);
            }
        })
    }

    const provider = {
        localTracks,
        updateLocalTracks: tracks => {setLocalTracks(tracks)},
        roomSid,
        updateRoomSid: sid => {setRoomSid(sid)},
        roomName,
        updateRoomName: name => {setRoomName(name)},
        token,
        updateToken: () => {getToken()}
    }

    return <VideoContext.Provider value={provider}>{children}</VideoContext.Provider>
}

function useVideo() {
    const context = React.useContext(VideoContext);
    if (!context) {
        throw new Error('useVideo must be used within a video context provider');
    }
    return context;
}

export {VideoProvider, useVideo}
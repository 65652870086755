import PubSub from 'pubsub-js';
const server = '/dart/core';

class Core {
    static runNoAuthAction(action, params, handler) {
        let data = Object.assign({}, params);
        data.action = action;
        fetch(server, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(data)
        }).then(res => res.json())
        .then(
            (result) => {
                if (handler) {
                    if (result.hasOwnProperty('success')) {
                        if (result.success) {
                            handler(result, null);
                        } else {
                            handler(null, result.errorMessage);
                        }
                    } else {
                        handler(result, null);
                    }
                }
            },
            (error) => {
                // TODO: if 401 - logout
                if (handler) {
                    handler(null, error);
                }
            }
        );
    }  
    static runAction(action, params, handler) {
        const token = localStorage.token;
        if (token) {
            let data = Object.assign({}, params);
            data.action = action;
            try {
                fetch(server, {
                    method: "POST",
                    headers: {'Content-Type':'application/json', 'X-Dart-Token': token},
                    body: JSON.stringify(data)
                })
                .then((response) => {
                    if (action === 'authorize' && params.newToken) {
                        window['X-Dart-Token'] = response.headers.get('X-Dart-Token');
                    }
                    if (response.status === 401) {
                        PubSub.publish('warning', 'you dont have access to this');
                    }
                    return response.json();
                })
                .then(
                    (result) => {
                        if (handler) {
                            if (result.hasOwnProperty('success')) {
                                if (result.success) {
                                    handler(result);
                                } else {
                                    PubSub.publish('error', 'Error:' + result.errorMessage);
                                    handler(result);
                                }
                            } else {
                                handler(result);
                            }
                        }
                    }
                )
                .catch(error => {
                    console.error(error.message)
                    PubSub.publish('error', 'A server error has occurred, please try again or contact customer support.');
                });
            } catch (error) {
                console.log(error);
            }
        } else if (handler) {
            PubSub.publish('error', 'Your session appears to be expired, please log in.');
            handler(null);
        }
    }
}

export default Core;
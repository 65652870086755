import React, {useState} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { convertToUnixTime } from '_base';
import Toggle from 'components/toggle';

function ActivityDetails({act, onSave, types}) {

    // eslint-disable-next-line no-unused-vars
    const [activity, setActivity] = useState(act);
    const [title, setTitle] = useState(activity.title);
    const [description, setDescription] = useState(activity.description);
    const [type, setType] = useState(activity.type);
    const [date, setDate] = useState(activity.date);
    const [start, setStart] = useState(convertToUnixTime(activity.start));
    const [end, setEnd] = useState(convertToUnixTime(activity.end));
    const [error, setError] = useState(null);
    const [createReminder, setCreateReminder] = useState(false);

    const save = () => {
        let data;
        if ('id' in activity) {
            data = {
                title: title,
                description: description,
                type: type,
                date: date,
                start: start,
                end: end,
                createReminder,
                id: activity.id
            }
        } else {
            data = {
                title: title,
                description: description,
                type: type,
                date: date,
                start: start,
                end: end,
                createReminder,
            }
        }
        
        if (onSave && data.title !== '' && data.description !== '') {
            onSave(data);
        }
        else {
          setError("Please enter Title and Description");
        }
    }

    const getTimes = () => {
      let times = [];
      let unixTracker = 0;
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
          times.push({id: i, time: i + ":" + j * 15 + ":00", unixTime: unixTracker * 900000 + 21600000});
          unixTracker++;
        }
      }
      return times;
    }

    return(
        <div>
            {error && <div>{error}</div>}
            <label>Title</label>
            <div><input type="text" value={title} onChange={(evt) => setTitle(evt.target.value)} style={{width:'350px'}} maxLength={32} /></div>
            <label>Description</label>
            <div><textarea value={description} onChange={(evt) => setDescription(evt.target.value)} style={{width:'350px',height:'100px'}} /></div>
            <label>Type</label>
            <select value={type} onChange={(evt) => setType(evt.target.value)}>
              {types.map((v) => <option key={v.id} value={v.id}>{v.value}</option>)}
            </select>
            <label>Date</label>
            <div><DatePicker selected={date} onChange={(date) => setDate(Date.parse(date))} /></div>
            <label>Start Time</label>
            <select value={start} onChange={(evt) => setStart(evt.target.value)}>
              {getTimes().map((time, i) => <option key={i} value={time.unixTime}>{moment().hour(time.time.split(':')[0]).minute(time.time.split(':')[1]).format('h:mm a')}</option>)}
            </select>
            <label>End Time</label>
            <select value={end} onChange={(evt) => setEnd(evt.target.value)}>
              {getTimes().map((time, i) => <option key={i} value={time.unixTime}>{moment().hour(time.time.split(':')[0]).minute(time.time.split(':')[1]).format('h:mm a')}</option>)}
            </select>
            {!activity.id &&
                <>
                    <label>Setup Reminder</label>
                    <Toggle checked={createReminder} onChange={newValue => setCreateReminder(newValue)}/>
                </>
            }
            <div><button onClick={save}>Save</button></div>
        </div>
    )
}

export default ActivityDetails
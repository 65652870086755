import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const AttendeesContext = React.createContext(null);

function AttendeesProvider({eventId, children}) {
    const { runAction } = useCore();
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
        getAttendees();
    }, [])

    const getAttendees = () => {
        let data = {eventId}
        runAction('get_attendees', data, (response) => {
            setAttendees(response.attendees);
        })
    }

    const sendNotification = (title, body, callback) => {
        let data = {eventId, title, body}
        runAction('notify_attendees', data, (response) => {
            callback();
        })
    }

    const provider = {
        attendees,
        sendNotification,
    }

    return <AttendeesContext.Provider value={provider}>{children}</AttendeesContext.Provider>
}

function useAttendees() {
    const context = React.useContext(AttendeesContext);
    if (!context) {
        throw new Error('useAttendees must be used withing an AttendeesProvider');
    }
    return context;
}

export { AttendeesProvider, useAttendees }
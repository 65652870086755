import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'contexts/auth';
import Core from '../../util/core';
import AmazingTable from 'components/amazingTable';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import Pattern from './Pattern';
import { TableSetupProvider } from 'contexts/tableSetup';

const patternColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created).format('YYYY-MM-DD')},
    {id: 'name', label: 'Name', display: true, datum: 'name'},
    {id: 'pattern', label: 'Pattern', display: true, datum: 'pattern'},
    {id: 'holdMessage', label: 'Hold Message', display: true, formatter: obj => obj.holdMessage ? 'Yes' : 'No'},
    {id: 'rejectMessage', label: 'Reject Message', display: true, formatter: obj => obj.rejectMessage ? 'Yes' : 'No'},
]

function Patterns() {
    const auth = useAuth();
    const [patterns, setPatterns] = useState([]);
    const [pattern, setPattern] = useState(null);
    const patternPanel = useRef(null);

    useEffect(() => {
        getPatterns();
    }, [])

    const getPatterns = () => {
        Core.runAction('get_monitor_patterns', {groupId: auth.group.id}, response => {
            setPatterns(response.patterns);
        });
    }
    
    const createPattern = () => {
        setPattern(null);
        patternPanel.current.show();
    }

    const editPattern = (ptrn) => {
        setPattern(ptrn);
        patternPanel.current.show();
    }

    const savePattern = (ptrn) => {
        const action = ptrn.id ? 'update_monitor_pattern' : 'create_monitor_pattern';
        Core.runAction(action, ptrn, response => {
            getPatterns();
            patternPanel.current.hide();
        })
    }

    const deletePatterns = (ptrns) => {
        Core.runAction('delete_monitor_pattern', {ids: ptrns.map(p => p.id)}, response => {
            getPatterns();
        });
    }

    return (
        <>
            <TableSetupProvider tableName='pattern' defaultColumns={patternColumns}>
                <AmazingTable 
                    rows={patterns}
                    onRefresh={getPatterns}
                    onCreate={createPattern}
                    onRowClick={editPattern}
                    onDelete={deletePatterns}
                />
            </TableSetupProvider>
            <SidePanel title='Pattern' ref={patternPanel}>
                <Pattern pattern={pattern} onSave={savePattern} />
            </SidePanel>
        </>
    )
}

export default Patterns;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Core from '../../util/core';
import PubSub from 'pubsub-js';
import './purchase.scss';
import { useAuth } from '../../contexts/auth';

function Purchase(props) {
    const [areaCode, setAreaCode] = useState('');
    const [numbers, setNumbers] = useState([]);
    const [number, setNumber] = useState(null);
    const [typedNumber, setTypedNumber] = useState('');
    const auth = useAuth();

    const search = () => {
        let data = {areaCode: areaCode, isoCountry: auth.tenant.isoCountry};
        Core.runAction('get_numbers', data, response => {
            setNumbers(response.numbers);
        })
    }

    const pickNumber = (number) => {
        setNumber(number);
    }

    const reserveNumber = () => {
        Core.runAction('reserve_number', {...number}, response => {
            auth.tenant.twilioNumber = response.twilioNumber;
            PubSub.publish('success', 'Phone number ' + number.number + ' successfully purchased.');
            props.onBack();
        });
    }

    return (
        <div className='purchase'>
            <div className='setupInstructions'>
                <p>To purchase a new number to use for your groups voice and texting you will need to search for numbers that are in your desired area code.</p>
            </div>
            <div className='setupPane' style={{top:'150px'}}>
                <div className='setupPaneTitle'>Type in an area code to begin</div>
                <div className='setupPaneList'>
                    <div style={{width:'100%'}}>
                        <div style={{width:'70%',margin:'auto'}}>
                            <ul>
                                <li>
                                    <input type='text' value={areaCode} onChange={evt => setAreaCode(evt.target.value)}
                                        style={{textAlign:'center'}} placeholder='Area Code*' maxLength='3' />
                                </li>
                                <li style={{margin:'10px 0'}}>
                                    <div className='numberSearchResults'>
                                        <ul>
                                            {
                                                numbers.map((n, idx) => <li key={idx} onClick={() => pickNumber(n)}>{n.number + (n.locality ? " (" + n.locality + ")" : "")}</li>)
                                            }
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <button className='linkButton' onClick={props.onBack}>Cancel</button>
                                    <button onClick={search} disabled={areaCode.length !== 3}><FontAwesomeIcon icon={faSearch}/> Search</button>
                                </li>
                                {number &&
                                    <>
                                        <p>You have chosen <span style={{fontWeight:'bold'}}>{number.number}</span>. To confirm that you want to proceed with this number type just the number 
                                        <span style={{fontWeight:'bold'}}>exactly as it appears</span>, in the space below</p>
                                        <input type='text' value={typedNumber} onChange={e => setTypedNumber(e.target.value)} />
                                        <button disabled={typedNumber !== number.number} onClick={reserveNumber}>Complete Number Purchase</button>
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Purchase;
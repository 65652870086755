import React, { useState } from 'react';
import { publish } from 'pubsub-js';
import WaitButton from 'components/waitButton';
import { useCore } from 'contexts/core';
import { useCountries } from 'contexts/countries';
import Modal from 'components/modal';

function ValidateEmail() {
    const { runAction } = useCore();
    const { countries } = useCountries();
    const [senderEmail, setSenderEmail] = useState('');
    const [senderName, setSenderName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('US');
    const [zip, setZip] = useState('');

    const [validating, setValidating] = useState(false);

    const [showResend, setShowResend] = useState(false);
    const [verifiedSender, setVerifiedSender] = useState(null);


    const validateForm = () => {
        let message = '';
        if (senderName.length === 0) {
            message = 'A sender name is required';
        } else if (senderEmail.length === 0) {
            message = 'A sender email address is required';
        } else if (address1.length === 0 || city.length === 0 || state.length === 0 || country.length === 0 || zip.length === 0) {
            message = 'A complete sender address is required';
        }
        if (message.length > 0) {
            publish('error', message);
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setSenderName('');
        setSenderEmail('');
        setAddress1('');
        setAddress2('');
        setCity('');
        setState('');
        setCountry('US');
        setZip('');
    }

    const sendValidationEmail = () => {
        if (validateForm()) {
            setValidating(true);
            let data = {senderEmail, senderName, address1, address2, city, state, country, zip}
            runAction('create_verified_sender', data, response => {
                if (response.success) {
                    if (response.status === 'SENT') {
                        publish('notification', `Verification for ${senderEmail} has been initiated, sender must forward the verification email to support@middletwin.com within 48 hours to complete the process.`);
                        clearForm();
                    } else if (response.status === 'VERIFIED') {
                        publish('notification', `Email ${senderEmail} has been verified, sender can use it.`);
                        clearForm();
                    } else if (response.status === 'NOTVERIFIED') {
                        setShowResend(true);
                        setVerifiedSender(JSON.parse(response.verifiedSender));
                    } else {
                        publish('notification', `Email ${senderEmail} cannot be verified, please contact support.`);
                    }
                } else {
                    publish('error', `Verification failed with the following error: ${response.errorMessage}`);
                }
                setValidating(false);
            })
        }
    }

    const resendVerificationHandler = () => {
        const data = {senderId: verifiedSender.id};
        runAction('resend_sender_verification', data, response => {
            if (response.success) {
                publish('notification', `Verification for ${senderEmail} has been resent, sender must forward the verification email to support@middletwin.com within 48 hours to complete the process.`);
                clearForm();
                setShowResend(false);
                setVerifiedSender(null);
            } else {
                publish('error', `Request failed with the following error: ${response.errorMessage}`);
            }
        });
    }

    const deleteSenderHandler = () => {
        const data = {senderId: verifiedSender.id};
        runAction('delete_sender', data, response => {
            if (response.success) {
                publish('notification', `Verification request for ${senderEmail} has been deleted.`);
                clearForm();
                setShowResend(false);
                setVerifiedSender(null);
            } else {
                publish('error', `Request failed with the following error: ${response.errorMessage}`);
            }
        });
    }

    return (
        <div style={{padding: '50px'}}>
            <h1>Verify sender email address</h1>
            <p>All outgoing emails need to have a sender email address. By default all emails have the sender email 
                address set to donotreply@middletwin.com. If this works for you then you do not need to do anything. 
                However, if you want to use a different sender email address for reply emails, you will need to 
                verify the email address that you want to use as the sender email address.</p>
            <p style={{fontWeight:'bold'}}>You need to have access to this email address.</p>
            <p>Middletwin uses Sendgrid for sending emails. Email addresses must be verified in
                Sendgrid, but don't worry, we have made this a simple step for you to perform. Simply fill out this form
                and click the Verify button. An email will be sent to that email address from Sendgrid. You will need to 
                forward the email to support@middletwin.com for us to verify the sender. We will notify you when the email 
                address is ready to use.
            </p>
            <div style={{display:'flex',flexFlow:'column nowrap'}}>
            <label>Sender name*</label>
            <input type='text' value={senderName} onChange={e => setSenderName(e.target.value)} maxLength={128} style={{width:'400px'}} />
            <label>Sender email address (reply to)*</label>
            <input type='text' value={senderEmail} onChange={e => setSenderEmail(e.target.value)} maxLength={128} style={{width:'400px'}} />
            <label>Address line 1*</label>
            <input type='text' value={address1} onChange={e => setAddress1(e.target.value)} maxLength={64} style={{width:'400px'}} />
            <label>Address line 2</label>
            <input type='text' value={address2} onChange={e => setAddress2(e.target.value)} maxLength={64} style={{width:'400px'}} />
            <label>City*</label>
            <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength={32} style={{width:'400px'}} />
            <label>State*</label>
            <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength={24} style={{width:'400px'}} />
            <label>Country*</label>
            <select value={country} onChange={evt => setCountry(evt.target.value)} style={{width:'400px'}}>
                {
                    countries.map((c, i) => <option key={i} value={c.isoCode}>{c.label}</option>)
                }
            </select>
            <label>Zip*</label>
            <input type='text' value={zip} onChange={e => setZip(e.target.value)} maxLength={10} style={{width:'400px'}} />
            <WaitButton onClick={sendValidationEmail} label='Verify sender' waitMode={validating}></WaitButton>
            </div>
            <p>You can verify multiple senders. Contact us if you would like to verify a domain so that any email that uses that domain will be allowed.</p>
            <Modal 
                title='Resend verification' 
                showing={showResend}
                buttons={[
                    {   type: 'primary', 
                        handler: () => {resendVerificationHandler()},
                        label:'Resend verification'
                    },
                    {   type: 'primary', 
                        handler: () => {deleteSenderHandler()},
                        label:'Delete request'
                    }
                ]} 
                onHide={() => setShowResend(false)}>
                <p>{verifiedSender?.from_email} is not verified. You can resend the verification or delete the request.</p>
            </Modal>
        </div>
    )
}

export default ValidateEmail;
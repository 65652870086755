import React, { useState } from 'react';
import Toggle from '../../components/toggle';
import Core from '../../util/core';
import PubSub from 'pubsub-js';
import { useAuth } from '../../contexts/auth';
import { useSettings } from '../../contexts/settings';

function VCard() {
    const auth = useAuth();
    const settings = useSettings()
    const [approval, setApproval] = useState(auth.group.vcardApproval);
    const [email, setEmail] = useState(auth.group.vcardEmail);
    const [companyName, setCompanyName] = useState(auth.group.vcardCompanyName);
    const [companyNameEditable, setCompanyNameEditable] = useState(auth.group.vcardCompanyNameEditable);

    const save = () => {
        let data = {...auth.group, vcardApproval: approval, vcardEmail: email, vcardCompanyName: companyName, 
            vcardCompanyNameEditable: companyNameEditable};
        Core.runAction('update_affiliate', data, response => {
            PubSub.publish('success', 'The settings have been saved');
            settings.refreshGroupSettings(auth.group.uuid);
        })
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div style={{textAlign:'center'}}><h3>VCard</h3></div>
            <div>
                <label>Approval</label>
                <p>If you want changes to vcards to go through an approval process, then turn on vcard approval here and type in an email
                address for the person who will be responsible for vcard approval</p>
                <label>Enable VCard Approval</label> <Toggle checked={approval} onChange={val => setApproval(val)} />
                <label>VCard Reviewers Email</label>
                <input type="text" style={{width:'100%'}} value={email} onChange={e => setEmail(e.target.value)}
                        placeholder='(128 characters max)' maxLength='128'/>
            </div>
            <div >
                <label>Company Name</label>
                <p>Set the default company name that you want to have pre-filled on all new VCards for the accounts within your group. You
                can also set the can be changed toggle to off if you dont want the users in the accounts to be able to change this company name.</p>
                <label>Company Name</label>
                <input type="text" style={{width:'100%'}} value={companyName} onChange={e => setCompanyName(e.target.value)}
                        placeholder='(64 characters max)' maxLength='64' />
                <label>Can be changed</label> <Toggle checked={companyNameEditable} onChange={val => setCompanyNameEditable(val)} />
            </div>
            <button onClick={save}>Save</button>
        </div>
    )
}

export default VCard;
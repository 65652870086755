import React, {useState} from 'react';
import MiniList from 'components/MiniList';
import { useCore } from 'contexts/core';

const searchResultListColumns = [
    {id: 'name', formatter: obj => <>{`${obj.fname} ${obj.lname}`}</>},
    {id: 'city', datum: 'city'},
    {id: 'state', datum: 'state'},
]

function RegistrationSearch(props) {
    const { runAction } = useCore();
    const [searchVal, setSearchVal] = useState('');
    const [entries, setEntries] = useState([]);

    const search = () => {
        let data = {};
        if (isNaN(parseInt(searchVal))) {
            data.search_term = searchVal;
        } else {
            data.event = props.eventId;
            data.car_number = searchVal;
        }
        runAction('get_matching', data, response => {
            setEntries(response.entries);
        })
    }

    const entrySelectHandler = (ntry) => {
        props.onSelectEntry(ntry);
    }

    return (
        <>
            <label>Search</label>
            <div style={{justifyContent:'space-between',paddingBottom:'5px'}}>
                <input type='text' value={searchVal} onChange={e => setSearchVal(e.target.value)} style={{flexGrow:'1'}}/>
                <button onClick={search}>Search</button>
            </div>
            <MiniList 
                columns={searchResultListColumns}
                rows={entries}
                onRowClick={entrySelectHandler}
                noRowsLabel='No search results' />
            <hr />
        </>
    )
}

export default RegistrationSearch;
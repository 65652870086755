import React, { useEffect, useState } from 'react';

const PageContentContext = React.createContext(null);

function PageContentProvider({page, children}) {
    const [name, setName] = useState('');
    const [type, setType] = useState(1);
    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (page) {
            setName(page.name);
            setType(page.type);
            try {
            const content = JSON.parse(page.content);
                setSections(content.sections);
            } catch (err) {

            }
        }
    }, [page])

    
    const insertSection = (section) => {
        setSections([...sections, section]);
    }

    const removeSection = (sectionIndex) => {
        setSections(sections.toSpliced(sectionIndex, 1));
    }

    const moveSectionUp = (sectionIndex) => {
        if (sectionIndex > 0) {
            let newSections = [...sections];
            const section = newSections.splice(sectionIndex, 1)[0];
            newSections.splice(sectionIndex-1, 0, section);
            setSections(newSections);
        }
    }

    const moveSectionDown = (sectionIndex) => {
        if (sectionIndex < sections.length -1) {
            let newSections = [...sections];
            const section = newSections.splice(sectionIndex, 1)[0];
            newSections.splice(sectionIndex+1, 0, section);
            setSections(newSections);
        }
    }

    const insertElement = (sectionIndex, element) => {
        if (sections[sectionIndex].elements.length < 3) {
            let section = {...sections[sectionIndex]}
            section.elements.push(element);
            setSections(sections.toSpliced(sectionIndex, 1, section));
        }
    }

    const moveElementLeft = (sectionIndex, elementIndex) => {
        if (elementIndex > 0) {
            let section = {...sections[sectionIndex]}
            const element = section.elements.splice(elementIndex, 1)[0];
            section.elements.splice(elementIndex-1, 0, element);
            setSections(sections.toSpliced(sectionIndex, 1, section));
        }
    }

    const moveElementRight = (sectionIndex, elementIndex) => {
        let section = {...sections[sectionIndex]}
        if (elementIndex < section.elements.length -1) {
            const element = section.elements.splice(elementIndex, 1)[0];
            section.elements.splice(elementIndex+1, 0, element);
            setSections(sections.toSpliced(sectionIndex, 1, section));
        }
    }

    const removeElement = (sectionIndex, elementIndex) => {
        let section = {...sections[sectionIndex]}
        section.elements.splice(elementIndex, 1);
        setSections(sections.toSpliced(sectionIndex, 1, section));
    }

    const updateElement = (sectionIndex, elementIndex, settings) => {
        let section = {...sections[sectionIndex]};
        const newSettings = {...section.elements[elementIndex].settings, ...settings};
        section.elements[elementIndex].settings = newSettings;
        setSections(sections.toSpliced(sectionIndex, 1, section));
    }

    const provider = {
        name, setName,
        type, setType,
        sections,
        insertSection,
        moveSectionUp,
        moveSectionDown,
        removeSection,
        insertElement,
        moveElementLeft,
        moveElementRight,
        removeElement,
        updateElement,
    }

    return <PageContentContext.Provider value={provider}>{children}</PageContentContext.Provider>
}

function usePageContent() {
    const context = React.useContext(PageContentContext);
    if (!context) {
        throw new Error('usePageContent must be used within a PageContentProvider');
    }
    return context;
}

export { PageContentProvider, usePageContent }
import React, {useState, useEffect} from 'react';
import moment from 'moment';

function TimePicker({interval=60, value, onChange}) {
    const [times, setTimes] = useState([])

    useEffect(() => {
        let mTime = moment().hour(0).minute(0);
        let timeArr = [];
        if (interval === 60) {
            for (let i = 0; i < 24; i++) {
                timeArr.push({value: (i*60*60*1000),label: mTime.format('h:mm A')})
                mTime.add(1, 'hour');
            }
        } else if (interval === 30) {
            for (let i = 0; i < 48; i++) {
                timeArr.push({value: (i*30*60*1000),label: mTime.format('h:mm A')})
                mTime.add(.5, 'hour');
            }
        }
        setTimes(timeArr);
    }, [])

    return(
        <select value={value} onChange={(evt) => onChange(evt.target.value)}>
            {times.map((t) => <option key={t.value} value={t.value}>{t.label}</option>)}
        </select>
    )
}

export default TimePicker;
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';
import Core from 'util/core';
import { useLoaderData } from 'react-router-dom';

export async function loader({params}) {
    return params;
}

function VCard() {
    const loaderData = useLoaderData();
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [organization, setOrganization] = useState('');
    const [email, setEmail] = useState('');
    const [workphone, setWorkphone] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (loaderData.tenant !== null) {
            let data = {tenant: loaderData.tenant, format: "json"};
            if (loaderData.id !== null) {
                data.id = loaderData.id;
            }
            Core.runNoAuthAction('get_vcard.vcf', data, response => {
                if (response.vcard) {
                    // parse the vcard
                    const lines = response.vcard.contents.split("\n");
                    lines.forEach((line) => {
                        const col = line.indexOf(":");
                        const key = line.substring(0, col);
                        const val = line.substring(col + 1);
                        console.log(key + "=" + val);
                        if (key.startsWith("FN")) {
                            setName(val);
                        } else if (key.startsWith("TITLE")) {
                            setTitle(val);
                        } else if (key.startsWith("ORG")) {
                            setOrganization(val);
                        } else if (key.startsWith("EMAIL")) {
                            setEmail(val);
                        } else if (key.startsWith("TEL")) {
                            if (key.indexOf('WORK') > 0) {
                                setWorkphone(val);
                            } else if (key.indexOf('CELL') > 0) {
                                setMobile(val);
                            }
                        } else if (key.startsWith("ADR")) {
                            const adrParts = val.split(";");
                            setAddress(adrParts[2]);
                            setCity(adrParts[3]);
                            setState(adrParts[4]);
                            setZip(adrParts[5]);
                        } else if (key.startsWith("PHOTO")) {
                            setImage("data:image/png;base64," + val);
                        }
                    });
                }
            })
        }
    }, [])

    const loadVCard = () => {
       // window.open("/dart/core?action=get_vcard.vcf&format=vcard&t=" + this.tenant);
    }

    return (
        <div style={{fontSize:'12px'}}>
            <div style={{width:'314px',height:'314px',border:'1px solid #ccc',textAlign:'center'}}>
                {image ? <img src={image} alt='contact headshot'/> : <div style={{color:'#ccc',marginTop:'150px'}}>No Photo</div>}
            </div>
            <table><tbody>
                <tr><td><span style={{color:'dodgerblue'}}>{name}</span></td></tr>
                <tr><td><span>{title}</span>|<span>{organization}</span></td></tr>
                <tr><td><span>{address}</span></td></tr>
                <tr><td><span>{city}</span> <span>{state}</span>, <span>{zip}</span></td></tr>
                <tr><td><label style={{fontWeight:'bold'}}>Phone:</label> <span>{workphone}</span></td></tr>
                <tr><td><label style={{fontWeight:'bold'}}>Mobile:</label> <span>{mobile}</span></td></tr>
                <tr><td><label style={{fontWeight:'bold'}}>Email:</label> <span>{email}</span></td></tr>
            </tbody></table>
            <button onClick={loadVCard}><FontAwesomeIcon icon={faAddressCard} style={{margin:'3px 1px'}} /></button>
        </div>
    )
}

export default VCard;
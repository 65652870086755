import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLoaderData } from 'react-router-dom';
import { useCore } from 'contexts/core';

export async function loader({params}) {
    return params;
}

function Redeem() {
    const loaderData = useLoaderData();
    const { runNoAuthAction } = useCore();
    const [ticket, setTicket] = useState(null);
    const [message, setMessage] = useState('Loading ticket...');

    useEffect(() => {
        if (loaderData.tenant) {
            let data = {tenantUUID: loaderData.tenant, id: loaderData.id};
            runNoAuthAction('get_ticket', data, response => {
                if (response.ticket) {
                    setTicket(response.ticket);
                    
                    if (response.ticket.redeemed !== null) {
                        setMessage('This ticket has already been redeemed.');
                    } else if (moment().isBefore(new Date(response.event.startDate))) {
                        setMessage('Tickets cannot be redeemed prior to the event.');
                    } else {
                        setMessage('Click Redeem Ticket to redeem this ticket. Once a ticket has been redeemed, it can not be used again.')
                    }
                } else {
                    setMessage('Failed to load ticket, ticket invalid');
                }
            })
        }
    }, [loaderData.tenant])

    const redeem = () => {
        let data = {...ticket, tenantUUID: loaderData.tenant, redeem: true}
        runNoAuthAction('update_ticket', data, response => {
            setTicket(null);
            if (response.message !== null) {
                setMessage(response.message);
            } else {
                setMessage('There was an error while redeeming the ticket. Call 612-363-0902 for assistance.');
            }
        });
    }

    return (
        <div style={{width:'500px',margin:'auto',textAlign:'center'}}>
            <div style={{padding:'100px 0'}}>{message}</div>
            {ticket &&
                <>
                    {ticket.redeemed === null &&
                        <button onClick={redeem}>Redeem Ticket</button>
                    }
                </>
            }
        </div>
    )
}

export default Redeem;
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import Core from '../../util/core';
import AmazingTable from '../../components/amazingTable';
import SidePanel from 'components/sidePanel';
import Communication from './Communication';
import { TableSetupProvider } from 'contexts/tableSetup';

const flaggedColumns = [
    {id: 'id', label: 'ID', display: false, datum: 'id'},
    {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created).format('YYYY-MM-DD hh:mm:ss a')},
    {id: 'accountid', label: 'Account ID', display: false, datum: 'accountId'},
    {id: 'account', label: 'Account Name', display: true, datum: 'account'},
    {id: 'patternid', label: 'Pattern ID', display: false, datum: 'patternId'},
    {id: 'pattern', label: 'Pattern Name', display: true, datum: 'pattern'},
    {id: 'commid', label: 'Communication ID', display: false, datum: 'commId'},
]

function Flagged() {
    const auth = useAuth();
    const [flaggedList, setFlaggedList] = useState([]);
    const [flagged, setFlagged] = useState(null);
    const [communication, setCommunication] = useState(null);
    const commPanel = useRef(null);

    useEffect(() => {
        getFlagged();
    }, [])

    const getFlagged = () => {
        Core.runAction('get_flagged', {groupId: auth.group.id}, response => {
            setFlaggedList(response.flagged);
        })
    }
    
    const getDetails = (flaggedItem) => {
        let data = {commId: flaggedItem.commId, accountId: flaggedItem.accountId}
        setFlagged(flaggedItem);
        Core.runAction('get_flagged_details', data, response => {
            setCommunication(response.communication);
            commPanel.current.show();
        });
    }

    const messageActionHandler = (action, reason) => {
        let data = {id: flagged.id, groupId: auth.group.id, accountId: flagged.accountId, commId: flagged.commId, messageAction: action};
        if (action === 'reject') {
            data.reason = reason;
        }
        Core.runAction('update_flagged', data, response => {
            setFlagged(null);
            setCommunication(null);
            commPanel.current.hide();
        });
    }

    return (
        <>
            <TableSetupProvider tableName='flagged' defaultColumns={flaggedColumns}>
                <AmazingTable
                    rows={flaggedList}
                    onRefresh={getFlagged}
                    onRowClick={getDetails} />
            </TableSetupProvider>
            <SidePanel title='Communication' ref={commPanel}>
                <Communication comm={communication} onMessageAction={messageActionHandler} />
            </SidePanel>
        </>
    )
}

export default Flagged;

import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Shows from './shows';
import Activities from './activities/activities';
import { ActivitiesProvider } from 'contexts/activities';
import { EventsProvider } from 'contexts/events';
import { faCalendarAlt, faCar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export async function loader({params}) {
    return params;
}

function Events({}) {
    const loaderData = useLoaderData();
    const [view, setView] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (loaderData) {
            setView(loaderData.view ? loaderData.view : null);
        }
    }, [loaderData])

    const getTitle = () => {
        return view === 'shows' ? 'Car Shows/Events' : 'Club Activities';
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Events {view && ': ' + getTitle()}</div>
                </div>
            </div>
            <EventsProvider>
                {!view &&
                    <div className='showLanding'>
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/shows')}>
                            <FontAwesomeIcon icon={faCar} size='6x'/>
                            <h6>Car Shows/Events</h6>
                        </div>
                        <div className='showLandingTile' onClick={() => navigate('/admin/events/activities')}>
                            <FontAwesomeIcon icon={faCalendarAlt} size='6x'/>
                            <h6>Club Activities</h6>
                        </div>
                    </div>
                }
                {view === 'shows' && <Shows showId={loaderData.showId} />}
                {view === 'activities' && <ActivitiesProvider><Activities /></ActivitiesProvider> }
            </EventsProvider>
        </div>
    )
}

export default Events;
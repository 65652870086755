import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../components/loading';
import { useVideo } from '../../contexts/video';
import {connect} from 'twilio-video';
import Core from '../../util/core';
import Participant from './participant';
import PubSub from 'pubsub-js';

function Room() {
    const video = useVideo();
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const roomDiv = useRef(null);

    useEffect(() => {
        if (video.roomSid.length > 0) {
            getToken();
        }
        return () => {
            disconnectFromRoom();
        }
    }, [video.roomSid]);

    const getToken = () => {
        Core.runAction('get_token', {roomSid: video.roomSid}, response => {
            if (response.success) {
                video.updateToken(response.token);
            } else {
                setError(response.errorMessage);
            }
            setLoading(false);
        })
    }

    const disconnectFromRoom = () => {
        if (room) {
            room.disconnect();
        }
    }

    const connectToRoom = () => {
        if (video.localTracks === null) {
            PubSub.publish('warning', 'You must first create your tracks')
            return;
        }
        connect(video.token, {audio: true, name: video.roomName, tracks: video.localTracks})
        .then( room => {
            setRoom(room);
            console.log(`successfully joined room ${room.name} state:${room.state}`);
            // events
            // disconnected/reconnecting/participantReconnecting/reconnected/participantReconnected/
            // when the room is disconnected, remove the tracks
            room.on('disconnected', room => {
                console.log(`room disconnected ${room.name} state:${room.state}`)
                //video.updateRoomSid('');
                //video.updateToken('');
                //setRoom(null);
            });
            room.on('dominantSpeakerChanged', participant => {
                console.log(`dominant speaker is now ${participant.identity}`);
            })
            // when a participant connects
            room.on('participantConnected', participant => {
                console.log(`a remote participant connected ${participant.identity}`);
                setParticipants(participants.concat(participant));
            });
            // when a participant disconnects
            room.on('participantDisconnected', participant => {
                console.log(`a remote participant disconnected ${participant.identity}`);
                setParticipants(participants.filter(p => p.SID !== participant.SID));
            });
            room.on('participantReconnected', participant => {
                console.log(`a remote participant reconnected ${participant.identity}`);
            });
            room.on('participantReconnecting', participant => {
                console.log(`a remote participant is reconnecting ${participant.identity}`);
            });
            room.on('reconnected', () => {
                console.log(`the room is reconnected`);
            });
            room.on('reconnecting', () => {
                console.log(`the room is reconnecting`);
            });
            room.on('recordingStarted', () => {
                console.log(`the room recording has started`);
            });
            room.on('recordingStopped', () => {
                console.log(`the room recording has stopped`);
            });
            room.on('trackDimensionsChanged', (track, participant) => {
                console.log(`the track dimensions have changed ${participant.identity}`);
            });

            // for all the participants currently in the room
            let participantArray = [];
            room.participants.forEach(participant => {
                participantArray.push(participant)
            });
            setParticipants(participantArray);
        }, error => {
            console.error(`unable to connect to room ${error.message}`);
            setError(error.message);
        })
    }

    return (
        <React.Fragment>
            {loading && <Loading />}
            {error && <p>Error: {error}</p>}
            {(video.roomSid.length > 0 && video.token.length > 0) && 
                <React.Fragment>
                    <div style={{border:'1px solid'}}>
                        <div style={{width:'640px',height:'480px'}} ref={roomDiv}></div>
                    </div>
                    <button onClick={connectToRoom} disabled={video.token.length === 0}>Connect To Room</button>
                    <button onClick={disconnectFromRoom}>Leave room</button>
                    <div>
                        <ul>
                            {
                                participants.map((participant, idx) => <li key={idx}><Participant participant={participant} /></li>)
                            }
                        </ul>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Room;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const PricingTeirsContext = React.createContext(null);

function PricingTeirsProvider({memberTypeId, children}) {
    const { runAction } = useCore();
    const [pricingTeirs, setPricingTeirs] = useState([]);

    useEffect(() => {
        loadPricingTeirs();
    }, [])

    const loadPricingTeirs = () => {
        let data = {memberTypeId}
        runAction('get_membertype_pricing_teirs', data, (response) => {
            setPricingTeirs(response.memberTypePricingTeirs);
        });
    }

    const savePricingTeir = (data) => {
        const action = data.id ? 'update_membertype_pricing_teir' : 'create_membertype_pricing_teir';
        runAction(action, {memberTypeId, ...data}, () => {
            loadPricingTeirs();
        });
    }

    const deleteTeirs = (teirIds) => {
        let data = {ids: teirIds}
        runAction('delete_membertype_pricing_teir', data, () => {
            loadPricingTeirs();
        })
    }

    const provider = {
        pricingTeirs,
        loadPricingTeirs,
        savePricingTeir,
        deleteTeirs
    }

    return <PricingTeirsContext.Provider value={provider}>{children}</PricingTeirsContext.Provider>
}

function usePricingTeirs() {
    const context = React.useContext(PricingTeirsContext);
    if (!context) {
        throw new Error('usePricingTeirs must be used within a PricingTeirsProvider');
    }
    return context;
}

export { PricingTeirsProvider , usePricingTeirs }
import React from 'react';
import { faCircle as faOpenCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RadioItem(props) {

    return (
        <li>
            <FontAwesomeIcon icon={props.selected ? faSolidCircle : faOpenCircle} onClick={() => props.onClick(props.option)} />
            <div className='radioLabel'>{props.option.label}</div>
        </li>
    )
} 

export default RadioItem;
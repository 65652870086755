import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const TrackerContext = React.createContext(null);

function TrackerProvider({campaignUuid, children}) {
    const { runAction } = useCore();
    const [viewsLoading, setViewsLoading] = useState(true);
    const [views, setViews] = useState([]);

    useEffect(() => {
        getUserViews();
    }, [])

    const getUserViews = () => {
        setViewsLoading(true);
        runAction('get_user_trackerviews', {campaign: campaignUuid}, response => {
            if (response) {
                setViews(response.stats);
            }
            setViewsLoading(false);
        });
    }

    const provider = {
        viewsLoading,
        views,
        getUserViews,
    }

    return <TrackerContext.Provider value={provider}>{children}</TrackerContext.Provider>
}

function useTracker() {
    const context = React.useContext(TrackerContext);
    if (!context) {
        throw new Error('useTracker must be used withing a TrackerProvider');
    }
    return context;
}

export { TrackerProvider, useTracker }
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const StoreContext = React.createContext(null);

function StoreProvider({children}) {
    const { runAction } = useCore();
    const [storeLoading, setStoreLoading] = useState(true);
    const [products, setProducts] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        loadStore();
    }, [])

    const loadStore = () => {
        setStoreLoading(true);
        runAction('get_store', {}, (response) => {
            setProducts(response.products);
            setCategories(response.categories);
            setStoreLoading(false);
        });
    }

    const createProduct = (data, callback) => {
        runAction('create_product', data, () => {
            loadStore();
            callback();
        })
    }

    const updateProduct = (data, callback) => {
        runAction('update_product', data, () => {
            loadStore();
            callback();
        })
    }

    const deleteProducts = (ids) => {
        runAction('delete_product', {ids}, () => {
            loadStore();
        });
    }

    const createCategory = (data, callback) => {
        runAction('create_category', data, () => {
            loadStore();
            callback();
        })
    }

    const provider = {
        storeLoading, loadStore,
        products,
        categories,
        createProduct, updateProduct, deleteProducts,
        createCategory,
    }

    return <StoreContext.Provider value={provider}>{children}</StoreContext.Provider>
}

function useStore() {
    const context = React.useContext(StoreContext);
    return context;
}

export { StoreProvider, useStore }
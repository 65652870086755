import React, {useState} from 'react';
import CurrencyInput from 'react-currency-input-field';

function Inventory({item, onSave}) {
    const [id] = useState(item.id || null)
    const [name, setName] = useState(item.name);
    const [quantity, setQuantity] = useState(item.quantity);
    const [price, setPrice] = useState(item.price);
    const [shipping, setShipping] = useState(item.shipping);
    const [discount, setDiscount] = useState(item.discount);

    const save = () => {
        let data = {
            name: name,
            quantity: quantity,
            price: price,
            shipping: shipping,
            discount: discount,
            available: Date.now()
        }
        if (id !== null) {
            data.id = id;
        }
        onSave(data);
    }

    return (
        <div>
            <div className='contact-preview'>
                <label>Label</label>
                <input type='text' value={name} onChange={evt => setName(evt.target.value)} placeholder='Required' maxLength='64' />
                <label>Quantity</label>
                <CurrencyInput allowDecimals={false} allowNegativeValue={false} value={quantity} onValueChange={(value) => setQuantity(value)} />
                <label>Price</label>
                <CurrencyInput prefix='$' value={price} onValueChange={(value) => setPrice(value)} />
                <label>Shipping</label>
                <CurrencyInput prefix='$' value={shipping} onValueChange={(value) => setShipping(value)} />
                <label>Discount</label>
                <CurrencyInput prefix='$' value={discount} onValueChange={(value) => setDiscount(value)} />
            </div>
            <div className='buttonsHolder'>
                <button onClick={save} data-testid='save_button'>Save</button>
            </div>
        </div>
    )
}

export default Inventory
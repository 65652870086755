import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loading from 'components/loading';
import Core from 'util/core';
import { formatCurrency } from '_base';
import { useLoaderData } from 'react-router-dom';
import 'style/Tickets.scss';

export async function loader({params}) {
    return params;
}

function Tickets() {
    const loaderData = useLoaderData();
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTickets();
    }, [loaderData.event])

    const getTickets = () => {
        let data = {event: loaderData.event}
        Core.runAction('get_tickets', data, (response) => {
            setTickets(response.tickets);
            setLoading(false);
        })
    }

    return (
        <div>{loading ? <Loading /> : (tickets.length > 0 ? tickets.map(t => <TicketGroup key={t.id} eventTicket={t} />) : 'You have no tickets')}</div>
    )
}

export default Tickets;

function TicketGroup({eventTicket}) {
    return (
        <div className='ticket-group'>
            <div className='ticket-group-heading'>
                <div>{eventTicket.tickets.length} : {eventTicket.description}</div>
                <div>{formatCurrency(eventTicket.price)}</div>
            </div>
            <ul>
                {eventTicket.tickets.length > 0 ?
                eventTicket.tickets.map(t => 
                    <li key={t.id}>
                        <div>{`${t.name} (${t.email})`}</div>
                        <div>{`Purchased ${moment(t.purchased).format('YYYY-MM-DD')}`}</div>
                    </li>
                ) : 'No tickets have been purchased'}
            </ul>
        </div>
    )
}
import React, { useRef } from 'react';
import { useSettings } from '../../contexts/settings';
import PubSub from 'pubsub-js';
import {isSupported} from '../../util/contentType';
import logo from 'assets/logo-white.png';

function AccountTheme() {
    const settings = useSettings();
    const fileInput = useRef(null);
    const imageCanvas = useRef(null);

    const updateSettings = (key, value) => {
        let groupSettings = {...settings.group};
        groupSettings[key] = value;
        settings.updateGroupSettings(groupSettings);
    }

    const handleFileSelection = (evt) => {
        var files = evt.target.files;
		// eslint-disable-next-line no-cond-assign
        for (var i = 0, f; f = files[i]; i++) {
            var name = escape(f.name);
            console.log("File Info: " 
                    + "Name: " + name + ", " 
                    + "Type: " + (f.type || 'n/a') + ", "
                    + "Size: " + f.size + " bytes, " 
                    + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
            if (isSupported(f.type)) {
                var img = new Image();
                // eslint-disable-next-line no-loop-func
                img.onload = () => placeImageOnCanvas(img);
                img.crossOrigin = 'Anonymous';
                img.src = URL.createObjectURL(f);
            } else {
                PubSub.publish('warning', f.name + ' is not a supported image type.');
            }
        }
    }

    const selectImage = () => {
        fileInput.current.click();
    }

    const placeImageOnCanvas = (imageObj) => {
        let imgCanvas = imageCanvas.current;
        var context = imgCanvas.getContext('2d');
        context.clearRect(0, 0, imgCanvas.width, imgCanvas.height);
        
        var imageAspectRatio = imageObj.width / imageObj.height;
        var canvasAspectRatio = imgCanvas.width / imgCanvas.height;
        var renderableHeight, renderableWidth, xStart, yStart;

        // If image's aspect ratio is less than canvas's we fit on height
        // and place the image centrally along width
        if(imageAspectRatio < canvasAspectRatio) {
            renderableHeight = imgCanvas.height;
            renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
            xStart = (imgCanvas.width - renderableWidth) / 2;
            yStart = 0;
        }
        // If image's aspect ratio is greater than canvas's we fit on width
        // and place the image centrally along height
        else if(imageAspectRatio > canvasAspectRatio) {
            renderableWidth = imgCanvas.width
            renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
            xStart = 0;
            yStart = (imgCanvas.height - renderableHeight) / 2;
        }

        // Happy path - keep aspect ratio
        else {
            renderableHeight = imgCanvas.height;
            renderableWidth = imgCanvas.width;
            xStart = 0;
            yStart = 0;
        }
        context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
        // add the image to the images array
        let image = imgCanvas.toDataURL("image/png", 1.0);
        updateSettings('logo', image);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            {settings.group &&
            <>
                <div className='settingsSection'>
                    <div style={{textAlign:'center'}}><h3>Header Color</h3></div>
                    <p>Override the Middletwin header color by setting the color here. Use any valid css color including hex color values (preferred).</p>
                    <label>Header Color</label>
                    <input type='text' value={settings.group.headerColor} onChange={e => updateSettings('headerColor', e.target.value)} />
                </div>
                <div className='settingsSection'>
                    <div style={{textAlign:'center'}}><h3>Text Color</h3></div>
                    <p>Override the Middletwin text color by setting the color here. Use any valid css color including hex color values (preferred).</p>
                    <label>Text Color</label>
                    <input type='text' value={settings.group.textColor} onChange={e => updateSettings('textColor', e.target.value)} />
                </div>
                <div className='settingsSection'>
                    <div style={{textAlign:'center'}}><h3>Group Logo</h3></div>
                    <p>Image should be PNG format and no larger than 100 pixels wide by 18. The Middletwin logo will be displayed if none is set.</p>
                    <input type='file' style={{display:'none'}} ref={fileInput} onChange={handleFileSelection} />
                    <label>Group logo</label>
                    <div style={{margin:'10px',border:'1px solid gray',width:'100px'}}>
                        <div className='image-canvas'>
                            <canvas width='100' height='18' ref={imageCanvas} style={{backgroundImage:`url( ${settings.group.logo} )`}}></canvas>
                        </div>
                    </div>
                    <div>
                        <button className='linkButton' onClick={selectImage}>Change Image</button>
                    </div>
                </div>
                <div>
                    <label>Preview</label>
                    <div className='main-container' style={{width:'400px',height:'120px',border:'1px solid black'}}>
                        <div className='top-bar' style={{backgroundColor: settings.group.headerColor,color: settings.group.textColor}}>
                            <div className='top-bar-inner'>
                                <div className='top-bar-group'>
                                    <div className='top-bar-logo'>
                                        {settings.group.logo !== ''
                                            ? <img src={settings.group.logo} alt='Group Logo'/>
                                            : <img src={logo} alt='Middletwin Logo'/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='top-bar-apps'>
                                <div className='app-buttons'>
                                    <div className='app-button selected'>Dashboard</div>
                                    <div className='app-button'>Members</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    )
}

export default AccountTheme;
import React, { useState } from 'react';
import { useSettings } from '../../contexts/settings';
import Toggle from '../../components/toggle';

function SMSConsent() {
    const settings = useSettings();
    const [enabled, setEnabled] = useState(settings.group.consentEnabled);
    const [consentMessage, setConsentMessage] = useState(settings.group.consentMsg);

    const save = () => {
        let groupSettings = {...settings.group, consentEnabled: enabled, consentMsg: consentMessage};
        settings.updateGroupSettings(groupSettings);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
			<div style={{textAlign:'center'}}><h3>SMS Positive Consent</h3></div>
            <p>Optional: A positive consent message is a message that must be sent to any members to enable communication.
		        The recipient must reply to the message with the keyword &quot;ACCEPT&quot; in order for the member to receive 
		        any other messages.</p>
            <label>Consent Enabled</label>
            <div style={{display:'flex'}}>Disabled <Toggle checked={enabled} onChange={val => setEnabled(val)} /> Enabled</div>
            <label>Consent Message</label>
            <textarea style={{width:'100%',height:'200px'}} value={consentMessage} onChange={e => setConsentMessage(e.target.value)} maxLength='2048'></textarea>
			<button onClick={save}>Save</button>
        </div>
    )
}

export default SMSConsent;
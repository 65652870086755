import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import {createLocalTracks} from 'twilio-video';
import { useVideo } from '../../contexts/video';
import './video.scss';

function LocalTracks() {
    const video = useVideo();
    const [myTracks, setMyTracks] = useState(null);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const localTracksDiv = useRef(null);

    useEffect(() => {
        createLocalTrack();
        return () => {
            destroyLocalTrack();
        }
    }, [])

    const createLocalTrack = () => {
        createLocalTracks({audio: true, video:{width:320}}).then(tracks => {
            setMyTracks(tracks);
            video.updateLocalTracks(tracks);
            tracks.forEach(track => {
                localTracksDiv.current.appendChild(track.attach());
            })
        })
    }

    const destroyLocalTrack = () => {
        if (myTracks) {
            myTracks.forEach(track => {
                video.updateLocalTracks(null);
                const attachedElements = track.detach();
                attachedElements.forEach(elem => elem.remove());
                track.stop();
            });
        }
    }

    const toggleTrack = (type) => {
        if (myTracks) {
            let isEnabled = true;
            myTracks.forEach(track => {
                if (track.kind === type) {
                    if (track.isEnabled) {
                        track.disable();
                        isEnabled = false;
                    } else {
                        track.enable();
                    }
                }
            });
            if (type === 'audio') {
                setAudioEnabled(isEnabled);
            } else {
                setVideoEnabled(isEnabled);
            }
        }
    }

    return (
        <React.Fragment>
            <div className='video-track'>
                <div className='video-area' ref={localTracksDiv}></div>
                {myTracks &&
                    <div className='round-button-area'>
                        <button onClick={() => toggleTrack('audio')}><FontAwesomeIcon icon={audioEnabled ? faMicrophoneSlash : faMicrophone} /></button>
                        <button onClick={() => toggleTrack('video')}><FontAwesomeIcon icon={videoEnabled ? faVideoSlash : faVideo} /></button>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default LocalTracks;
import AmazingTable from 'components/amazingTable';
import { TrackerProvider, useTracker } from 'contexts/tracker';
import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen, faPercent } from '@fortawesome/pro-regular-svg-icons';
import './billing/Finance.scss';
import Loading from 'components/loading';
import { TableSetupProvider } from 'contexts/tableSetup';

export async function loader({params}) {
    return params;
}

function EmailStats() {
    const loaderData = useLoaderData();
    const [campaignUuid, setCampaignUuid] = useState(null);

    useEffect(() => {
        if (loaderData) {
            setCampaignUuid(loaderData.campaign);
        }
    }, [loaderData])

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Campaign statistics</div>
                </div>
            </div>
            {campaignUuid &&
                <TrackerProvider campaignUuid={campaignUuid}>
                    <UserViews />
                </TrackerProvider>
            }
        </div>
    )
}

function UserViews() {
    const { viewsLoading, views } = useTracker();

    const columns = [
        { id: 'email', label: 'Email', display: true, datum: 'key' },
        { id: 'views', label: 'Views', display: true, datum: 'value' },
    ]

    return (
        <div className='transactions-outer'>
            {viewsLoading ? <Loading /> :
                <div className='snapshot-area'>
                    <div className='snapshot'>
                        <div className='total'>
                            <div className='total-icon'><FontAwesomeIcon icon={faEnvelope}/></div>
                            <div className='total-label-and-ammount'>
                                <div className='total-label'>Total</div>
                                <div className='total-ammount'>{views.length}</div>
                            </div>
                        </div>
                        <div className='total'>
                            <div className='total-icon'><FontAwesomeIcon icon={faEnvelopeOpen}/></div>
                            <div className='total-label-and-ammount'>
                                <div className='total-label'>Opened</div>
                                <div className='total-ammount'>{views.reduce((a, b) => {return a+((b.value > 0) ? 1 : 0)}, 0)}</div>
                            </div>
                        </div>
                        <div className='total'>
                            <div className='total-icon'><FontAwesomeIcon icon={faPercent}/></div>
                            <div className='total-label-and-ammount'>
                                <div className='total-label'>Open rate</div>
                                <div className='total-ammount'>{Math.round((views.reduce((a, b) => {return a+(b.value > 0 ? 1 : 0)}, 0) / views.length) * 100)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <TableSetupProvider tableName='emailstats' defaultColumns={columns}>
                <AmazingTable 
                    rows={views}
                    loading={viewsLoading}
                />
            </TableSetupProvider>
        </div>
    )
}

export default EmailStats;
import React, { useState } from 'react';
import 'style/pages.scss';
import { PageContentProvider } from 'routes/pages/context/pageContent';
import { usePage } from 'contexts/page';
import moment from 'moment';
import AmazingTable from 'components/amazingTable';
import { TableSetupProvider } from 'contexts/tableSetup';
import DetailsPanel from 'components/detailsPanel';
import PageDetails from './pageDetails';
import Modal from 'components/modal';
import NewPageSelector from './newPageSelector';

function Pages() {
    const { pagesLoading, pages, getPages, savePage, deletePages } = usePage();
    const [showAddPageDialog, setShowAddPageDialog] = useState(false);
    const [page, setPage] = useState(null);

    const columns = [
        {id: 'id', label:'ID', display: false, datum: 'id'},
        {id: 'created', label: 'Created', display: false, formatter: obj => moment(obj.created).format('YYYY-MM-DD')},
        {id: 'name', label: 'Name', display: true, datum: 'name'},
        {id: 'type', label: 'Type', display: true, formatter: obj => obj.type < 3 ? 'Web' : 'Registration', style:{width:'100px'}},
    ]

    const createPageHandler = (newPage) => {
        setPage(newPage);
        setShowAddPageDialog(false);
    }
    const editPageHandler = (row) => {
        setPage(row);
    }
    const hidePageHandler = () => {
        setPage(null);
    }

    const savePageHandler = (data) => {
        console.log(data);
        const combinedData = {...page, ...data};
        console.log(combinedData);
        savePage(combinedData);
        setPage(null);
    }

    const deletePagesHandler = (rows) => {
        const ids = rows.map(p => p.id);
        deletePages(ids);
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Pages</div>
                </div>
            </div>
            <TableSetupProvider tableName='pages' defaultColumns={columns}>
                <AmazingTable 
                    loading={pagesLoading}
                    rows={pages}
                    onRefresh={getPages}
                    onCreate={() => setShowAddPageDialog(true)}
                    onCreateLabel='Add page'
                    onRowClick={editPageHandler}
                    onDelete={deletePagesHandler}
                />
            </TableSetupProvider>
            <DetailsPanel title='Page editor' showing={page !== null} onHide={hidePageHandler}>
                {page !== null &&  <PageContentProvider page={page}><PageDetails onSave={savePageHandler} onHide={hidePageHandler} /></PageContentProvider>}
            </DetailsPanel>
            <Modal title='Add page' showing={showAddPageDialog} onHide={() => setShowAddPageDialog(false)}>
                <NewPageSelector onSelect={createPageHandler}/>
                <p>Select the type of page you want to add.</p>
            </Modal>
        </div>
    )
}


export default Pages;
import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const FeaturesContext = React.createContext(null);

function FeaturesProvider({children}) {
    const { runAction } = useCore();
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        getFeatures();
    }, []);

    const getFeatures = () => {
        runAction('get_features', {},
            (response) => {
                if (response) {
                    setFeatures(response.features);
                }
            });
    }

    const provider = {
        features,
    }

    return <FeaturesContext.Provider value={provider}>{children}</FeaturesContext.Provider>
}

function useFeatures() {
    const context = React.useContext(FeaturesContext);
    if (!context) {
        throw new Error('useFeatures must be used within a FeaturesProvider');
    }
    return context;
}

export { FeaturesProvider, useFeatures }
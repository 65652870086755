import React from 'react';
import Toggle from '../../components/toggle';
import { useSettings } from '../../contexts/settings';

function AccountControls() {
    const settings = useSettings();

    const updateSetting = (key, value) => {
        let groupSettings = {...settings.group};
        groupSettings[key] = value;
        settings.updateGroupSettings(groupSettings);
    }

    return (
        <div style={{maxWidth:'768px',padding:'20px 40px'}}>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Browser Inactivity Timeout</h3></div>
                <p>The browser inactivity timeout is an automatic logout timer that occurs after the defined number of minutes of inactivity in the web browser. 
                By Default account users can disable this timeout, allowing them to stay logged in all day so that they can reliably receive 
                browser notifications for new incoming SMS messages, just like a new email notification.</p>
                <p style={{fontSize:'larger',textAlign:'center'}}>WE STRONGLY DISCOURAGE DISABLING THIS!!!</p>
                <p>Changes to this take effect after next login for the account.</p>
                <label>Timeout</label>
                <div><input type='text' style={{width:'100px'}} value={settings.group.inactivityTimeout} onChange={e => updateSetting('inactivityTimeout', e.target.value)} placeholder='(10-1440)' /> Minutes</div>
                <label>Allow disabling inactivity timeout</label>
                <div style={{display:'flex'}}>Disabled <Toggle checked={settings.group.allowInactivityDisable} onChange={val => updateSetting('allowInactivityDisable', val)} /> Enabled</div>
            </div>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Login Review</h3></div>
                <p>If you want to have additional logins that are added to the account placed into a review state, then you can turn on
                the login review feature here. The group admin will receive an email when a new login is added to an account and will
                need to login to this admin portal, locate the account, review the login, and approve the login before it can be used.</p>
                <label>Login Review</label>
                <div style={{display:'flex'}}>Disabled <Toggle checked={settings.group.reviewAccountLogins} onChange={val => updateSetting('reviewAccountLogins', val)} /> Enabled</div>
            </div>
            <div className='settingsSection'>
                <div style={{textAlign:'center'}}><h3>Account Messaging</h3></div>
                <p>By turning on account messaging, account users will be able to find other accounts in this group by searching by name, phone number, or Middletwin number,
                the same way they search for contacts from their CRM connection. This is seemless to the account users.</p>
                <label>Account Messaging</label> 
                <div style={{display:'flex'}}>Disabled <Toggle checked={settings.group.accountMessaging} onChange={val => updateSetting('accountMessaging', val)} /> Enabled</div>
            </div>
        </div>
    )
}

export default AccountControls;
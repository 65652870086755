import React, { useEffect, useState, useRef } from 'react';
import Core from '../../../util/core';
import AmazingTable from '../../../components/amazingTable';
import SidePanel from 'components/sidePanel';
import Workflow from './workflow';
import PubSub from 'pubsub-js';
import { formatPhoneNumber } from '../../../_base';
import { TableSetupProvider } from 'contexts/tableSetup';

const columns = [
    {id: 'id', label:'ID', display: false, datum: 'id'},
    {id: 'request', label:'Request', display: true, datum: 'request'},
    {id: 'requestValue', label:'Request Value', display: true, 
        formatter:(obj) => {return obj.request === 'incomingVoice' ? formatPhoneNumber(JSON.parse(obj.requestValue).phone) : obj.requestValue}},
    {id: 'response', label:'Response', display: true, datum: 'response'},
    {id: 'responseValue', label:'Response Value', display: true, 
        formatter:(obj) => {return obj.response === 'forwardTo' ? formatPhoneNumber(JSON.parse(obj.responseValue).phone): obj.responseValue}},
    {id: 'ordinal', label:'Order', display: true, datum: 'Ordinal'}
];

function Workflows() {
    const [workflows, setWorkflows] = useState([]);
    const [workflow, setWorkflow] = useState(null);
    const workflowPanel = useRef(null);

    useEffect(() => {
        getWorkflows();
    }, [])

    const getWorkflows = () => {
        Core.runAction('get_workflows', {}, response => {
            setWorkflows(response.workflows);
        });
    }

    const deleteWorkflows = (wflows) => {
        Core.runAction('delete_workflow', {id: wflows[0].id}, response => {
            PubSub.publish('success', 'Auto response deleted');
            getWorkflows();
        });
    }

    const saveWorkflowHandler = (wflow) => {
        let action = 'create_workflow';
        if (workflow) {
            action = 'update_workflow';
            wflow.id = workflow.id;
            wflow.previousId = workflow.previousId;
        }
        Core.runAction(action, {...wflow}, ()=> {
            PubSub.publish('success', 'Auto response saved');
            workflowPanel.current.hide();
            setWorkflow(null);
            getWorkflows();
        })
    }

    const selectWorkflow = (wflow) => {
        setWorkflow(wflow);
        workflowPanel.current.show();
    }

    return (
        <>
            <TableSetupProvider tableName='workflows' defaultColumns={columns}>
                <AmazingTable 
                    rows={workflows}
                    onRefresh={getWorkflows}
                    onDelete={deleteWorkflows}
                    onCreate={() => workflowPanel.current.show()}
                    onRowClick={selectWorkflow}
                />
            </TableSetupProvider>
            <SidePanel title='Auto Route' ref={workflowPanel}>
                <Workflow workflow={workflow} onSave={saveWorkflowHandler} />
            </SidePanel>
        </>
    )
}

export default Workflows;
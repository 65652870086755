import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SubscriptionContext = React.createContext(null);

function SubscriptionProvider({tenantId = null, children}) {
    const { runAction } = useCore();
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        getSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSubscription = () => {
        setSubscriptionLoading(true);
        const data = tenantId ? {tenant: tenantId} : {};
        runAction('get_subscription', data, (response) => {
            setSubscription(response.subscription);
            setSubscriptionLoading(false);
        })
    }

    const add1Month = () => {
        let data = {id: subscription.id, renew: 'month'};
        runAction('update_subscription', data, (response) => {
            getSubscription();
        })
    }

    const add1Year = () => {
        let data = {id: subscription.id, renew: 'year'};
        runAction('update_subscription', data, (response) => {
            getSubscription();
        })
    }
    
    const saveSubscription = (settings) => {
        const data = {tenant: tenantId, id: subscription.id, ...settings};
        runAction('update_subscription', data, (response) => {
            getSubscription();
        })
    }

    const provider = {
        subscriptionLoading,
        subscription,
        add1Month,
        add1Year,
        saveSubscription
    }

    return <SubscriptionContext.Provider value={provider}>{children}</SubscriptionContext.Provider>
}

function useSubscription() {
    const context = React.useContext(SubscriptionContext);
    if (!context) {
        throw new Error('useSubscription must be used within a SubscriptionProvider');
    }
    return context;
}

export { SubscriptionProvider, useSubscription }